import React, {useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {GET_KONTAKTDETAILS, KONTAKTENTFERNEN, KONTAKTSYNCHRONISIEREN} from "../Queries";
import {Newsletter2} from "./Newsletter";
import {
    Kontakt_ActiveCampaign_Id_soll_entfernt_werden, Kontakt_ActiveCampaign_Sync_anfordern,

} from "../Daten/CheckinMessages.gen";
import {DataLine, Loading, TH} from "../components";

export const Kontaktdetails2 = () => {
    const [kontakt, setKontakt] = useState();

    let {id} = useParams();
    const {refetch, loading: loading} = useQuery(GET_KONTAKTDETAILS,{
        variables:{id: id},
        onCompleted:(data) => {
            if(data){
                if(data && data.kontakte){
                    setKontakt(data.kontakte[0])
                }
            }
        },
        onError:(error) => {
            alert("Beim Aufruf des Kontakts ist ein Fehler aufgetreten: " + error.message)
        },
        fetchPolicy : "network-only"
    });

    const [entferneK,
        {loading: reLoading},
    ] = useMutation(KONTAKTENTFERNEN, {
        variables: {id},
        onCompleted: (data) => {
            if (data[Kontakt_ActiveCampaign_Id_soll_entfernt_werden.messageName] === "ok") {
                //
            }
        }
    });


   const  removeKontakt = async() => {
        let message = new Kontakt_ActiveCampaign_Id_soll_entfernt_werden(id);
       entferneK({variables: {message: JSON.stringify(message)}}).then(r => {
           if(r.data.Kontakt_ActiveCampaign_Id_soll_entfernt_werden === "ok")
              // location.reload()
           refetch({id}).then(r => {
               setKontakt(r.data.kontakte[0])

           })
       })
    }

    const [syncAnfordern,
        {loading:syncloading},
    ] = useMutation(KONTAKTSYNCHRONISIEREN, {
        variables: {id},
        onCompleted: (data) => {
            if (data[Kontakt_ActiveCampaign_Sync_anfordern.messageName] === "ok") {
                //
            }
        }
    });



   const  resync = () =>{
       const message = new Kontakt_ActiveCampaign_Sync_anfordern(id);

       syncAnfordern({variables: {message: JSON.stringify(message)}}).then(r => {
          if(r.data.Kontakt_ActiveCampaign_Sync_anfordern === "ok"){
              setTimeout( () => {
                  refetch({id}).then((result) => {
                     setKontakt(result.data.kontakte[0])
                  })
              }, 10000);
          }

       })


    }


   return <div className="kontakt-container">
       {loading || syncloading && <Loading />}
        <h2 className="heading">{kontakt?.anrede?.text}  {kontakt?.vorname} {kontakt?.nachname}</h2>
        <table className="w-full alternating-table">
            <tr>
                <th>Firma</th>
                <td>{kontakt?.firma}</td>
            </tr>
            <tr>
                <th>E-Mail</th>
                <td><a href={"mailto:" + kontakt?.email}
                       className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">{kontakt?.email}</a></td>
            </tr>
            <tr>
                <th>Telefon</th>
                <td>
                    {kontakt?.telefon ?
                        <a href={"tel:" + kontakt.telefon}
                           className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">{kontakt.telefon}</a> : "-"}
                </td>
            </tr>
            <tr>
                <th>Newsletter</th>
                <td>  <Newsletter2 kontaktId={kontakt?.Id} newsLetter={kontakt?.newsletter} /> </td>

            </tr>
            <tr>
                <th>Active Campaign</th>
               <td>  {(syncloading || loading || reLoading) && <Loading />} {(kontakt && kontakt.activeCampaignId) ?
               <div> <a href={"https://dasscrumteam.activehosted.com/app/contacts/" + kontakt?.activeCampaignId}
                   target="_blank" className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">zum Kontakt</a>
                 <button type={"submit"}  className="btn btn-gruen" onClick={() => removeKontakt()}>Verknüpfung löschen</button> </div>
                :
                    <button type={"submit"}  className="btn btn-gruen" onClick={() => resync()}>Verknüpfen</button> }


               </td>

            </tr>
        </table>

       <div><tr> <TH>Buchungen</TH></tr>
           <TH>Kurse:</TH>
           <ul>
               {kontakt?.buchungen.map(buchungen => {
                   return  <li key={buchungen?.Id}>
                   <div className={"inline-flex"}>
                       <Link to={"/kurstermine/" + buchungen.kurstermin.Id} className={"text-dst-logo-gruen-hinten  hover:text-dst-logo-gruen-mitte"}>{buchungen.kurstermin.kursCode} </Link>

                   </div>
                   </li> }
               )}
           </ul>

       </div>


<div><tr><TH>Teilnahmen</TH></tr>
    <TH>Kurse:</TH>
    <ul>
        {kontakt?.teilnahmen.map(teilnahmen => {
            return  <li key={teilnahmen?.Id}>
                <div className={"inline-flex"}>
                 <ul> <li>  <Link to={"/kurstermine/" + teilnahmen?.kurstermin?.Id} className={"text-dst-logo-gruen-hinten  hover:text-dst-logo-gruen-mitte"}>{teilnahmen.kurstermin.kursCode} </Link> </li>

            <li>   <Link to={"/kurstermine/" + teilnahmen?.kurstermin?.Id + "/buchung/" + teilnahmen?.Id}
                          className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">zur Buchung</Link> </li>
            <li>    <Link to={"/kontakte/" + teilnahmen?.bucher?.Id} className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">{teilnahmen?.bucher?.vorname} {teilnahmen?.bucher?.nachname}</Link> {teilnahmen?.bucher?.firma}</li>
                 </ul> </div>
            </li> }
        )}
        <li>   <Link to={"/kurstermine/" + kontakt?.buchungen[0]?.kurstermin.Id} className={"text-dst-logo-gruen-hinten  hover:text-dst-logo-gruen-mitte"}>{kontakt?.buchungen[0]?.kurstermin.kursCode} </Link></li>

            <li>   <TH>Teilnehmerzahl: {" "+(kontakt?.buchungen[0]?.teilnehmerzahl)? kontakt?.buchungen[0]?.teilnehmerzahl : ""}</TH>

        </li>
    </ul>


</div>


       <div>
           <h3>{kontakt?.moeglicheDuplikate.length} mögliche Duplikat(e) von {kontakt?.vorname} {kontakt?.nachname}:</h3>
           <ol>
               {kontakt?.moeglicheDuplikate.map(k => {
                       return <li>
                           <a href={"/kontakte/" + k.Id}>
                               { k.vorname} {k.nachname} {k.email}
                           </a>
                       </li>})}
           </ol>
       </div>
    </div>


}