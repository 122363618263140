import React, {useState} from "react"
import {gql, useQuery} from "@apollo/client";
import {
    Kurstermin_Status_soll_Geplant_werden,
    Kurstermin_Status_soll_Veroeffentlicht_werden,
    Kurstermin_Status_soll_Wenige_Restplaetze_werden,
    Kurstermin_Status_soll_Ausverkauft_werden,
    Kurstermin_Status_soll_Durchgefuehrt_werden,
    Kurstermin_Status_soll_Abgeschlossen_werden,
    Kurstermin_Status_soll_Abgebrochen_werden, Kurstermin_soll_abgesagt_werden,
} from "../Daten/FlugplanMessages.gen"


export const GET_KURSTERMIN_STATUS = gql`query kurstermin($id: String){kurstermine(Id: $id){
    status{
        text,
        transitions{text}
    },
    inListe,
    oeffentlich,
    teilnehmerKreis,
    buchungen { count },
    teilnehmer { count },
    teilnahmeModus { text },
    Id,
    sprache: spracheTyp {
                code
    },
    moodleTermin
}}`

export const useKursStatus = ({id, onStatusChanged}) => {

    const [kursTerminInfo, setKursTerminInfo] = useState()

    const {loading, refetch, client} = useQuery(GET_KURSTERMIN_STATUS,
        {
            variables: {id},
            onCompleted: (data) => {
                if (data && data.kurstermine && data.kurstermine.length > 0) {
                    setKursTerminInfo(data.kurstermine[0])
                }
                if (onStatusChanged) {
                    onStatusChanged(data.kurstermine[0])
                }
            }
        }
    )

    async function send(message) {
        try {
            const mutation = gql(`mutation mut($message: String!){${message.constructor.messageName}(message:$message)}`)
            let result = await client.mutate({
                mutation,
                variables: {message: JSON.stringify(message)},

            })
            const res = result.data[message.constructor.messageName]
            if (res === "ok") {
                refetch({id}).then(r => {
                    setKursTerminInfo(r.data.kurstermine[0])
                    onStatusChanged(r.data.kurstermine[0])
                })
            } else {
                alert("Beim Speichern ist ein Fehler aufgetreten:\n: " + res)
            }
        } catch (error) {
            alert("Beim Speichern ist ein Fehler aufgetreten:\n: " + error);
        }
    }


    function setKursTerminStatus(newStatus, grund) {
        let message = null;

        switch(newStatus) {
            case "Geplant":
                message = new Kurstermin_Status_soll_Geplant_werden(id)
                break;
            case "Veröffentlicht":
                message = new Kurstermin_Status_soll_Veroeffentlicht_werden(id)
                break;
            case "Abgesagt":
                message = new Kurstermin_soll_abgesagt_werden(id, grund)
                break;
            case "Ausverkauft":
                message = new Kurstermin_Status_soll_Ausverkauft_werden(id)
                break;
            case "Wird durchgeführt":
                //message = new Kurstermin_Status_soll_Wird_durchgefuehrt_werden(id)
                alert("Dieser Status wird nur vom System gesetzt, wenn der erste Kurstag beginnt!")
                onStatusChanged(kursTerminInfo.status)
                return;
            case "Wenige Restplätze":
                message = new Kurstermin_Status_soll_Wenige_Restplaetze_werden(id)
                break;
            case "Durchgeführt":
                message = new Kurstermin_Status_soll_Durchgefuehrt_werden(id)
                break;
            case "Abgeschlossen":
                message = new Kurstermin_Status_soll_Abgeschlossen_werden(id)
                break;
            case "Abgebrochen":
                message = new Kurstermin_Status_soll_Abgebrochen_werden(id)
                break;
            default:
                console.log("Does not happen")

        }
        if (message) {
            send(message, newStatus).then(() => {

            })

        }


    }


    return {kursTerminInfo, loading, setKursTerminStatus}

}