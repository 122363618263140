/*gen*/class Moodle_Teilnehmer_soll_angemeldet_werden {
/*gen*/    static messageName = "Moodle_Teilnehmer_soll_angemeldet_werden"
/*gen*/	kontakt_Id
/*gen*/	initialpasswort
/*gen*/	constructor(kontakt_Id, initialpasswort) {
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Id = kontakt_Id
/*gen*/		
/*gen*/			
/*gen*/			this.initialpasswort = initialpasswort
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Moodle_Teilnehmer_soll_in_Moodle_Kurs_eingeschrieben_werden {
/*gen*/    static messageName = "Moodle_Teilnehmer_soll_in_Moodle_Kurs_eingeschrieben_werden"
/*gen*/	kontakt_Id
/*gen*/	kurstermin_Id
/*gen*/	constructor(kontakt_Id, kurstermin_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Id = kontakt_Id
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Moodle_Teilnehmer_soll_aus_Moodle_Kurs_herausgenommen_werden {
/*gen*/    static messageName = "Moodle_Teilnehmer_soll_aus_Moodle_Kurs_herausgenommen_werden"
/*gen*/	kontakt_Id
/*gen*/	kurstermin_Id
/*gen*/	constructor(kontakt_Id, kurstermin_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Id = kontakt_Id
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Moodle_Externe_Einschreibung_soll_angefordert_werden {
/*gen*/    static messageName = "Moodle_Externe_Einschreibung_soll_angefordert_werden"
/*gen*/	kontakt_Id
/*gen*/	kurstermin_Id
/*gen*/	constructor(kontakt_Id, kurstermin_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Id = kontakt_Id
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/export {
/*gen*/    Moodle_Teilnehmer_soll_angemeldet_werden,
/*gen*/    Moodle_Teilnehmer_soll_in_Moodle_Kurs_eingeschrieben_werden,
/*gen*/    Moodle_Teilnehmer_soll_aus_Moodle_Kurs_herausgenommen_werden,
/*gen*/    Moodle_Externe_Einschreibung_soll_angefordert_werden,
/*gen*/}