import React, {useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {
    BUCHUNG_BEMERKUNG_ERSTELLEN,
    BUCHUNG_BEMERKUNGEN
} from "../Queries";
import {
    Kursbuchung_Bemerkung_soll_erstellt_werden,
} from "../Daten/BemerkungenMessages.gen";
import {btnCancel, btnOK, EditorButton, Loading, Textarea} from "../components";


export const BuchungBemerkung = ({id}) => {
    console.log(id)
    const [bemerkungen, setBemerkungen] = useState([])
    const [neueBemerkung, setNeueBemerkung] = useState("")

    const {loading, refetch } = useQuery(BUCHUNG_BEMERKUNGEN,
        {
            variables: {buchung:id},
            onCompleted: (data) => {
                if(data) {
                    setBemerkungen(data.buchungen[0]?.bemerkungen);

                }
            }}
    );

    const [erstelleBemerkung,
        {loading: speicherLoading, error: speicherError},
    ] = useMutation(BUCHUNG_BEMERKUNG_ERSTELLEN, {
        onCompleted: (data) => {
            if (data[Kursbuchung_Bemerkung_soll_erstellt_werden] === "ok") {

            }
        }
    });

   function saveBemerkung(e){
        if(neueBemerkung){
            const message = new Kursbuchung_Bemerkung_soll_erstellt_werden(id, neueBemerkung)
            erstelleBemerkung({variables: {message: JSON.stringify(message)}}).then(r => {
                refetch({id}).then(r => {
                    setBemerkungen(r?.data?.buchungen[0]?.bemerkungen)
                })
            })
        }
    }

   function resetBemerkung(){
       setNeueBemerkung("")
    }

   return <div className={"w-full py-4 inline-block"}>

       <Textarea  className={""}
           title={"Bemerkungen"}
           type={"text"}
           name={"bemerkung"}
           onChange={e => setNeueBemerkung(e.target.value)}
           value={neueBemerkung}

       />
       <EditorButton name={"btnSaveBemerkung"} type={"submit"} onClick={e => saveBemerkung(e)}  className={"rounded-l"}>{btnOK}</EditorButton>
       <EditorButton name={"btnResetBemerkung"} type={"reset"} onClick={e => resetBemerkung(e)}  className={"rounded-l"}>{btnCancel}</EditorButton>

       { loading && <Loading />}
       { (!loading) &&
          <ul>
               {bemerkungen?.map(item => {
                   return <li key={item.Id}>{item.text} <br /> <div className="flex justify-between w-full text-sm mb-2">
                       <div className="w-1/2">Autor: {item?.autor?.name}</div>
                       <div className="w-1/2 text-right">Datum: {item?.erstellt?.humanReadable}</div>
                   </div></li>
               })}
           </ul>
       }
   </div>

}

