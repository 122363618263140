import React, {useState} from "react"
import {ModalContainer2} from "../UI/ModalContainer";
import {Loading, SubmitButton} from "../components";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import {Kontakt_Moodle_Teilnehmer_soll_zugeordnet_werden} from "../Daten/CheckinMessages.gen";
import {client} from "../index";
import {GET_MOODLE_TEILNEHMER, GET_KONTAKTE_FOR_MOODLEUPDATE} from "../Queries";




export default function KontakteZuMoodleZuordnen({closeHandler}) {
    const [saving, setSaving] = useState(false)

    const [getMoodleTeilnehmer, {
        loading: moodleLoading,
        data: moodleData,
    }] = useLazyQuery(GET_MOODLE_TEILNEHMER);


    const { data, loading } = useQuery(GET_KONTAKTE_FOR_MOODLEUPDATE, {
        fetchPolicy: "network-only",
        onCompleted: () => {
            getMoodleTeilnehmer()
        }
    });



     const starteKontaktZuordnung = (e) => {
        e.preventDefault()
         setSaving(true)

        if (data?.kontakte && moodleData?.moodleTeilnehmer) {

            let mutations = []

            data.kontakte.filter(k => k.moodleTeilnehmer == null && k.email != null).forEach(k => {
                let mTn = moodleData.moodleTeilnehmer.find(m => m.email.toLowerCase() === k.email.toLowerCase())
                if (mTn) {
                    let message = Kontakt_Moodle_Teilnehmer_soll_zugeordnet_werden.messageName
                    let query = new Kontakt_Moodle_Teilnehmer_soll_zugeordnet_werden(k.Id,mTn.Id.toString());
                    const mutation = gql`mutation ${message}($message: String!){${message}(message: $message)}`
                    const variables = { message: JSON.stringify(query) }
                    mutations.push(client.mutate({ mutation, variables }))


                }
            })

            Promise.all(mutations).then(
                () => {
                    setSaving(false);
                    onClose()
                }
            )



        } else {
            alert("Kann keine Zuordnung vornehmen.")
        }


    }

    const onClose = () => {
        if (closeHandler) {
            closeHandler()
        }
    }


    function calcMoodleTeilnehmerInKontakten() {
        let kontakte = data?.kontakte
        if (kontakte) {
            return kontakte.reduce( (acc, cur) => {
                return (cur.moodleTeilnehmer) ? acc + 1 : acc
            }, 0 )

        } else {
            return 0
        }

    }

    return <ModalContainer2 title={"Kontakte zu Moodle zuordnen"} onClose={onClose} >
        <h3>Kontakte zu Moodle zuordnen</h3>
        <p>Wir versuchen, anhand der Email-Adresse eines Kontaktes eine Verknüpfung zu einem Moodle-Teilnehmer herzustellen.</p>
        <form onSubmit={e => starteKontaktZuordnung(e)}>
            <table className={"border-2 alternating-table"}>
                <thead>
                <th>Kontakt</th><th>Email</th><th>Moodle-Mail</th><th>Ergebnis</th>
                </thead>
                <tr><td>Peter Müller</td><td>pm@gmx.de</td><td>pm@gmx.de</td><td>Ⓜ️</td></tr>
                <tr><td>Susi Sorglos</td><td>sorglos@firma.com</td><td>susi@web.de</td><td>❓</td></tr>

            </table>
            <div className={"h-16"}>
                {(loading || saving) && <Loading />}
                {!loading && data && <p>Wir haben insgesamt <strong>{data.kontakte.length}</strong> Kute-Kontakte gefunden. Davon sind <strong>{ calcMoodleTeilnehmerInKontakten() }</strong> bereits einem Moodle-Teilnehmer zugeordnet.</p>}
                {(moodleLoading || saving) && <Loading />}
                {moodleData?.moodleTeilnehmer && <p>Wir haben <strong>{moodleData?.moodleTeilnehmer.length}</strong> Moodle-Teilnehmer für die Zuordnung gefunden.
                </p>}
            </div>
            <div><SubmitButton title={"Zuordnung starten"} disabled={ saving || loading || moodleLoading}/>
                <button className="btn btn-rot" onClick={onClose}>Abbrechen</button></div>
        </form>
    </ModalContainer2>

}