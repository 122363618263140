/*gen*/class Abaninja_Rechnung_soll_angelegt_werden {
/*gen*/    static messageName = "Abaninja_Rechnung_soll_angelegt_werden"
/*gen*/	kontakt_Id
/*gen*/	kontakt_Adresse_Id
/*gen*/	kontakt_Person_Id
/*gen*/	introduction_Text
/*gen*/	conditions_Text
/*gen*/	beschreibung
/*gen*/	produktId
/*gen*/	buchung_Id
/*gen*/	referenz
/*gen*/	dokument
/*gen*/	constructor(kontakt_Id, kontakt_Adresse_Id, kontakt_Person_Id, introduction_Text, conditions_Text, beschreibung, produktId, buchung_Id, referenz, dokument) {
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Id = kontakt_Id
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Adresse_Id = kontakt_Adresse_Id
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Person_Id = kontakt_Person_Id
/*gen*/		
/*gen*/			
/*gen*/			this.introduction_Text = introduction_Text
/*gen*/		
/*gen*/			
/*gen*/			this.conditions_Text = conditions_Text
/*gen*/		
/*gen*/			
/*gen*/			this.beschreibung = beschreibung
/*gen*/		
/*gen*/			
/*gen*/			this.produktId = produktId
/*gen*/		
/*gen*/			
/*gen*/			this.buchung_Id = buchung_Id
/*gen*/		
/*gen*/			
/*gen*/			this.referenz = referenz
/*gen*/		
/*gen*/			
/*gen*/			this.dokument = dokument
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Abaninja_Kontakt_soll_angelegt_werden {
/*gen*/    static messageName = "Abaninja_Kontakt_soll_angelegt_werden"
/*gen*/	firma
/*gen*/	bundesland
/*gen*/	kontaktvorname
/*gen*/	kontaktnachname
/*gen*/	email
/*gen*/	strasse
/*gen*/	hausnr
/*gen*/	pLZ
/*gen*/	ort
/*gen*/	land
/*gen*/	ustID
/*gen*/	weahrung
/*gen*/	gender
/*gen*/	constructor(firma, bundesland, kontaktvorname, kontaktnachname, email, strasse, hausnr, pLZ, ort, land, ustID, weahrung, gender) {
/*gen*/		
/*gen*/			
/*gen*/			this.firma = firma
/*gen*/		
/*gen*/			
/*gen*/			this.bundesland = bundesland
/*gen*/		
/*gen*/			
/*gen*/			this.kontaktvorname = kontaktvorname
/*gen*/		
/*gen*/			
/*gen*/			this.kontaktnachname = kontaktnachname
/*gen*/		
/*gen*/			
/*gen*/			this.email = email
/*gen*/		
/*gen*/			
/*gen*/			this.strasse = strasse
/*gen*/		
/*gen*/			
/*gen*/			this.hausnr = hausnr
/*gen*/		
/*gen*/			
/*gen*/			this.pLZ = pLZ
/*gen*/		
/*gen*/			
/*gen*/			this.ort = ort
/*gen*/		
/*gen*/			
/*gen*/			this.land = land
/*gen*/		
/*gen*/			
/*gen*/			this.ustID = ustID
/*gen*/		
/*gen*/			
/*gen*/			this.weahrung = weahrung
/*gen*/		
/*gen*/			
/*gen*/			this.gender = gender
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/export {
/*gen*/    Abaninja_Rechnung_soll_angelegt_werden,
/*gen*/    Abaninja_Kontakt_soll_angelegt_werden,
/*gen*/}