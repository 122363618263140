/*gen*/class Kontakt_Bemerkung_soll_erstellt_werden {
/*gen*/    static messageName = "Kontakt_Bemerkung_soll_erstellt_werden"
/*gen*/	betrifft
/*gen*/	text
/*gen*/	constructor(betrifft, text) {
/*gen*/		
/*gen*/			
/*gen*/			this.betrifft = betrifft
/*gen*/		
/*gen*/			
/*gen*/			this.text = text
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kontakt_Bemerkung_soll_bearbeitet_werden {
/*gen*/    static messageName = "Kontakt_Bemerkung_soll_bearbeitet_werden"
/*gen*/	bemerkung
/*gen*/	text
/*gen*/	constructor(bemerkung, text) {
/*gen*/		
/*gen*/			
/*gen*/			this.bemerkung = bemerkung
/*gen*/		
/*gen*/			
/*gen*/			this.text = text
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kontakt_Bemerkung_soll_ausgeblendet_werden {
/*gen*/    static messageName = "Kontakt_Bemerkung_soll_ausgeblendet_werden"
/*gen*/	bemerkung
/*gen*/	constructor(bemerkung) {
/*gen*/		
/*gen*/			
/*gen*/			this.bemerkung = bemerkung
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Teilnahme_Bemerkung_soll_erstellt_werden {
/*gen*/    static messageName = "Teilnahme_Bemerkung_soll_erstellt_werden"
/*gen*/	betrifft
/*gen*/	text
/*gen*/	constructor(betrifft, text) {
/*gen*/		
/*gen*/			
/*gen*/			this.betrifft = betrifft
/*gen*/		
/*gen*/			
/*gen*/			this.text = text
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Teilnahme_Bemerkung_soll_bearbeitet_werden {
/*gen*/    static messageName = "Teilnahme_Bemerkung_soll_bearbeitet_werden"
/*gen*/	bemerkung
/*gen*/	text
/*gen*/	constructor(bemerkung, text) {
/*gen*/		
/*gen*/			
/*gen*/			this.bemerkung = bemerkung
/*gen*/		
/*gen*/			
/*gen*/			this.text = text
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Teilnahme_Bemerkung_soll_ausgeblendet_werden {
/*gen*/    static messageName = "Teilnahme_Bemerkung_soll_ausgeblendet_werden"
/*gen*/	bemerkung
/*gen*/	constructor(bemerkung) {
/*gen*/		
/*gen*/			
/*gen*/			this.bemerkung = bemerkung
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursbuchung_Bemerkung_soll_erstellt_werden {
/*gen*/    static messageName = "Kursbuchung_Bemerkung_soll_erstellt_werden"
/*gen*/	betrifft
/*gen*/	text
/*gen*/	constructor(betrifft, text) {
/*gen*/		
/*gen*/			
/*gen*/			this.betrifft = betrifft
/*gen*/		
/*gen*/			
/*gen*/			this.text = text
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursbuchung_Bemerkung_soll_bearbeitet_werden {
/*gen*/    static messageName = "Kursbuchung_Bemerkung_soll_bearbeitet_werden"
/*gen*/	bemerkung
/*gen*/	text
/*gen*/	constructor(bemerkung, text) {
/*gen*/		
/*gen*/			
/*gen*/			this.bemerkung = bemerkung
/*gen*/		
/*gen*/			
/*gen*/			this.text = text
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursbuchung_Bemerkung_soll_ausgeblendet_werden {
/*gen*/    static messageName = "Kursbuchung_Bemerkung_soll_ausgeblendet_werden"
/*gen*/	bemerkung
/*gen*/	constructor(bemerkung) {
/*gen*/		
/*gen*/			
/*gen*/			this.bemerkung = bemerkung
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Bemerkung_soll_erstellt_werden {
/*gen*/    static messageName = "Kurstermin_Bemerkung_soll_erstellt_werden"
/*gen*/	betrifft
/*gen*/	text
/*gen*/	constructor(betrifft, text) {
/*gen*/		
/*gen*/			
/*gen*/			this.betrifft = betrifft
/*gen*/		
/*gen*/			
/*gen*/			this.text = text
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Bemerkung_soll_bearbeitet_werden {
/*gen*/    static messageName = "Kurstermin_Bemerkung_soll_bearbeitet_werden"
/*gen*/	bemerkung
/*gen*/	text
/*gen*/	constructor(bemerkung, text) {
/*gen*/		
/*gen*/			
/*gen*/			this.bemerkung = bemerkung
/*gen*/		
/*gen*/			
/*gen*/			this.text = text
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Bemerkung_soll_ausgeblendet_werden {
/*gen*/    static messageName = "Kurstermin_Bemerkung_soll_ausgeblendet_werden"
/*gen*/	bemerkung
/*gen*/	constructor(bemerkung) {
/*gen*/		
/*gen*/			
/*gen*/			this.bemerkung = bemerkung
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursort_Bemerkung_soll_erstellt_werden {
/*gen*/    static messageName = "Kursort_Bemerkung_soll_erstellt_werden"
/*gen*/	betrifft
/*gen*/	text
/*gen*/	constructor(betrifft, text) {
/*gen*/		
/*gen*/			
/*gen*/			this.betrifft = betrifft
/*gen*/		
/*gen*/			
/*gen*/			this.text = text
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursort_Bemerkung_soll_bearbeitet_werden {
/*gen*/    static messageName = "Kursort_Bemerkung_soll_bearbeitet_werden"
/*gen*/	bemerkung
/*gen*/	text
/*gen*/	constructor(bemerkung, text) {
/*gen*/		
/*gen*/			
/*gen*/			this.bemerkung = bemerkung
/*gen*/		
/*gen*/			
/*gen*/			this.text = text
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursort_Bemerkung_soll_ausgeblendet_werden {
/*gen*/    static messageName = "Kursort_Bemerkung_soll_ausgeblendet_werden"
/*gen*/	bemerkung
/*gen*/	constructor(bemerkung) {
/*gen*/		
/*gen*/			
/*gen*/			this.bemerkung = bemerkung
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Trainer_Bemerkung_soll_erstellt_werden {
/*gen*/    static messageName = "Trainer_Bemerkung_soll_erstellt_werden"
/*gen*/	betrifft
/*gen*/	text
/*gen*/	constructor(betrifft, text) {
/*gen*/		
/*gen*/			
/*gen*/			this.betrifft = betrifft
/*gen*/		
/*gen*/			
/*gen*/			this.text = text
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Trainer_Bemerkung_soll_bearbeitet_werden {
/*gen*/    static messageName = "Trainer_Bemerkung_soll_bearbeitet_werden"
/*gen*/	bemerkung
/*gen*/	text
/*gen*/	constructor(bemerkung, text) {
/*gen*/		
/*gen*/			
/*gen*/			this.bemerkung = bemerkung
/*gen*/		
/*gen*/			
/*gen*/			this.text = text
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Trainer_Bemerkung_soll_ausgeblendet_werden {
/*gen*/    static messageName = "Trainer_Bemerkung_soll_ausgeblendet_werden"
/*gen*/	bemerkung
/*gen*/	constructor(bemerkung) {
/*gen*/		
/*gen*/			
/*gen*/			this.bemerkung = bemerkung
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Bemerkung_erstellen {
/*gen*/    static messageName = "Bemerkung_erstellen"
/*gen*/	betrifft_Entitaet
/*gen*/	betrifft
/*gen*/	text
/*gen*/	constructor(betrifft_Entitaet, betrifft, text) {
/*gen*/		
/*gen*/			
/*gen*/			this.betrifft_Entitaet = betrifft_Entitaet
/*gen*/		
/*gen*/			
/*gen*/			this.betrifft = betrifft
/*gen*/		
/*gen*/			
/*gen*/			this.text = text
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Bemerkung_bearbeiten {
/*gen*/    static messageName = "Bemerkung_bearbeiten"
/*gen*/	bemerkung
/*gen*/	text
/*gen*/	constructor(bemerkung, text) {
/*gen*/		
/*gen*/			
/*gen*/			this.bemerkung = bemerkung
/*gen*/		
/*gen*/			
/*gen*/			this.text = text
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Bemerkung_ausblenden {
/*gen*/    static messageName = "Bemerkung_ausblenden"
/*gen*/	bemerkung
/*gen*/	constructor(bemerkung) {
/*gen*/		
/*gen*/			
/*gen*/			this.bemerkung = bemerkung
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/export {
/*gen*/    Kontakt_Bemerkung_soll_erstellt_werden,
/*gen*/    Kontakt_Bemerkung_soll_bearbeitet_werden,
/*gen*/    Kontakt_Bemerkung_soll_ausgeblendet_werden,
/*gen*/    Teilnahme_Bemerkung_soll_erstellt_werden,
/*gen*/    Teilnahme_Bemerkung_soll_bearbeitet_werden,
/*gen*/    Teilnahme_Bemerkung_soll_ausgeblendet_werden,
/*gen*/    Kursbuchung_Bemerkung_soll_erstellt_werden,
/*gen*/    Kursbuchung_Bemerkung_soll_bearbeitet_werden,
/*gen*/    Kursbuchung_Bemerkung_soll_ausgeblendet_werden,
/*gen*/    Kurstermin_Bemerkung_soll_erstellt_werden,
/*gen*/    Kurstermin_Bemerkung_soll_bearbeitet_werden,
/*gen*/    Kurstermin_Bemerkung_soll_ausgeblendet_werden,
/*gen*/    Kursort_Bemerkung_soll_erstellt_werden,
/*gen*/    Kursort_Bemerkung_soll_bearbeitet_werden,
/*gen*/    Kursort_Bemerkung_soll_ausgeblendet_werden,
/*gen*/    Trainer_Bemerkung_soll_erstellt_werden,
/*gen*/    Trainer_Bemerkung_soll_bearbeitet_werden,
/*gen*/    Trainer_Bemerkung_soll_ausgeblendet_werden,
/*gen*/    Bemerkung_erstellen,
/*gen*/    Bemerkung_bearbeiten,
/*gen*/    Bemerkung_ausblenden,
/*gen*/}