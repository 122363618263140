import * as React from "react"
import {BlueAlert} from "./UI/Alert"
import * as serviceWorker from "./serviceWorker";

export class UpdateCheck extends React.Component {
    state = {
        updateAvailable: false,
    }

    worker = null

    constructor(props, context) {
        super(props, context)

        serviceWorker.register({
            onUpdate: registration => {
                const waitingServiceWorker = registration.waiting

                if (waitingServiceWorker) {
                    waitingServiceWorker.addEventListener("statechange", event => {
                        if (event.target.state === "activated") {
                            window.location.reload()
                        }
                    });

                    this.worker = waitingServiceWorker
                    this.setState({updateAvailable: true})
                }
            },
        })
    }

    render() {
        if (!this.state.updateAvailable) {
            return null
        }

        return <BlueAlert dismissible={false}
                          onClick={() => this.worker && this.worker.postMessage({type: 'SKIP_WAITING'})}
                          title="Neue Version verfügbar">
            <div>
                Es ist eine neue Version von reisebüro2 verfügbar. Bitte klick hier oder lade die Seite mit deinem
                Browser neu.
            </div>
        </BlueAlert>
    }
}