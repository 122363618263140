import React, {useState} from "react";
import {Loading, Textarea, TH, labelClass, EditorField2, EditorButton, btnOK, btnCancel} from "../components";
import {useQuery, useMutation, gql} from "@apollo/client";
import {Kurstermin_Freitext_soll_gesetzt_werden} from "../Daten/FlugplanMessages.gen"
import {MUTATE_FREITEXT_ZUORDNEN} from "../Queries"

const GET_KURSTERMIN_FREITEXTE = gql`query kurstermin($id: String){kurstermine(Id: $id){
    freitext,
    freitextEn,
    Id
}}`


export const Freitext = ({id}) => {
    const [isEditingDE, setIseditingDE] = useState(false);
    const [isEditingEN, setIseditingEN] = useState(false);
    const [freitextDe, setFreitextDe] = useState()
    const [freitextEn, setFreitextEn] = useState()

    const { loading, refetch } = useQuery(GET_KURSTERMIN_FREITEXTE,
        {
            variables: {id},
            onCompleted: (data) => {
                if(data) {
                    setFreitextDe(data.kurstermine[0]?.freitext);
                    setFreitextEn(data.kurstermine[0]?.freitextEn);

                }
            }}
    )

    const [speichereFreitext,
        {loading: speicherLoading, error: speicherError},
    ] = useMutation(MUTATE_FREITEXT_ZUORDNEN, {
        onCompleted: (data) => {
            if (data[Kurstermin_Freitext_soll_gesetzt_werden] === "ok") {
                //
            }
        }
    });

    function onFreitextChange(e) {
        switch(e.target.name) {
            case "freitextDe":
                setIseditingDE(true);
                setFreitextDe(e.target.value)
                break;
            case "freitextEn":
                setIseditingEN(true);
                setFreitextEn(e.target.value)
                break;
            default:
                console.log("Does not happen")
        }
    }




    function saveFreitext(e) {
        // switch
        let message = undefined
        switch(e.target.name) {
            case "btnSaveDe":
                message= new Kurstermin_Freitext_soll_gesetzt_werden( id, "DE", freitextDe)
                break;
            case "btnSaveEn":
                message = new Kurstermin_Freitext_soll_gesetzt_werden( id, "EN", freitextEn)
                break;
            default:
                console.log("Does not happen")
        }
        if (message) {
            speichereFreitext({variables: {message: JSON.stringify(message)}}).then(r => {
                    console.log(r)
                }
            )
        }

    }



    function resetFreitext(e) {

        const updateField = e.target.name

        refetch().then (result => {
            if(result.data) {
                if (updateField === "btnResetDe") setFreitextDe(result.data.kurstermine[0].freitext);
                if (updateField === "btnResetEn") setFreitextEn(result.data.kurstermine[0].freitextEn);
            }
        })
    }



    if (loading) return <Loading />
    if (speicherError) return <span>Fehler beim Speichern</span>

    return <div>
        <label className={labelClass}>Freitext</label>
        <Textarea title={"Freitext"}
                  type={"text"}
                  name={"freitextDe"}
                  onChange={e => onFreitextChange(e)}
                  value={freitextDe}
                  readonly={speicherLoading}
        />

      {isEditingDE &&  <EditorButton name={"btnSaveDe"} type={"submit"} onClick={e => saveFreitext(e)}  className={"rounded-l"}>{btnOK}</EditorButton> }
       {isEditingDE && <EditorButton name={"btnResetDe"} type={"reset"} onClick={e => resetFreitext(e)}  className={"rounded-l"}>{btnCancel}</EditorButton> }


        <br />
        <label className={labelClass}>Freitext(Englisch)</label>
        <Textarea title={"Freitext Englisch"}
                  type={"text"}
                  name={"freitextEn"}
                  onChange={e => onFreitextChange(e)}
                  value={freitextEn}
                  readonly={speicherLoading}
        />
        <br />
      {isEditingEN &&  <EditorButton name={"btnSaveEn"} type={"submit"} onClick={e => saveFreitext(e)}  className={"rounded-l"}>{btnOK}</EditorButton> }
       {isEditingEN && <EditorButton name={"btnSaveEn"} type={"reset"} onClick={e => resetFreitext(e)}  className={"rounded-l"}>{btnCancel}</EditorButton> }


    </div>

}