import React, {useState} from "react";
import {TrainerAutosuggestField} from "../AutoSuggestFields";
import {useMutation, useQuery} from "@apollo/client";
import {MUTATE_TRAINER_ZUORDNEN, GET_KURSTERMIN_TRAINER, MUTATE_TRAINER_ENTFERNEN} from "../Queries";
import {
    Kurstermin_Trainer_soll_entfernt_werden,
    Kurstermin_Trainer_soll_zugeordnet_werden
} from "../Daten/FlugplanMessages.gen";
import {btnAdd, btnRemove, EditorButton, labelClass, Loading} from "../components";

export const KursTrainer = ({id}) => {

    const [isEditing, setIsEditing] = useState(false)

    const {loading, error, data, refetch} = useQuery(GET_KURSTERMIN_TRAINER,
        {
            variables: {id}
        }
    )


    function onTrainerChange(trainer) {
        setIsEditing(false)

        if (trainer) {
            const message = new Kurstermin_Trainer_soll_zugeordnet_werden(id, trainer.Id)
            setzeTrainer({variables: {message: JSON.stringify(message)}}).then(r => {
                console.log(r)
                refetch({id}).then(()=>{})
            })
        }
    }

    const [setzeTrainer,
        {loading: setzeTrainerLoading, error: setzeTrainerError},
    ] = useMutation(MUTATE_TRAINER_ZUORDNEN, {
        onCompleted: (data) => {
            if (data[Kurstermin_Trainer_soll_zugeordnet_werden.messageName] === "ok") {
                refetch({id}).then(()=>{})
            }
        }
    });

    const [entferneTrainer,
        {loading: entferneTrainerLoading, error: entferneTrainerError},
    ] = useMutation(MUTATE_TRAINER_ENTFERNEN, {
        onCompleted: (data) => {
            if (data[Kurstermin_Trainer_soll_entfernt_werden.messageName] === "ok") {
                refetch({id}).then(()=>{})
            }
        }
    });

    function removeTrainer(trainer) {
        if (trainer) {
            const message = new Kurstermin_Trainer_soll_entfernt_werden(id, trainer.Id)
            entferneTrainer({variables: {message: JSON.stringify(message)}}).then(() => {
                refetch({id}).then(()=>{})
            })
        }
    }

    if (loading || setzeTrainerLoading || entferneTrainerLoading) return <Loading/>
    if (error || setzeTrainerError || entferneTrainerError) return <span>Fehler beim Aktualisieren der Trainer</span>


    return <div className={"w-full inline-block"}>
        <label className={labelClass}>Trainer</label>
        <div className={"w-full inline-flex p-2"}>
            <div className={"w-2/6 pr-4"}>
                <ul className={"md:list-reset "}>
                    {data?.kurstermine[0]?.trainer?.map(trainer => {
                            return <li key={trainer.Id}>
                                <div className={"inline-flex"}>
                                <div className={"w-48"}>{trainer.vornamen + " " + trainer.nachname} </div>
                                <EditorButton name={"btnDel"} type="submit"
                                              onClick={() => removeTrainer(trainer)}>{btnRemove}</EditorButton>
                                </div>
                            </li>
                        }
                    )}
                </ul>
            </div>
            <div className={"w-2/6"}>
                {isEditing && <TrainerAutosuggestField setValueObject={newTrainer => onTrainerChange(newTrainer)}/>}
                {!isEditing &&
                <EditorButton name={"btnAdd"} className={"btn btn-gruen"} type="submit" onClick={() => setIsEditing(true)}>{btnAdd}</EditorButton>}
            </div>
        </div>
    </div>
}