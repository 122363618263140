import React, { useState, useEffect } from "react"
import { FilterField2, Loading, HeadingLine, DataLine } from "./components"
import { GET_REGIONS} from "./Queries"
import { useQuery } from "@apollo/client"
import { sortByName } from "./Infrastruktur/Helpers"
import { RegionAnlegen2} from "./RegionAnlegen2"
import {Region_soll_ausgeblendet_werden, Region_soll_eingeblendet_werden} from "./Daten/FlugplanMessages.gen";
import {sendMessage} from "./SendMessages";
export const RegionListe2 = () => {
    // get data from API
    const { loading, data, refetch } = useQuery(GET_REGIONS, { pollInterval: 120000 });

    // search field
    const [filterString, setFilterString] = useState();

     // visibility for editor
     const [regionEditorVisible, setRegionEditorVisible] = useState(false);

     // location for editor
     const [currentRegion, setCurrentRegion] = useState()
     const closeEditor = () => { setRegionEditorVisible(false); setCurrentRegion(undefined); refetch() };
 
     // refetch if editor is opened or closed
     useEffect(() => {
         refetch().then(()=>{});
     }, [regionEditorVisible])

    // filter for search
    const filter = region => {
        if (!filterString) { return true }
        const lowerQuery = filterString.toLowerCase();
        return region.name.toLowerCase().indexOf(lowerQuery) > -1 ||
            region.land.name.toLowerCase().indexOf(lowerQuery) > -1 ||
            region.Id.toLowerCase().indexOf(lowerQuery) > -1
    }

    // action buttons
    const links = [
        { label: "Neue Region", func: () => { createRegion() } }
    ]


    console.log(process.env)
    const createRegion = () => {
     
        setCurrentRegion(undefined);
        setRegionEditorVisible(true) 

    }

    const editRegion = (region) => {

        setCurrentRegion(region);
        setRegionEditorVisible(true)
    }

    function blendeRegionEinOderAus (region) {
         const message = region.ausgeblendet
             ? new Region_soll_eingeblendet_werden(region.Id)
             : new Region_soll_ausgeblendet_werden(region.Id)
        sendMessage(message).then(()=>{
            refetch().then(()=>{})
        })


    }

    return (
        <div>
            <HeadingLine title="Regionen" icon="🗺" links={links} />
            {regionEditorVisible && <RegionAnlegen2 def_region={currentRegion} closeHandler={closeEditor} />}
            {loading && <Loading />}
            {data && <div>
                <FilterField2 filterString={filterString} handleFilterChange={setFilterString} />
                <table style={{ width: "100%" }}>
                    <thead><tr><th>Name</th><th>Name(En)</th><th>Land</th><th>ID - Flughafen-Code</th><th>&nbsp;</th><th>Sichtbar</th></tr></thead>
                    <tbody>
                    {data && !loading && data.regionen
                        .filter(filter)
                        .sort(sortByName)
                        .map((region, index) =>
                            <DataLine className={ region.ausgeblendet? "text-dst-grau-1" : ""} key={region.Id} id={region.Id} index={index} rowData={[
                            { text: region.name },
                            { text: region.englischer_Name},
                            { text: region.land.name },
                            { text: region.Id },
                            { text: "Bearbeiten", func: () => { editRegion(region)}},
                            { text: region.ausgeblendet ? "◎" : "◉", func: (() => blendeRegionEinOderAus(region) )}
                        ]} />)}
                    </tbody>
                </table></div>}
        </div>
    )
}
