import React, { useState } from "react"
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { GET_BENUTZER } from "../Queries";
import { Benutzer_Passwort_Login_soll_aktiviert_werden } from "../Daten/BenutzerManagerMessages.gen";
import { ModalContainer2 } from "../UI/ModalContainer";
import { ErrorMessage, InputField, Loading, SubmitButton } from "../components";

export const UserBearbeiten = ({ id, closeHandler }) => {
    const [user, setUser] = useState();
    const [dirtyFields, setDirty] = useState([]);
    const [errorText, setErrorText] = useState();
    const client = useApolloClient()
    const Fields = Object.freeze({
        PASSWORT: "passwort",
        PASSWORT2: "passwort2"
    })

    const localFields = [Fields.PASSWORT2];

    const { loading } = useQuery(GET_BENUTZER, {
        fetchPolicy: "no-cache",
        onCompleted: data => {
            setUser(data.benutzer.find(downloadedUser => downloadedUser.Id === id));
        }
    });
    const [saveLoading, setSaveLoading] = useState(false);

    const saveUser = async () => {
        setSaveLoading(true);
        const mutations = [];
        for (const field of dirtyFields.filter(dirtyField => !localFields.includes(dirtyField))) {
            let query;
            let message;
            switch (field) {
                case Fields.PASSWORT:
                    message = Benutzer_Passwort_Login_soll_aktiviert_werden.messageName;
                    query = new Benutzer_Passwort_Login_soll_aktiviert_werden(user.Id, user.name, user.passwort);
                    break;
            }
            const mutation = gql`mutation ${message}($message: String!){${message}(message: $message)}`
            const variables = { message: JSON.stringify(query) }
            mutations.push(client.mutate({ mutation, variables }))
        }

        Promise.all(mutations).then(
            () => { setSaveLoading(false); cleanupAndClose() }
        )
    }
    const onSubmit = (e) => {
        e.preventDefault();
        saveUser();
    }

    // gets executed every time a value in a field changes
    const onChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });

        if (!dirtyFields.includes(e.target.name)) {
            const dirtyCopy = [...dirtyFields]
            dirtyCopy.push(e.target.name)
            setDirty(dirtyCopy)
        }
    }

    const isSamePassword = () => user.passwort === user.passwort2;

    // call onClose on actions that could occur accidentally 
    const onClose = () => {
        if (dirtyFields.length === 0 || window.confirm("Wirklich verlassen? Geänderte Werte werden nicht gespeichert!")) {
            closeHandler();
        }
    }

    // cleanup and close (e.g. on cancel and on successful save)
    const cleanupAndClose = () => {
        setDirty([]);
        closeHandler()
    }

    return (
        <ModalContainer2 title={`Passwort ändern für ${user ? user.name : '???'}`} onClose={onClose} >
            {
                !loading && !saveLoading && user &&
                <div className="passwort-aendern-container">
                    <code>ID: {user.Id}</code>
                    {!isSamePassword() && <ErrorMessage customText="Die Passwörter stimmen nicht überein." emoji="⛔️" />}
                    <form onSubmit={onSubmit} className="passworteingabe">
                        <InputField name={Fields.PASSWORT} type="password" title={Fields.PASSWORT} value={user.passwort} onChange={onChange} />
                        <InputField name={Fields.PASSWORT2} type="password" title={Fields.PASSWORT2} value={user.passwort2} onChange={onChange} />
                        <div><SubmitButton title="Ändern" disabled={() => !isSamePassword()} />
                            <button className="btn btn-rot" onClick={cleanupAndClose}>Abbrechen</button></div>
                    </form>
                </div >
            }
            {(loading || saveLoading) && <Loading />}
        </ModalContainer2>)
}






