
export const KUTE_BERECHTIGUNGEN = [
 "stammdaten_lesen",
 "stammdaten_verwalten",
 "buchung_erstellen",
 "buchungen_lesen",
 "buchungen_verwalten",
 "selbstverwaltung",
 "benutzer_verwalten",
 "admintools_nutzen",
 "Kontakt_Bemerkung_schreiben",
 "Kontakt_Bemerkung_anzeigen",
 "Kontakt_Bemerkung_verwalten",
 "Teilnahme_Bemerkung_schreiben",
 "Teilnahme_Bemerkung_anzeigen",
 "Teilnahme_Bemerkung_verwalten",
 "Kursbuchung_Bemerkung_schreiben",
 "Kursbuchung_Bemerkung_anzeigen",
 "Kursbuchung_Bemerkung_verwalten",
 "Kurstermin_Bemerkung_schreiben",
 "Kurstermin_Bemerkung_anzeigen",
 "Kurstermin_Bemerkung_verwalten",
 "Kursort_Bemerkung_schreiben",
 "Kursort_Bemerkung_anzeigen",
 "Kursort_Bemerkung_verwalten",
 "Trainer_Bemerkung_schreiben",
 "Trainer_Bemerkung_anzeigen",
 "Trainer_Bemerkung_verwalten",
 "alle_berechtigungen",
]

export const KUTE_BUCHUNGS_BERECHTIGUNGEN = [
 "kontaktbemerkung_verwalten",
 "teilnahmebemerkung_schreiben",
 "kursterminbemerkung_schreiben",
 "stammdaten_lesen",
 "trainerbemerkung_anzeigen",
 "kursbuchungbemerkung_verwalten",
 "stammdaten_verwalten",
 "buchungen_verwalten",
 "teilnahmebemerkung_verwalten",
 "kontaktbemerkung_schreiben",
 "kontaktbemerkung_anzeigen",
 "kursbuchungbemerkung_schreiben",
 "kursterminbemerkung_verwalten",
 "buchung_erstellen",
 "teilnahmebemerkung_anzeigen",
 "kursortbemerkung_anzeigen",
 "trainerbemerkung_verwalten",
 "trainerbemerkung_schreiben",
 "buchungen_lesen",
 "kursterminbemerkung_anzeigen",
 "kursbuchungbemerkung_anzeigen",
 "kursortbemerkung_schreiben",
 "kursortbemerkung_verwalten",
]