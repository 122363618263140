export const schweizerPlz = [
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne St-Paul",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne 17",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne 19",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne Sévelin",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne 25",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1002",
        "ORTBEZ18": "Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1004",
        "ORTBEZ18": "Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1006",
        "ORTBEZ18": "Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1007",
        "ORTBEZ18": "Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1009",
        "ORTBEZ18": "Pully",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1010",
        "ORTBEZ18": "Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1015",
        "ORTBEZ18": "Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1020",
        "ORTBEZ18": "Renens VD 1",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1020",
        "ORTBEZ18": "Renens VD Village",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1032",
        "ORTBEZ18": "Romanel-s-Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1033",
        "ORTBEZ18": "Cheseaux-Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1038",
        "ORTBEZ18": "Bercher",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1377",
        "ORTBEZ18": "Oulens-Echallens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1040",
        "ORTBEZ18": "Villars-le-Terroir",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1417",
        "ORTBEZ18": "Epautheyres",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1041",
        "ORTBEZ18": "Poliez-le-Grand",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1041",
        "ORTBEZ18": "Bottens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1053",
        "ORTBEZ18": "Bretigny-Morrens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1034",
        "ORTBEZ18": "Boussens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1407",
        "ORTBEZ18": "Bioley-Magnoux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1066",
        "ORTBEZ18": "Epalinges",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1094",
        "ORTBEZ18": "Paudex",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1096",
        "ORTBEZ18": "Cully",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1410",
        "ORTBEZ18": "St-Cierges",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1073",
        "ORTBEZ18": "Mollie-Margot",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1081",
        "ORTBEZ18": "Montpreveyres",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1085",
        "ORTBEZ18": "Vulliens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1510",
        "ORTBEZ18": "Syens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1112",
        "ORTBEZ18": "Echichens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1126",
        "ORTBEZ18": "Vaux-sur-Morges",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1128",
        "ORTBEZ18": "Reverolle",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1114",
        "ORTBEZ18": "Colombier VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1115",
        "ORTBEZ18": "Vullierens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1116",
        "ORTBEZ18": "Cottens VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1117",
        "ORTBEZ18": "Grancy",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1304",
        "ORTBEZ18": "Senarclens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1121",
        "ORTBEZ18": "Bremblens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1124",
        "ORTBEZ18": "Gollion",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1144",
        "ORTBEZ18": "Ballens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1149",
        "ORTBEZ18": "Berolle",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1145",
        "ORTBEZ18": "Bière",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1148",
        "ORTBEZ18": "L'Isle",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1164",
        "ORTBEZ18": "Buchillon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1165",
        "ORTBEZ18": "Allaman",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1172",
        "ORTBEZ18": "Bougy-Villars",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1175",
        "ORTBEZ18": "Lavigny",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1182",
        "ORTBEZ18": "Gilly",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1183",
        "ORTBEZ18": "Bursins",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1188",
        "ORTBEZ18": "Gimel",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 2 Dépôt",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 3",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 2 Transit",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 17",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 20",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 28 Balexert",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 2 Dist",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1204",
        "ORTBEZ18": "Genève",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1209",
        "ORTBEZ18": "Genève",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 2",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 4",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 10",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 13",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 26",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1212",
        "ORTBEZ18": "Grand-Lancy 1",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1213",
        "ORTBEZ18": "Petit-Lancy",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1213",
        "ORTBEZ18": "Petit-Lancy 2",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1213",
        "ORTBEZ18": "Onex",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1219",
        "ORTBEZ18": "Châtelaine",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1224",
        "ORTBEZ18": "Chêne-Bougeries",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1226",
        "ORTBEZ18": "Thônex",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1227",
        "ORTBEZ18": "Carouge GE",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1231",
        "ORTBEZ18": "Conches",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1232",
        "ORTBEZ18": "Confignon",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1233",
        "ORTBEZ18": "Bernex",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1234",
        "ORTBEZ18": "Vessy",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1236",
        "ORTBEZ18": "Cartigny",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1237",
        "ORTBEZ18": "Avully",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1242",
        "ORTBEZ18": "Satigny",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1243",
        "ORTBEZ18": "Presinge",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1251",
        "ORTBEZ18": "Gy",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1254",
        "ORTBEZ18": "Jussy",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1279",
        "ORTBEZ18": "Chavannes-de-Bogis",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1270",
        "ORTBEZ18": "Trélex",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1271",
        "ORTBEZ18": "Givrins",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1268",
        "ORTBEZ18": "Burtigny",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1261",
        "ORTBEZ18": "Le Vaud",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1261",
        "ORTBEZ18": "Longirod",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1281",
        "ORTBEZ18": "Russin",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1283",
        "ORTBEZ18": "Dardagny",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1283",
        "ORTBEZ18": "La Plaine",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1285",
        "ORTBEZ18": "Athenaz (Avusy)",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1292",
        "ORTBEZ18": "Chambésy",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1293",
        "ORTBEZ18": "Bellevue",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1298",
        "ORTBEZ18": "Céligny",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1315",
        "ORTBEZ18": "La Sarraz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1344",
        "ORTBEZ18": "L'Abbaye",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1345",
        "ORTBEZ18": "Le Lieu",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1306",
        "ORTBEZ18": "Daillens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1326",
        "ORTBEZ18": "Juriens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1148",
        "ORTBEZ18": "Mont-la-Ville",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1350",
        "ORTBEZ18": "Orbe",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1353",
        "ORTBEZ18": "Bofflens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1439",
        "ORTBEZ18": "Rances",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1374",
        "ORTBEZ18": "Corcelles-Chavorn",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1400",
        "ORTBEZ18": "Yverdon-les-Bains",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1400",
        "ORTBEZ18": "Yverdon 2",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1418",
        "ORTBEZ18": "Vuarrens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1420",
        "ORTBEZ18": "Fiez",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1429",
        "ORTBEZ18": "Giez",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1407",
        "ORTBEZ18": "Donneloye",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1408",
        "ORTBEZ18": "Prahins",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1413",
        "ORTBEZ18": "Orzens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1431",
        "ORTBEZ18": "Novalles",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1415",
        "ORTBEZ18": "Molondin",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1422",
        "ORTBEZ18": "Grandson",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1426",
        "ORTBEZ18": "Concise",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1432",
        "ORTBEZ18": "Belmont-Yverdon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1433",
        "ORTBEZ18": "Suchy",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1434",
        "ORTBEZ18": "Ependes VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1435",
        "ORTBEZ18": "Essert-Pittet",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1437",
        "ORTBEZ18": "Suscévaz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1445",
        "ORTBEZ18": "Vuiteboeuf",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1450",
        "ORTBEZ18": "Ste-Croix",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1452",
        "ORTBEZ18": "Les Rasses",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1462",
        "ORTBEZ18": "Yvonand",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1468",
        "ORTBEZ18": "Cheyres",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1474",
        "ORTBEZ18": "Châbles FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1483",
        "ORTBEZ18": "Montet (Broye)",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1482",
        "ORTBEZ18": "Cugy FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1541",
        "ORTBEZ18": "Bussy FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1484",
        "ORTBEZ18": "Aumont",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1485",
        "ORTBEZ18": "Nuvilly",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1486",
        "ORTBEZ18": "Vuissens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1538",
        "ORTBEZ18": "Treytorrens-Payern",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1510",
        "ORTBEZ18": "Moudon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1513",
        "ORTBEZ18": "Hermenches",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1528",
        "ORTBEZ18": "Surpierre",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1536",
        "ORTBEZ18": "Combremont-Petit",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1526",
        "ORTBEZ18": "Forel-sur-Lucens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1526",
        "ORTBEZ18": "Cremin",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1543",
        "ORTBEZ18": "Grandcour",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1545",
        "ORTBEZ18": "Chevroux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1553",
        "ORTBEZ18": "Châtonnaye",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1562",
        "ORTBEZ18": "Corcelles-Payerne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1565",
        "ORTBEZ18": "Missy",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1580",
        "ORTBEZ18": "Avenches",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1586",
        "ORTBEZ18": "Vallamand",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1587",
        "ORTBEZ18": "Montmagny",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1589",
        "ORTBEZ18": "Chabrey",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1595",
        "ORTBEZ18": "Faoug",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1610",
        "ORTBEZ18": "Châtillens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1525",
        "ORTBEZ18": "Henniez",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1091",
        "ORTBEZ18": "Aran",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1619",
        "ORTBEZ18": "Les Paccots",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1623",
        "ORTBEZ18": "Semsales",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1624",
        "ORTBEZ18": "La Verrerie",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1627",
        "ORTBEZ18": "Vaulruz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1628",
        "ORTBEZ18": "Vuadens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1630",
        "ORTBEZ18": "Bulle",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1642",
        "ORTBEZ18": "Sorens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1644",
        "ORTBEZ18": "Avry-devant-Pont",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1656",
        "ORTBEZ18": "Im Fang",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1638",
        "ORTBEZ18": "Morlon",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1636",
        "ORTBEZ18": "Broc",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1669",
        "ORTBEZ18": "Albeuve",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1673",
        "ORTBEZ18": "Rue",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1678",
        "ORTBEZ18": "Siviriez",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1679",
        "ORTBEZ18": "Villaraboud",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1676",
        "ORTBEZ18": "Chavannes-Forts",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1677",
        "ORTBEZ18": "Prez-vers-Siviriez",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1680",
        "ORTBEZ18": "Romont FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1683",
        "ORTBEZ18": "Brenles",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1696",
        "ORTBEZ18": "Vuisternens-Ogoz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1687",
        "ORTBEZ18": "Estévenens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1692",
        "ORTBEZ18": "Massonnens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1687",
        "ORTBEZ18": "Vuisternens-Romont",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1608",
        "ORTBEZ18": "Oron-le-Châtel",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1699",
        "ORTBEZ18": "Porsel",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1700",
        "ORTBEZ18": "Fribourg",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1704",
        "ORTBEZ18": "Fribourg",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1707",
        "ORTBEZ18": "Fribourg",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1708",
        "ORTBEZ18": "Fribourg",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1763",
        "ORTBEZ18": "Granges-Paccot",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1720",
        "ORTBEZ18": "Corminboeuf",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1721",
        "ORTBEZ18": "Misery-Courtion",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1736",
        "ORTBEZ18": "St. Silvester",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1724",
        "ORTBEZ18": "Bonnefontaine",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1732",
        "ORTBEZ18": "Arconciel",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1720",
        "ORTBEZ18": "Chésopelloz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1717",
        "ORTBEZ18": "St. Ursen",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1723",
        "ORTBEZ18": "Marly",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1724",
        "ORTBEZ18": "Le Mouret",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1725",
        "ORTBEZ18": "Posieux",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1749",
        "ORTBEZ18": "Middes",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1740",
        "ORTBEZ18": "Neyruz FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1752",
        "ORTBEZ18": "Villars-sur-Glâne",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1753",
        "ORTBEZ18": "Matran",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1756",
        "ORTBEZ18": "Onnens FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1772",
        "ORTBEZ18": "Grolley",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1776",
        "ORTBEZ18": "Montagny-la-Ville",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1797",
        "ORTBEZ18": "Münchenwiler",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1793",
        "ORTBEZ18": "Jeuss",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1791",
        "ORTBEZ18": "Courtaman",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1800",
        "ORTBEZ18": "Vevey",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1800",
        "ORTBEZ18": "Vevey 1 Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1801",
        "ORTBEZ18": "Le Mont-Pèlerin",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1803",
        "ORTBEZ18": "Chardonne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1807",
        "ORTBEZ18": "Blonay",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1071",
        "ORTBEZ18": "St-Saphorin-Lavaux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1814",
        "ORTBEZ18": "La Tour-de-Peilz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1817",
        "ORTBEZ18": "Brent",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1820",
        "ORTBEZ18": "Montreux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1820",
        "ORTBEZ18": "Montreux 1",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1660",
        "ORTBEZ18": "L'Etivaz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1659",
        "ORTBEZ18": "Rougemont",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1847",
        "ORTBEZ18": "Rennaz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1846",
        "ORTBEZ18": "Chessel",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1866",
        "ORTBEZ18": "La Forclaz VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1863",
        "ORTBEZ18": "Le Sépey",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1864",
        "ORTBEZ18": "Vers-l'Eglise",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1880",
        "ORTBEZ18": "Fenalet-sur-Bex",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1880",
        "ORTBEZ18": "Frenières-sur-Bex",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1882",
        "ORTBEZ18": "Gryon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1884",
        "ORTBEZ18": "Villars-sur-Ollon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1890",
        "ORTBEZ18": "St-Maurice",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1891",
        "ORTBEZ18": "Vérossaz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1869",
        "ORTBEZ18": "Massongex",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1895",
        "ORTBEZ18": "Vionnaz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1897",
        "ORTBEZ18": "Les Evouettes",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1899",
        "ORTBEZ18": "Torgon",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1892",
        "ORTBEZ18": "Lavey-Village",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1893",
        "ORTBEZ18": "Muraz (Collombey)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1896",
        "ORTBEZ18": "Miex",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1897",
        "ORTBEZ18": "Bouveret",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1903",
        "ORTBEZ18": "Collonges",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1912",
        "ORTBEZ18": "Leytron",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1918",
        "ORTBEZ18": "La Tzoumaz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1920",
        "ORTBEZ18": "Martigny",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1920",
        "ORTBEZ18": "Martigny Bourg",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1923",
        "ORTBEZ18": "Les Marécottes",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1925",
        "ORTBEZ18": "Finhaut",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1926",
        "ORTBEZ18": "Fully",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1947",
        "ORTBEZ18": "Versegères",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1942",
        "ORTBEZ18": "Levron",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1948",
        "ORTBEZ18": "Fionnay",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1950",
        "ORTBEZ18": "Sion 1 Dist",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1950",
        "ORTBEZ18": "Sion 2",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1974",
        "ORTBEZ18": "Arbaz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1982",
        "ORTBEZ18": "Euseigne",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1985",
        "ORTBEZ18": "La Sage",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1992",
        "ORTBEZ18": "Les Agettes",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1993",
        "ORTBEZ18": "Veysonnaz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1996",
        "ORTBEZ18": "Baar (Nendaz)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1963",
        "ORTBEZ18": "Vétroz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "2004",
        "ORTBEZ18": "Neuchâtel Ecluse",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2009",
        "ORTBEZ18": "Neuchâtel La C.",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2015",
        "ORTBEZ18": "Areuse",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2023",
        "ORTBEZ18": "Gorgier",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2024",
        "ORTBEZ18": "St-Aubin-Sauges",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2028",
        "ORTBEZ18": "Vaumarcus",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2034",
        "ORTBEZ18": "Peseux",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2035",
        "ORTBEZ18": "Corcelles NE",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2043",
        "ORTBEZ18": "Boudevilliers",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2054",
        "ORTBEZ18": "Chézard-St-Martin",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2067",
        "ORTBEZ18": "Chaumont",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2068",
        "ORTBEZ18": "Hauterive NE",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2318",
        "ORTBEZ18": "Brot-Plamboz",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2105",
        "ORTBEZ18": "Travers",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2115",
        "ORTBEZ18": "Buttes",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2126",
        "ORTBEZ18": "Les Verrières",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2149",
        "ORTBEZ18": "Champ-du-Moulin",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2206",
        "ORTBEZ18": "Geneveys-Coffrane",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2304",
        "ORTBEZ18": "La Chx-de-F. Eplat",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2325",
        "ORTBEZ18": "Les Planchettes",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2300",
        "ORTBEZ18": "La Cibourg",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2336",
        "ORTBEZ18": "Les Bois",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2406",
        "ORTBEZ18": "La Châtagne",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2405",
        "ORTBEZ18": "La Chaux-du-Milieu",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2400",
        "ORTBEZ18": "Le Prévoux",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2414",
        "ORTBEZ18": "Cerneux-Péquignot",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2500",
        "ORTBEZ18": "Biel/Bienne 1",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2500",
        "ORTBEZ18": "Biel/Bienne 6",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2514",
        "ORTBEZ18": "Ligerz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2525",
        "ORTBEZ18": "Le Landeron",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2537",
        "ORTBEZ18": "Vauffelin",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2542",
        "ORTBEZ18": "Pieterlen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2543",
        "ORTBEZ18": "Lengnau BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2544",
        "ORTBEZ18": "Bettlach",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "2560",
        "ORTBEZ18": "Nidau",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2576",
        "ORTBEZ18": "Lüscherz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3237",
        "ORTBEZ18": "Brüttelen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2603",
        "ORTBEZ18": "Péry",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2604",
        "ORTBEZ18": "La Heutte",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2607",
        "ORTBEZ18": "Cortébert",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2608",
        "ORTBEZ18": "Mtgne-Courtelary",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2610",
        "ORTBEZ18": "Mont-Crosin",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2615",
        "ORTBEZ18": "Sonvilier",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2718",
        "ORTBEZ18": "Fornet-Dessus",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2718",
        "ORTBEZ18": "Lajoux JU",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2720",
        "ORTBEZ18": "La Tanne",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2722",
        "ORTBEZ18": "Les Reussilles",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2345",
        "ORTBEZ18": "Chaux-des-Breuleux",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2350",
        "ORTBEZ18": "Saignelégier",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2732",
        "ORTBEZ18": "Saicourt",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2732",
        "ORTBEZ18": "Saules BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2733",
        "ORTBEZ18": "Pontenet",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2735",
        "ORTBEZ18": "Malleray-Bévilard",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2735",
        "ORTBEZ18": "Bévilard",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2738",
        "ORTBEZ18": "Court",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2747",
        "ORTBEZ18": "Seehof",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2745",
        "ORTBEZ18": "Grandval",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2832",
        "ORTBEZ18": "Rebeuvelier",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2830",
        "ORTBEZ18": "Courrendlin",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2800",
        "ORTBEZ18": "Delémont 1",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2800",
        "ORTBEZ18": "Delémont 2",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2806",
        "ORTBEZ18": "Mettembert",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2807",
        "ORTBEZ18": "Pleigne",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2812",
        "ORTBEZ18": "Movelier",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2813",
        "ORTBEZ18": "Ederswiler",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2823",
        "ORTBEZ18": "Courcelon",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2825",
        "ORTBEZ18": "Courchapoix",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2842",
        "ORTBEZ18": "Rossemaison",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2853",
        "ORTBEZ18": "Courfaivre",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2856",
        "ORTBEZ18": "Boécourt",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2883",
        "ORTBEZ18": "Montmelon",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2885",
        "ORTBEZ18": "Epauvillers",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2887",
        "ORTBEZ18": "Soubey",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2950",
        "ORTBEZ18": "Courgenay",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2900",
        "ORTBEZ18": "Porrentruy 1",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2903",
        "ORTBEZ18": "Villars-Fontenais",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2904",
        "ORTBEZ18": "Bressaucourt",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2907",
        "ORTBEZ18": "Rocourt",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2914",
        "ORTBEZ18": "Damvant",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2925",
        "ORTBEZ18": "Buix",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2926",
        "ORTBEZ18": "Boncourt",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2932",
        "ORTBEZ18": "Coeuve",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2935",
        "ORTBEZ18": "Beurnevésin",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2942",
        "ORTBEZ18": "Alle",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2944",
        "ORTBEZ18": "Bonfol",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2952",
        "ORTBEZ18": "Cornol",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2954",
        "ORTBEZ18": "Asuel",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern Europaplatz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern 9",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern 22",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3002",
        "ORTBEZ18": "Bern PostFinance",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3007",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3010",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3011",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3014",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3018",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3033",
        "ORTBEZ18": "Wohlen b. Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3035",
        "ORTBEZ18": "Frieswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3038",
        "ORTBEZ18": "Kirchlindach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3046",
        "ORTBEZ18": "Wahlendorf",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3063",
        "ORTBEZ18": "Ittigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3066",
        "ORTBEZ18": "Stettlen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3068",
        "ORTBEZ18": "Utzigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3073",
        "ORTBEZ18": "Gümligen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3077",
        "ORTBEZ18": "Enggistein",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3088",
        "ORTBEZ18": "Oberbütschel",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3086",
        "ORTBEZ18": "Zimmerwald",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3096",
        "ORTBEZ18": "Oberbalm",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3098",
        "ORTBEZ18": "Schliern b. Köniz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3110",
        "ORTBEZ18": "Münsingen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3116",
        "ORTBEZ18": "Kirchdorf BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3122",
        "ORTBEZ18": "Kehrsatz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3125",
        "ORTBEZ18": "Toffen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3126",
        "ORTBEZ18": "Kaufdorf",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3128",
        "ORTBEZ18": "Kirchenthurnen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3132",
        "ORTBEZ18": "Riggisberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3664",
        "ORTBEZ18": "Burgistein",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3662",
        "ORTBEZ18": "Seftigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3663",
        "ORTBEZ18": "Gurzelen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3144",
        "ORTBEZ18": "Gasel",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3147",
        "ORTBEZ18": "Mittelhäusern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3148",
        "ORTBEZ18": "Lanzenhäusern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3152",
        "ORTBEZ18": "Mamishaus",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3156",
        "ORTBEZ18": "Riffenmatt",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3175",
        "ORTBEZ18": "Flamatt",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3176",
        "ORTBEZ18": "Neuenegg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3177",
        "ORTBEZ18": "Laupen BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3182",
        "ORTBEZ18": "Ueberstorf",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3203",
        "ORTBEZ18": "Mühleberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3206",
        "ORTBEZ18": "Rizenbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3215",
        "ORTBEZ18": "Gempenach",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3212",
        "ORTBEZ18": "Gurmels",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3232",
        "ORTBEZ18": "Ins",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3233",
        "ORTBEZ18": "Tschugg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3234",
        "ORTBEZ18": "Vinelz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3236",
        "ORTBEZ18": "Gampelen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3250",
        "ORTBEZ18": "Lyss Zustellung",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3254",
        "ORTBEZ18": "Messen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "3262",
        "ORTBEZ18": "Suberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3263",
        "ORTBEZ18": "Büetigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3273",
        "ORTBEZ18": "Kappelen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3274",
        "ORTBEZ18": "Hermrigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3280",
        "ORTBEZ18": "Meyriez",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3282",
        "ORTBEZ18": "Bargen BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3292",
        "ORTBEZ18": "Busswil BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3294",
        "ORTBEZ18": "Büren an der Aare",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3295",
        "ORTBEZ18": "Rüti b. Büren",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3297",
        "ORTBEZ18": "Leuzigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3302",
        "ORTBEZ18": "Moosseedorf",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3312",
        "ORTBEZ18": "Fraubrunnen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3325",
        "ORTBEZ18": "Hettiswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3303",
        "ORTBEZ18": "Zuzwil BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3305",
        "ORTBEZ18": "Iffwil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3306",
        "ORTBEZ18": "Etzelkofen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3317",
        "ORTBEZ18": "Limpach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3360",
        "ORTBEZ18": "Herzogenbuchsee",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4556",
        "ORTBEZ18": "Aeschi SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "3374",
        "ORTBEZ18": "Wangenried",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3362",
        "ORTBEZ18": "Niederönz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3363",
        "ORTBEZ18": "Oberönz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3368",
        "ORTBEZ18": "Bleienbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3401",
        "ORTBEZ18": "Burgdorf",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3412",
        "ORTBEZ18": "Heimiswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3414",
        "ORTBEZ18": "Oberburg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3416",
        "ORTBEZ18": "Affoltern BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3417",
        "ORTBEZ18": "Rüegsau",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3418",
        "ORTBEZ18": "Rüegsbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3419",
        "ORTBEZ18": "Biembach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3421",
        "ORTBEZ18": "Lyssach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3422",
        "ORTBEZ18": "Kirchberg BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3422",
        "ORTBEZ18": "Rüdtligen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3423",
        "ORTBEZ18": "Ersigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3427",
        "ORTBEZ18": "Utzenstorf",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3438",
        "ORTBEZ18": "Lauperswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3454",
        "ORTBEZ18": "Sumiswald",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3456",
        "ORTBEZ18": "Trachselwald",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3473",
        "ORTBEZ18": "Alchenstorf",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3475",
        "ORTBEZ18": "Hermiswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3476",
        "ORTBEZ18": "Oschwand",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3111",
        "ORTBEZ18": "Tägertschi",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3503",
        "ORTBEZ18": "Gysenstein",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3512",
        "ORTBEZ18": "Walkringen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3415",
        "ORTBEZ18": "Schafhausen i. E.",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3671",
        "ORTBEZ18": "Brenzikofen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3533",
        "ORTBEZ18": "Bowil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3538",
        "ORTBEZ18": "Röthenbach i. E.",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3553",
        "ORTBEZ18": "Gohl",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3600",
        "ORTBEZ18": "Thun 2 Zustellung",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3601",
        "ORTBEZ18": "Thun Aarezentrum",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3602",
        "ORTBEZ18": "Thun",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3605",
        "ORTBEZ18": "Thun",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3608",
        "ORTBEZ18": "Thun",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3618",
        "ORTBEZ18": "Süderen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3619",
        "ORTBEZ18": "Eriz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3622",
        "ORTBEZ18": "Homberg b. Thun",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3635",
        "ORTBEZ18": "Uebeschi",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3616",
        "ORTBEZ18": "Schwarzenegg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3623",
        "ORTBEZ18": "Teuffenthal BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3625",
        "ORTBEZ18": "Heiligenschwendi",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3626",
        "ORTBEZ18": "Hünibach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3646",
        "ORTBEZ18": "Einigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3654",
        "ORTBEZ18": "Gunten",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3655",
        "ORTBEZ18": "Sigriswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3711",
        "ORTBEZ18": "Emdthal",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3714",
        "ORTBEZ18": "Frutigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3717",
        "ORTBEZ18": "Blausee-Mitholz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3725",
        "ORTBEZ18": "Achseten",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3752",
        "ORTBEZ18": "Wimmis",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3753",
        "ORTBEZ18": "Oey",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3755",
        "ORTBEZ18": "Horboden",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3758",
        "ORTBEZ18": "Latterbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3765",
        "ORTBEZ18": "Oberwil i. S.",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3770",
        "ORTBEZ18": "Zweisimmen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3771",
        "ORTBEZ18": "Blankenburg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3773",
        "ORTBEZ18": "Matten(St.Stephan)",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3785",
        "ORTBEZ18": "Gsteig b. Gstaad",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3800",
        "ORTBEZ18": "Unterseen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3804",
        "ORTBEZ18": "Habkern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3813",
        "ORTBEZ18": "Saxeten",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3818",
        "ORTBEZ18": "Grindelwald",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3822",
        "ORTBEZ18": "Lauterbrunnen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3823",
        "ORTBEZ18": "Wengen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3825",
        "ORTBEZ18": "Mürren",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3854",
        "ORTBEZ18": "Oberried Brienz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3855",
        "ORTBEZ18": "Brienz BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3855",
        "ORTBEZ18": "Axalp",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3864",
        "ORTBEZ18": "Guttannen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3903",
        "ORTBEZ18": "Birgisch",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3914",
        "ORTBEZ18": "Belalp",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3907",
        "ORTBEZ18": "Simplon Hospiz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3907",
        "ORTBEZ18": "Simplon Dorf",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3910",
        "ORTBEZ18": "Saas-Grund",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3917",
        "ORTBEZ18": "Goppenstein",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3926",
        "ORTBEZ18": "Embd",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3927",
        "ORTBEZ18": "Herbriggen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3922",
        "ORTBEZ18": "Stalden VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3924",
        "ORTBEZ18": "St. Niklaus VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3937",
        "ORTBEZ18": "Baltschieder",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3930",
        "ORTBEZ18": "Eyholz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3932",
        "ORTBEZ18": "Visperterminen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3943",
        "ORTBEZ18": "Eischoll",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3955",
        "ORTBEZ18": "Albinen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3957",
        "ORTBEZ18": "Erschmatt",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3976",
        "ORTBEZ18": "Noës",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3953",
        "ORTBEZ18": "Leuk Stadt",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3954",
        "ORTBEZ18": "Leukerbad",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3960",
        "ORTBEZ18": "Corin-de-la-Crête",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3960",
        "ORTBEZ18": "Loc",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3971",
        "ORTBEZ18": "Chermignon",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3974",
        "ORTBEZ18": "Mollens VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3961",
        "ORTBEZ18": "Chandolin",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3961",
        "ORTBEZ18": "Ayer",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3963",
        "ORTBEZ18": "Crans-Montana",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3968",
        "ORTBEZ18": "Veyras",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3998",
        "ORTBEZ18": "Gluringen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3998",
        "ORTBEZ18": "Reckingen VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3999",
        "ORTBEZ18": "Oberwald",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3987",
        "ORTBEZ18": "Riederalp",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3988",
        "ORTBEZ18": "Ulrichen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "4000",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4001",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4004",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4005",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4007",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4009",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4012",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4017",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4021",
        "ORTBEZ18": "Basel Sort",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4032",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4055",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4058",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4091",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4101",
        "ORTBEZ18": "Bruderholz",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4104",
        "ORTBEZ18": "Oberwil BL",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4108",
        "ORTBEZ18": "Witterswil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4116",
        "ORTBEZ18": "Metzerlen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4123",
        "ORTBEZ18": "Allschwil 1",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4133",
        "ORTBEZ18": "Pratteln 1",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4142",
        "ORTBEZ18": "Münchenstein",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4148",
        "ORTBEZ18": "Pfeffingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4153",
        "ORTBEZ18": "Reinach BL 1",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4203",
        "ORTBEZ18": "Grellingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4206",
        "ORTBEZ18": "Seewen SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4208",
        "ORTBEZ18": "Nunningen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4225",
        "ORTBEZ18": "Brislach",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4228",
        "ORTBEZ18": "Erschwil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4243",
        "ORTBEZ18": "Dittingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4223",
        "ORTBEZ18": "Blauen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4252",
        "ORTBEZ18": "Bärschwil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4254",
        "ORTBEZ18": "Liesberg Dorf",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4303",
        "ORTBEZ18": "Kaiseraugst Lieb.",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4305",
        "ORTBEZ18": "Olsberg",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4310",
        "ORTBEZ18": "Rheinfelden 1",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4310",
        "ORTBEZ18": "Rheinfelden 2",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4313",
        "ORTBEZ18": "Möhlin",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4314",
        "ORTBEZ18": "Zeiningen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4317",
        "ORTBEZ18": "Wegenstetten",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4323",
        "ORTBEZ18": "Wallbach",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4325",
        "ORTBEZ18": "Schupfart",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5275",
        "ORTBEZ18": "Etzgen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5326",
        "ORTBEZ18": "Schwaderloch",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5324",
        "ORTBEZ18": "Full-Reuenthal",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4410",
        "ORTBEZ18": "Liestal",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4411",
        "ORTBEZ18": "Seltisberg",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4417",
        "ORTBEZ18": "Ziefen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4424",
        "ORTBEZ18": "Arboldswil",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4426",
        "ORTBEZ18": "Lauwil",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4432",
        "ORTBEZ18": "Lampenberg",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4436",
        "ORTBEZ18": "Oberdorf BL",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4438",
        "ORTBEZ18": "Langenbruck",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4441",
        "ORTBEZ18": "Thürnen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4444",
        "ORTBEZ18": "Rümlingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4446",
        "ORTBEZ18": "Buckten",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4447",
        "ORTBEZ18": "Känerkinden",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4453",
        "ORTBEZ18": "Nusshof",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4456",
        "ORTBEZ18": "Tenniken",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4457",
        "ORTBEZ18": "Diegten",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4458",
        "ORTBEZ18": "Eptingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4465",
        "ORTBEZ18": "Hemmiken",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4463",
        "ORTBEZ18": "Buus",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4468",
        "ORTBEZ18": "Kienberg",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4493",
        "ORTBEZ18": "Wenslingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4496",
        "ORTBEZ18": "Kilchberg BL",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4500",
        "ORTBEZ18": "Solothurn 1 Zust",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4502",
        "ORTBEZ18": "Solothurn",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4535",
        "ORTBEZ18": "Hubersdorf",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4558",
        "ORTBEZ18": "Hersiwil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4512",
        "ORTBEZ18": "Bellach",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4514",
        "ORTBEZ18": "Lommiswil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4522",
        "ORTBEZ18": "Rüttenen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4528",
        "ORTBEZ18": "Zuchwil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4533",
        "ORTBEZ18": "Riedholz",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4536",
        "ORTBEZ18": "Attiswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4537",
        "ORTBEZ18": "Wiedlisbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4553",
        "ORTBEZ18": "Subingen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4563",
        "ORTBEZ18": "Gerlafingen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4564",
        "ORTBEZ18": "Zielebach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4586",
        "ORTBEZ18": "Kyburg-Buchegg",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4579",
        "ORTBEZ18": "Gossliwil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4585",
        "ORTBEZ18": "Biezwil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4588",
        "ORTBEZ18": "Unterramsern",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4588",
        "ORTBEZ18": "Oberramsern",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4574",
        "ORTBEZ18": "Lüsslingen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4613",
        "ORTBEZ18": "Rickenbach SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4625",
        "ORTBEZ18": "Oberbuchsiten",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4626",
        "ORTBEZ18": "Niederbuchsiten",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4652",
        "ORTBEZ18": "Winznau",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4655",
        "ORTBEZ18": "Stüsslingen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4656",
        "ORTBEZ18": "Starrkirch-Wil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4658",
        "ORTBEZ18": "Däniken SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4665",
        "ORTBEZ18": "Oftringen 1",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4615",
        "ORTBEZ18": "Allerheiligenberg",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4702",
        "ORTBEZ18": "Oensingen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4704",
        "ORTBEZ18": "Niederbipp",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4543",
        "ORTBEZ18": "Deitingen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4710",
        "ORTBEZ18": "Balsthal",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4716",
        "ORTBEZ18": "Welschenrohr",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4717",
        "ORTBEZ18": "Mümliswil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4718",
        "ORTBEZ18": "Holderbank SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4801",
        "ORTBEZ18": "Zofingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4802",
        "ORTBEZ18": "Strengelbach",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4803",
        "ORTBEZ18": "Vordemwald",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4805",
        "ORTBEZ18": "Brittnau",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4812",
        "ORTBEZ18": "Mühlethal",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4856",
        "ORTBEZ18": "Glashütten",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4901",
        "ORTBEZ18": "Langenthal",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4955",
        "ORTBEZ18": "Gondiswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4932",
        "ORTBEZ18": "Lotzwil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4936",
        "ORTBEZ18": "Kleindietwil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4944",
        "ORTBEZ18": "Auswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4938",
        "ORTBEZ18": "Rohrbachgraben",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4950",
        "ORTBEZ18": "Huttwil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4953",
        "ORTBEZ18": "Schwarzenbach BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "5000",
        "ORTBEZ18": "Aarau Postauto AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5012",
        "ORTBEZ18": "Schönenwerd",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "5034",
        "ORTBEZ18": "Suhr",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5036",
        "ORTBEZ18": "Oberentfelden",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5040",
        "ORTBEZ18": "Schöftland",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5042",
        "ORTBEZ18": "Hirschthal",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5043",
        "ORTBEZ18": "Holziken",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5044",
        "ORTBEZ18": "Schlossrued",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5053",
        "ORTBEZ18": "Staffelbach",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5054",
        "ORTBEZ18": "Kirchleerau",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5054",
        "ORTBEZ18": "Moosleerau",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5056",
        "ORTBEZ18": "Attelwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5057",
        "ORTBEZ18": "Reitnau",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5102",
        "ORTBEZ18": "Rupperswil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5103",
        "ORTBEZ18": "Wildegg",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5106",
        "ORTBEZ18": "Veltheim AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5112",
        "ORTBEZ18": "Thalheim AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5200",
        "ORTBEZ18": "Brugg AG 1",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5210",
        "ORTBEZ18": "Windisch",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5200",
        "ORTBEZ18": "Brugg AG Kaserne",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5234",
        "ORTBEZ18": "Villigen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5236",
        "ORTBEZ18": "Remigen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5237",
        "ORTBEZ18": "Mönthal",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5242",
        "ORTBEZ18": "Birr",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5242",
        "ORTBEZ18": "Lupfig",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5243",
        "ORTBEZ18": "Mülligen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5070",
        "ORTBEZ18": "Frick",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5312",
        "ORTBEZ18": "Döttingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5322",
        "ORTBEZ18": "Koblenz",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5400",
        "ORTBEZ18": "Baden",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5401",
        "ORTBEZ18": "Baden",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5402",
        "ORTBEZ18": "Baden",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5404",
        "ORTBEZ18": "Baden",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5405",
        "ORTBEZ18": "Baden",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5415",
        "ORTBEZ18": "Nussbaumen AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5423",
        "ORTBEZ18": "Freienwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5425",
        "ORTBEZ18": "Schneisingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5426",
        "ORTBEZ18": "Lengnau AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5430",
        "ORTBEZ18": "Wettingen 1",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5430",
        "ORTBEZ18": "Wettingen Dorf",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5430",
        "ORTBEZ18": "Wettingen 3",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5442",
        "ORTBEZ18": "Fislisbach",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5443",
        "ORTBEZ18": "Niederrohrdorf",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5445",
        "ORTBEZ18": "Eggenwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5452",
        "ORTBEZ18": "Oberrohrdorf",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5502",
        "ORTBEZ18": "Hunzenschwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5505",
        "ORTBEZ18": "Brunegg",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5507",
        "ORTBEZ18": "Mellingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5512",
        "ORTBEZ18": "Wohlenschwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5524",
        "ORTBEZ18": "Nesselnbach",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5524",
        "ORTBEZ18": "Niederwil AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5600",
        "ORTBEZ18": "Lenzburg",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5600",
        "ORTBEZ18": "Ammerswil AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5603",
        "ORTBEZ18": "Staufen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5605",
        "ORTBEZ18": "Dottikon",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5607",
        "ORTBEZ18": "Hägglingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5608",
        "ORTBEZ18": "Stetten AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5610",
        "ORTBEZ18": "Wohlen AG Jurastr",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5619",
        "ORTBEZ18": "Büttikon AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5617",
        "ORTBEZ18": "Tennwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5620",
        "ORTBEZ18": "Bremgarten AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5620",
        "ORTBEZ18": "Bremgarten AG Kas",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5622",
        "ORTBEZ18": "Waltenschwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5626",
        "ORTBEZ18": "Hermetschwil-Staff",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5628",
        "ORTBEZ18": "Aristau",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5630",
        "ORTBEZ18": "Muri AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5637",
        "ORTBEZ18": "Beinwil (Freiamt)",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5643",
        "ORTBEZ18": "Sins",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5643",
        "ORTBEZ18": "Alikon",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5704",
        "ORTBEZ18": "Egliswil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5705",
        "ORTBEZ18": "Hallwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5712",
        "ORTBEZ18": "Beinwil am See",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5722",
        "ORTBEZ18": "Gränichen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5723",
        "ORTBEZ18": "Teufenthal AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5724",
        "ORTBEZ18": "Dürrenäsch",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5725",
        "ORTBEZ18": "Leutwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5726",
        "ORTBEZ18": "Unterkulm",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5728",
        "ORTBEZ18": "Gontenschwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5732",
        "ORTBEZ18": "Zetzwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5737",
        "ORTBEZ18": "Menziken",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5745",
        "ORTBEZ18": "Safenwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern Wesemlin",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern Kreuzstutz",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern Schönbühl",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern 16",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6004",
        "ORTBEZ18": "Luzern",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6012",
        "ORTBEZ18": "Obernau",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6014",
        "ORTBEZ18": "Luzern",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6017",
        "ORTBEZ18": "Ruswil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6020",
        "ORTBEZ18": "Emmenbrücke Bahnh.",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6025",
        "ORTBEZ18": "Neudorf",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6028",
        "ORTBEZ18": "Herlisberg",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6035",
        "ORTBEZ18": "Perlen",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6037",
        "ORTBEZ18": "Root",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6048",
        "ORTBEZ18": "Horw",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6052",
        "ORTBEZ18": "Hergiswil NW",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6060",
        "ORTBEZ18": "Ramersberg",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6064",
        "ORTBEZ18": "Kerns",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6067",
        "ORTBEZ18": "Melchtal",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6072",
        "ORTBEZ18": "Sachseln",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6074",
        "ORTBEZ18": "Giswil",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6105",
        "ORTBEZ18": "Schachen LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6106",
        "ORTBEZ18": "Werthenstein",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6110",
        "ORTBEZ18": "Wolhusen",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6110",
        "ORTBEZ18": "Fontannen",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6122",
        "ORTBEZ18": "Menznau",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6123",
        "ORTBEZ18": "Geiss",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6126",
        "ORTBEZ18": "Daiwil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6132",
        "ORTBEZ18": "Rohrmatt",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6154",
        "ORTBEZ18": "Hofstatt",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6160",
        "ORTBEZ18": "Entlebuch Business",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6166",
        "ORTBEZ18": "Hasle LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6182",
        "ORTBEZ18": "Escholzmatt",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6196",
        "ORTBEZ18": "Marbach LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6204",
        "ORTBEZ18": "Sempach",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6207",
        "ORTBEZ18": "Nottwil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6208",
        "ORTBEZ18": "Oberkirch LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6210",
        "ORTBEZ18": "Sursee",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6211",
        "ORTBEZ18": "Buchs LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6214",
        "ORTBEZ18": "Schenkon",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6221",
        "ORTBEZ18": "Rickenbach LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6244",
        "ORTBEZ18": "Nebikon",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6246",
        "ORTBEZ18": "Altishofen",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6248",
        "ORTBEZ18": "Alberswil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6252",
        "ORTBEZ18": "Dagmersellen",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6260",
        "ORTBEZ18": "Reiden",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6264",
        "ORTBEZ18": "Pfaffnau",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6284",
        "ORTBEZ18": "Gelfingen",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6286",
        "ORTBEZ18": "Altwis",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6288",
        "ORTBEZ18": "Schongau",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6300",
        "ORTBEZ18": "Zug Altstadt",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6313",
        "ORTBEZ18": "Finstersee",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6313",
        "ORTBEZ18": "Menzingen",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6314",
        "ORTBEZ18": "Neuägeri",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6315",
        "ORTBEZ18": "Oberägeri",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6317",
        "ORTBEZ18": "Oberwil b. Zug",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6318",
        "ORTBEZ18": "Walchwil",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6330",
        "ORTBEZ18": "Cham",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6343",
        "ORTBEZ18": "Rotkreuz",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6362",
        "ORTBEZ18": "Stansstad",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6363",
        "ORTBEZ18": "Obbürgen",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6375",
        "ORTBEZ18": "Beckenried",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6377",
        "ORTBEZ18": "Seelisberg",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6383",
        "ORTBEZ18": "Dallenwil",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6387",
        "ORTBEZ18": "Oberrickenbach",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6390",
        "ORTBEZ18": "Engelberg",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6405",
        "ORTBEZ18": "Immensee",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6410",
        "ORTBEZ18": "Goldau",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6410",
        "ORTBEZ18": "Rigi Kulm",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6414",
        "ORTBEZ18": "Oberarth",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6422",
        "ORTBEZ18": "Steinen",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6423",
        "ORTBEZ18": "Seewen SZ",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6424",
        "ORTBEZ18": "Lauerz",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6436",
        "ORTBEZ18": "Muotathal",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6436",
        "ORTBEZ18": "Bisisthal",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6438",
        "ORTBEZ18": "Ibach",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6454",
        "ORTBEZ18": "Flüelen",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6462",
        "ORTBEZ18": "Seedorf UR",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6463",
        "ORTBEZ18": "Bürglen UR",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6467",
        "ORTBEZ18": "Schattdorf",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6473",
        "ORTBEZ18": "Silenen",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6476",
        "ORTBEZ18": "Intschi",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6482",
        "ORTBEZ18": "Gurtnellen",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6490",
        "ORTBEZ18": "Andermatt",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6500",
        "ORTBEZ18": "Bellinzona",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6500",
        "ORTBEZ18": "Bellinzona 2",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6500",
        "ORTBEZ18": "Bellinzona 5",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6500",
        "ORTBEZ18": "Bellinzona Dist",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6513",
        "ORTBEZ18": "Monte Carasso",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6515",
        "ORTBEZ18": "Gudo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6516",
        "ORTBEZ18": "Cugnasco",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6527",
        "ORTBEZ18": "Lodrino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6528",
        "ORTBEZ18": "Camorino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6537",
        "ORTBEZ18": "Grono",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6583",
        "ORTBEZ18": "S. Antonio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6549",
        "ORTBEZ18": "Laura",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6541",
        "ORTBEZ18": "Sta. Maria",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6544",
        "ORTBEZ18": "Braggio",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6572",
        "ORTBEZ18": "Quartino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6577",
        "ORTBEZ18": "Ranzo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6579",
        "ORTBEZ18": "Piazzogna",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6592",
        "ORTBEZ18": "S. Antonino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6593",
        "ORTBEZ18": "Cadenazzo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6594",
        "ORTBEZ18": "Contone",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6596",
        "ORTBEZ18": "Gordola",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6598",
        "ORTBEZ18": "Tenero",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6599",
        "ORTBEZ18": "Robasacco",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6600",
        "ORTBEZ18": "Muralto",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6604",
        "ORTBEZ18": "Locarno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6605",
        "ORTBEZ18": "Locarno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6618",
        "ORTBEZ18": "Arcegno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6647",
        "ORTBEZ18": "Mergoscia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6661",
        "ORTBEZ18": "Auressio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6611",
        "ORTBEZ18": "Mosogno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6664",
        "ORTBEZ18": "Vergeletto",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6611",
        "ORTBEZ18": "Gresso",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6611",
        "ORTBEZ18": "Crana",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6637",
        "ORTBEZ18": "Sonogno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6613",
        "ORTBEZ18": "Porto Ronco",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6614",
        "ORTBEZ18": "Brissago",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6616",
        "ORTBEZ18": "Losone",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6658",
        "ORTBEZ18": "Borgnone",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6652",
        "ORTBEZ18": "Tegna",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6653",
        "ORTBEZ18": "Verscio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6654",
        "ORTBEZ18": "Cavigliano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6677",
        "ORTBEZ18": "Moghegno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6678",
        "ORTBEZ18": "Lodano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6678",
        "ORTBEZ18": "Giumaglio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6674",
        "ORTBEZ18": "Riveo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6683",
        "ORTBEZ18": "Niva (Vallemaggia)",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6692",
        "ORTBEZ18": "Menzonio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6694",
        "ORTBEZ18": "Prato-Sornico",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6673",
        "ORTBEZ18": "Maggia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6674",
        "ORTBEZ18": "Someo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6675",
        "ORTBEZ18": "Cevio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6703",
        "ORTBEZ18": "Osogna",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6716",
        "ORTBEZ18": "Leontica",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6723",
        "ORTBEZ18": "Prugiasco",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6724",
        "ORTBEZ18": "Ponto Valentino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6719",
        "ORTBEZ18": "Aquila",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6720",
        "ORTBEZ18": "Campo (Blenio)",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6715",
        "ORTBEZ18": "Dongio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6716",
        "ORTBEZ18": "Acquarossa",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6718",
        "ORTBEZ18": "Olivone",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6718",
        "ORTBEZ18": "Camperio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6742",
        "ORTBEZ18": "Pollegio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6743",
        "ORTBEZ18": "Bodio TI",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6746",
        "ORTBEZ18": "Lavorgo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6746",
        "ORTBEZ18": "Calonico",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6746",
        "ORTBEZ18": "Nivo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6747",
        "ORTBEZ18": "Chironico",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6760",
        "ORTBEZ18": "Faido",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6777",
        "ORTBEZ18": "Varenzo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6749",
        "ORTBEZ18": "Sobrio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6749",
        "ORTBEZ18": "Cavagnago",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6763",
        "ORTBEZ18": "Osco",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6817",
        "ORTBEZ18": "Maroggia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6818",
        "ORTBEZ18": "Melano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6822",
        "ORTBEZ18": "Arogno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6830",
        "ORTBEZ18": "Chiasso",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6839",
        "ORTBEZ18": "Sagno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6837",
        "ORTBEZ18": "Caneggio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6837",
        "ORTBEZ18": "Bruzella",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6834",
        "ORTBEZ18": "Morbio Inferiore",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6809",
        "ORTBEZ18": "Medeglia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6810",
        "ORTBEZ18": "Isone",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6873",
        "ORTBEZ18": "Corteglia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6853",
        "ORTBEZ18": "Ligornetto",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6867",
        "ORTBEZ18": "Serpiano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6872",
        "ORTBEZ18": "Somazzo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6874",
        "ORTBEZ18": "Castel San Pietro",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6877",
        "ORTBEZ18": "Coldrerio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6901",
        "ORTBEZ18": "Lugano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6902",
        "ORTBEZ18": "Lugano 2 Caselle",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6904",
        "ORTBEZ18": "Lugano 4 Caselle",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6917",
        "ORTBEZ18": "Barbengo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6979",
        "ORTBEZ18": "Brè sopra Lugano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6912",
        "ORTBEZ18": "Pazzallo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6922",
        "ORTBEZ18": "Morcote",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6924",
        "ORTBEZ18": "Sorengo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6935",
        "ORTBEZ18": "Bosco Luganese",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6942",
        "ORTBEZ18": "Savosa",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6943",
        "ORTBEZ18": "Vezia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6948",
        "ORTBEZ18": "Porza",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6944",
        "ORTBEZ18": "Cureglia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6947",
        "ORTBEZ18": "Vaglio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6954",
        "ORTBEZ18": "Sala Capriasca",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6951",
        "ORTBEZ18": "Scareglia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6951",
        "ORTBEZ18": "Colla",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6951",
        "ORTBEZ18": "Bogno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6951",
        "ORTBEZ18": "Cozzo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6959",
        "ORTBEZ18": "Maglio di Colla",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6952",
        "ORTBEZ18": "Canobbio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6964",
        "ORTBEZ18": "Davesco-Soragno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6966",
        "ORTBEZ18": "Villa Luganese",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6991",
        "ORTBEZ18": "Neggio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6994",
        "ORTBEZ18": "Aranno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6992",
        "ORTBEZ18": "Cimo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6980",
        "ORTBEZ18": "Castelrotto",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6981",
        "ORTBEZ18": "Bombinasco",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6982",
        "ORTBEZ18": "Agno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6984",
        "ORTBEZ18": "Pura",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6987",
        "ORTBEZ18": "Caslano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "7000",
        "ORTBEZ18": "Chur",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7000",
        "ORTBEZ18": "Chur Kaserne",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7001",
        "ORTBEZ18": "Chur",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7015",
        "ORTBEZ18": "Tamins",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7017",
        "ORTBEZ18": "Flims Dorf",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7018",
        "ORTBEZ18": "Flims Waldhaus",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7031",
        "ORTBEZ18": "Laax GR",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7063",
        "ORTBEZ18": "Praden",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7064",
        "ORTBEZ18": "Tschiertschen",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7075",
        "ORTBEZ18": "Churwalden",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7078",
        "ORTBEZ18": "Lenzerheide/Lai",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7082",
        "ORTBEZ18": "Vaz/Obervaz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7083",
        "ORTBEZ18": "Lantsch/Lenz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7014",
        "ORTBEZ18": "Trin",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7027",
        "ORTBEZ18": "Castiel",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7028",
        "ORTBEZ18": "Pagig",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7058",
        "ORTBEZ18": "Litzirüti",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7104",
        "ORTBEZ18": "Versam",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7109",
        "ORTBEZ18": "Thalkirch",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7155",
        "ORTBEZ18": "Ladir",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7153",
        "ORTBEZ18": "Falera",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7128",
        "ORTBEZ18": "Riein",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7111",
        "ORTBEZ18": "Pitasch",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7112",
        "ORTBEZ18": "Duvin",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7141",
        "ORTBEZ18": "Luven",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7143",
        "ORTBEZ18": "Morissen",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7146",
        "ORTBEZ18": "Vattiz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7147",
        "ORTBEZ18": "Vignogn",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7148",
        "ORTBEZ18": "Lumbrein",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7149",
        "ORTBEZ18": "Vrin",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7134",
        "ORTBEZ18": "Obersaxen",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7156",
        "ORTBEZ18": "Rueun",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7157",
        "ORTBEZ18": "Siat",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7158",
        "ORTBEZ18": "Waltensburg/Vuorz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7162",
        "ORTBEZ18": "Tavanasa",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7167",
        "ORTBEZ18": "Zignau",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7175",
        "ORTBEZ18": "Sumvitg",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7180",
        "ORTBEZ18": "Disentis/Mustér",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7182",
        "ORTBEZ18": "Cavardiras",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7186",
        "ORTBEZ18": "Segnas",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7206",
        "ORTBEZ18": "Igis",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7212",
        "ORTBEZ18": "Seewis Dorf",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7220",
        "ORTBEZ18": "Schiers",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7226",
        "ORTBEZ18": "Stels",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7232",
        "ORTBEZ18": "Furna",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7245",
        "ORTBEZ18": "Ascharina",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7246",
        "ORTBEZ18": "St. Antönien",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7247",
        "ORTBEZ18": "Saas im Prättigau",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7250",
        "ORTBEZ18": "Klosters",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7252",
        "ORTBEZ18": "Klosters Dorf",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7260",
        "ORTBEZ18": "Davos Dorf",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7304",
        "ORTBEZ18": "Maienfeld",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7317",
        "ORTBEZ18": "Valens",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "7315",
        "ORTBEZ18": "Vättis",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "7320",
        "ORTBEZ18": "Sargans",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "7326",
        "ORTBEZ18": "Weisstannen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "7405",
        "ORTBEZ18": "Rothenbrunnen",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7412",
        "ORTBEZ18": "Scharans",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7414",
        "ORTBEZ18": "Fürstenau",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7417",
        "ORTBEZ18": "Paspels",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7426",
        "ORTBEZ18": "Flerden",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7423",
        "ORTBEZ18": "Sarn",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7424",
        "ORTBEZ18": "Präz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7431",
        "ORTBEZ18": "Mutten",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7433",
        "ORTBEZ18": "Wergenstein",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7433",
        "ORTBEZ18": "Lohn GR",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7434",
        "ORTBEZ18": "Sufers",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7436",
        "ORTBEZ18": "Medels",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7437",
        "ORTBEZ18": "Nufenen",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7438",
        "ORTBEZ18": "Hinterrhein",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7440",
        "ORTBEZ18": "Andeer",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7443",
        "ORTBEZ18": "Pignia",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7444",
        "ORTBEZ18": "Ausserferrera",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7451",
        "ORTBEZ18": "Alvaschein",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7462",
        "ORTBEZ18": "Salouf",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7464",
        "ORTBEZ18": "Parsonz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7473",
        "ORTBEZ18": "Alvaneu Bad",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7482",
        "ORTBEZ18": "Stugl/Stuls",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7484",
        "ORTBEZ18": "Latsch",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7500",
        "ORTBEZ18": "St. Moritz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7500",
        "ORTBEZ18": "St. Moritz 3",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7502",
        "ORTBEZ18": "Bever",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7504",
        "ORTBEZ18": "Pontresina",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7513",
        "ORTBEZ18": "Silvaplana",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7515",
        "ORTBEZ18": "Sils/Segl Baselgia",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7522",
        "ORTBEZ18": "La Punt Chamues-ch",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7526",
        "ORTBEZ18": "Cinuos-chel",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7527",
        "ORTBEZ18": "Brail",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7530",
        "ORTBEZ18": "Zernez",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7551",
        "ORTBEZ18": "Ftan",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7554",
        "ORTBEZ18": "Sent",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7556",
        "ORTBEZ18": "Ramosch",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7602",
        "ORTBEZ18": "Casaccia",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7604",
        "ORTBEZ18": "Borgonovo",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7742",
        "ORTBEZ18": "Poschiavo",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7742",
        "ORTBEZ18": "La Rösa",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "8003",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8004",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8006",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8010",
        "ORTBEZ18": "Zürich Mülligen PL",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8021",
        "ORTBEZ18": "Zürich 1 Sihlpost",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8021",
        "ORTBEZ18": "Zürich 1",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8023",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8032",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8033",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8034",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8042",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8046",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8050",
        "ORTBEZ18": "Zürich Dist Ba",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8052",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8055",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8057",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8058",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8061",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8088",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8091",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8092",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8102",
        "ORTBEZ18": "Oberengstringen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8103",
        "ORTBEZ18": "Unterengstringen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8108",
        "ORTBEZ18": "Dällikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8112",
        "ORTBEZ18": "Otelfingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "5436",
        "ORTBEZ18": "Würenlos",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8124",
        "ORTBEZ18": "Maur",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8125",
        "ORTBEZ18": "Zollikerberg",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8126",
        "ORTBEZ18": "Zumikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8134",
        "ORTBEZ18": "Adliswil 1",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8143",
        "ORTBEZ18": "Uetliberg",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8152",
        "ORTBEZ18": "Glattbrugg",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8153",
        "ORTBEZ18": "Rümlang",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8155",
        "ORTBEZ18": "Niederhasli",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8156",
        "ORTBEZ18": "Oberhasli",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8164",
        "ORTBEZ18": "Bachs",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8165",
        "ORTBEZ18": "Schöfflisdorf",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8166",
        "ORTBEZ18": "Niederweningen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8172",
        "ORTBEZ18": "Niederglatt ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8180",
        "ORTBEZ18": "Bülach",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8184",
        "ORTBEZ18": "Bachenbülach",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8200",
        "ORTBEZ18": "Schaffhausen 1",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8202",
        "ORTBEZ18": "Schaffhausen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8204",
        "ORTBEZ18": "Schaffh. Breite",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8208",
        "ORTBEZ18": "Schaffhausen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8231",
        "ORTBEZ18": "Hemmental",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8236",
        "ORTBEZ18": "Opfertshofen SH",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8242",
        "ORTBEZ18": "Bibern SH",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8212",
        "ORTBEZ18": "Neuhausen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8222",
        "ORTBEZ18": "Beringen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8223",
        "ORTBEZ18": "Guntmadingen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8232",
        "ORTBEZ18": "Merishausen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8233",
        "ORTBEZ18": "Bargen SH",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8241",
        "ORTBEZ18": "Barzheim",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8245",
        "ORTBEZ18": "Feuerthalen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8254",
        "ORTBEZ18": "Basadingen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8265",
        "ORTBEZ18": "Mammern",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8266",
        "ORTBEZ18": "Steckborn",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8274",
        "ORTBEZ18": "Tägerwilen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8280",
        "ORTBEZ18": "Kreuzlingen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8280",
        "ORTBEZ18": "Kreuzlingen 3",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8301",
        "ORTBEZ18": "Glattzentrum",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8304",
        "ORTBEZ18": "Wallisellen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8320",
        "ORTBEZ18": "Fehraltorf",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8331",
        "ORTBEZ18": "Auslikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8332",
        "ORTBEZ18": "Russikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8355",
        "ORTBEZ18": "Aadorf",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8360",
        "ORTBEZ18": "Eschlikon TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8362",
        "ORTBEZ18": "Balterswil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8370",
        "ORTBEZ18": "Sirnach",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8372",
        "ORTBEZ18": "Wiezikon b.Sirnach",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8374",
        "ORTBEZ18": "Oberwangen TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8376",
        "ORTBEZ18": "Au TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8400",
        "ORTBEZ18": "Winterthur 1 Ann.",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8400",
        "ORTBEZ18": "Winterthur Kaserne",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8404",
        "ORTBEZ18": "Winterthur",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8406",
        "ORTBEZ18": "Winterthur",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8409",
        "ORTBEZ18": "Winterthur",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8410",
        "ORTBEZ18": "Winterthur",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8411",
        "ORTBEZ18": "Winterthur",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8415",
        "ORTBEZ18": "Gräslikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8416",
        "ORTBEZ18": "Flaach",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8422",
        "ORTBEZ18": "Pfungen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8426",
        "ORTBEZ18": "Lufingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "5467",
        "ORTBEZ18": "Fisibach",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5463",
        "ORTBEZ18": "Wislikofen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8447",
        "ORTBEZ18": "Dachsen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8450",
        "ORTBEZ18": "Andelfingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8453",
        "ORTBEZ18": "Alten",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8458",
        "ORTBEZ18": "Dorf",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8454",
        "ORTBEZ18": "Buchberg",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8460",
        "ORTBEZ18": "Marthalen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8404",
        "ORTBEZ18": "Reutlingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8475",
        "ORTBEZ18": "Ossingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8476",
        "ORTBEZ18": "Unterstammheim",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8471",
        "ORTBEZ18": "Rutschwil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8478",
        "ORTBEZ18": "Thalheim",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8468",
        "ORTBEZ18": "Guntalingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8483",
        "ORTBEZ18": "Kollbrunn",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8487",
        "ORTBEZ18": "Rämismühle",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8487",
        "ORTBEZ18": "Zell ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8493",
        "ORTBEZ18": "Saland",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8495",
        "ORTBEZ18": "Schmidrüti",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8499",
        "ORTBEZ18": "Sternenberg",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8500",
        "ORTBEZ18": "Frauenfeld",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8500",
        "ORTBEZ18": "Frauenfeld 1 Zust",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8506",
        "ORTBEZ18": "Lanzenneunforn",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8508",
        "ORTBEZ18": "Homburg",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8522",
        "ORTBEZ18": "Häuslenen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8525",
        "ORTBEZ18": "Niederneunforn",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8532",
        "ORTBEZ18": "Weiningen TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8537",
        "ORTBEZ18": "Nussbaumen TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8542",
        "ORTBEZ18": "Wiesendangen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8544",
        "ORTBEZ18": "Attikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8546",
        "ORTBEZ18": "Islikon",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8553",
        "ORTBEZ18": "Hüttlingen-Mettdf",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8554",
        "ORTBEZ18": "Müllheim-Wigoltgn",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8556",
        "ORTBEZ18": "Wigoltingen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8565",
        "ORTBEZ18": "Hugelshofen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8566",
        "ORTBEZ18": "Neuwilen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8572",
        "ORTBEZ18": "Berg TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8573",
        "ORTBEZ18": "Siegershausen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8575",
        "ORTBEZ18": "Bürglen TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9217",
        "ORTBEZ18": "Neukirch a.d. Thur",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8582",
        "ORTBEZ18": "Dozwil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8589",
        "ORTBEZ18": "Sitterdorf",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8583",
        "ORTBEZ18": "Götighofen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8584",
        "ORTBEZ18": "Opfershofen TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8585",
        "ORTBEZ18": "Langrickenbach",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8586",
        "ORTBEZ18": "Kümmertshausen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8594",
        "ORTBEZ18": "Güttingen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8595",
        "ORTBEZ18": "Altnau",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8596",
        "ORTBEZ18": "Scherzingen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8602",
        "ORTBEZ18": "Wangen ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8603",
        "ORTBEZ18": "Schwerzenbach",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8606",
        "ORTBEZ18": "Greifensee",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8608",
        "ORTBEZ18": "Bubikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8610",
        "ORTBEZ18": "Uster",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8612",
        "ORTBEZ18": "Uster Niederuster",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8613",
        "ORTBEZ18": "Uster Nossikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8614",
        "ORTBEZ18": "Bertschikon Gossau",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8615",
        "ORTBEZ18": "Wermatswil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8620",
        "ORTBEZ18": "Wetzikon ZH 1",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8624",
        "ORTBEZ18": "Grüt (Gossau ZH)",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8625",
        "ORTBEZ18": "Gossau ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8630",
        "ORTBEZ18": "Rüti ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8634",
        "ORTBEZ18": "Hombrechtikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8635",
        "ORTBEZ18": "Dürnten",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8637",
        "ORTBEZ18": "Laupen ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8640",
        "ORTBEZ18": "Kempraten",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8646",
        "ORTBEZ18": "Wagen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8722",
        "ORTBEZ18": "Kaltbrunn",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8727",
        "ORTBEZ18": "Walde SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8730",
        "ORTBEZ18": "Uznach",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8732",
        "ORTBEZ18": "Neuhaus SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8734",
        "ORTBEZ18": "Ermenswil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8735",
        "ORTBEZ18": "Rüeterswil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8738",
        "ORTBEZ18": "Uetliburg SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8750",
        "ORTBEZ18": "Glarus",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8752",
        "ORTBEZ18": "Näfels",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8753",
        "ORTBEZ18": "Mollis",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8762",
        "ORTBEZ18": "Schwändi GL",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8762",
        "ORTBEZ18": "Sool",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8767",
        "ORTBEZ18": "Elm",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8773",
        "ORTBEZ18": "Haslen GL",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8775",
        "ORTBEZ18": "Luchsingen",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8777",
        "ORTBEZ18": "Diesbach GL",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8783",
        "ORTBEZ18": "Linthal",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8805",
        "ORTBEZ18": "Richterswil Burghd",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8808",
        "ORTBEZ18": "Pfäffikon SZ",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8832",
        "ORTBEZ18": "Wollerau",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8833",
        "ORTBEZ18": "Samstagern",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8835",
        "ORTBEZ18": "Feusisberg",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8846",
        "ORTBEZ18": "Willerzell",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8847",
        "ORTBEZ18": "Egg SZ",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8841",
        "ORTBEZ18": "Gross",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8842",
        "ORTBEZ18": "Unteriberg",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8843",
        "ORTBEZ18": "Oberiberg",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8854",
        "ORTBEZ18": "Siebnen",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8857",
        "ORTBEZ18": "Vorderthal",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8862",
        "ORTBEZ18": "Schübelbach",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8863",
        "ORTBEZ18": "Buttikon SZ",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8864",
        "ORTBEZ18": "Reichenburg",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8865",
        "ORTBEZ18": "Bilten",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8867",
        "ORTBEZ18": "Niederurnen",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8868",
        "ORTBEZ18": "Oberurnen",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8878",
        "ORTBEZ18": "Quinten",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8885",
        "ORTBEZ18": "Mols",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8887",
        "ORTBEZ18": "Mels",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8886",
        "ORTBEZ18": "Mädris-Vermol",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8892",
        "ORTBEZ18": "Berschis",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8893",
        "ORTBEZ18": "Flums Hochwiese",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8894",
        "ORTBEZ18": "Flumserberg Saxli",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8896",
        "ORTBEZ18": "Flumserberg Bergh",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8898",
        "ORTBEZ18": "Flumserberg Tboden",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8903",
        "ORTBEZ18": "Birmensdorf ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8904",
        "ORTBEZ18": "Aesch ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8906",
        "ORTBEZ18": "Bonstetten",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8907",
        "ORTBEZ18": "Wettswil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8909",
        "ORTBEZ18": "Zwillikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8914",
        "ORTBEZ18": "Aeugst am Albis",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8933",
        "ORTBEZ18": "Maschwanden",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8934",
        "ORTBEZ18": "Knonau",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8952",
        "ORTBEZ18": "Schlieren",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8953",
        "ORTBEZ18": "Dietikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8962",
        "ORTBEZ18": "Bergdietikon",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "9000",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9000",
        "ORTBEZ18": "St. Gallen Kaserne",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9001",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9002",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9003",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9004",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9005",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9006",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9013",
        "ORTBEZ18": "St. Gallen Lachen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9030",
        "ORTBEZ18": "Abtwil SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9033",
        "ORTBEZ18": "Untereggen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9035",
        "ORTBEZ18": "Grub AR",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9043",
        "ORTBEZ18": "Trogen",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9044",
        "ORTBEZ18": "Wald AR",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9054",
        "ORTBEZ18": "Haslen AI",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "9058",
        "ORTBEZ18": "Brülisau",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "9064",
        "ORTBEZ18": "Hundwil",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9100",
        "ORTBEZ18": "Herisau",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9103",
        "ORTBEZ18": "Schwellbrunn",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9104",
        "ORTBEZ18": "Waldstatt",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9108",
        "ORTBEZ18": "Gonten",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "9113",
        "ORTBEZ18": "Degersheim",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9126",
        "ORTBEZ18": "Necker",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9200",
        "ORTBEZ18": "Gossau SG 1",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9203",
        "ORTBEZ18": "Niederwil SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9205",
        "ORTBEZ18": "Waldkirch",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9213",
        "ORTBEZ18": "Hauptwil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9220",
        "ORTBEZ18": "Bischofszell",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9223",
        "ORTBEZ18": "Schweizersholz",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9223",
        "ORTBEZ18": "Halden",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9230",
        "ORTBEZ18": "Flawil 2",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9231",
        "ORTBEZ18": "Egg (Flawil)",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9604",
        "ORTBEZ18": "Lütisburg",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9242",
        "ORTBEZ18": "Oberuzwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9245",
        "ORTBEZ18": "Oberbüren",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9304",
        "ORTBEZ18": "Bernhardzell",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9305",
        "ORTBEZ18": "Berg SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9312",
        "ORTBEZ18": "Häggenschwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9313",
        "ORTBEZ18": "Muolen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9314",
        "ORTBEZ18": "Steinebrunn",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9320",
        "ORTBEZ18": "Stachen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9322",
        "ORTBEZ18": "Egnach",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9325",
        "ORTBEZ18": "Roggwil TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9404",
        "ORTBEZ18": "Rorschacherberg",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9405",
        "ORTBEZ18": "Wienacht-Tobel",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9410",
        "ORTBEZ18": "Heiden",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9411",
        "ORTBEZ18": "Reute AR",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9413",
        "ORTBEZ18": "Oberegg",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "9423",
        "ORTBEZ18": "Altenrhein",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9424",
        "ORTBEZ18": "Rheineck",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9425",
        "ORTBEZ18": "Thal",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9428",
        "ORTBEZ18": "Platz",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9435",
        "ORTBEZ18": "Heerbrugg",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9444",
        "ORTBEZ18": "Diepoldsau",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9450",
        "ORTBEZ18": "Altstätten SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9451",
        "ORTBEZ18": "Kriessern",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9470",
        "ORTBEZ18": "Buchs SG Räfis",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9476",
        "ORTBEZ18": "Weite",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9479",
        "ORTBEZ18": "Oberschan",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9487",
        "ORTBEZ18": "Gamprin-Bendern",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "9488",
        "ORTBEZ18": "Schellenberg",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "9490",
        "ORTBEZ18": "Vaduz",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "9491",
        "ORTBEZ18": "Ruggell",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "9497",
        "ORTBEZ18": "Triesenberg",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "9500",
        "ORTBEZ18": "Wil SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9502",
        "ORTBEZ18": "Braunau",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9507",
        "ORTBEZ18": "Stettfurt",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9526",
        "ORTBEZ18": "Zuckenriet",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9534",
        "ORTBEZ18": "Gähwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9536",
        "ORTBEZ18": "Schwarzenbach SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9545",
        "ORTBEZ18": "Wängi",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9553",
        "ORTBEZ18": "Bettwiesen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9555",
        "ORTBEZ18": "Tobel",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9565",
        "ORTBEZ18": "Schmidshof",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9573",
        "ORTBEZ18": "Littenheid",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9602",
        "ORTBEZ18": "Müselbach",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9614",
        "ORTBEZ18": "Libingen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9615",
        "ORTBEZ18": "Dietfurt",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9621",
        "ORTBEZ18": "Oberhelfenschwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9631",
        "ORTBEZ18": "Ulisbach",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9643",
        "ORTBEZ18": "Krummenau",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9651",
        "ORTBEZ18": "Ennetbühl",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9656",
        "ORTBEZ18": "Alt St. Johann",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9658",
        "ORTBEZ18": "Wildhaus",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "3185",
        "ORTBEZ18": "Schmitten FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "6303",
        "ORTBEZ18": "Zug",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6900",
        "ORTBEZ18": "Lugano Stazione",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "3024",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3030",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1734",
        "ORTBEZ18": "Tentlingen",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1029",
        "ORTBEZ18": "Villars-Ste-Croix",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "2301",
        "ORTBEZ18": "La Chx-de-Fonds 1",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "1295",
        "ORTBEZ18": "Mies",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1295",
        "ORTBEZ18": "Tannay",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "6370",
        "ORTBEZ18": "Oberdorf NW",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "3961",
        "ORTBEZ18": "St-Jean VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3380",
        "ORTBEZ18": "Walliswil-Nbipp",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1267",
        "ORTBEZ18": "Vich",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1595",
        "ORTBEZ18": "Clavaleyres",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "2715",
        "ORTBEZ18": "Monible",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2827",
        "ORTBEZ18": "Schelten",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2572",
        "ORTBEZ18": "Mörigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1773",
        "ORTBEZ18": "Chandon",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1534",
        "ORTBEZ18": "Chapelle (Broye)",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1483",
        "ORTBEZ18": "Frasses",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1470",
        "ORTBEZ18": "Lully FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1541",
        "ORTBEZ18": "Morens FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1565",
        "ORTBEZ18": "Vallon",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1483",
        "ORTBEZ18": "Vesin",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1675",
        "ORTBEZ18": "Blessens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1608",
        "ORTBEZ18": "Chapelle (Glâne)",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1670",
        "ORTBEZ18": "Esmonts",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1673",
        "ORTBEZ18": "Gillarens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1681",
        "ORTBEZ18": "Hennens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1688",
        "ORTBEZ18": "Lieffrens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1675",
        "ORTBEZ18": "Mossel",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1686",
        "ORTBEZ18": "La Neirigue",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1694",
        "ORTBEZ18": "Villargiroud",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1669",
        "ORTBEZ18": "Lessoc",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1625",
        "ORTBEZ18": "Maules",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1782",
        "ORTBEZ18": "La Corbaz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1754",
        "ORTBEZ18": "Corjolens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1782",
        "ORTBEZ18": "Cormagens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1727",
        "ORTBEZ18": "Magnedens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1726",
        "ORTBEZ18": "Posat",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1695",
        "ORTBEZ18": "Rueyres-St-Laurent",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1724",
        "ORTBEZ18": "Senèdes",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1723",
        "ORTBEZ18": "Villarsel-Marly",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1721",
        "ORTBEZ18": "Cormérod",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1784",
        "ORTBEZ18": "Wallenried",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1624",
        "ORTBEZ18": "Grattavache",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1148",
        "ORTBEZ18": "Chavannes-Veyron",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1041",
        "ORTBEZ18": "Naz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1421",
        "ORTBEZ18": "Grandevent",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1423",
        "ORTBEZ18": "Romairon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1134",
        "ORTBEZ18": "Chigny",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1041",
        "ORTBEZ18": "Montaubion-Chardon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1513",
        "ORTBEZ18": "Rossenges",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1608",
        "ORTBEZ18": "Bussigny-sur-Oron",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1607",
        "ORTBEZ18": "Les Thioleyres",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1180",
        "ORTBEZ18": "Tartegnin",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1436",
        "ORTBEZ18": "Chamblon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1432",
        "ORTBEZ18": "Gressy",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1404",
        "ORTBEZ18": "Villars-Epeney",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "2933",
        "ORTBEZ18": "Damphreux",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2953",
        "ORTBEZ18": "Fregiécourt",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "3429",
        "ORTBEZ18": "Hellsau",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3324",
        "ORTBEZ18": "Mötschwil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3424",
        "ORTBEZ18": "Oberösch",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3256",
        "ORTBEZ18": "Bangerten",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3303",
        "ORTBEZ18": "Münchringen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3671",
        "ORTBEZ18": "Herbligen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3274",
        "ORTBEZ18": "Bühl BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3274",
        "ORTBEZ18": "Merzligen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3632",
        "ORTBEZ18": "Niederstocken",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3629",
        "ORTBEZ18": "Jaberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3116",
        "ORTBEZ18": "Noflen BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3128",
        "ORTBEZ18": "Rümligen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3376",
        "ORTBEZ18": "Berken",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6277",
        "ORTBEZ18": "Lieli",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "8777",
        "ORTBEZ18": "Betschwanden",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "3212",
        "ORTBEZ18": "Kleingurmels",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "4584",
        "ORTBEZ18": "Gächliwil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4436",
        "ORTBEZ18": "Liedertswil",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "9057",
        "ORTBEZ18": "Schwende",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "7130",
        "ORTBEZ18": "Schnaus",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7423",
        "ORTBEZ18": "Portein",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "1820",
        "ORTBEZ18": "Veytaux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8586",
        "ORTBEZ18": "Engishofen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8553",
        "ORTBEZ18": "Mettendorf TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8274",
        "ORTBEZ18": "Gottlieben",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8505",
        "ORTBEZ18": "Dettighofen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8259",
        "ORTBEZ18": "Rheinklingen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8572",
        "ORTBEZ18": "Graltshausen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8585",
        "ORTBEZ18": "Klarsreuti",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8556",
        "ORTBEZ18": "Engwang",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8556",
        "ORTBEZ18": "Illhart",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "6963",
        "ORTBEZ18": "Cureggia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6814",
        "ORTBEZ18": "Lamone",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "3989",
        "ORTBEZ18": "Ritzingen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3989",
        "ORTBEZ18": "Selkingen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3940",
        "ORTBEZ18": "Steg VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "9498",
        "ORTBEZ18": "Planken",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "2933",
        "ORTBEZ18": "Lugnez",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "1624",
        "ORTBEZ18": "Progens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "4584",
        "ORTBEZ18": "Lüterswil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "8905",
        "ORTBEZ18": "Arni AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8427",
        "ORTBEZ18": "Freienstein",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "3963",
        "ORTBEZ18": "Aminona",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "5053",
        "ORTBEZ18": "Wittwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6900",
        "ORTBEZ18": "Paradiso",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "1754",
        "ORTBEZ18": "Avry-sur-Matran",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1783",
        "ORTBEZ18": "Barberêche",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "2052",
        "ORTBEZ18": "La Vue-des-Alpes",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "1709",
        "ORTBEZ18": "Fribourg Beaumont",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1400",
        "ORTBEZ18": "Yverdon Villette",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8118",
        "ORTBEZ18": "Pfaffhausen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1428",
        "ORTBEZ18": "Provence",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "9028",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9027",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "6600",
        "ORTBEZ18": "Solduno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "3609",
        "ORTBEZ18": "Thun",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1723",
        "ORTBEZ18": "Marly 1",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1775",
        "ORTBEZ18": "Grandsivaz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "8926",
        "ORTBEZ18": "Hauptikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8926",
        "ORTBEZ18": "Uerzlikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1026",
        "ORTBEZ18": "Echandens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8050",
        "ORTBEZ18": "Zürich Postauto",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "5406",
        "ORTBEZ18": "Rütihof",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8106",
        "ORTBEZ18": "Adlikon b.Regensdf",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9400",
        "ORTBEZ18": "Rorschach Zust",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "6343",
        "ORTBEZ18": "Holzhäusern ZG",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "1400",
        "ORTBEZ18": "Yverdon Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "3415",
        "ORTBEZ18": "Hasle b. Burgdorf",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6363",
        "ORTBEZ18": "Fürigen",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6410",
        "ORTBEZ18": "Rigi Scheidegg",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8775",
        "ORTBEZ18": "Luchsingen-Hätzin",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "4040",
        "ORTBEZ18": "Basel",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "8759",
        "ORTBEZ18": "Netstal",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8522",
        "ORTBEZ18": "Aawangen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "6260",
        "ORTBEZ18": "Hintermoos",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 71 CS CP",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "6383",
        "ORTBEZ18": "Wiesenberg",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6021",
        "ORTBEZ18": "Emmenbrücke 1",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6281",
        "ORTBEZ18": "Hochdorf",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6011",
        "ORTBEZ18": "Kriens",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6431",
        "ORTBEZ18": "Schwyz",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8725",
        "ORTBEZ18": "Gebertingen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "1260",
        "ORTBEZ18": "Nyon 2",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "9501",
        "ORTBEZ18": "Wil SG 1",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "6060",
        "ORTBEZ18": "Sarnen 2",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 84 Votation",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "3800",
        "ORTBEZ18": "Interlaken Pauto",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern Postauto",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "4070",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "6156",
        "ORTBEZ18": "Luthern Bad",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "8510",
        "ORTBEZ18": "Frauenfeld",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "6900",
        "ORTBEZ18": "Lugano 4",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern 60 UPD",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1700",
        "ORTBEZ18": "Fribourg Caserne",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 26 Caserne",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "6616",
        "ORTBEZ18": "Losone Caserma",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern Kaserne",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "3250",
        "ORTBEZ18": "Lyss Kaserne",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8887",
        "ORTBEZ18": "Mels Kaserne",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "6802",
        "ORTBEZ18": "Rivera Caserma",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6370",
        "ORTBEZ18": "Stans Kaserne",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6780",
        "ORTBEZ18": "Airolo Caserma",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern 22 Kaserne",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6460",
        "ORTBEZ18": "Altdorf UR 2 Ryn.",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6460",
        "ORTBEZ18": "Altdorf UR 2",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "1068",
        "ORTBEZ18": "Les Monts-de-Pully",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8285",
        "ORTBEZ18": "Kreuzlingen Ifolor",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "4039",
        "ORTBEZ18": "Basel SSF",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "3600",
        "ORTBEZ18": "Thun Dist Ba",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1227",
        "ORTBEZ18": "Carouge GE Dist",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1860",
        "ORTBEZ18": "Aigle Distribution",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1030",
        "ORTBEZ18": "Bussigny Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1023",
        "ORTBEZ18": "Crissier 1 Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "4142",
        "ORTBEZ18": "Münchenstein 1 Zus",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4104",
        "ORTBEZ18": "Oberwil BL Zust",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "5620",
        "ORTBEZ18": "Bremgarten 1 Zust",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4310",
        "ORTBEZ18": "Rheinfelden 1 Zust",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6440",
        "ORTBEZ18": "Brunnen Zust",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6030",
        "ORTBEZ18": "Ebikon Zustellung",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6390",
        "ORTBEZ18": "Engelberg Zust",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6410",
        "ORTBEZ18": "Goldau Zust",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6102",
        "ORTBEZ18": "Malters Zust",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "3280",
        "ORTBEZ18": "Murten Zustellung",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "8134",
        "ORTBEZ18": "Adliswil 1 Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9320",
        "ORTBEZ18": "Arbon Zustellung",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8903",
        "ORTBEZ18": "Birmensdorf Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8307",
        "ORTBEZ18": "Effretikon Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8708",
        "ORTBEZ18": "Männedorf Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8800",
        "ORTBEZ18": "Thalwil Zustellung",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "3360",
        "ORTBEZ18": "Hbuchsee Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8636",
        "ORTBEZ18": "Wald ZH Zustellung",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8570",
        "ORTBEZ18": "Weinfelden Zust",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8126",
        "ORTBEZ18": "Zumikon Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9050",
        "ORTBEZ18": "Appenzell Zust",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "3053",
        "ORTBEZ18": "M'buchsee Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3700",
        "ORTBEZ18": "Spiez Zustellung",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3612",
        "ORTBEZ18": "Steffisburg 1 Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1212",
        "ORTBEZ18": "Grand-Lancy Dist",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "2400",
        "ORTBEZ18": "Le Locle Dist",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2740",
        "ORTBEZ18": "Moutier 1 Dist",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "5018",
        "ORTBEZ18": "Erlinsbach",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "1630",
        "ORTBEZ18": "Bulle 1 Dist",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "7270",
        "ORTBEZ18": "Davos Zust",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "3400",
        "ORTBEZ18": "Burgdorf Dist Fil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8325",
        "ORTBEZ18": "Effretikon Dist Ba",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8520",
        "ORTBEZ18": "Frauenfeld PZ",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8520",
        "ORTBEZ18": "Frauenfeld Dist Ba",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8564",
        "ORTBEZ18": "Wagerswil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "2950",
        "ORTBEZ18": "Courtemautruy",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "1148",
        "ORTBEZ18": "Villars-Bozon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8274",
        "ORTBEZ18": "Tägerwilen Zust. P",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "1630",
        "ORTBEZ18": "Bulle Dist Fil",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "8087",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1033",
        "ORTBEZ18": "Cheseaux Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "3960",
        "ORTBEZ18": "Niouc",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1912",
        "ORTBEZ18": "Produit (Leytron)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1912",
        "ORTBEZ18": "Montagnon (Leytron",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1914",
        "ORTBEZ18": "Auddes-sur-Riddes",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1932",
        "ORTBEZ18": "Les Valettes",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1941",
        "ORTBEZ18": "Cries (Vollèges)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1945",
        "ORTBEZ18": "Fontaine Dessus",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1945",
        "ORTBEZ18": "Dranse (Liddes)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1945",
        "ORTBEZ18": "Chandonne (Liddes)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1945",
        "ORTBEZ18": "Rive Haute(Liddes)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1945",
        "ORTBEZ18": "Petit Vichères",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1947",
        "ORTBEZ18": "Champsec",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1955",
        "ORTBEZ18": "Grugnay (Chamoson)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1966",
        "ORTBEZ18": "Blignou (Ayent)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1969",
        "ORTBEZ18": "Liez (St-Martin)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1969",
        "ORTBEZ18": "Suen (St-Martin)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1991",
        "ORTBEZ18": "Pravidondaz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1996",
        "ORTBEZ18": "Brignon (Nendaz)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1992",
        "ORTBEZ18": "La Vernaz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1996",
        "ORTBEZ18": "Saclentse",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1873",
        "ORTBEZ18": "Champoussin",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "2063",
        "ORTBEZ18": "Saules",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2610",
        "ORTBEZ18": "St-Imier Dist",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1680",
        "ORTBEZ18": "Romont FR Dist",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1880",
        "ORTBEZ18": "Bex Distribution",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1214",
        "ORTBEZ18": "Vernier Dist",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "3210",
        "ORTBEZ18": "Kerzers Zust",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3415",
        "ORTBEZ18": "Hasle-Rüegsau Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3855",
        "ORTBEZ18": "Brienz BE Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4460",
        "ORTBEZ18": "Gelterkinden Zust",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4512",
        "ORTBEZ18": "Bellach Zustellung",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4614",
        "ORTBEZ18": "Hägendorf Zust",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "5070",
        "ORTBEZ18": "Frick Zustellung",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5722",
        "ORTBEZ18": "Gränichen Zust",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6060",
        "ORTBEZ18": "Sarnen 2 Zust",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6403",
        "ORTBEZ18": "Küssnacht SZ Zust",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8965",
        "ORTBEZ18": "Berikon 1 Zust",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8808",
        "ORTBEZ18": "Pfäffikon SZ Zust",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8840",
        "ORTBEZ18": "Einsiedeln Zust",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8854",
        "ORTBEZ18": "Siebnen Zust",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "9230",
        "ORTBEZ18": "Flawil 1 Zust",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9053",
        "ORTBEZ18": "Teufen AR Zust",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9410",
        "ORTBEZ18": "Heiden Zustellung",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9470",
        "ORTBEZ18": "Buchs SG 1 Zust",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "7503",
        "ORTBEZ18": "Samedan Zustellung",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "9024",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "1772",
        "ORTBEZ18": "Nierlet-les-Bois",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1892",
        "ORTBEZ18": "Morcles",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "2074",
        "ORTBEZ18": "Marin-Centre",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "9479",
        "ORTBEZ18": "Gretschins",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9479",
        "ORTBEZ18": "Malans SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "4640",
        "ORTBEZ18": "Härkingen CIRCLE",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4630",
        "ORTBEZ18": "Härkingen CALL",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "1320",
        "ORTBEZ18": "Daillens CIRCLE",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "6010",
        "ORTBEZ18": "Kriens 1",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 18",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "2149",
        "ORTBEZ18": "Fretereules",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2149",
        "ORTBEZ18": "Brot-Dessous",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "1806",
        "ORTBEZ18": "St-Légier Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1096",
        "ORTBEZ18": "Villette (Lavaux)",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "6383",
        "ORTBEZ18": "Wirzweli",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "2333",
        "ORTBEZ18": "La Cibourg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2616",
        "ORTBEZ18": "La Cibourg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8556",
        "ORTBEZ18": "Lamperswil TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "2748",
        "ORTBEZ18": "Les Ecorcheresses",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "5415",
        "ORTBEZ18": "Nussbaumen AG Zust",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "3770",
        "ORTBEZ18": "Zweisimmen Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1223",
        "ORTBEZ18": "Cologny Dist",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "3454",
        "ORTBEZ18": "Sumiswald Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6963",
        "ORTBEZ18": "Pregassona Dist",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "3920",
        "ORTBEZ18": "Zermatt Zustellung",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3977",
        "ORTBEZ18": "Granges VS Dist",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "4034",
        "ORTBEZ18": "Basel 34 Breite",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "8606",
        "ORTBEZ18": "Nänikon Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9463",
        "ORTBEZ18": "Oberriet SG Zust",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8472",
        "ORTBEZ18": "Seuzach Zustellung",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8596",
        "ORTBEZ18": "Münsterlingen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8068",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9604",
        "ORTBEZ18": "Unterrindal",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "2013",
        "ORTBEZ18": "Boudry Dist Ba",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "3039",
        "ORTBEZ18": "Bern PF OC",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4042",
        "ORTBEZ18": "Basel PF OC",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "3983",
        "ORTBEZ18": "Greich",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "6023",
        "ORTBEZ18": "Rothenburg Zust",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6043",
        "ORTBEZ18": "Adligenswil Zust",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6045",
        "ORTBEZ18": "Meggen Zustellung",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6055",
        "ORTBEZ18": "Alpnach Zustellung",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "8081",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8450",
        "ORTBEZ18": "Andelfingen Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8722",
        "ORTBEZ18": "Kaltbrunn Zust",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "3041",
        "ORTBEZ18": "Bern UBS",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8488",
        "ORTBEZ18": "Turbenthal Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "7050",
        "ORTBEZ18": "Arosa Zust",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "1934",
        "ORTBEZ18": "Le Châble VS Dist",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1071",
        "ORTBEZ18": "Chexbres Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1854",
        "ORTBEZ18": "Leysin Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8135",
        "ORTBEZ18": "Sihlwald",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8152",
        "ORTBEZ18": "Glattpark(Opfikon)",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "5645",
        "ORTBEZ18": "Fenkrieden",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4075",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "8820",
        "ORTBEZ18": "Wädenswil Dist Ba",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "7430",
        "ORTBEZ18": "Thusis Zustellung",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7130",
        "ORTBEZ18": "Ilanz Zustellung",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "1296",
        "ORTBEZ18": "Coppet Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1782",
        "ORTBEZ18": "Formangueires",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3940",
        "ORTBEZ18": "Steg-Gampel",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1300",
        "ORTBEZ18": "Eclépens CC",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "3050",
        "ORTBEZ18": "Bern DS",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8010",
        "ORTBEZ18": "Zürich SPS AG",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "4702",
        "ORTBEZ18": "Oensingen Hub",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "1919",
        "ORTBEZ18": "Martigny Mutuel",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1001",
        "ORTBEZ18": "Lausanne Sozialb.",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "4800",
        "ORTBEZ18": "Zofingen PFinance",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4807",
        "ORTBEZ18": "Zofingen PF",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8010",
        "ORTBEZ18": "Zürich BZI",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1311",
        "ORTBEZ18": "Eclépens SC",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8060",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6333",
        "ORTBEZ18": "Hünenberg See",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "4809",
        "ORTBEZ18": "Zofingen PF",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "3400",
        "ORTBEZ18": "Burgdorf PF mob",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8059",
        "ORTBEZ18": "Zürich 59 EO",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8059",
        "ORTBEZ18": "Zürich 59 Ausland",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8310",
        "ORTBEZ18": "Grafstal",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "3017",
        "ORTBEZ18": "Bern Zustellung",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8471",
        "ORTBEZ18": "Oberwil (Dägerlen)",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "3029",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4621",
        "ORTBEZ18": "Härkingen Briefkl.",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "9050",
        "ORTBEZ18": "Appenzell Eggerst.",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "9050",
        "ORTBEZ18": "Appenzell Meister.",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "9108",
        "ORTBEZ18": "Gontenbad",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern PF Fil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "9108",
        "ORTBEZ18": "Jakobsbad",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "7110",
        "ORTBEZ18": "Peiden",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "8012",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1300",
        "ORTBEZ18": "Eclépens SAB",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8010",
        "ORTBEZ18": "Zürich BZI DE",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "2802",
        "ORTBEZ18": "Develier PostAuto",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "3206",
        "ORTBEZ18": "Gammen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2360",
        "ORTBEZ18": "Le Bémont JU",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2362",
        "ORTBEZ18": "Montfaucon",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2363",
        "ORTBEZ18": "Les Enfers",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2364",
        "ORTBEZ18": "St-Brais",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "4600",
        "ORTBEZ18": "Olten Personal",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "8002",
        "ORTBEZ18": "Zürich PF Fil Enge",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1001",
        "ORTBEZ18": "Lausanne PF331-140",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1001",
        "ORTBEZ18": "Lausanne Jurassien",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8010",
        "ORTBEZ18": "Zürich Kanton An.",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8010",
        "ORTBEZ18": "Zürich CS Annahme",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "3434",
        "ORTBEZ18": "Landiswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8970",
        "ORTBEZ18": "Urdorf Exchange",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1985",
        "ORTBEZ18": "La Forclaz VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1401",
        "ORTBEZ18": "Yverdon Conseil",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "5405",
        "ORTBEZ18": "Baden Täfern",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "9533",
        "ORTBEZ18": "Dietschwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "2301",
        "ORTBEZ18": "La Chx-de-F. Combe",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "8162",
        "ORTBEZ18": "Sünikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8332",
        "ORTBEZ18": "Rumlikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "4800",
        "ORTBEZ18": "Zofi. KC PKGK4",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4800",
        "ORTBEZ18": "Zofi. KC PKGK 5",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "3072",
        "ORTBEZ18": "Ostermundigen Ober",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "7212",
        "ORTBEZ18": "Seewis-Schmitten",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "9023",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "4800",
        "ORTBEZ18": "Zofingen KC PK 2",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5000",
        "ORTBEZ18": "Aarau Altstadt",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne 10",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne 18",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1800",
        "ORTBEZ18": "Vevey Orient",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern Altstadt",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "8574",
        "ORTBEZ18": "Lengwil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "1953",
        "ORTBEZ18": "Sion RZ",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "8030",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "4800",
        "ORTBEZ18": "Zofingen Abg. St",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "1345",
        "ORTBEZ18": "Le Séchey",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1721",
        "ORTBEZ18": "Cournillens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3961",
        "ORTBEZ18": "Mission",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "4604",
        "ORTBEZ18": "Olten 4 Hammer",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "5506",
        "ORTBEZ18": "Mägenwil PL3",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5606",
        "ORTBEZ18": "Dintikon PL3",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "2305",
        "ORTBEZ18": "La Chaux-de-Fonds",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "5312",
        "ORTBEZ18": "Döttingen-Klingnau",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4028",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "3030",
        "ORTBEZ18": "Bern PAS",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "5313",
        "ORTBEZ18": "Klingnau Wiesenweg",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8953",
        "ORTBEZ18": "Dietikon Silbern",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6242",
        "ORTBEZ18": "Wauwil Dorfstrasse",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "8411",
        "ORTBEZ18": "Winterthur Pflanz.",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8010",
        "ORTBEZ18": "Zürich SPS",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genf SPS",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "3001",
        "ORTBEZ18": "Bern SPS",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4002",
        "ORTBEZ18": "Basel SPS",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "5001",
        "ORTBEZ18": "Aarau SPS",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6501",
        "ORTBEZ18": "Bellinzona SPS",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "5463",
        "ORTBEZ18": "Wislikofen Probst.",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8620",
        "ORTBEZ18": "Wetzikon Kastell",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9494",
        "ORTBEZ18": "Schaan IMPC",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "9442",
        "ORTBEZ18": "Berneck Zust",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "6302",
        "ORTBEZ18": "Zug",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "3800",
        "ORTBEZ18": "Interlaken Bönig.",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "7500",
        "ORTBEZ18": "St. Moritz Dorf",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "9320",
        "ORTBEZ18": "Arbon Stickereistr",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "6403",
        "ORTBEZ18": "Küssnacht Dist Hub",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8620",
        "ORTBEZ18": "Wetzikon Post",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8330",
        "ORTBEZ18": "Pfäffikon Bhfstr",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8025",
        "ORTBEZ18": "Zürich Uraniastr",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "7606",
        "ORTBEZ18": "Bondo",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "8015",
        "ORTBEZ18": "Zürich 15 Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9494",
        "ORTBEZ18": "Schaan BZ Annahme",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "8352",
        "ORTBEZ18": "Elsau St. Gallers.",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "5734",
        "ORTBEZ18": "Reinach AG Postpl",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6030",
        "ORTBEZ18": "Ebikon Mall Switz.",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "8604",
        "ORTBEZ18": "Volketswil Zentrum",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1700",
        "ORTBEZ18": "Fribourg Pérolles",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "8037",
        "ORTBEZ18": "Zürich Scheffelstr",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1870",
        "ORTBEZ18": "Monthey Ave gare",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "5303",
        "ORTBEZ18": "Würenlingen Dorf",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5200",
        "ORTBEZ18": "Brugg AG Bahnhof",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "3963",
        "ORTBEZ18": "Crans-sur-Sierre",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3645",
        "ORTBEZ18": "Gwatt Lohnerstr",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3250",
        "ORTBEZ18": "Lyss Werkstrasse",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6548",
        "ORTBEZ18": "Sta. Domenica",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6591",
        "ORTBEZ18": "Cadenazzo Spart P",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "4127",
        "ORTBEZ18": "Birsfelden Haupt.",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "9500",
        "ORTBEZ18": "Wil SG Hubstrasse",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "3600",
        "ORTBEZ18": "Thun Aarestrasse",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1930",
        "ORTBEZ18": "Vétroz CALL",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1630",
        "ORTBEZ18": "Bulle Moulin",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3071",
        "ORTBEZ18": "Ostermundigen Sort",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3018",
        "ORTBEZ18": "Bern Freiburgstr",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1630",
        "ORTBEZ18": "Bulle Gare TPF",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "4147",
        "ORTBEZ18": "Aesch BL Hauptstr",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "5600",
        "ORTBEZ18": "Lenzburg Muracker",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6055",
        "ORTBEZ18": "Alpnach Brünigstr",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "1800",
        "ORTBEZ18": "Vevey Av G. Guisan",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1630",
        "ORTBEZ18": "Bulle Jardins P.L.",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "8027",
        "ORTBEZ18": "Zürich 27 Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1310",
        "ORTBEZ18": "Daillens SPS",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1920",
        "ORTBEZ18": "Martigny rue Avou.",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "6593",
        "ORTBEZ18": "Cadenazzo ZF",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "8920",
        "ORTBEZ18": "Urdorf PZ",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9472",
        "ORTBEZ18": "Grabs Staatsstr",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "4800",
        "ORTBEZ18": "Zofingen U. Graben",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "1110",
        "ORTBEZ18": "Morges Charpent.",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "6102",
        "ORTBEZ18": "Malters Luzernstr",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "4415",
        "ORTBEZ18": "Lausen Grammontstr",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "3015",
        "ORTBEZ18": "Bern Weltpoststr",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4665",
        "ORTBEZ18": "Oftringen Tychb.",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "3752",
        "ORTBEZ18": "Wimmis Herrenmatte",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6675",
        "ORTBEZ18": "Cevio Dist",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "3902",
        "ORTBEZ18": "Glis Gliserallee",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "6003",
        "ORTBEZ18": "Luzern Hirschengr",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6981",
        "ORTBEZ18": "Beride d. Bedigli.",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "3322",
        "ORTBEZ18": "Urtenen-S. Z-Platz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "5103",
        "ORTBEZ18": "Wildegg Poststr",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "1300",
        "ORTBEZ18": "Eclépens RevProtec",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "4036",
        "ORTBEZ18": "Basel CALL",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "8140",
        "ORTBEZ18": "Rümlang CIRCLE",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "7270",
        "ORTBEZ18": "Davos Platz Talstr",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "4142",
        "ORTBEZ18": "Münchenstein Stöck",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4009",
        "ORTBEZ18": "Basel Ahornstrasse",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4503",
        "ORTBEZ18": "Solothurn Allmend",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "5050",
        "ORTBEZ18": "Buchs AG CALL",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4130",
        "ORTBEZ18": "Pratteln CALL",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "8870",
        "ORTBEZ18": "Niederurnen PF",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8808",
        "ORTBEZ18": "Pfäffikon Poststr",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8350",
        "ORTBEZ18": "Wallisellen CALL",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "4089",
        "ORTBEZ18": "Basel ZollGesperrt",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4089",
        "ORTBEZ18": "Basel ZollBeschau",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4089",
        "ORTBEZ18": "Basel ZollExtern",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "2540",
        "ORTBEZ18": "Grenchen Maienstr.",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "1950",
        "ORTBEZ18": "Sion rue Casernes",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "6517",
        "ORTBEZ18": "Arbedo Dist.",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne 2",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne 12",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne 14",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne 16",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne 22",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne 24",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne 27",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1003",
        "ORTBEZ18": "Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1008",
        "ORTBEZ18": "Prilly",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1012",
        "ORTBEZ18": "Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1014",
        "ORTBEZ18": "Lausanne Adm cant",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1018",
        "ORTBEZ18": "Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1022",
        "ORTBEZ18": "Chavannes-Renens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1024",
        "ORTBEZ18": "Ecublens VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1026",
        "ORTBEZ18": "Echandens-Denges",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1027",
        "ORTBEZ18": "Lonay",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1030",
        "ORTBEZ18": "Bussigny",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1040",
        "ORTBEZ18": "St-Barthélemy VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1042",
        "ORTBEZ18": "Bettens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1063",
        "ORTBEZ18": "Peyres-Possens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1036",
        "ORTBEZ18": "Sullens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1043",
        "ORTBEZ18": "Sugnens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1044",
        "ORTBEZ18": "Fey",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1045",
        "ORTBEZ18": "Ogens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1042",
        "ORTBEZ18": "Bioley-Orjulaz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1052",
        "ORTBEZ18": "Mont-sur-Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1053",
        "ORTBEZ18": "Cugy VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1055",
        "ORTBEZ18": "Froideville",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1410",
        "ORTBEZ18": "Thierrens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1092",
        "ORTBEZ18": "Belmont-Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1058",
        "ORTBEZ18": "Villars-Tiercelin",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1059",
        "ORTBEZ18": "Peney-le-Jorat",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1062",
        "ORTBEZ18": "Sottens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1063",
        "ORTBEZ18": "Chapelle-s-Moudon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1080",
        "ORTBEZ18": "Les Cullayes",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1082",
        "ORTBEZ18": "Corcelles-le-Jorat",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1088",
        "ORTBEZ18": "Ropraz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1084",
        "ORTBEZ18": "Carrouge VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1076",
        "ORTBEZ18": "Ferlens VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1077",
        "ORTBEZ18": "Servion",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1673",
        "ORTBEZ18": "Auboranges",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1110",
        "ORTBEZ18": "Morges 1",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1167",
        "ORTBEZ18": "Lussy-sur-Morges",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1132",
        "ORTBEZ18": "Lully VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1113",
        "ORTBEZ18": "St-Saphorin-Morges",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1122",
        "ORTBEZ18": "Romanel-sur-Morges",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1123",
        "ORTBEZ18": "Aclens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1134",
        "ORTBEZ18": "Vufflens-Château",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1135",
        "ORTBEZ18": "Denens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1169",
        "ORTBEZ18": "Yens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1146",
        "ORTBEZ18": "Mollens VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1142",
        "ORTBEZ18": "Pampigny",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1141",
        "ORTBEZ18": "Sévery",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1162",
        "ORTBEZ18": "St-Prex",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1166",
        "ORTBEZ18": "Perroy",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1170",
        "ORTBEZ18": "Aubonne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1173",
        "ORTBEZ18": "Féchy",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1184",
        "ORTBEZ18": "Vinzel",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1184",
        "ORTBEZ18": "Luins",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1185",
        "ORTBEZ18": "Mont-sur-Rolle",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1197",
        "ORTBEZ18": "Prangins",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 4",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 7",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 13",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 19",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 21",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève Dist Ba",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1201",
        "ORTBEZ18": "Genève",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1202",
        "ORTBEZ18": "Genève",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1203",
        "ORTBEZ18": "Genève",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1205",
        "ORTBEZ18": "Genève",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1206",
        "ORTBEZ18": "Genève",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1207",
        "ORTBEZ18": "Genève",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 1",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 3",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 6",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 8",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 11",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 19",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 27",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1213",
        "ORTBEZ18": "Petit-Lancy 1",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1215",
        "ORTBEZ18": "Genève",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1217",
        "ORTBEZ18": "Meyrin",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1218",
        "ORTBEZ18": "Le Grand-Saconnex",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1219",
        "ORTBEZ18": "Le Lignon",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1225",
        "ORTBEZ18": "Chêne-Bourg",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1227",
        "ORTBEZ18": "Les Acacias",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1228",
        "ORTBEZ18": "Plan-les-Ouates",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1239",
        "ORTBEZ18": "Collex",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1241",
        "ORTBEZ18": "Puplinge",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1246",
        "ORTBEZ18": "Corsier GE",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1248",
        "ORTBEZ18": "Hermance",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1253",
        "ORTBEZ18": "Vandoeuvres",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1260",
        "ORTBEZ18": "Nyon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1260",
        "ORTBEZ18": "Nyon 1 Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1277",
        "ORTBEZ18": "Borex",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1275",
        "ORTBEZ18": "Chéserex",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1276",
        "ORTBEZ18": "Gingins",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1273",
        "ORTBEZ18": "Arzier-Le Muids",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1261",
        "ORTBEZ18": "Marchissy",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1269",
        "ORTBEZ18": "Bassins",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1188",
        "ORTBEZ18": "St-George",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1263",
        "ORTBEZ18": "Crassier",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1265",
        "ORTBEZ18": "La Cure",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1266",
        "ORTBEZ18": "Duillier",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1284",
        "ORTBEZ18": "Chancy",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1286",
        "ORTBEZ18": "Soral",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1288",
        "ORTBEZ18": "Aire-la-Ville",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1291",
        "ORTBEZ18": "Commugny",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1294",
        "ORTBEZ18": "Genthod",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1297",
        "ORTBEZ18": "Founex",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1299",
        "ORTBEZ18": "Crans VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1304",
        "ORTBEZ18": "Cossonay-Ville",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1304",
        "ORTBEZ18": "Dizy",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1307",
        "ORTBEZ18": "Lussery-Villars",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1338",
        "ORTBEZ18": "Ballaigues",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1341",
        "ORTBEZ18": "L'Orient",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1346",
        "ORTBEZ18": "Les Bioux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1342",
        "ORTBEZ18": "Le Pont",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1343",
        "ORTBEZ18": "Les Charbonnières",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1347",
        "ORTBEZ18": "Le Sentier",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1148",
        "ORTBEZ18": "Cuarnens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1313",
        "ORTBEZ18": "Ferreyres",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1321",
        "ORTBEZ18": "Arnex-sur-Orbe",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1318",
        "ORTBEZ18": "Pompaples",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1312",
        "ORTBEZ18": "Eclépens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1329",
        "ORTBEZ18": "Bretonnières",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1324",
        "ORTBEZ18": "Premier",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1352",
        "ORTBEZ18": "Agiez",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1354",
        "ORTBEZ18": "Montcherand",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1355",
        "ORTBEZ18": "L'Abergement",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1356",
        "ORTBEZ18": "Les Clées",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1375",
        "ORTBEZ18": "Penthéréaz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1376",
        "ORTBEZ18": "Goumoens-la-Ville",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1417",
        "ORTBEZ18": "Essertines-Yverdon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1423",
        "ORTBEZ18": "Villars-Burquin",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1453",
        "ORTBEZ18": "Mauborget",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1430",
        "ORTBEZ18": "Orges",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1406",
        "ORTBEZ18": "Cronay",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1427",
        "ORTBEZ18": "Bonvillars",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1424",
        "ORTBEZ18": "Champagne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1438",
        "ORTBEZ18": "Mathod",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1443",
        "ORTBEZ18": "Champvent",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1446",
        "ORTBEZ18": "Baulmes",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1454",
        "ORTBEZ18": "L'Auberson",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1450",
        "ORTBEZ18": "Le Château-Croix",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1464",
        "ORTBEZ18": "Chêne-Pâquier",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1470",
        "ORTBEZ18": "Estavayer-le-Lac",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1473",
        "ORTBEZ18": "Font",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1489",
        "ORTBEZ18": "Murist",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1512",
        "ORTBEZ18": "Chavannes-s-Moudon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1514",
        "ORTBEZ18": "Bussy-sur-Moudon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1515",
        "ORTBEZ18": "Neyruz-sur-Moudon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1410",
        "ORTBEZ18": "Denezy",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1522",
        "ORTBEZ18": "Lucens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1521",
        "ORTBEZ18": "Curtilles",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1532",
        "ORTBEZ18": "Fétigny",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1533",
        "ORTBEZ18": "Ménières",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1551",
        "ORTBEZ18": "Vers-chez-Perrin",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1552",
        "ORTBEZ18": "Trey",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1682",
        "ORTBEZ18": "Villars-Bramard",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1682",
        "ORTBEZ18": "Dompierre VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1535",
        "ORTBEZ18": "Combremont-Grand",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1563",
        "ORTBEZ18": "Dompierre FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1564",
        "ORTBEZ18": "Domdidier",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1567",
        "ORTBEZ18": "Delley",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1568",
        "ORTBEZ18": "Portalban",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1566",
        "ORTBEZ18": "St-Aubin FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1583",
        "ORTBEZ18": "Villarepos",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1585",
        "ORTBEZ18": "Salavaux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1607",
        "ORTBEZ18": "Palézieux-Village",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1673",
        "ORTBEZ18": "Ecublens FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1524",
        "ORTBEZ18": "Marnand",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1071",
        "ORTBEZ18": "Chexbres",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1607",
        "ORTBEZ18": "Palézieux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1615",
        "ORTBEZ18": "Bossonnens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1616",
        "ORTBEZ18": "Attalens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1617",
        "ORTBEZ18": "Remaufens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1633",
        "ORTBEZ18": "Vuippens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1645",
        "ORTBEZ18": "Le Bry",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1646",
        "ORTBEZ18": "Echarlens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1648",
        "ORTBEZ18": "Hauteville",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1654",
        "ORTBEZ18": "Cerniat FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1652",
        "ORTBEZ18": "Botterens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1632",
        "ORTBEZ18": "Riaz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1634",
        "ORTBEZ18": "La Roche FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1649",
        "ORTBEZ18": "Pont-la-Ville",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1637",
        "ORTBEZ18": "Charmey (Gruyère)",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1661",
        "ORTBEZ18": "Pâquier-Montbarry",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1663",
        "ORTBEZ18": "Pringy",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1667",
        "ORTBEZ18": "Enney",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1669",
        "ORTBEZ18": "Neirivue",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1663",
        "ORTBEZ18": "Gruyères",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1663",
        "ORTBEZ18": "Epagny",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1666",
        "ORTBEZ18": "Grandvillard",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1675",
        "ORTBEZ18": "Vauderens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1674",
        "ORTBEZ18": "Vuarmarens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1673",
        "ORTBEZ18": "Promasens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1682",
        "ORTBEZ18": "Prévonloup",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1683",
        "ORTBEZ18": "Chesalles-Moudon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1694",
        "ORTBEZ18": "Villarsiviriaux",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1684",
        "ORTBEZ18": "Mézières FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1686",
        "ORTBEZ18": "Grangettes-Romont",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1688",
        "ORTBEZ18": "Sommentier",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1625",
        "ORTBEZ18": "Sâles (Gruyère)",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1609",
        "ORTBEZ18": "St-Martin FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1697",
        "ORTBEZ18": "La Joux FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1699",
        "ORTBEZ18": "Pont (Veveyse)",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1700",
        "ORTBEZ18": "Fribourg 1",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1735",
        "ORTBEZ18": "Giffers",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1737",
        "ORTBEZ18": "Plasselb",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1716",
        "ORTBEZ18": "Schwarzsee",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1728",
        "ORTBEZ18": "Rossens FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1719",
        "ORTBEZ18": "Brünisried",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1731",
        "ORTBEZ18": "Ependes FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1733",
        "ORTBEZ18": "Treyvaux",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1713",
        "ORTBEZ18": "St. Antoni",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1722",
        "ORTBEZ18": "Bourguillon",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1726",
        "ORTBEZ18": "Farvagny-le-Grand",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1752",
        "ORTBEZ18": "Villars-Glâne 1",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1773",
        "ORTBEZ18": "Léchelles",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1774",
        "ORTBEZ18": "Cousset",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1795",
        "ORTBEZ18": "Courlevon",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1794",
        "ORTBEZ18": "Salvenach",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1789",
        "ORTBEZ18": "Lugnorre",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1784",
        "ORTBEZ18": "Courtepin",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1785",
        "ORTBEZ18": "Cressier FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1786",
        "ORTBEZ18": "Sugiez",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1787",
        "ORTBEZ18": "Môtier (Vully)",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1800",
        "ORTBEZ18": "Vevey 1",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1808",
        "ORTBEZ18": "Monts-de-Corsier",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1809",
        "ORTBEZ18": "Fenil-sur-Corsier",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1805",
        "ORTBEZ18": "Jongny",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1806",
        "ORTBEZ18": "St-Légier-Chiésaz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1820",
        "ORTBEZ18": "Montreux 2",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1820",
        "ORTBEZ18": "Montreux 1 Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1824",
        "ORTBEZ18": "Caux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1832",
        "ORTBEZ18": "Villard-sur-Chamby",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1669",
        "ORTBEZ18": "Sciernes-d'Albeuve",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1660",
        "ORTBEZ18": "Les Moulins",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1659",
        "ORTBEZ18": "Flendruz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1833",
        "ORTBEZ18": "Les Avants",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1658",
        "ORTBEZ18": "Rossinière",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1660",
        "ORTBEZ18": "Château-d'Oex",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1844",
        "ORTBEZ18": "Villeneuve VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1852",
        "ORTBEZ18": "Roche VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1853",
        "ORTBEZ18": "Yvorne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1854",
        "ORTBEZ18": "Leysin",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1867",
        "ORTBEZ18": "St-Triphon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1860",
        "ORTBEZ18": "Aigle",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1856",
        "ORTBEZ18": "Corbeyrier",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1862",
        "ORTBEZ18": "Les Mosses",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1865",
        "ORTBEZ18": "Les Diablerets",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1867",
        "ORTBEZ18": "Ollon VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1868",
        "ORTBEZ18": "Collombey",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1871",
        "ORTBEZ18": "Choëx",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1871",
        "ORTBEZ18": "Les Giettes",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1874",
        "ORTBEZ18": "Champéry",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1880",
        "ORTBEZ18": "Les Plans-sur-Bex",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1885",
        "ORTBEZ18": "Chesières",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1890",
        "ORTBEZ18": "Mex VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1902",
        "ORTBEZ18": "Evionnaz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1904",
        "ORTBEZ18": "Vernayaz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1906",
        "ORTBEZ18": "Charrat",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1914",
        "ORTBEZ18": "Isérables",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1955",
        "ORTBEZ18": "Chamoson",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1920",
        "ORTBEZ18": "Martigny 1",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1921",
        "ORTBEZ18": "Martigny-Croix",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1923",
        "ORTBEZ18": "Le Trétien",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1927",
        "ORTBEZ18": "Chemin",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1929",
        "ORTBEZ18": "Trient",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1932",
        "ORTBEZ18": "Bovernier",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1945",
        "ORTBEZ18": "Liddes",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1946",
        "ORTBEZ18": "Bourg-St-Pierre",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1943",
        "ORTBEZ18": "Praz-de-Fort",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1936",
        "ORTBEZ18": "Verbier",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1938",
        "ORTBEZ18": "Champex-Lac",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1950",
        "ORTBEZ18": "Sion",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1976",
        "ORTBEZ18": "Erde",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1976",
        "ORTBEZ18": "Daillon",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1971",
        "ORTBEZ18": "Grimisuat",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1961",
        "ORTBEZ18": "Vernamiège",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1973",
        "ORTBEZ18": "Nax",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1984",
        "ORTBEZ18": "Les Haudères",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1987",
        "ORTBEZ18": "Hérémence",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1988",
        "ORTBEZ18": "Les Collons",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1991",
        "ORTBEZ18": "Salins",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1992",
        "ORTBEZ18": "Les Mayens-de-Sion",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1997",
        "ORTBEZ18": "Haute-Nendaz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1994",
        "ORTBEZ18": "Aproz (Nendaz)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1962",
        "ORTBEZ18": "Pont-de-la-Morge",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1964",
        "ORTBEZ18": "Conthey",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1965",
        "ORTBEZ18": "Savièse",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1966",
        "ORTBEZ18": "Ayent",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1966",
        "ORTBEZ18": "Signèse (Ayent)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1967",
        "ORTBEZ18": "Bramois",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1988",
        "ORTBEZ18": "Thyon",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "2000",
        "ORTBEZ18": "Neuchâtel",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2001",
        "ORTBEZ18": "Neuchâtel 1 Dépôt",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2007",
        "ORTBEZ18": "Neuchâtel 7",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2001",
        "ORTBEZ18": "Neuchâtel 1",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2000",
        "ORTBEZ18": "Neuchâtel 2 Dist",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2006",
        "ORTBEZ18": "Neuchâtel Vauseyon",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2012",
        "ORTBEZ18": "Auvernier",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2014",
        "ORTBEZ18": "Bôle",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2022",
        "ORTBEZ18": "Bevaix",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2042",
        "ORTBEZ18": "Valangin",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2046",
        "ORTBEZ18": "Fontaines NE",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2053",
        "ORTBEZ18": "Cernier",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2054",
        "ORTBEZ18": "Les Vieux-Prés",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2063",
        "ORTBEZ18": "Vilars NE",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2065",
        "ORTBEZ18": "Savagnier",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2072",
        "ORTBEZ18": "St-Blaise",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2073",
        "ORTBEZ18": "Enges",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "3238",
        "ORTBEZ18": "Gals",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2088",
        "ORTBEZ18": "Cressier NE",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2112",
        "ORTBEZ18": "Môtiers NE",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2113",
        "ORTBEZ18": "Boveresse",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2123",
        "ORTBEZ18": "St-Sulpice NE",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2406",
        "ORTBEZ18": "La Brévine",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2406",
        "ORTBEZ18": "Le Brouillet",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2406",
        "ORTBEZ18": "Les Taillères",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2127",
        "ORTBEZ18": "Les Bayards",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2124",
        "ORTBEZ18": "Les Sagnettes",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2116",
        "ORTBEZ18": "Mont-de-Buttes",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "1454",
        "ORTBEZ18": "La Vraconnaz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "2019",
        "ORTBEZ18": "Rochefort",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2037",
        "ORTBEZ18": "Montmollin",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2300",
        "ORTBEZ18": "La Chaux-de-Fonds",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2300",
        "ORTBEZ18": "La Chaux-de-Fds 1",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2302",
        "ORTBEZ18": "La Chaux-de-Fonds",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2301",
        "ORTBEZ18": "La Chaux-de-Fonds",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2316",
        "ORTBEZ18": "Petit-Martel",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2338",
        "ORTBEZ18": "Les Emibois",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2314",
        "ORTBEZ18": "La Sagne NE",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2400",
        "ORTBEZ18": "Le Locle",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2500",
        "ORTBEZ18": "Biel/Bienne J.P.",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2500",
        "ORTBEZ18": "Biel/Bienne 7",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2501",
        "ORTBEZ18": "Biel/Bienne",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2503",
        "ORTBEZ18": "Biel/Bienne",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2505",
        "ORTBEZ18": "Biel/Bienne",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2515",
        "ORTBEZ18": "Prêles",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2517",
        "ORTBEZ18": "Diesse",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2518",
        "ORTBEZ18": "Nods",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2533",
        "ORTBEZ18": "Evilard",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2540",
        "ORTBEZ18": "Grenchen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "2540",
        "ORTBEZ18": "Grenchen 1",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "2540",
        "ORTBEZ18": "Grenchen Schmelzi",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "2552",
        "ORTBEZ18": "Orpund",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2553",
        "ORTBEZ18": "Safnern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2554",
        "ORTBEZ18": "Meinisberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2558",
        "ORTBEZ18": "Aegerten",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2563",
        "ORTBEZ18": "Ipsach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2564",
        "ORTBEZ18": "Bellmund",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2565",
        "ORTBEZ18": "Jens",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2605",
        "ORTBEZ18": "Sonceboz-Sombeval",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2606",
        "ORTBEZ18": "Corgémont",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2608",
        "ORTBEZ18": "Courtelary",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2615",
        "ORTBEZ18": "Montagne-Sonvilier",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2717",
        "ORTBEZ18": "Fornet-Dessous",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2715",
        "ORTBEZ18": "Châtelat-Monible",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2716",
        "ORTBEZ18": "Sornetan",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2345",
        "ORTBEZ18": "Les Breuleux",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2345",
        "ORTBEZ18": "Le Cerneux-Veusil",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2732",
        "ORTBEZ18": "Reconvilier",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2736",
        "ORTBEZ18": "Sorvilier",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2740",
        "ORTBEZ18": "Moutier",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2748",
        "ORTBEZ18": "Souboz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2744",
        "ORTBEZ18": "Belprahon",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2803",
        "ORTBEZ18": "Bourrignon",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2805",
        "ORTBEZ18": "Soyhières",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2807",
        "ORTBEZ18": "Lucelle",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2814",
        "ORTBEZ18": "Roggenburg",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "2822",
        "ORTBEZ18": "Courroux",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2824",
        "ORTBEZ18": "Vicques",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2828",
        "ORTBEZ18": "Montsevelier",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2829",
        "ORTBEZ18": "Vermes",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2843",
        "ORTBEZ18": "Châtillon JU",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2855",
        "ORTBEZ18": "Glovelier",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2857",
        "ORTBEZ18": "Montavon",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2864",
        "ORTBEZ18": "Soulce",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2873",
        "ORTBEZ18": "Saulcy",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2882",
        "ORTBEZ18": "St-Ursanne",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2884",
        "ORTBEZ18": "Montenol",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2886",
        "ORTBEZ18": "Epiquerez",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2900",
        "ORTBEZ18": "Porrentruy 1 Dist",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2905",
        "ORTBEZ18": "Courtedoux",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2906",
        "ORTBEZ18": "Chevenez",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2916",
        "ORTBEZ18": "Fahy",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2924",
        "ORTBEZ18": "Montignez",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern 6",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern 13",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern 14",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern 15",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern 16",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern Sulgenbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern Postauto",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3001",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3005",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3032",
        "ORTBEZ18": "Hinterkappelen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3036",
        "ORTBEZ18": "Detligen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3042",
        "ORTBEZ18": "Ortschwaben",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3043",
        "ORTBEZ18": "Uettligen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3048",
        "ORTBEZ18": "Worblaufen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3054",
        "ORTBEZ18": "Schüpfen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3067",
        "ORTBEZ18": "Boll",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3072",
        "ORTBEZ18": "Ostermundigen 1",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3075",
        "ORTBEZ18": "Rüfenacht BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3078",
        "ORTBEZ18": "Richigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3082",
        "ORTBEZ18": "Schlosswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3089",
        "ORTBEZ18": "Hinterfultigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3112",
        "ORTBEZ18": "Allmendingen b. BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3114",
        "ORTBEZ18": "Wichtrach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3115",
        "ORTBEZ18": "Gerzensee",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3629",
        "ORTBEZ18": "Kiesen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3628",
        "ORTBEZ18": "Uttigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3124",
        "ORTBEZ18": "Belpberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3665",
        "ORTBEZ18": "Wattenwil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3145",
        "ORTBEZ18": "Niederscherli",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3150",
        "ORTBEZ18": "Schwarzenburg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3153",
        "ORTBEZ18": "Rüschegg Gambach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3154",
        "ORTBEZ18": "Rüschegg Heubach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3172",
        "ORTBEZ18": "Niederwangen BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3173",
        "ORTBEZ18": "Oberwangen b. Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3174",
        "ORTBEZ18": "Thörishaus",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3178",
        "ORTBEZ18": "Bösingen",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3126",
        "ORTBEZ18": "Gelterfingen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3204",
        "ORTBEZ18": "Rosshäusern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3205",
        "ORTBEZ18": "Gümmenen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3210",
        "ORTBEZ18": "Kerzers",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3225",
        "ORTBEZ18": "Müntschemier",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3256",
        "ORTBEZ18": "Dieterswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3257",
        "ORTBEZ18": "Ammerzwil BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3266",
        "ORTBEZ18": "Wiler b. Seedorf",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3268",
        "ORTBEZ18": "Lobsigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3270",
        "ORTBEZ18": "Aarberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3272",
        "ORTBEZ18": "Walperswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3286",
        "ORTBEZ18": "Muntelier",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3293",
        "ORTBEZ18": "Dotzigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3313",
        "ORTBEZ18": "Büren zum Hof",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3314",
        "ORTBEZ18": "Schalunen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3315",
        "ORTBEZ18": "Bätterkinden",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3315",
        "ORTBEZ18": "Kräiligen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3322",
        "ORTBEZ18": "Urtenen-Schönbühl",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3322",
        "ORTBEZ18": "Mattstetten",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3324",
        "ORTBEZ18": "Hindelbank",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3309",
        "ORTBEZ18": "Kernenried",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3372",
        "ORTBEZ18": "Wanzwil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3373",
        "ORTBEZ18": "Heimenhausen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3373",
        "ORTBEZ18": "Röthenbach Hbsee",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3365",
        "ORTBEZ18": "Seeberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3365",
        "ORTBEZ18": "Grasswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3366",
        "ORTBEZ18": "Bettenhausen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3367",
        "ORTBEZ18": "Thörigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3400",
        "ORTBEZ18": "Burgdorf",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3400",
        "ORTBEZ18": "Burgdorf 1",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3400",
        "ORTBEZ18": "Burgdorf Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3402",
        "ORTBEZ18": "Burgdorf",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3413",
        "ORTBEZ18": "Kaltacker",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3432",
        "ORTBEZ18": "Lützelflüh-Goldb",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3434",
        "ORTBEZ18": "Obergoldbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3435",
        "ORTBEZ18": "Ramsei",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3436",
        "ORTBEZ18": "Zollbrück",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3437",
        "ORTBEZ18": "Rüderswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3457",
        "ORTBEZ18": "Wasen im Emmental",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3462",
        "ORTBEZ18": "Weier im Emmental",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3463",
        "ORTBEZ18": "Häusernmoos",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3464",
        "ORTBEZ18": "Schmidigen-Mühlew",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3465",
        "ORTBEZ18": "Dürrenroth",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3472",
        "ORTBEZ18": "Wynigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3475",
        "ORTBEZ18": "Riedtwil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3507",
        "ORTBEZ18": "Biglen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3510",
        "ORTBEZ18": "Konolfingen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3672",
        "ORTBEZ18": "Oberdiessbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3673",
        "ORTBEZ18": "Linden",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3674",
        "ORTBEZ18": "Bleiken",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3532",
        "ORTBEZ18": "Zäziwil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3535",
        "ORTBEZ18": "Schüpbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3550",
        "ORTBEZ18": "Langnau i. E.",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3552",
        "ORTBEZ18": "Bärau",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3555",
        "ORTBEZ18": "Trubschachen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3600",
        "ORTBEZ18": "Thun",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3603",
        "ORTBEZ18": "Thun",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3617",
        "ORTBEZ18": "Fahrni b. Thun",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3636",
        "ORTBEZ18": "Längenbühl",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3614",
        "ORTBEZ18": "Unterlangenegg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3615",
        "ORTBEZ18": "Heimenschwand",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3638",
        "ORTBEZ18": "Blumenstein",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3647",
        "ORTBEZ18": "Reutigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3656",
        "ORTBEZ18": "Aeschlen ob Gunten",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3702",
        "ORTBEZ18": "Hondrich",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3703",
        "ORTBEZ18": "Aeschi b. Spiez",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3706",
        "ORTBEZ18": "Leissigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3707",
        "ORTBEZ18": "Därligen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3711",
        "ORTBEZ18": "Mülenen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3713",
        "ORTBEZ18": "Reichenbach i. K.",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3715",
        "ORTBEZ18": "Adelboden",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3716",
        "ORTBEZ18": "Kandergrund",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3718",
        "ORTBEZ18": "Kandersteg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3722",
        "ORTBEZ18": "Scharnachtal",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3724",
        "ORTBEZ18": "Ried (Frutigen)",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3754",
        "ORTBEZ18": "Diemtigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3762",
        "ORTBEZ18": "Erlenbach i. S.",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3764",
        "ORTBEZ18": "Weissenburg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3775",
        "ORTBEZ18": "Lenk im Simmental",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3776",
        "ORTBEZ18": "Oeschseite",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3777",
        "ORTBEZ18": "Saanenmöser",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3778",
        "ORTBEZ18": "Schönried",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3784",
        "ORTBEZ18": "Feutersoey",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3803",
        "ORTBEZ18": "Beatenberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3805",
        "ORTBEZ18": "Goldswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3806",
        "ORTBEZ18": "Bönigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3812",
        "ORTBEZ18": "Wilderswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3814",
        "ORTBEZ18": "Gsteigwiler",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3815",
        "ORTBEZ18": "Zweilütschinen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3816",
        "ORTBEZ18": "Lütschental",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3822",
        "ORTBEZ18": "Isenfluh",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3826",
        "ORTBEZ18": "Gimmelwald",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3853",
        "ORTBEZ18": "Niederried Interl",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3857",
        "ORTBEZ18": "Unterbach BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3860",
        "ORTBEZ18": "Meiringen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3900",
        "ORTBEZ18": "Gamsen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3914",
        "ORTBEZ18": "Blatten b. Naters",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3901",
        "ORTBEZ18": "Rothwald",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3907",
        "ORTBEZ18": "Gabi (Simplon)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3907",
        "ORTBEZ18": "Gondo",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3918",
        "ORTBEZ18": "Wiler (Lötschen)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3904",
        "ORTBEZ18": "Naters",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3905",
        "ORTBEZ18": "Saas-Almagell",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3920",
        "ORTBEZ18": "Zermatt",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3923",
        "ORTBEZ18": "Törbel",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3928",
        "ORTBEZ18": "Randa",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3925",
        "ORTBEZ18": "Grächen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3942",
        "ORTBEZ18": "St. German",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3933",
        "ORTBEZ18": "Staldenried",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3938",
        "ORTBEZ18": "Ausserberg",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3934",
        "ORTBEZ18": "Zeneggen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3935",
        "ORTBEZ18": "Bürchen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3947",
        "ORTBEZ18": "Ergisch",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3948",
        "ORTBEZ18": "Oberems",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3956",
        "ORTBEZ18": "Guttet-Feschel",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3953",
        "ORTBEZ18": "Varen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1978",
        "ORTBEZ18": "Lens",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3979",
        "ORTBEZ18": "Grône",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3946",
        "ORTBEZ18": "Turtmann",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3970",
        "ORTBEZ18": "Salgesch",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3977",
        "ORTBEZ18": "Granges VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1958",
        "ORTBEZ18": "St-Léonard",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3961",
        "ORTBEZ18": "Vissoie",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3967",
        "ORTBEZ18": "Vercorin",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3960",
        "ORTBEZ18": "Muraz (Sierre)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3982",
        "ORTBEZ18": "Bitsch",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3997",
        "ORTBEZ18": "Bellwald",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3989",
        "ORTBEZ18": "Blitzingen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3992",
        "ORTBEZ18": "Bettmeralp",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3996",
        "ORTBEZ18": "Binn",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3984",
        "ORTBEZ18": "Fiesch",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "4000",
        "ORTBEZ18": "Basel 2 Zust",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4000",
        "ORTBEZ18": "Basel Dist Ba",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4010",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4015",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4018",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4024",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4025",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4030",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4031",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4052",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4053",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4057",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4059",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4102",
        "ORTBEZ18": "Binningen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4102",
        "ORTBEZ18": "Binningen 1",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4103",
        "ORTBEZ18": "Bottmingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4112",
        "ORTBEZ18": "Flüh",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4114",
        "ORTBEZ18": "Hofstetten SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4117",
        "ORTBEZ18": "Burg im Leimental",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4118",
        "ORTBEZ18": "Rodersdorf",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4123",
        "ORTBEZ18": "Allschwil",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4123",
        "ORTBEZ18": "Allschwil Dorf",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4123",
        "ORTBEZ18": "Allschwil Lindenpl",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4125",
        "ORTBEZ18": "Riehen Rauracher",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4126",
        "ORTBEZ18": "Bettingen",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4132",
        "ORTBEZ18": "Muttenz",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4133",
        "ORTBEZ18": "Pratteln",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4140",
        "ORTBEZ18": "Pratteln CIRCLE",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4142",
        "ORTBEZ18": "Münchenstein 1",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4142",
        "ORTBEZ18": "Münchenstein Zollw",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4143",
        "ORTBEZ18": "Dornach",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4147",
        "ORTBEZ18": "Aesch BL",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4153",
        "ORTBEZ18": "Reinach BL",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4207",
        "ORTBEZ18": "Bretzwil",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4222",
        "ORTBEZ18": "Zwingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4245",
        "ORTBEZ18": "Kleinlützel",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4204",
        "ORTBEZ18": "Himmelried",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4232",
        "ORTBEZ18": "Fehren",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4233",
        "ORTBEZ18": "Meltingen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4246",
        "ORTBEZ18": "Wahlen b. Laufen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4302",
        "ORTBEZ18": "Augst BL",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4303",
        "ORTBEZ18": "Kaiseraugst",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4310",
        "ORTBEZ18": "Rheinfelden",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4315",
        "ORTBEZ18": "Zuzgen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4316",
        "ORTBEZ18": "Hellikon",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4324",
        "ORTBEZ18": "Obermumpf",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4333",
        "ORTBEZ18": "Münchwilen AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5080",
        "ORTBEZ18": "Laufenburg",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5082",
        "ORTBEZ18": "Kaisten",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5085",
        "ORTBEZ18": "Sulz AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5273",
        "ORTBEZ18": "Oberhofen AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5276",
        "ORTBEZ18": "Wil AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4402",
        "ORTBEZ18": "Frenkendorf",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4416",
        "ORTBEZ18": "Bubendorf",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4422",
        "ORTBEZ18": "Arisdorf",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4423",
        "ORTBEZ18": "Hersberg",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4435",
        "ORTBEZ18": "Niederdorf",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4442",
        "ORTBEZ18": "Diepflingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4455",
        "ORTBEZ18": "Zunzgen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4461",
        "ORTBEZ18": "Böckten",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4469",
        "ORTBEZ18": "Anwil",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4466",
        "ORTBEZ18": "Ormalingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4467",
        "ORTBEZ18": "Rothenfluh",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4492",
        "ORTBEZ18": "Tecknau",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4494",
        "ORTBEZ18": "Oltingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4495",
        "ORTBEZ18": "Zeglingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4497",
        "ORTBEZ18": "Rünenberg",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4500",
        "ORTBEZ18": "Solothurn",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4500",
        "ORTBEZ18": "Solothurn 1",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4503",
        "ORTBEZ18": "Solothurn",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4515",
        "ORTBEZ18": "Weissenstein",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4525",
        "ORTBEZ18": "Balm b. Günsberg",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4523",
        "ORTBEZ18": "Niederwil SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4539",
        "ORTBEZ18": "Rumisberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4539",
        "ORTBEZ18": "Farnern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4532",
        "ORTBEZ18": "Feldbrunnen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4552",
        "ORTBEZ18": "Derendingen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4554",
        "ORTBEZ18": "Etziken",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "3375",
        "ORTBEZ18": "Inkwil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4564",
        "ORTBEZ18": "Obergerlafingen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4565",
        "ORTBEZ18": "Recherswil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4566",
        "ORTBEZ18": "Kriegstetten",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4582",
        "ORTBEZ18": "Brügglen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4576",
        "ORTBEZ18": "Tscheppach",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4587",
        "ORTBEZ18": "Aetingen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4574",
        "ORTBEZ18": "Nennigkofen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4600",
        "ORTBEZ18": "Olten",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4600",
        "ORTBEZ18": "Olten 2 Zustellung",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4601",
        "ORTBEZ18": "Olten 1 Fächer",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4616",
        "ORTBEZ18": "Kappel SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4618",
        "ORTBEZ18": "Boningen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4632",
        "ORTBEZ18": "Trimbach",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4657",
        "ORTBEZ18": "Dulliken",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4663",
        "ORTBEZ18": "Aarburg",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4665",
        "ORTBEZ18": "Oftringen 2",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4633",
        "ORTBEZ18": "Hauenstein",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4634",
        "ORTBEZ18": "Wisen SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4703",
        "ORTBEZ18": "Kestenholz",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "3380",
        "ORTBEZ18": "Wangen an der Aare",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4542",
        "ORTBEZ18": "Luterbach",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4719",
        "ORTBEZ18": "Ramiswil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4712",
        "ORTBEZ18": "Laupersdorf",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4716",
        "ORTBEZ18": "Gänsbrunnen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4852",
        "ORTBEZ18": "Rothrist",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4853",
        "ORTBEZ18": "Murgenthal",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4911",
        "ORTBEZ18": "Schwarzhäusern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4912",
        "ORTBEZ18": "Aarwangen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4913",
        "ORTBEZ18": "Bannwil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4915",
        "ORTBEZ18": "St. Urban",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "4916",
        "ORTBEZ18": "Untersteckholz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4917",
        "ORTBEZ18": "Busswil b.Melchnau",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4919",
        "ORTBEZ18": "Reisiswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4922",
        "ORTBEZ18": "Bützberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4923",
        "ORTBEZ18": "Wynau",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4924",
        "ORTBEZ18": "Obersteckholz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4933",
        "ORTBEZ18": "Rütschelen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4937",
        "ORTBEZ18": "Ursenbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4938",
        "ORTBEZ18": "Rohrbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4942",
        "ORTBEZ18": "Walterswil BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4952",
        "ORTBEZ18": "Eriswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4954",
        "ORTBEZ18": "Wyssachen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "5000",
        "ORTBEZ18": "Aarau",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5000",
        "ORTBEZ18": "Aarau 1 Zustellung",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5004",
        "ORTBEZ18": "Aarau",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5015",
        "ORTBEZ18": "Erlinsbach SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "5017",
        "ORTBEZ18": "Barmelweid",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5022",
        "ORTBEZ18": "Rombach",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5024",
        "ORTBEZ18": "Küttigen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5025",
        "ORTBEZ18": "Asp",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5027",
        "ORTBEZ18": "Herznach",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5035",
        "ORTBEZ18": "Unterentfelden",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5046",
        "ORTBEZ18": "Walde AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5113",
        "ORTBEZ18": "Holderbank AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5103",
        "ORTBEZ18": "Möriken AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5246",
        "ORTBEZ18": "Scherz",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5200",
        "ORTBEZ18": "Brugg AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5201",
        "ORTBEZ18": "Brugg AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5222",
        "ORTBEZ18": "Umiken",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5233",
        "ORTBEZ18": "Stilli",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5073",
        "ORTBEZ18": "Gipf-Oberfrick",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5064",
        "ORTBEZ18": "Wittnau",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5063",
        "ORTBEZ18": "Wölflinswil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5074",
        "ORTBEZ18": "Eiken",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5300",
        "ORTBEZ18": "Turgi",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5304",
        "ORTBEZ18": "Endingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5306",
        "ORTBEZ18": "Tegerfelden",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5313",
        "ORTBEZ18": "Klingnau",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5317",
        "ORTBEZ18": "Hettenschwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5318",
        "ORTBEZ18": "Mandach",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5408",
        "ORTBEZ18": "Ennetbaden",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5413",
        "ORTBEZ18": "Birmenstorf AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5416",
        "ORTBEZ18": "Kirchdorf AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5430",
        "ORTBEZ18": "Wettingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5432",
        "ORTBEZ18": "Neuenhof",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5504",
        "ORTBEZ18": "Othmarsingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5506",
        "ORTBEZ18": "Mägenwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5522",
        "ORTBEZ18": "Tägerig",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5600",
        "ORTBEZ18": "Lenzburg Stadt",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5604",
        "ORTBEZ18": "Hendschiken",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5610",
        "ORTBEZ18": "Wohlen AG 1",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5613",
        "ORTBEZ18": "Hilfikon",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5618",
        "ORTBEZ18": "Bettwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5623",
        "ORTBEZ18": "Boswil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5625",
        "ORTBEZ18": "Kallern",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5627",
        "ORTBEZ18": "Besenbüren",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5632",
        "ORTBEZ18": "Buttwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5634",
        "ORTBEZ18": "Merenschwand",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5636",
        "ORTBEZ18": "Benzenschwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5645",
        "ORTBEZ18": "Aettenschwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5733",
        "ORTBEZ18": "Leimbach AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5734",
        "ORTBEZ18": "Reinach AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5735",
        "ORTBEZ18": "Pfeffikon LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "5736",
        "ORTBEZ18": "Burg AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern 4",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern 6",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern 7",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern 15",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern Zust",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6002",
        "ORTBEZ18": "Luzern",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6003",
        "ORTBEZ18": "Luzern",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6006",
        "ORTBEZ18": "Luzern",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6010",
        "ORTBEZ18": "Kriens",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6015",
        "ORTBEZ18": "Luzern",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6019",
        "ORTBEZ18": "Sigigen",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6020",
        "ORTBEZ18": "Emmenbrücke",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6020",
        "ORTBEZ18": "Emmenbrücke 3",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6023",
        "ORTBEZ18": "Rothenburg",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6024",
        "ORTBEZ18": "Hildisrieden",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6026",
        "ORTBEZ18": "Rain",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6032",
        "ORTBEZ18": "Emmen",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6033",
        "ORTBEZ18": "Buchrain",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6038",
        "ORTBEZ18": "Gisikon",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6042",
        "ORTBEZ18": "Dietwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6044",
        "ORTBEZ18": "Udligenswil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6055",
        "ORTBEZ18": "Alpnach Dorf",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6068",
        "ORTBEZ18": "Melchsee-Frutt",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6066",
        "ORTBEZ18": "St. Niklausen OW",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6078",
        "ORTBEZ18": "Bürglen OW",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6078",
        "ORTBEZ18": "Lungern",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "3860",
        "ORTBEZ18": "Brünig",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6083",
        "ORTBEZ18": "Hasliberg Hohfluh",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6084",
        "ORTBEZ18": "Hasliberg Wasserw",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6086",
        "ORTBEZ18": "Hasliberg Reuti",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6010",
        "ORTBEZ18": "Pilatus Kulm",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6114",
        "ORTBEZ18": "Steinhuserberg",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6113",
        "ORTBEZ18": "Romoos",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6125",
        "ORTBEZ18": "Menzberg",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6130",
        "ORTBEZ18": "Willisau",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6142",
        "ORTBEZ18": "Gettnau",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6143",
        "ORTBEZ18": "Ohmstal",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6145",
        "ORTBEZ18": "Fischbach LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6146",
        "ORTBEZ18": "Grossdietwil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6147",
        "ORTBEZ18": "Altbüron",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6153",
        "ORTBEZ18": "Ufhusen",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6156",
        "ORTBEZ18": "Luthern",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6162",
        "ORTBEZ18": "Entlebuch",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6163",
        "ORTBEZ18": "Ebnet",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6162",
        "ORTBEZ18": "Rengg",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6173",
        "ORTBEZ18": "Flühli LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6205",
        "ORTBEZ18": "Eich",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6206",
        "ORTBEZ18": "Neuenkirch",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6212",
        "ORTBEZ18": "St. Erhard",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6215",
        "ORTBEZ18": "Beromünster",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6216",
        "ORTBEZ18": "Mauensee",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6218",
        "ORTBEZ18": "Ettiswil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6233",
        "ORTBEZ18": "Büron",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6235",
        "ORTBEZ18": "Winikon",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6242",
        "ORTBEZ18": "Wauwil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6253",
        "ORTBEZ18": "Uffikon",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6275",
        "ORTBEZ18": "Ballwil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6276",
        "ORTBEZ18": "Hohenrain",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6280",
        "ORTBEZ18": "Hochdorf",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6285",
        "ORTBEZ18": "Hitzkirch",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6285",
        "ORTBEZ18": "Retschwil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6289",
        "ORTBEZ18": "Müswangen",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6301",
        "ORTBEZ18": "Zug",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6315",
        "ORTBEZ18": "Morgarten",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6314",
        "ORTBEZ18": "Unterägeri",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6300",
        "ORTBEZ18": "Zugerberg",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6331",
        "ORTBEZ18": "Hünenberg",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6344",
        "ORTBEZ18": "Meierskappel",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6345",
        "ORTBEZ18": "Neuheim",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6356",
        "ORTBEZ18": "Rigi Kaltbad",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6365",
        "ORTBEZ18": "Kehrsiten",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6372",
        "ORTBEZ18": "Ennetmoos",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6373",
        "ORTBEZ18": "Ennetbürgen",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6376",
        "ORTBEZ18": "Emmetten",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6388",
        "ORTBEZ18": "Grafenort",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6410",
        "ORTBEZ18": "Rigi Klösterli",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6410",
        "ORTBEZ18": "Rigi Staffel",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6432",
        "ORTBEZ18": "Rickenbach SZ",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6440",
        "ORTBEZ18": "Brunnen",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6452",
        "ORTBEZ18": "Sisikon",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6452",
        "ORTBEZ18": "Riemenstalden",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6461",
        "ORTBEZ18": "Isenthal",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6464",
        "ORTBEZ18": "Spiringen",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6465",
        "ORTBEZ18": "Unterschächen",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6466",
        "ORTBEZ18": "Bauen",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6469",
        "ORTBEZ18": "Haldi b.Schattdorf",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6468",
        "ORTBEZ18": "Attinghausen",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6472",
        "ORTBEZ18": "Erstfeld",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6474",
        "ORTBEZ18": "Amsteg",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6475",
        "ORTBEZ18": "Bristen",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6484",
        "ORTBEZ18": "Wassen UR",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6485",
        "ORTBEZ18": "Meien",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6487",
        "ORTBEZ18": "Göschenen",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6493",
        "ORTBEZ18": "Hospental",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6500",
        "ORTBEZ18": "Bellinzona Caserma",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6512",
        "ORTBEZ18": "Giubiasco",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6535",
        "ORTBEZ18": "Roveredo GR",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6582",
        "ORTBEZ18": "Pianezzo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6538",
        "ORTBEZ18": "Verdabbio",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6542",
        "ORTBEZ18": "Buseno",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6545",
        "ORTBEZ18": "Selma",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6548",
        "ORTBEZ18": "Augio",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6548",
        "ORTBEZ18": "Rossa",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6556",
        "ORTBEZ18": "Leggia",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6565",
        "ORTBEZ18": "S. Bernardino",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6557",
        "ORTBEZ18": "Cama",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6563",
        "ORTBEZ18": "Mesocco",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6574",
        "ORTBEZ18": "Vira (Gambarogno)",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6575",
        "ORTBEZ18": "S. Nazzaro",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6576",
        "ORTBEZ18": "Gerra (Gambarogno)",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6595",
        "ORTBEZ18": "Riazzino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6597",
        "ORTBEZ18": "Agarone",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6600",
        "ORTBEZ18": "Locarno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6601",
        "ORTBEZ18": "Locarno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6600",
        "ORTBEZ18": "Locarno Dist",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6656",
        "ORTBEZ18": "Golino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6661",
        "ORTBEZ18": "Loco",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6661",
        "ORTBEZ18": "Berzona",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6662",
        "ORTBEZ18": "Russo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6663",
        "ORTBEZ18": "Comologno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6635",
        "ORTBEZ18": "Gerra (Verzasca)",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6612",
        "ORTBEZ18": "Ascona",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6644",
        "ORTBEZ18": "Orselina",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6648",
        "ORTBEZ18": "Minusio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6655",
        "ORTBEZ18": "Verdasio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6659",
        "ORTBEZ18": "Moneto",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6670",
        "ORTBEZ18": "Avegno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6682",
        "ORTBEZ18": "Linescio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6683",
        "ORTBEZ18": "Cerentino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6684",
        "ORTBEZ18": "Campo (VM)",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6684",
        "ORTBEZ18": "Cimalmotto",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6692",
        "ORTBEZ18": "Brontallo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6695",
        "ORTBEZ18": "Peccia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6695",
        "ORTBEZ18": "Piano di Peccia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6696",
        "ORTBEZ18": "Fusio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6672",
        "ORTBEZ18": "Gordevio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6676",
        "ORTBEZ18": "Bignasco",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6721",
        "ORTBEZ18": "Ludiano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6723",
        "ORTBEZ18": "Castro",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6713",
        "ORTBEZ18": "Malvaglia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6717",
        "ORTBEZ18": "Dangio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6717",
        "ORTBEZ18": "Torre",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6745",
        "ORTBEZ18": "Giornico",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6760",
        "ORTBEZ18": "Molare",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6760",
        "ORTBEZ18": "Calpiogna",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6760",
        "ORTBEZ18": "Campello",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6772",
        "ORTBEZ18": "Rodi-Fiesso",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6777",
        "ORTBEZ18": "Quinto",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6780",
        "ORTBEZ18": "Airolo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6781",
        "ORTBEZ18": "Villa Bedretto",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6780",
        "ORTBEZ18": "Madrano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6781",
        "ORTBEZ18": "Bedretto",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6744",
        "ORTBEZ18": "Personico",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6748",
        "ORTBEZ18": "Anzonico",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6760",
        "ORTBEZ18": "Rossura",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6773",
        "ORTBEZ18": "Prato (Leventina)",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6802",
        "ORTBEZ18": "Rivera",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6804",
        "ORTBEZ18": "Bironico",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6807",
        "ORTBEZ18": "Taverne",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6808",
        "ORTBEZ18": "Torricella",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6814",
        "ORTBEZ18": "Lamone-Cadempino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6815",
        "ORTBEZ18": "Melide",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6816",
        "ORTBEZ18": "Bissone",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6825",
        "ORTBEZ18": "Capolago",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6826",
        "ORTBEZ18": "Riva San Vitale",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6828",
        "ORTBEZ18": "Balerna",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6830",
        "ORTBEZ18": "Chiasso 1",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6830",
        "ORTBEZ18": "Chiasso 3",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6832",
        "ORTBEZ18": "Pedrinate",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6836",
        "ORTBEZ18": "Serfontana",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6806",
        "ORTBEZ18": "Sigirino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6823",
        "ORTBEZ18": "Pugerna",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6850",
        "ORTBEZ18": "Mendrisio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6854",
        "ORTBEZ18": "S. Pietro",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6855",
        "ORTBEZ18": "Stabio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6862",
        "ORTBEZ18": "Rancate",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6872",
        "ORTBEZ18": "Salorino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6906",
        "ORTBEZ18": "Lugano 6 Caselle",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6913",
        "ORTBEZ18": "Carabbia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6916",
        "ORTBEZ18": "Grancia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6919",
        "ORTBEZ18": "Carabietta",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6929",
        "ORTBEZ18": "Gravesano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6930",
        "ORTBEZ18": "Bedano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6939",
        "ORTBEZ18": "Arosio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6939",
        "ORTBEZ18": "Mugena",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6938",
        "ORTBEZ18": "Vezio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6937",
        "ORTBEZ18": "Breno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6938",
        "ORTBEZ18": "Fescoggia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6827",
        "ORTBEZ18": "Brusino Arsizio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6918",
        "ORTBEZ18": "Figino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6926",
        "ORTBEZ18": "Montagnola",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6927",
        "ORTBEZ18": "Agra",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6932",
        "ORTBEZ18": "Breganzona",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6934",
        "ORTBEZ18": "Bioggio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6936",
        "ORTBEZ18": "Cademario",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6945",
        "ORTBEZ18": "Origlio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6946",
        "ORTBEZ18": "Ponte Capriasca",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6953",
        "ORTBEZ18": "Lugaggia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6951",
        "ORTBEZ18": "Insone",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6956",
        "ORTBEZ18": "Lopagno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6957",
        "ORTBEZ18": "Roveredo TI",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6958",
        "ORTBEZ18": "Bidogno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6955",
        "ORTBEZ18": "Cagiallo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6959",
        "ORTBEZ18": "Piandera Paese",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6976",
        "ORTBEZ18": "Castagnola",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6977",
        "ORTBEZ18": "Ruvigliana",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6978",
        "ORTBEZ18": "Gandria",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6990",
        "ORTBEZ18": "Cassina d'Agno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6992",
        "ORTBEZ18": "Vernate",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6981",
        "ORTBEZ18": "Banco",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6986",
        "ORTBEZ18": "Miglieglia",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6989",
        "ORTBEZ18": "Purasca",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6997",
        "ORTBEZ18": "Sessa",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6986",
        "ORTBEZ18": "Curio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "7004",
        "ORTBEZ18": "Chur",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7006",
        "ORTBEZ18": "Chur",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7007",
        "ORTBEZ18": "Chur",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7012",
        "ORTBEZ18": "Felsberg",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7013",
        "ORTBEZ18": "Domat/Ems",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7023",
        "ORTBEZ18": "Haldenstein",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7050",
        "ORTBEZ18": "Arosa",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7062",
        "ORTBEZ18": "Passugg",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7074",
        "ORTBEZ18": "Malix",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7076",
        "ORTBEZ18": "Parpan",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7077",
        "ORTBEZ18": "Valbella",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7016",
        "ORTBEZ18": "Trin Mulin",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7027",
        "ORTBEZ18": "Lüen",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7028",
        "ORTBEZ18": "St. Peter",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7057",
        "ORTBEZ18": "Langwies",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7107",
        "ORTBEZ18": "Safien Platz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7151",
        "ORTBEZ18": "Schluein",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7115",
        "ORTBEZ18": "Surcasti",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7116",
        "ORTBEZ18": "Tersnaus",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7144",
        "ORTBEZ18": "Vella",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7145",
        "ORTBEZ18": "Degen",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7137",
        "ORTBEZ18": "Flond",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7132",
        "ORTBEZ18": "Vals",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7163",
        "ORTBEZ18": "Danis",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7164",
        "ORTBEZ18": "Dardin",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7165",
        "ORTBEZ18": "Breil/Brigels",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7174",
        "ORTBEZ18": "S. Benedetg",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7183",
        "ORTBEZ18": "Mumpé Medel",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7184",
        "ORTBEZ18": "Curaglia",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7185",
        "ORTBEZ18": "Platta",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7187",
        "ORTBEZ18": "Camischolas",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7173",
        "ORTBEZ18": "Surrein",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7202",
        "ORTBEZ18": "Says",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7213",
        "ORTBEZ18": "Valzeina",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7214",
        "ORTBEZ18": "Grüsch",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7222",
        "ORTBEZ18": "Lunden",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7228",
        "ORTBEZ18": "Pusserein",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7235",
        "ORTBEZ18": "Fideris",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7241",
        "ORTBEZ18": "Conters",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7265",
        "ORTBEZ18": "Davos Wolfgang",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7270",
        "ORTBEZ18": "Davos Platz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7302",
        "ORTBEZ18": "Landquart",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7303",
        "ORTBEZ18": "Mastrils",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7310",
        "ORTBEZ18": "Bad Ragaz",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "7323",
        "ORTBEZ18": "Wangs",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "7402",
        "ORTBEZ18": "Bonaduz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7403",
        "ORTBEZ18": "Rhäzüns",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7413",
        "ORTBEZ18": "Fürstenaubruck",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7415",
        "ORTBEZ18": "Rodels",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7407",
        "ORTBEZ18": "Trans",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7418",
        "ORTBEZ18": "Tumegl/Tomils",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7419",
        "ORTBEZ18": "Scheid",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7427",
        "ORTBEZ18": "Urmein",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7428",
        "ORTBEZ18": "Tschappina",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7421",
        "ORTBEZ18": "Summaprada",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7433",
        "ORTBEZ18": "Mathon",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7445",
        "ORTBEZ18": "Innerferrera",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7447",
        "ORTBEZ18": "Avers",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7453",
        "ORTBEZ18": "Tinizong",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7454",
        "ORTBEZ18": "Rona",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7456",
        "ORTBEZ18": "Sur",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7463",
        "ORTBEZ18": "Riom",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7477",
        "ORTBEZ18": "Filisur",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7494",
        "ORTBEZ18": "Davos Wiesen",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7500",
        "ORTBEZ18": "St. Moritz 1",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7503",
        "ORTBEZ18": "Samedan",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7505",
        "ORTBEZ18": "Celerina",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7514",
        "ORTBEZ18": "Sils/Segl Maria",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7516",
        "ORTBEZ18": "Maloja",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7517",
        "ORTBEZ18": "Plaun da Lej",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7523",
        "ORTBEZ18": "Madulain",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7532",
        "ORTBEZ18": "Tschierv",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7533",
        "ORTBEZ18": "Fuldera",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7534",
        "ORTBEZ18": "Lü",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7546",
        "ORTBEZ18": "Ardez",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7552",
        "ORTBEZ18": "Vulpera",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7558",
        "ORTBEZ18": "Strada",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7559",
        "ORTBEZ18": "Tschlin",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7610",
        "ORTBEZ18": "Soglio",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7608",
        "ORTBEZ18": "Castasegna",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7742",
        "ORTBEZ18": "Sfazù",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7743",
        "ORTBEZ18": "Brusio",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7747",
        "ORTBEZ18": "Viano",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7744",
        "ORTBEZ18": "Campocologno",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7741",
        "ORTBEZ18": "S. Carlo GR",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7710",
        "ORTBEZ18": "Alp Grüm",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7745",
        "ORTBEZ18": "Li Curt",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7746",
        "ORTBEZ18": "Le Prese",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7748",
        "ORTBEZ18": "Campascio",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "8000",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8005",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8008",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8010",
        "ORTBEZ18": "Zürich BZ FP",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8022",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8024",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8026",
        "ORTBEZ18": "Zürich Helvetiapl.",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8032",
        "ORTBEZ18": "Zürich Neumünster",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8038",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8045",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8047",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8048",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8050",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8051",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8090",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8104",
        "ORTBEZ18": "Weiningen ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8114",
        "ORTBEZ18": "Dänikon ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8117",
        "ORTBEZ18": "Fällanden",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8121",
        "ORTBEZ18": "Benglen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8122",
        "ORTBEZ18": "Binz",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8134",
        "ORTBEZ18": "Adliswil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8135",
        "ORTBEZ18": "Langnau am Albis",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8143",
        "ORTBEZ18": "Stallikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8154",
        "ORTBEZ18": "Oberglatt ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8157",
        "ORTBEZ18": "Dielsdorf",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8173",
        "ORTBEZ18": "Neerach",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8175",
        "ORTBEZ18": "Windlach",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8182",
        "ORTBEZ18": "Hochfelden",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8185",
        "ORTBEZ18": "Winkel",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8200",
        "ORTBEZ18": "Schaffh 1 Zust",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8201",
        "ORTBEZ18": "Schaffhausen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8203",
        "ORTBEZ18": "Schaffhausen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8243",
        "ORTBEZ18": "Altdorf SH",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8239",
        "ORTBEZ18": "Dörflingen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8212",
        "ORTBEZ18": "Neuhausen 1",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8213",
        "ORTBEZ18": "Neunkirch",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8214",
        "ORTBEZ18": "Gächlingen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8215",
        "ORTBEZ18": "Hallau",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8217",
        "ORTBEZ18": "Wilchingen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8218",
        "ORTBEZ18": "Osterfingen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8240",
        "ORTBEZ18": "Thayngen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8247",
        "ORTBEZ18": "Flurlingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8259",
        "ORTBEZ18": "Kaltenbach",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8259",
        "ORTBEZ18": "Etzwilen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8260",
        "ORTBEZ18": "Stein am Rhein",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8261",
        "ORTBEZ18": "Hemishofen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8262",
        "ORTBEZ18": "Ramsen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8264",
        "ORTBEZ18": "Eschenz",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8267",
        "ORTBEZ18": "Berlingen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8280",
        "ORTBEZ18": "Kreuzlingen ceha!",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8303",
        "ORTBEZ18": "Bassersdorf",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8306",
        "ORTBEZ18": "Brüttisellen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8307",
        "ORTBEZ18": "Effretikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8315",
        "ORTBEZ18": "Lindau",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8308",
        "ORTBEZ18": "Agasul",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8310",
        "ORTBEZ18": "Kemptthal",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8312",
        "ORTBEZ18": "Winterberg ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8311",
        "ORTBEZ18": "Brütten",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8489",
        "ORTBEZ18": "Wildberg",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8330",
        "ORTBEZ18": "Pfäffikon ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8335",
        "ORTBEZ18": "Hittnau",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8342",
        "ORTBEZ18": "Wernetshausen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8344",
        "ORTBEZ18": "Bäretswil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8352",
        "ORTBEZ18": "Elsau",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8354",
        "ORTBEZ18": "Hofstetten ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8356",
        "ORTBEZ18": "Ettenhausen TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8357",
        "ORTBEZ18": "Guntershausen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8363",
        "ORTBEZ18": "Bichelsee",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8400",
        "ORTBEZ18": "Winterthur",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8401",
        "ORTBEZ18": "Winterthur",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8402",
        "ORTBEZ18": "Winterthur",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8408",
        "ORTBEZ18": "Winterthur",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8412",
        "ORTBEZ18": "Aesch (Neftenbach)",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8415",
        "ORTBEZ18": "Berg am Irchel",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8418",
        "ORTBEZ18": "Schlatt ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8425",
        "ORTBEZ18": "Oberembrach",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8192",
        "ORTBEZ18": "Zweidlen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8187",
        "ORTBEZ18": "Weiach",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "5332",
        "ORTBEZ18": "Rekingen AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5330",
        "ORTBEZ18": "Bad Zurzach",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5465",
        "ORTBEZ18": "Mellikon",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5334",
        "ORTBEZ18": "Böbikon",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8444",
        "ORTBEZ18": "Henggart",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8248",
        "ORTBEZ18": "Uhwiesen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8451",
        "ORTBEZ18": "Kleinandelfingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8452",
        "ORTBEZ18": "Adlikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8459",
        "ORTBEZ18": "Volken",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8455",
        "ORTBEZ18": "Rüdlingen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8461",
        "ORTBEZ18": "Oerlingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8465",
        "ORTBEZ18": "Rudolfingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8462",
        "ORTBEZ18": "Rheinau",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8463",
        "ORTBEZ18": "Benken ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8472",
        "ORTBEZ18": "Seuzach",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8474",
        "ORTBEZ18": "Dinhard",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8479",
        "ORTBEZ18": "Altikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8467",
        "ORTBEZ18": "Truttikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8468",
        "ORTBEZ18": "Waltalingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8482",
        "ORTBEZ18": "Sennhof",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8484",
        "ORTBEZ18": "Weisslingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8484",
        "ORTBEZ18": "Neschwil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8486",
        "ORTBEZ18": "Rikon im Tösstal",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8492",
        "ORTBEZ18": "Wila",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8496",
        "ORTBEZ18": "Steg im Tösstal",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8497",
        "ORTBEZ18": "Fischenthal",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8500",
        "ORTBEZ18": "Gerlikon",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8502",
        "ORTBEZ18": "Frauenfeld Talbach",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8507",
        "ORTBEZ18": "Hörhausen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8512",
        "ORTBEZ18": "Thundorf",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8526",
        "ORTBEZ18": "Oberneunforn",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8532",
        "ORTBEZ18": "Warth",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8545",
        "ORTBEZ18": "Rickenbach ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8547",
        "ORTBEZ18": "Gachnang",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8548",
        "ORTBEZ18": "Ellikon a d Thur",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8552",
        "ORTBEZ18": "Felben-Wellhausen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8554",
        "ORTBEZ18": "Bonau",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8564",
        "ORTBEZ18": "Lipperswil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8560",
        "ORTBEZ18": "Märstetten",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8566",
        "ORTBEZ18": "Dotnacht",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8564",
        "ORTBEZ18": "Engwilen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8564",
        "ORTBEZ18": "Wäldi",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8570",
        "ORTBEZ18": "Weinfelden",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8574",
        "ORTBEZ18": "Illighausen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8576",
        "ORTBEZ18": "Mauren TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8577",
        "ORTBEZ18": "Schönholzerswilen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8580",
        "ORTBEZ18": "Sommeri",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8580",
        "ORTBEZ18": "Hagenwil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8581",
        "ORTBEZ18": "Schocherswil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8588",
        "ORTBEZ18": "Zihlschlacht",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8585",
        "ORTBEZ18": "Happerswil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8586",
        "ORTBEZ18": "Erlen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8586",
        "ORTBEZ18": "Riedt b. Erlen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8590",
        "ORTBEZ18": "Romanshorn",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8599",
        "ORTBEZ18": "Salmsach",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8592",
        "ORTBEZ18": "Uttwil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8597",
        "ORTBEZ18": "Landschlacht",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8598",
        "ORTBEZ18": "Bottighofen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8600",
        "ORTBEZ18": "Dübendorf 1",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8606",
        "ORTBEZ18": "Nänikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8610",
        "ORTBEZ18": "Uster 1",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8618",
        "ORTBEZ18": "Oetwil am See",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8620",
        "ORTBEZ18": "Wetzikon ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8621",
        "ORTBEZ18": "Wetzikon ZH Roben.",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8623",
        "ORTBEZ18": "Wetzikon ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8626",
        "ORTBEZ18": "Ottikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8633",
        "ORTBEZ18": "Wolfhausen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8636",
        "ORTBEZ18": "Wald ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8638",
        "ORTBEZ18": "Goldingen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8640",
        "ORTBEZ18": "Rapperswil SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8640",
        "ORTBEZ18": "Rapperswil SG Zust",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8645",
        "ORTBEZ18": "Jona",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8700",
        "ORTBEZ18": "Küsnacht ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8702",
        "ORTBEZ18": "Zollikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8702",
        "ORTBEZ18": "Zollikon Dorf",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8703",
        "ORTBEZ18": "Erlenbach ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8707",
        "ORTBEZ18": "Uetikon am See",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8713",
        "ORTBEZ18": "Uerikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8714",
        "ORTBEZ18": "Feldbach",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8715",
        "ORTBEZ18": "Bollingen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8717",
        "ORTBEZ18": "Benken SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8718",
        "ORTBEZ18": "Schänis",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8723",
        "ORTBEZ18": "Rufi",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8725",
        "ORTBEZ18": "Ernetschwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8726",
        "ORTBEZ18": "Ricken SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8733",
        "ORTBEZ18": "Eschenbach SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8735",
        "ORTBEZ18": "St. Gallenkappel",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8737",
        "ORTBEZ18": "Gommiswald",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8739",
        "ORTBEZ18": "Rieden SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8740",
        "ORTBEZ18": "Uznach Vögele AG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8750",
        "ORTBEZ18": "Riedern",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8754",
        "ORTBEZ18": "Netstal",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8762",
        "ORTBEZ18": "Schwanden GL",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8766",
        "ORTBEZ18": "Matt",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8775",
        "ORTBEZ18": "Hätzingen",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8784",
        "ORTBEZ18": "Braunwald",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8800",
        "ORTBEZ18": "Thalwil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8804",
        "ORTBEZ18": "Au ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8805",
        "ORTBEZ18": "Richterswil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8806",
        "ORTBEZ18": "Bäch SZ",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8807",
        "ORTBEZ18": "Freienbach",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8810",
        "ORTBEZ18": "Horgen 1",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8813",
        "ORTBEZ18": "Horgen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8820",
        "ORTBEZ18": "Wädenswil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8824",
        "ORTBEZ18": "Schönenberg ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8834",
        "ORTBEZ18": "Schindellegi",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8840",
        "ORTBEZ18": "Einsiedeln",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8849",
        "ORTBEZ18": "Alpthal",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8844",
        "ORTBEZ18": "Euthal",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8845",
        "ORTBEZ18": "Studen SZ",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8852",
        "ORTBEZ18": "Altendorf",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8853",
        "ORTBEZ18": "Lachen SZ",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8856",
        "ORTBEZ18": "Tuggen",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8872",
        "ORTBEZ18": "Weesen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8873",
        "ORTBEZ18": "Amden",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8874",
        "ORTBEZ18": "Mühlehorn",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8880",
        "ORTBEZ18": "Walenstadt",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8881",
        "ORTBEZ18": "Tscherlach",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8883",
        "ORTBEZ18": "Quarten",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8884",
        "ORTBEZ18": "Oberterzen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8888",
        "ORTBEZ18": "Heiligkreuz (Mels)",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8895",
        "ORTBEZ18": "Flumserberg Portel",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8908",
        "ORTBEZ18": "Hedingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8912",
        "ORTBEZ18": "Obfelden",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8913",
        "ORTBEZ18": "Ottenbach",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8915",
        "ORTBEZ18": "Hausen am Albis",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8925",
        "ORTBEZ18": "Ebertswil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8926",
        "ORTBEZ18": "Kappel am Albis",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8942",
        "ORTBEZ18": "Oberrieden",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8135",
        "ORTBEZ18": "Sihlbrugg Station",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6340",
        "ORTBEZ18": "Sihlbrugg",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "8953",
        "ORTBEZ18": "Dietikon 1",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8953",
        "ORTBEZ18": "Dietikon Glanzenb.",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8954",
        "ORTBEZ18": "Geroldswil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8957",
        "ORTBEZ18": "Spreitenbach",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8965",
        "ORTBEZ18": "Berikon",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8966",
        "ORTBEZ18": "Oberwil-Lieli",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8967",
        "ORTBEZ18": "Widen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "9000",
        "ORTBEZ18": "St. Gallen 1 Ann.",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9004",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9008",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9009",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9011",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9014",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9015",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9016",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9032",
        "ORTBEZ18": "Engelburg",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9036",
        "ORTBEZ18": "Grub SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9038",
        "ORTBEZ18": "Rehetobel",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9042",
        "ORTBEZ18": "Speicher",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9052",
        "ORTBEZ18": "Niederteufen",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9053",
        "ORTBEZ18": "Teufen AR",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9063",
        "ORTBEZ18": "Stein AR",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9100",
        "ORTBEZ18": "Herisau 1",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9102",
        "ORTBEZ18": "Herisau",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9107",
        "ORTBEZ18": "Urnäsch",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9115",
        "ORTBEZ18": "Dicken",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9123",
        "ORTBEZ18": "Nassen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9127",
        "ORTBEZ18": "St. Peterzell",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9633",
        "ORTBEZ18": "Bächli (Hemberg)",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9200",
        "ORTBEZ18": "Gossau SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9200",
        "ORTBEZ18": "Gossau SG 2",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9204",
        "ORTBEZ18": "Andwil SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9212",
        "ORTBEZ18": "Arnegg",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9215",
        "ORTBEZ18": "Schönenberg TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9216",
        "ORTBEZ18": "Hohentannen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9225",
        "ORTBEZ18": "Wilen (Gottshaus)",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9225",
        "ORTBEZ18": "St. Pelagiberg",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9248",
        "ORTBEZ18": "Bichwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9243",
        "ORTBEZ18": "Jonschwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9249",
        "ORTBEZ18": "Algetshausen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9300",
        "ORTBEZ18": "Wittenbach",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9315",
        "ORTBEZ18": "Winden",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9308",
        "ORTBEZ18": "Lömmenschwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9315",
        "ORTBEZ18": "Neukirch (Egnach)",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9320",
        "ORTBEZ18": "Arbon",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9320",
        "ORTBEZ18": "Frasnacht",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9323",
        "ORTBEZ18": "Steinach",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9402",
        "ORTBEZ18": "Mörschwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9403",
        "ORTBEZ18": "Goldach",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9411",
        "ORTBEZ18": "Schachen b. Reute",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9422",
        "ORTBEZ18": "Staad SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9428",
        "ORTBEZ18": "Walzenhausen",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9430",
        "ORTBEZ18": "St. Margrethen SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9436",
        "ORTBEZ18": "Balgach",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9437",
        "ORTBEZ18": "Marbach SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9450",
        "ORTBEZ18": "Lüchingen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9443",
        "ORTBEZ18": "Widnau",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9445",
        "ORTBEZ18": "Rebstein",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9453",
        "ORTBEZ18": "Eichberg",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9464",
        "ORTBEZ18": "Rüthi (Rheintal)",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9465",
        "ORTBEZ18": "Salez",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9466",
        "ORTBEZ18": "Sennwald",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9467",
        "ORTBEZ18": "Frümsen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9469",
        "ORTBEZ18": "Haag (Rheintal)",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9470",
        "ORTBEZ18": "Buchs SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9470",
        "ORTBEZ18": "Buchs SG 1",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9472",
        "ORTBEZ18": "Grabs",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9477",
        "ORTBEZ18": "Trübbach",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9478",
        "ORTBEZ18": "Azmoos",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9485",
        "ORTBEZ18": "Nendeln",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "9486",
        "ORTBEZ18": "Schaanwald",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "9493",
        "ORTBEZ18": "Mauren FL",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "9500",
        "ORTBEZ18": "Wil SG 1",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9503",
        "ORTBEZ18": "Stehrenberg",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9506",
        "ORTBEZ18": "Lommis",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9508",
        "ORTBEZ18": "Weingarten-Kalth",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9517",
        "ORTBEZ18": "Mettlen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9525",
        "ORTBEZ18": "Lenggenwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9532",
        "ORTBEZ18": "Rickenbach b. Wil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9533",
        "ORTBEZ18": "Kirchberg SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9542",
        "ORTBEZ18": "Münchwilen TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9543",
        "ORTBEZ18": "St. Margarethen TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9546",
        "ORTBEZ18": "Tuttwil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9547",
        "ORTBEZ18": "Wittenwil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9552",
        "ORTBEZ18": "Bronschhofen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9556",
        "ORTBEZ18": "Affeltrangen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9565",
        "ORTBEZ18": "Bussnang",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9602",
        "ORTBEZ18": "Bazenheid",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9608",
        "ORTBEZ18": "Ganterschwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9612",
        "ORTBEZ18": "Dreien",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9613",
        "ORTBEZ18": "Mühlrüti",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9620",
        "ORTBEZ18": "Lichtensteig",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9642",
        "ORTBEZ18": "Ebnat-Kappel",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9650",
        "ORTBEZ18": "Nesslau",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9655",
        "ORTBEZ18": "Stein SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9652",
        "ORTBEZ18": "Neu St. Johann",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "6911",
        "ORTBEZ18": "Campione",
        "KANTON": "IT"
    },
    {
        "POSTLEITZAHL": "8238",
        "ORTBEZ18": "Büsingen",
        "KANTON": "DE"
    },
    {
        "POSTLEITZAHL": "1934",
        "ORTBEZ18": "Bruson",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1400",
        "ORTBEZ18": "Cheseaux-Noréaz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1920",
        "ORTBEZ18": "Martigny 1 Dist",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "8317",
        "ORTBEZ18": "Tagelswangen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "3855",
        "ORTBEZ18": "Schwanden-Brienz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6009",
        "ORTBEZ18": "Luzern",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "5232",
        "ORTBEZ18": "Villigen PSI",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "2830",
        "ORTBEZ18": "Vellerat",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2717",
        "ORTBEZ18": "Rebévelier",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2575",
        "ORTBEZ18": "Hagneck",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1473",
        "ORTBEZ18": "Châtillon FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1566",
        "ORTBEZ18": "Les Friques",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1528",
        "ORTBEZ18": "Praratoud",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1470",
        "ORTBEZ18": "Seiry",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1541",
        "ORTBEZ18": "Sévaz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1680",
        "ORTBEZ18": "Berlens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1670",
        "ORTBEZ18": "Bionnens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1690",
        "ORTBEZ18": "Lussy FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1687",
        "ORTBEZ18": "La Magne",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1674",
        "ORTBEZ18": "Morlens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1782",
        "ORTBEZ18": "Autafond",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1724",
        "ORTBEZ18": "Essert FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1726",
        "ORTBEZ18": "Farvagny-le-Petit",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1724",
        "ORTBEZ18": "Ferpicloz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1726",
        "ORTBEZ18": "Grenilles",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1782",
        "ORTBEZ18": "Lossy",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1724",
        "ORTBEZ18": "Montévraz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1724",
        "ORTBEZ18": "Oberried FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1695",
        "ORTBEZ18": "Villarsel-Gibloux",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1792",
        "ORTBEZ18": "Guschelmuth",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1609",
        "ORTBEZ18": "Besencens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1609",
        "ORTBEZ18": "Fiaugères",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1148",
        "ORTBEZ18": "Mauraz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1376",
        "ORTBEZ18": "Eclagnens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1376",
        "ORTBEZ18": "Goumoens-le-Jux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1026",
        "ORTBEZ18": "Denges",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1682",
        "ORTBEZ18": "Lovatens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1063",
        "ORTBEZ18": "Martherenges",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1290",
        "ORTBEZ18": "Chavannes-des-Bois",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1610",
        "ORTBEZ18": "Vuibroye",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1682",
        "ORTBEZ18": "Cerniaz VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1554",
        "ORTBEZ18": "Rossens VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1525",
        "ORTBEZ18": "Seigneux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1195",
        "ORTBEZ18": "Dully",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1415",
        "ORTBEZ18": "Démoret",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1443",
        "ORTBEZ18": "Essert-s-Champvent",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1443",
        "ORTBEZ18": "Villars-Champvent",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1047",
        "ORTBEZ18": "Oppens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "2027",
        "ORTBEZ18": "Montalchez",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "3283",
        "ORTBEZ18": "Niederried b. Kall",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3472",
        "ORTBEZ18": "Rumendingen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3421",
        "ORTBEZ18": "Rüti b. Lyssach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3425",
        "ORTBEZ18": "Willadingen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3053",
        "ORTBEZ18": "Deisswil Mbuchsee",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3305",
        "ORTBEZ18": "Scheunen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3309",
        "ORTBEZ18": "Zauggenried",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3510",
        "ORTBEZ18": "Freimettigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3510",
        "ORTBEZ18": "Häutligen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3504",
        "ORTBEZ18": "Oberhünigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3207",
        "ORTBEZ18": "Golaten",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3272",
        "ORTBEZ18": "Epsach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3127",
        "ORTBEZ18": "Lohnstorf",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3116",
        "ORTBEZ18": "Mühledorf BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3636",
        "ORTBEZ18": "Forst",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3366",
        "ORTBEZ18": "Bollodingen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4704",
        "ORTBEZ18": "Wolfisberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8774",
        "ORTBEZ18": "Leuggelbach",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "3216",
        "ORTBEZ18": "Agriswil",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3280",
        "ORTBEZ18": "Greng",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3215",
        "ORTBEZ18": "Lurtigen",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3206",
        "ORTBEZ18": "Wallenbuch",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3254",
        "ORTBEZ18": "Balm b. Messen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4554",
        "ORTBEZ18": "Hüniken",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4566",
        "ORTBEZ18": "Oekingen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4556",
        "ORTBEZ18": "Steinhof SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4558",
        "ORTBEZ18": "Winistorf",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4535",
        "ORTBEZ18": "Kammersrohr",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "5619",
        "ORTBEZ18": "Uezwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8905",
        "ORTBEZ18": "Islisberg",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5058",
        "ORTBEZ18": "Wiliberg",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8580",
        "ORTBEZ18": "Hefenhofen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8586",
        "ORTBEZ18": "Buchackern",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8586",
        "ORTBEZ18": "Ennetaach",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8546",
        "ORTBEZ18": "Kefikon TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8553",
        "ORTBEZ18": "Eschikofen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8573",
        "ORTBEZ18": "Alterswilen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8566",
        "ORTBEZ18": "Ellighausen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8566",
        "ORTBEZ18": "Lippoldswilen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8585",
        "ORTBEZ18": "Herrenhof",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9562",
        "ORTBEZ18": "Buch b. Märwil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9556",
        "ORTBEZ18": "Zezikon",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8512",
        "ORTBEZ18": "Wetzikon TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8585",
        "ORTBEZ18": "Birwinken",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "6724",
        "ORTBEZ18": "Largario",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6723",
        "ORTBEZ18": "Marolta",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "3995",
        "ORTBEZ18": "Steinhaus",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3983",
        "ORTBEZ18": "Bister",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3983",
        "ORTBEZ18": "Filet",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "6212",
        "ORTBEZ18": "Kaltbach",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "1653",
        "ORTBEZ18": "Crésuz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "2027",
        "ORTBEZ18": "Fresens",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2953",
        "ORTBEZ18": "Pleujouse",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "3984",
        "ORTBEZ18": "Fieschertal",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "6814",
        "ORTBEZ18": "Cadempino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "9470",
        "ORTBEZ18": "Werdenberg",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "1626",
        "ORTBEZ18": "Rueyres-Treyfayes",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1884",
        "ORTBEZ18": "Arveyes",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "6005",
        "ORTBEZ18": "St. Niklausen LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "7226",
        "ORTBEZ18": "Fajauna",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "8212",
        "ORTBEZ18": "Nohl",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9122",
        "ORTBEZ18": "Ebersol",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "6341",
        "ORTBEZ18": "Baar",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "3656",
        "ORTBEZ18": "Ringoldswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1195",
        "ORTBEZ18": "Bursinel",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "6207",
        "ORTBEZ18": "Nottwil SPZ",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "2715",
        "ORTBEZ18": "Châtelat",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1091",
        "ORTBEZ18": "Chenaux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1723",
        "ORTBEZ18": "Marly Grand Pré",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "6363",
        "ORTBEZ18": "Bürgenstock",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "1754",
        "ORTBEZ18": "Avry-Centre FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1260",
        "ORTBEZ18": "Nyon car postal",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "3900",
        "ORTBEZ18": "Brig Postauto",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "8500",
        "ORTBEZ18": "Frauenfeld Pauto",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "3415",
        "ORTBEZ18": "Rüegsauschachen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8071",
        "ORTBEZ18": "Zürich CS PZ",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "3040",
        "ORTBEZ18": "Bern Verarb.zentr.",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "9020",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "6500",
        "ORTBEZ18": "Bellinzona CS",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "8268",
        "ORTBEZ18": "Salenstein",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "1215",
        "ORTBEZ18": "Genève ICC OLS",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1950",
        "ORTBEZ18": "Sion 4",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "9026",
        "ORTBEZ18": "St. Gallen K AG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "1988",
        "ORTBEZ18": "Thyon-Les Collons",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "8965",
        "ORTBEZ18": "Berikon 2 Dorf",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8965",
        "ORTBEZ18": "Berikon-Widen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5046",
        "ORTBEZ18": "Schmiedrued-Walde",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6031",
        "ORTBEZ18": "Ebikon",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6061",
        "ORTBEZ18": "Sarnen 1",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "8302",
        "ORTBEZ18": "Kloten Kaserne",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6900",
        "ORTBEZ18": "Lugano Loreto",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6907",
        "ORTBEZ18": "Lugano 7 Caselle",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "1240",
        "ORTBEZ18": "Genève",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "9401",
        "ORTBEZ18": "Rorschach",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "5620",
        "ORTBEZ18": "Bremgarten AG 1",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8010",
        "ORTBEZ18": "Zürich BZ",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6060",
        "ORTBEZ18": "Sarnen 2 Büntenp.",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "9014",
        "ORTBEZ18": "St. Gallen 14 Zust",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8730",
        "ORTBEZ18": "Uznach Postauto",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9001",
        "ORTBEZ18": "St. Gallen Postaut",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "4524",
        "ORTBEZ18": "Balmberg",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4524",
        "ORTBEZ18": "Oberbalmberg",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "6602",
        "ORTBEZ18": "Muralto",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6677",
        "ORTBEZ18": "Aurigeno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6600",
        "ORTBEZ18": "Locarno 4 Solduno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6900",
        "ORTBEZ18": "Lugano 6",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "2575",
        "ORTBEZ18": "Gerolfingen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8260",
        "ORTBEZ18": "Stein am Rhein 2",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "1400",
        "ORTBEZ18": "Yverdon Caserne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "6810",
        "ORTBEZ18": "Isone Caserma",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "1510",
        "ORTBEZ18": "Moudon Caserne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1890",
        "ORTBEZ18": "St-Maurice Caserne",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1950",
        "ORTBEZ18": "Sion Caserne",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3380",
        "ORTBEZ18": "Wangen Kaserne",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1145",
        "ORTBEZ18": "Bière Caserne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8086",
        "ORTBEZ18": "Zürich R Digest",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1660",
        "ORTBEZ18": "La Lécherette",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8085",
        "ORTBEZ18": "Zürich Versich.",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "2075",
        "ORTBEZ18": "Thielle",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2075",
        "ORTBEZ18": "Wavre",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "3632",
        "ORTBEZ18": "Oberstocken",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1762",
        "ORTBEZ18": "Givisiez Dist Ba",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1213",
        "ORTBEZ18": "Petit-Lancy Dist",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1290",
        "ORTBEZ18": "Versoix Dist",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "2540",
        "ORTBEZ18": "Grenchen Zust",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4702",
        "ORTBEZ18": "Oensingen Zust",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4313",
        "ORTBEZ18": "Möhlin Zustellung",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8957",
        "ORTBEZ18": "Spreitenbach Zust",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4800",
        "ORTBEZ18": "Zofingen Zust",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6020",
        "ORTBEZ18": "Emmenbrücke 1 Zust",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6010",
        "ORTBEZ18": "Kriens 2 Zust",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6015",
        "ORTBEZ18": "Luzern R'bühl Zust",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6130",
        "ORTBEZ18": "Willisau Zust",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "1870",
        "ORTBEZ18": "Monthey 1 Dist",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "8910",
        "ORTBEZ18": "Affoltern a.A.Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1350",
        "ORTBEZ18": "Orbe Distribution",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8700",
        "ORTBEZ18": "Küsnacht ZH Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1020",
        "ORTBEZ18": "Renens VD 1 Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8330",
        "ORTBEZ18": "Pfäffikon ZH Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "3714",
        "ORTBEZ18": "Frutigen Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3073",
        "ORTBEZ18": "Gümligen Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8820",
        "ORTBEZ18": "Wädenswil Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8304",
        "ORTBEZ18": "Wallisellen Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8406",
        "ORTBEZ18": "Winterthur 6 Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "3422",
        "ORTBEZ18": "Kirchberg BE Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8408",
        "ORTBEZ18": "Winterthur 8 Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6850",
        "ORTBEZ18": "Mendrisio Dist",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "3110",
        "ORTBEZ18": "Münsingen Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3076",
        "ORTBEZ18": "Worb Zustellung",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4710",
        "ORTBEZ18": "Balsthal Zust",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4563",
        "ORTBEZ18": "Gerlafingen Zust",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "5200",
        "ORTBEZ18": "Brugg AG 1 Zust",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "1274",
        "ORTBEZ18": "Signy-Centre",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern 31",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 5 Dépôt",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1310",
        "ORTBEZ18": "Daillens Dist Ba",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1310",
        "ORTBEZ18": "Daillens Centre Co",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1530",
        "ORTBEZ18": "Payerne Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8098",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9442",
        "ORTBEZ18": "Berneck Dist Ba",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9565",
        "ORTBEZ18": "Oberbussnang",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8867",
        "ORTBEZ18": "Niederurnen D. Hub",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "5624",
        "ORTBEZ18": "Waldhäusern AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern 65",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4410",
        "ORTBEZ18": "Liestal Dist Ba",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "1912",
        "ORTBEZ18": "Dugny (Leytron)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1933",
        "ORTBEZ18": "Chamoille",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1945",
        "ORTBEZ18": "Fornex(Liddes)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1945",
        "ORTBEZ18": "Les Moulins VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1945",
        "ORTBEZ18": "Palasuit (Liddes)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1955",
        "ORTBEZ18": "Némiaz (Chamoson)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1966",
        "ORTBEZ18": "Luc (Ayent)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1966",
        "ORTBEZ18": "La Place (Ayent)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1966",
        "ORTBEZ18": "Argnou (Ayent)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1969",
        "ORTBEZ18": "Trogne (St-Martin)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1983",
        "ORTBEZ18": "Lanna",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1991",
        "ORTBEZ18": "Misériez (Salins)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1996",
        "ORTBEZ18": "Condémines (Nendaz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "2063",
        "ORTBEZ18": "Fenin",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "1873",
        "ORTBEZ18": "Les Crosets",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1095",
        "ORTBEZ18": "Lutry Distribution",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1052",
        "ORTBEZ18": "Mont-Lausanne Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "2074",
        "ORTBEZ18": "Marin-Epagnier Dis",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2520",
        "ORTBEZ18": "La Neuveville Dist",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2720",
        "ORTBEZ18": "Tramelan Dist",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1180",
        "ORTBEZ18": "Rolle Distribution",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "5734",
        "ORTBEZ18": "Reinach AG Zust",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5630",
        "ORTBEZ18": "Muri AG Zustellung",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6280",
        "ORTBEZ18": "Hochdorf Zust",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6260",
        "ORTBEZ18": "Reiden Zustellung",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "8260",
        "ORTBEZ18": "Stein a.Rh. 1 Zust",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "9450",
        "ORTBEZ18": "Altstätten SG Zust",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9424",
        "ORTBEZ18": "Rheineck Zust",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "7310",
        "ORTBEZ18": "Bad Ragaz Zust",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "4609",
        "ORTBEZ18": "Olten",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "1818",
        "ORTBEZ18": "Montreux Redoute",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1787",
        "ORTBEZ18": "Mur (Vully) FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "8530",
        "ORTBEZ18": "Frauenfeld CALL",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "6010",
        "ORTBEZ18": "Kriens 2",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "8080",
        "ORTBEZ18": "Zürich PN",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "2500",
        "ORTBEZ18": "Biel/Bienne 10",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8564",
        "ORTBEZ18": "Hattenhausen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "1440",
        "ORTBEZ18": "Montagny-Chamard",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "9249",
        "ORTBEZ18": "Oberstetten",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8564",
        "ORTBEZ18": "Gunterswilen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "1023",
        "ORTBEZ18": "Crissier 1",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "2610",
        "ORTBEZ18": "Les Pontins",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1617",
        "ORTBEZ18": "Tatroz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "2037",
        "ORTBEZ18": "Montezillon",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "1820",
        "ORTBEZ18": "Territet",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "3963",
        "ORTBEZ18": "Crans-Montana 1",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "6939",
        "ORTBEZ18": "Arosio-Mugena",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "2338",
        "ORTBEZ18": "Muriaux",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "1162",
        "ORTBEZ18": "St-Prex Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8615",
        "ORTBEZ18": "Freudwil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "3661",
        "ORTBEZ18": "Uetendorf Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6710",
        "ORTBEZ18": "Biasca Dist",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "1969",
        "ORTBEZ18": "Eison (St-Martin)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "4588",
        "ORTBEZ18": "Brittern",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "5415",
        "ORTBEZ18": "Rieden AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "3071",
        "ORTBEZ18": "Ostermundigen Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4033",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "8902",
        "ORTBEZ18": "Urdorf Dist Ba",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1816",
        "ORTBEZ18": "Chailly Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8240",
        "ORTBEZ18": "Thayngen Zust",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "3715",
        "ORTBEZ18": "Adelboden Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3780",
        "ORTBEZ18": "Gstaad Zustellung",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "5242",
        "ORTBEZ18": "Birr-Lupfig Zust",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5726",
        "ORTBEZ18": "Unterkulm Zust",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "1347",
        "ORTBEZ18": "Le Sentier Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8901",
        "ORTBEZ18": "Urdorf",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8546",
        "ORTBEZ18": "Menzengrüt",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "2035",
        "ORTBEZ18": "Corcelles NE Dist",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "6162",
        "ORTBEZ18": "Entlebuch Zust",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6170",
        "ORTBEZ18": "Schüpfheim Zust",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "7078",
        "ORTBEZ18": "Lenzerheide Zust",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "4041",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "8107",
        "ORTBEZ18": "Buchs ZH Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9602",
        "ORTBEZ18": "Bazenheid Zust",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "1926",
        "ORTBEZ18": "Fully Dist",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "8543",
        "ORTBEZ18": "Gundetswil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1039",
        "ORTBEZ18": "Cheseaux Polyval",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "6346",
        "ORTBEZ18": "Baar 3",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6331",
        "ORTBEZ18": "Hünenberg Zust",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 73",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "4621",
        "ORTBEZ18": "Härkingen BZ",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "9494",
        "ORTBEZ18": "Schaan BZ",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "8096",
        "ORTBEZ18": "Zürich IBRS local",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1700",
        "ORTBEZ18": "Fribourg PF Fil.",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3400",
        "ORTBEZ18": "Burgdorf Filiale",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4611",
        "ORTBEZ18": "Härkingen SC",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "8011",
        "ORTBEZ18": "Zürich Mülligen SC",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8010",
        "ORTBEZ18": "Zürich BZ AST",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "4310",
        "ORTBEZ18": "Rheinfelden SPI",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8543",
        "ORTBEZ18": "Kefikon ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8465",
        "ORTBEZ18": "Wildensbuch",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8471",
        "ORTBEZ18": "Berg (Dägerlen)",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9494",
        "ORTBEZ18": "Schaan BZ GKS",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "8412",
        "ORTBEZ18": "Riet (Neftenbach)",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8074",
        "ORTBEZ18": "Zürich Voice Pub",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9057",
        "ORTBEZ18": "Wasserauen",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "6593",
        "ORTBEZ18": "Cadenazzo Dist",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "5506",
        "ORTBEZ18": "Mägenwil PL Hub",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5506",
        "ORTBEZ18": "Mägenwil HUB",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "1356",
        "ORTBEZ18": "La Russille",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "4310",
        "ORTBEZ18": "Rheinfelden PAuto",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "2362",
        "ORTBEZ18": "Montfavergier",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "6010",
        "ORTBEZ18": "Kriens KC PGGK2",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "4800",
        "ORTBEZ18": "Zofi. KC PKGK 3",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8010",
        "ORTBEZ18": "Zürich Helsana",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1984",
        "ORTBEZ18": "La Tour VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3071",
        "ORTBEZ18": "Ostermundigen KATA",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8645",
        "ORTBEZ18": "Jona Buech Gewerbe",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "4621",
        "ORTBEZ18": "Härkingen BZ FP",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "8322",
        "ORTBEZ18": "Gündisau",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "3930",
        "ORTBEZ18": "Visp Verkauf",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1300",
        "ORTBEZ18": "Eclépens Clinique",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "7212",
        "ORTBEZ18": "Seewis-Pardisla",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "8212",
        "ORTBEZ18": "Neuhausen Rundbuck",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "3075",
        "ORTBEZ18": "Vielbringen Worb",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1663",
        "ORTBEZ18": "Gruyères V. Ville",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "9200",
        "ORTBEZ18": "Gossau SG LZB FP",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8155",
        "ORTBEZ18": "Nassenwil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "5225",
        "ORTBEZ18": "Bözberg",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "1310",
        "ORTBEZ18": "Daillens PL3",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "4133",
        "ORTBEZ18": "Pratteln PL3",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "5612",
        "ORTBEZ18": "Villmergen PL3",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6593",
        "ORTBEZ18": "Cadenazzo PL3",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "8580",
        "ORTBEZ18": "Biessenhofen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "6203",
        "ORTBEZ18": "Sempach Bahnhof GK",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "8970",
        "ORTBEZ18": "Urdorf Exchange PL",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6875",
        "ORTBEZ18": "Campora",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6130",
        "ORTBEZ18": "Willisau Rotmatt",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "5004",
        "ORTBEZ18": "Aarau Tellistr",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "9423",
        "ORTBEZ18": "Altenrhein Dorfstr",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "6002",
        "ORTBEZ18": "Luzern SPS",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6301",
        "ORTBEZ18": "Zug SPS",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern 1 PostParc",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "9524",
        "ORTBEZ18": "Zuzwil SG Gewerbe",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9450",
        "ORTBEZ18": "Altstätten SG 2",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "3802",
        "ORTBEZ18": "Interlaken Ost",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8489",
        "ORTBEZ18": "Schalchen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "3030",
        "ORTBEZ18": "Bern Finform",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3613",
        "ORTBEZ18": "Steffisburg a.B.",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6032",
        "ORTBEZ18": "Emmen Seetalstr",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "8025",
        "ORTBEZ18": "Zürich 25 Urania",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "7304",
        "ORTBEZ18": "Maienfeld Stutz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern Weissenbühl",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6331",
        "ORTBEZ18": "Hünenberg Bösch",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "8253",
        "ORTBEZ18": "Diessenhofen Bahn",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9443",
        "ORTBEZ18": "Widnau Industrie",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8370",
        "ORTBEZ18": "Sirnach Zust",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8730",
        "ORTBEZ18": "Uznach Zürcherstr",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8600",
        "ORTBEZ18": "Dübendorf Wilstr",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6010",
        "ORTBEZ18": "Kriens Luzernerstr",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6998",
        "ORTBEZ18": "Monteggio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6998",
        "ORTBEZ18": "Monteggio 2",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6048",
        "ORTBEZ18": "Horw Gemeindehaus",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6370",
        "ORTBEZ18": "Stans Bahnhofplatz",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "8354",
        "ORTBEZ18": "Dickbuch",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6233",
        "ORTBEZ18": "Büron Industriestr",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "4450",
        "ORTBEZ18": "Sissach Postgasse",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4665",
        "ORTBEZ18": "Oftringen LS ThC",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8424",
        "ORTBEZ18": "Embrach Dorfstr",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "7000",
        "ORTBEZ18": "Chur Postplatz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "4605",
        "ORTBEZ18": "Olten",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "6591",
        "ORTBEZ18": "Cadenazzo CPR",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6530",
        "ORTBEZ18": "Cadenazzo CALL",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "8203",
        "ORTBEZ18": "Schaffh. Buchthal.",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "6033",
        "ORTBEZ18": "Buchrain Schiltw.",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6130",
        "ORTBEZ18": "Willisau Dist Hub",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6280",
        "ORTBEZ18": "Hochdorf Hauptstr",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "2560",
        "ORTBEZ18": "Nidau Weyermatt",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "7200",
        "ORTBEZ18": "Untervaz Sort.",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7200",
        "ORTBEZ18": "Untervaz RPZ",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7230",
        "ORTBEZ18": "Untervaz CALL",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "2500",
        "ORTBEZ18": "Biel/Bienne B/G GK",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3090",
        "ORTBEZ18": "Ostermundigen CALL",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6043",
        "ORTBEZ18": "Adligenswil Ebik.",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "5070",
        "ORTBEZ18": "Frick Widenplatz",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5430",
        "ORTBEZ18": "Wettingen Bahnhof",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4123",
        "ORTBEZ18": "Allschwil Basler",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4133",
        "ORTBEZ18": "Pratteln Bahnhofst",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "8036",
        "ORTBEZ18": "Zürich 36 Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8134",
        "ORTBEZ18": "Adliswil Poststr",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "3360",
        "ORTBEZ18": "Herzogenbuchs. Lag",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4810",
        "ORTBEZ18": "Zofingen PF",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8018",
        "ORTBEZ18": "Zürich PF",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8940",
        "ORTBEZ18": "Urdorf CIRCLE",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "4528",
        "ORTBEZ18": "Zuchwil Hauptstr",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4133",
        "ORTBEZ18": "Pratteln RPZ",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "6260",
        "ORTBEZ18": "Mehlsecken Dörfli",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "4600",
        "ORTBEZ18": "Olten Bahnhofstr",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4132",
        "ORTBEZ18": "Muttenz Hauptstr",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "1023",
        "ORTBEZ18": "Crissier Chisaz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8810",
        "ORTBEZ18": "Horgen Bahnhofstr",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "4057",
        "ORTBEZ18": "Basel Hochberger",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "7526",
        "ORTBEZ18": "Chapella",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "1815",
        "ORTBEZ18": "Clarens Collège",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1440",
        "ORTBEZ18": "Montagny-Ch. Cham.",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8072",
        "ORTBEZ18": "Zürich CIRCLE",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 80",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "4802",
        "ORTBEZ18": "Strengelbach D.Hub",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4014",
        "ORTBEZ18": "Basel CIRCLE",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "1723",
        "ORTBEZ18": "Marly Rte Châtelet",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "8130",
        "ORTBEZ18": "Rümlang CALL",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8150",
        "ORTBEZ18": "Rümlang RPZ",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8150",
        "ORTBEZ18": "Rümlang Sort",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1180",
        "ORTBEZ18": "Rolle Ch. Famolens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "4922",
        "ORTBEZ18": "Bützberg RPZ",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "5612",
        "ORTBEZ18": "Villmergen Grenzw.",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5033",
        "ORTBEZ18": "Buchs AG RPZ",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5030",
        "ORTBEZ18": "Buchs AG Sort",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5060",
        "ORTBEZ18": "Buchs AG CIRCLE",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4134",
        "ORTBEZ18": "Pratteln Sort",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "3550",
        "ORTBEZ18": "Langnau i. E.Bahn",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1026",
        "ORTBEZ18": "Echandens-D. Lonay",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "6850",
        "ORTBEZ18": "Mendrisio V.S:F.",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "1214",
        "ORTBEZ18": "Vernier r. Village",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "4001",
        "ORTBEZ18": "Basel Rüdengasse",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "8300",
        "ORTBEZ18": "Wallisellen Sort",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8304",
        "ORTBEZ18": "Wallisellen RPZ",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8326",
        "ORTBEZ18": "Wallisellen CIRCLE",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1095",
        "ORTBEZ18": "Lutry Route Lavaux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "3098",
        "ORTBEZ18": "Köniz Stapfenstr.",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8706",
        "ORTBEZ18": "Meilen Dorfstrasse",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6916",
        "ORTBEZ18": "Grancia Dist.",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern Bogenschützen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1680",
        "ORTBEZ18": "Romont FR AncFerme",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1242",
        "ORTBEZ18": "Satigny Aire Ville",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne 1 Dépôt",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne 3",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne Ouchy",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne Bellevaux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne Grangette",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne Bourdon.",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1000",
        "ORTBEZ18": "Lausanne 26",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1001",
        "ORTBEZ18": "Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1005",
        "ORTBEZ18": "Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1011",
        "ORTBEZ18": "Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1020",
        "ORTBEZ18": "Renens VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1023",
        "ORTBEZ18": "Crissier",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1025",
        "ORTBEZ18": "St-Sulpice VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1028",
        "ORTBEZ18": "Préverenges",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1037",
        "ORTBEZ18": "Etagnières",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1040",
        "ORTBEZ18": "Echallens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1041",
        "ORTBEZ18": "Dommartin",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1063",
        "ORTBEZ18": "Boulens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1041",
        "ORTBEZ18": "Poliez-Pittet",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1035",
        "ORTBEZ18": "Bournens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1042",
        "ORTBEZ18": "Assens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1054",
        "ORTBEZ18": "Morrens VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1073",
        "ORTBEZ18": "Savigny",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1083",
        "ORTBEZ18": "Mézières VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1093",
        "ORTBEZ18": "La Conversion",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1095",
        "ORTBEZ18": "Lutry",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1097",
        "ORTBEZ18": "Riex",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1098",
        "ORTBEZ18": "Epesses",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1061",
        "ORTBEZ18": "Villars-Mendraz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1509",
        "ORTBEZ18": "Vucherens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1078",
        "ORTBEZ18": "Essertes",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1110",
        "ORTBEZ18": "Morges",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1110",
        "ORTBEZ18": "Morges 2",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1131",
        "ORTBEZ18": "Tolochenaz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1125",
        "ORTBEZ18": "Monnaz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1127",
        "ORTBEZ18": "Clarmont",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1136",
        "ORTBEZ18": "Bussy-Chardonney",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1168",
        "ORTBEZ18": "Villars-sous-Yens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1147",
        "ORTBEZ18": "Montricher",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1143",
        "ORTBEZ18": "Apples",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1163",
        "ORTBEZ18": "Etoy",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1174",
        "ORTBEZ18": "Montherod",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1176",
        "ORTBEZ18": "St-Livres",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1180",
        "ORTBEZ18": "Rolle",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1186",
        "ORTBEZ18": "Essertines-Rolle",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1187",
        "ORTBEZ18": "St-Oyens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1189",
        "ORTBEZ18": "Saubraz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1196",
        "ORTBEZ18": "Gland",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 1",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 6",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 8",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 12",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève 26",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève (CLT)",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1208",
        "ORTBEZ18": "Genève",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 12",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 20",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 22",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 23",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 28",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1212",
        "ORTBEZ18": "Grand-Lancy",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1214",
        "ORTBEZ18": "Vernier",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1215",
        "ORTBEZ18": "Genève 15 Dépôt",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1216",
        "ORTBEZ18": "Cointrin",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1217",
        "ORTBEZ18": "Meyrin 1",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1217",
        "ORTBEZ18": "Meyrin 2",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1219",
        "ORTBEZ18": "Aïre",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1220",
        "ORTBEZ18": "Les Avanchets",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1222",
        "ORTBEZ18": "Vésenaz",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1223",
        "ORTBEZ18": "Cologny",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1244",
        "ORTBEZ18": "Choulex",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1245",
        "ORTBEZ18": "Collonge-Bellerive",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1247",
        "ORTBEZ18": "Anières",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1252",
        "ORTBEZ18": "Meinier",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1255",
        "ORTBEZ18": "Veyrier",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1256",
        "ORTBEZ18": "Troinex",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1257",
        "ORTBEZ18": "La Croix-de-Rozon",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1258",
        "ORTBEZ18": "Perly",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1278",
        "ORTBEZ18": "La Rippe",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1274",
        "ORTBEZ18": "Grens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1272",
        "ORTBEZ18": "Genolier",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1262",
        "ORTBEZ18": "Eysins",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1264",
        "ORTBEZ18": "St-Cergue",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1268",
        "ORTBEZ18": "Begnins",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1287",
        "ORTBEZ18": "Laconnex",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1290",
        "ORTBEZ18": "Versoix",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1296",
        "ORTBEZ18": "Coppet",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1302",
        "ORTBEZ18": "Vufflens-la-Ville",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1305",
        "ORTBEZ18": "Penthalaz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1317",
        "ORTBEZ18": "Orny",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1316",
        "ORTBEZ18": "Chevilly",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1337",
        "ORTBEZ18": "Vallorbe",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1348",
        "ORTBEZ18": "Le Brassus",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1303",
        "ORTBEZ18": "Penthaz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1308",
        "ORTBEZ18": "La Chaux-Cossonay",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1148",
        "ORTBEZ18": "Moiry VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1322",
        "ORTBEZ18": "Croy",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1323",
        "ORTBEZ18": "Romainmôtier",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1325",
        "ORTBEZ18": "Vaulion",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1148",
        "ORTBEZ18": "La Praz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1357",
        "ORTBEZ18": "Lignerolle",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1358",
        "ORTBEZ18": "Valeyres-Rances",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1373",
        "ORTBEZ18": "Chavornay",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1372",
        "ORTBEZ18": "Bavois",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1400",
        "ORTBEZ18": "Yverdon 1",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1401",
        "ORTBEZ18": "Yverdon-les-Bains",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1416",
        "ORTBEZ18": "Pailly",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1421",
        "ORTBEZ18": "Fontaines-Grandson",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1431",
        "ORTBEZ18": "Vugelles-La Mothe",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1405",
        "ORTBEZ18": "Pomy",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1409",
        "ORTBEZ18": "Chanéaz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1412",
        "ORTBEZ18": "Valeyres-Ursins",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1046",
        "ORTBEZ18": "Rueyres",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1404",
        "ORTBEZ18": "Cuarny",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1423",
        "ORTBEZ18": "Fontanezier",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1425",
        "ORTBEZ18": "Onnens VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1426",
        "ORTBEZ18": "Corcelles-Concise",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1436",
        "ORTBEZ18": "Treycovagnes",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1441",
        "ORTBEZ18": "Valeyres-Montagny",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1442",
        "ORTBEZ18": "Montagny-Yverdon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1450",
        "ORTBEZ18": "La Sagne VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1453",
        "ORTBEZ18": "Bullet",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1463",
        "ORTBEZ18": "Rovray",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1464",
        "ORTBEZ18": "Chavannes-le-Chêne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1537",
        "ORTBEZ18": "Champtauroz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1522",
        "ORTBEZ18": "Oulens-sur-Lucens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1515",
        "ORTBEZ18": "Villars-le-Comte",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1523",
        "ORTBEZ18": "Granges-Marnand",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1527",
        "ORTBEZ18": "Villeneuve FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1529",
        "ORTBEZ18": "Cheiry",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1530",
        "ORTBEZ18": "Payerne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1534",
        "ORTBEZ18": "Sassel",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1542",
        "ORTBEZ18": "Rueyres-les-Prés",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1544",
        "ORTBEZ18": "Gletterens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1554",
        "ORTBEZ18": "Sédeilles",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1555",
        "ORTBEZ18": "Villarzel",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1475",
        "ORTBEZ18": "Autavaux",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1584",
        "ORTBEZ18": "Villars-le-Grand",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1587",
        "ORTBEZ18": "Constantine",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1588",
        "ORTBEZ18": "Cudrefin",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1090",
        "ORTBEZ18": "La Croix (Lutry)",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1091",
        "ORTBEZ18": "Grandvaux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1070",
        "ORTBEZ18": "Puidoux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1072",
        "ORTBEZ18": "Forel (Lavaux)",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1614",
        "ORTBEZ18": "Granges (Veveyse)",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1618",
        "ORTBEZ18": "Châtel-St-Denis",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1643",
        "ORTBEZ18": "Gumefens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1647",
        "ORTBEZ18": "Corbières",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1656",
        "ORTBEZ18": "Jaun",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1657",
        "ORTBEZ18": "Abländschen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1651",
        "ORTBEZ18": "Villarvolard",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1663",
        "ORTBEZ18": "Moléson-s-Gruyères",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1633",
        "ORTBEZ18": "Marsens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1635",
        "ORTBEZ18": "La Tour-de-Trême",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1665",
        "ORTBEZ18": "Estavannens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1610",
        "ORTBEZ18": "Oron-la-Ville",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1670",
        "ORTBEZ18": "Ursy",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1681",
        "ORTBEZ18": "Billens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1694",
        "ORTBEZ18": "Orsonnens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1695",
        "ORTBEZ18": "Villarlod",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1685",
        "ORTBEZ18": "Villariaz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1689",
        "ORTBEZ18": "Châtelard-p-Romont",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1626",
        "ORTBEZ18": "Romanens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1612",
        "ORTBEZ18": "Ecoteaux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1613",
        "ORTBEZ18": "Maracon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1611",
        "ORTBEZ18": "Le Crêt-p-Semsales",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1699",
        "ORTBEZ18": "Bouloz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1702",
        "ORTBEZ18": "Fribourg",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1700",
        "ORTBEZ18": "Fribourg 1 Dist",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1701",
        "ORTBEZ18": "Fribourg",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1738",
        "ORTBEZ18": "Sangernboden",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1727",
        "ORTBEZ18": "Corpataux",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1712",
        "ORTBEZ18": "Tafers",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1714",
        "ORTBEZ18": "Heitenried",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1715",
        "ORTBEZ18": "Alterswil FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1716",
        "ORTBEZ18": "Plaffeien",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1718",
        "ORTBEZ18": "Rechthalten",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1746",
        "ORTBEZ18": "Prez-vers-Noréaz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1747",
        "ORTBEZ18": "Corserey",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1748",
        "ORTBEZ18": "Torny-le-Grand",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1741",
        "ORTBEZ18": "Cottens FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1744",
        "ORTBEZ18": "Chénens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1745",
        "ORTBEZ18": "Lentigny",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1742",
        "ORTBEZ18": "Autigny",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1695",
        "ORTBEZ18": "Estavayer-Gibloux",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1691",
        "ORTBEZ18": "Villarimboud",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1752",
        "ORTBEZ18": "Villars-s-Glâne D.",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1757",
        "ORTBEZ18": "Noréaz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1690",
        "ORTBEZ18": "Villaz-St-Pierre",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1762",
        "ORTBEZ18": "Givisiez",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1796",
        "ORTBEZ18": "Courgevaux",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1792",
        "ORTBEZ18": "Cordast",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1788",
        "ORTBEZ18": "Praz (Vully)",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1782",
        "ORTBEZ18": "Belfaux",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1783",
        "ORTBEZ18": "Pensier",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1802",
        "ORTBEZ18": "Corseaux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1804",
        "ORTBEZ18": "Corsier-sur-Vevey",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1071",
        "ORTBEZ18": "Rivaz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1815",
        "ORTBEZ18": "Clarens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1816",
        "ORTBEZ18": "Chailly-Montreux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1822",
        "ORTBEZ18": "Chernex",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1823",
        "ORTBEZ18": "Glion",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1658",
        "ORTBEZ18": "La Tine",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1832",
        "ORTBEZ18": "Chamby",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1669",
        "ORTBEZ18": "Montbovon",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1845",
        "ORTBEZ18": "Noville",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1862",
        "ORTBEZ18": "La Comballaz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1884",
        "ORTBEZ18": "Huémoz",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1867",
        "ORTBEZ18": "Panex",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1870",
        "ORTBEZ18": "Monthey",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1870",
        "ORTBEZ18": "Monthey 2",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1872",
        "ORTBEZ18": "Troistorrents",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1873",
        "ORTBEZ18": "Val-d'Illiez",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1875",
        "ORTBEZ18": "Morgins",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1880",
        "ORTBEZ18": "Bex",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1896",
        "ORTBEZ18": "Vouvry",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1898",
        "ORTBEZ18": "St-Gingolph",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1905",
        "ORTBEZ18": "Dorénaz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1907",
        "ORTBEZ18": "Saxon",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1908",
        "ORTBEZ18": "Riddes",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1911",
        "ORTBEZ18": "Ovronnaz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1913",
        "ORTBEZ18": "Saillon",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1955",
        "ORTBEZ18": "St-Pierre-Clages",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1957",
        "ORTBEZ18": "Ardon",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1925",
        "ORTBEZ18": "Le Châtelard VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1928",
        "ORTBEZ18": "Ravoire",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1922",
        "ORTBEZ18": "Salvan",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1922",
        "ORTBEZ18": "Granges (Salvan)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1941",
        "ORTBEZ18": "Vollèges",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1948",
        "ORTBEZ18": "Lourtier",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1948",
        "ORTBEZ18": "Sarreyer",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1944",
        "ORTBEZ18": "La Fouly VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1933",
        "ORTBEZ18": "Sembrancher",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1934",
        "ORTBEZ18": "Le Châble VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1937",
        "ORTBEZ18": "Orsières",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1950",
        "ORTBEZ18": "Sion 1",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1951",
        "ORTBEZ18": "Sion",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1975",
        "ORTBEZ18": "St-Séverin",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1976",
        "ORTBEZ18": "Aven",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1968",
        "ORTBEZ18": "Mase",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1969",
        "ORTBEZ18": "St-Martin VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1981",
        "ORTBEZ18": "Vex",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1986",
        "ORTBEZ18": "Arolla",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1997",
        "ORTBEZ18": "Siviez (Nendaz)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1996",
        "ORTBEZ18": "Fey (Nendaz)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1996",
        "ORTBEZ18": "Beuson (Nendaz)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1996",
        "ORTBEZ18": "Basse-Nendaz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1983",
        "ORTBEZ18": "Evolène",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1972",
        "ORTBEZ18": "Anzère",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "2000",
        "ORTBEZ18": "Neuchâtel 2",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2002",
        "ORTBEZ18": "Neuchâtel 2",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2003",
        "ORTBEZ18": "Neuchâtel 3",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2013",
        "ORTBEZ18": "Colombier NE",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2016",
        "ORTBEZ18": "Cortaillod",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2017",
        "ORTBEZ18": "Boudry",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2025",
        "ORTBEZ18": "Chez-le-Bart",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2036",
        "ORTBEZ18": "Cormondrèche",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2052",
        "ORTBEZ18": "Fontainemelon",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2056",
        "ORTBEZ18": "Dombresson",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2057",
        "ORTBEZ18": "Villiers",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2058",
        "ORTBEZ18": "Le Pâquier NE",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2074",
        "ORTBEZ18": "Marin-Epagnier",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2087",
        "ORTBEZ18": "Cornaux NE",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2103",
        "ORTBEZ18": "Noiraigue",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2108",
        "ORTBEZ18": "Couvet",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2114",
        "ORTBEZ18": "Fleurier",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2117",
        "ORTBEZ18": "La Côte-aux-Fées",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2019",
        "ORTBEZ18": "Chambrelien",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2207",
        "ORTBEZ18": "Coffrane",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2208",
        "ORTBEZ18": "Les Hauts-Geneveys",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2303",
        "ORTBEZ18": "La Chaux-de-Fonds",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2300",
        "ORTBEZ18": "Chdfds 1 Dist",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2316",
        "ORTBEZ18": "Ponts-de-Martel",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2322",
        "ORTBEZ18": "Le Crêt-du-Locle",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2333",
        "ORTBEZ18": "La Ferrière",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2416",
        "ORTBEZ18": "Les Brenets",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2500",
        "ORTBEZ18": "Biel/Bienne",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2500",
        "ORTBEZ18": "Biel/Bienne 3",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2500",
        "ORTBEZ18": "Biel/Bienne 8",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2500",
        "ORTBEZ18": "Biel/Bienne Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2500",
        "ORTBEZ18": "Biel 1 Annahme",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2502",
        "ORTBEZ18": "Biel/Bienne",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2504",
        "ORTBEZ18": "Biel/Bienne",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2512",
        "ORTBEZ18": "Tüscherz-Alfermée",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2513",
        "ORTBEZ18": "Twann",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2516",
        "ORTBEZ18": "Lamboing",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2520",
        "ORTBEZ18": "La Neuveville",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2523",
        "ORTBEZ18": "Lignières",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2532",
        "ORTBEZ18": "Magglingen/Macolin",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2534",
        "ORTBEZ18": "Orvin",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2534",
        "ORTBEZ18": "Les Prés-d'Orvin",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2535",
        "ORTBEZ18": "Frinvillier",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2536",
        "ORTBEZ18": "Plagne",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2538",
        "ORTBEZ18": "Romont BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2545",
        "ORTBEZ18": "Selzach",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "2555",
        "ORTBEZ18": "Brügg BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2556",
        "ORTBEZ18": "Scheuren",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2557",
        "ORTBEZ18": "Studen BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2562",
        "ORTBEZ18": "Port",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2572",
        "ORTBEZ18": "Sutz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2575",
        "ORTBEZ18": "Täuffelen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2577",
        "ORTBEZ18": "Siselen BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2610",
        "ORTBEZ18": "St-Imier",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2610",
        "ORTBEZ18": "Mont-Soleil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2612",
        "ORTBEZ18": "Cormoret",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2613",
        "ORTBEZ18": "Villeret",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2616",
        "ORTBEZ18": "Renan BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2710",
        "ORTBEZ18": "Tavannes",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2712",
        "ORTBEZ18": "Le Fuet",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2714",
        "ORTBEZ18": "Le Prédame",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2713",
        "ORTBEZ18": "Bellelay",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2714",
        "ORTBEZ18": "Les Genevez JU",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2720",
        "ORTBEZ18": "Tramelan",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2723",
        "ORTBEZ18": "Mont-Tramelan",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2340",
        "ORTBEZ18": "Le Noirmont",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2353",
        "ORTBEZ18": "Les Pommerats",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2354",
        "ORTBEZ18": "Goumois",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2732",
        "ORTBEZ18": "Loveresse",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2742",
        "ORTBEZ18": "Perrefitte",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2743",
        "ORTBEZ18": "Eschert",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2746",
        "ORTBEZ18": "Crémines",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2747",
        "ORTBEZ18": "Corcelles BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2762",
        "ORTBEZ18": "Roches BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2800",
        "ORTBEZ18": "Delémont",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2800",
        "ORTBEZ18": "Delémont 1 Dist",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2802",
        "ORTBEZ18": "Develier",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2826",
        "ORTBEZ18": "Corban",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2827",
        "ORTBEZ18": "Mervelier",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2852",
        "ORTBEZ18": "Courtételle",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2854",
        "ORTBEZ18": "Bassecourt",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2863",
        "ORTBEZ18": "Undervelier",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2888",
        "ORTBEZ18": "Seleute",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2889",
        "ORTBEZ18": "Ocourt",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2900",
        "ORTBEZ18": "Porrentruy",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2900",
        "ORTBEZ18": "Porrentruy 2",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2902",
        "ORTBEZ18": "Fontenais",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2908",
        "ORTBEZ18": "Grandfontaine",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2912",
        "ORTBEZ18": "Roche-d'Or",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2915",
        "ORTBEZ18": "Bure",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2922",
        "ORTBEZ18": "Courchavon",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2923",
        "ORTBEZ18": "Courtemaîche",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2943",
        "ORTBEZ18": "Vendlincourt",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2946",
        "ORTBEZ18": "Miécourt",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2947",
        "ORTBEZ18": "Charmoille",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "2953",
        "ORTBEZ18": "Fregiécourt-Pleuj",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern 8",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern Fischermätt.",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern Spitalacker",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3003",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3004",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3006",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3008",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3012",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3013",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3015",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3019",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3020",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3027",
        "ORTBEZ18": "Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3095",
        "ORTBEZ18": "Spiegel b. Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3034",
        "ORTBEZ18": "Murzelen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3037",
        "ORTBEZ18": "Herrenschwanden",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3049",
        "ORTBEZ18": "Säriswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3045",
        "ORTBEZ18": "Meikirch",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3047",
        "ORTBEZ18": "Bremgarten b. Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3052",
        "ORTBEZ18": "Zollikofen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3053",
        "ORTBEZ18": "Münchenbuchsee",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3065",
        "ORTBEZ18": "Bolligen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3065",
        "ORTBEZ18": "Bolligen Dorf",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3072",
        "ORTBEZ18": "Ostermundigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3074",
        "ORTBEZ18": "Muri b. Bern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3076",
        "ORTBEZ18": "Worb",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3083",
        "ORTBEZ18": "Trimstein",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3084",
        "ORTBEZ18": "Wabern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3087",
        "ORTBEZ18": "Niedermuhlern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3088",
        "ORTBEZ18": "Rüeggisberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3097",
        "ORTBEZ18": "Liebefeld",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3098",
        "ORTBEZ18": "Köniz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3099",
        "ORTBEZ18": "Rüti b. Riggisberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3123",
        "ORTBEZ18": "Belp",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3127",
        "ORTBEZ18": "Mühlethurnen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3661",
        "ORTBEZ18": "Uetendorf",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3155",
        "ORTBEZ18": "Helgisried",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3157",
        "ORTBEZ18": "Milken",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3158",
        "ORTBEZ18": "Guggisberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3159",
        "ORTBEZ18": "Riedstätt",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3179",
        "ORTBEZ18": "Kriechenwil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3183",
        "ORTBEZ18": "Albligen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3184",
        "ORTBEZ18": "Wünnewil",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3186",
        "ORTBEZ18": "Düdingen",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3202",
        "ORTBEZ18": "Frauenkappelen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3207",
        "ORTBEZ18": "Wileroltigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3208",
        "ORTBEZ18": "Gurbrü",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3216",
        "ORTBEZ18": "Ried b. Kerzers",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3214",
        "ORTBEZ18": "Ulmiz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3213",
        "ORTBEZ18": "Liebistorf",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3226",
        "ORTBEZ18": "Treiten",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3235",
        "ORTBEZ18": "Erlach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3250",
        "ORTBEZ18": "Lyss",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3251",
        "ORTBEZ18": "Wengi b. Büren",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3252",
        "ORTBEZ18": "Worben",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3253",
        "ORTBEZ18": "Schnottwil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "3255",
        "ORTBEZ18": "Rapperswil BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3257",
        "ORTBEZ18": "Grossaffoltern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3264",
        "ORTBEZ18": "Diessbach b. Büren",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3267",
        "ORTBEZ18": "Seedorf BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3271",
        "ORTBEZ18": "Radelfingen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3280",
        "ORTBEZ18": "Murten",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3283",
        "ORTBEZ18": "Kallnach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3284",
        "ORTBEZ18": "Fräschels",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3285",
        "ORTBEZ18": "Galmiz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3296",
        "ORTBEZ18": "Arch",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3298",
        "ORTBEZ18": "Oberwil b. Büren",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3303",
        "ORTBEZ18": "Jegenstorf",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3308",
        "ORTBEZ18": "Grafenried",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3321",
        "ORTBEZ18": "Schönbühl EKZ",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3323",
        "ORTBEZ18": "Bäriswil BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3326",
        "ORTBEZ18": "Krauchthal",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3307",
        "ORTBEZ18": "Brunnenthal",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "3317",
        "ORTBEZ18": "Mülchi",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3429",
        "ORTBEZ18": "Höchstetten-Hells",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3376",
        "ORTBEZ18": "Graben",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3415",
        "ORTBEZ18": "Hasle-Rüegsau",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3422",
        "ORTBEZ18": "Alchenflüh",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3424",
        "ORTBEZ18": "Niederösch",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3425",
        "ORTBEZ18": "Koppigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3426",
        "ORTBEZ18": "Aefligen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3428",
        "ORTBEZ18": "Wiler b.Utzenstorf",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3433",
        "ORTBEZ18": "Schwanden i. E.",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3439",
        "ORTBEZ18": "Ranflüh",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3452",
        "ORTBEZ18": "Grünenmatt",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3453",
        "ORTBEZ18": "Heimisbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3455",
        "ORTBEZ18": "Grünen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3474",
        "ORTBEZ18": "Rüedisbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3504",
        "ORTBEZ18": "Niederhünigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3506",
        "ORTBEZ18": "Grosshöchstetten",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3508",
        "ORTBEZ18": "Arni BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3513",
        "ORTBEZ18": "Bigenthal",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3672",
        "ORTBEZ18": "Aeschlen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3531",
        "ORTBEZ18": "Oberthal",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3534",
        "ORTBEZ18": "Signau",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3536",
        "ORTBEZ18": "Aeschau",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3537",
        "ORTBEZ18": "Eggiwil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3543",
        "ORTBEZ18": "Emmenmatt",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3551",
        "ORTBEZ18": "Oberfrittenbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3556",
        "ORTBEZ18": "Trub",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3557",
        "ORTBEZ18": "Fankhaus (Trub)",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3604",
        "ORTBEZ18": "Thun",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3607",
        "ORTBEZ18": "Thun",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3631",
        "ORTBEZ18": "Höfen b. Thun",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3612",
        "ORTBEZ18": "Steffisburg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3613",
        "ORTBEZ18": "Steffisburg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3624",
        "ORTBEZ18": "Goldiwil (Thun)",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3627",
        "ORTBEZ18": "Heimberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3633",
        "ORTBEZ18": "Amsoldingen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3634",
        "ORTBEZ18": "Thierachern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3638",
        "ORTBEZ18": "Pohlern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3645",
        "ORTBEZ18": "Gwatt (Thun)",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3652",
        "ORTBEZ18": "Hilterfingen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3653",
        "ORTBEZ18": "Oberhofen Thun'see",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3656",
        "ORTBEZ18": "Tschingel",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3657",
        "ORTBEZ18": "Schwanden Sigrisw",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3658",
        "ORTBEZ18": "Merligen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3700",
        "ORTBEZ18": "Spiez",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3703",
        "ORTBEZ18": "Aeschiried",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3704",
        "ORTBEZ18": "Krattigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3705",
        "ORTBEZ18": "Faulensee",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3714",
        "ORTBEZ18": "Wengi b. Frutigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3723",
        "ORTBEZ18": "Kiental",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3756",
        "ORTBEZ18": "Zwischenflüh",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3757",
        "ORTBEZ18": "Schwenden",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3763",
        "ORTBEZ18": "Därstetten",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3766",
        "ORTBEZ18": "Boltigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3772",
        "ORTBEZ18": "St. Stephan",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3780",
        "ORTBEZ18": "Gstaad",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3781",
        "ORTBEZ18": "Turbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3782",
        "ORTBEZ18": "Lauenen b. Gstaad",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3783",
        "ORTBEZ18": "Grund b. Gstaad",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3792",
        "ORTBEZ18": "Saanen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3800",
        "ORTBEZ18": "Interlaken",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3800",
        "ORTBEZ18": "Matten b. Interl",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3801",
        "ORTBEZ18": "Jungfraujoch",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3807",
        "ORTBEZ18": "Iseltwald",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3816",
        "ORTBEZ18": "Burglauenen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3824",
        "ORTBEZ18": "Stechelberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3852",
        "ORTBEZ18": "Ringgenberg BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3856",
        "ORTBEZ18": "Brienzwiler",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3858",
        "ORTBEZ18": "Hofstetten BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3860",
        "ORTBEZ18": "Rosenlaui",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3862",
        "ORTBEZ18": "Innertkirchen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3863",
        "ORTBEZ18": "Gadmen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3900",
        "ORTBEZ18": "Brig",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3949",
        "ORTBEZ18": "Hohtenn",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3903",
        "ORTBEZ18": "Mund",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3913",
        "ORTBEZ18": "Rosswald",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3911",
        "ORTBEZ18": "Ried-Brig",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3912",
        "ORTBEZ18": "Termen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3922",
        "ORTBEZ18": "Eisten",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3908",
        "ORTBEZ18": "Saas-Balen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3902",
        "ORTBEZ18": "Glis",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3916",
        "ORTBEZ18": "Ferden",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3917",
        "ORTBEZ18": "Kippel",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3919",
        "ORTBEZ18": "Blatten(Lötschen)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3906",
        "ORTBEZ18": "Saas-Fee",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3929",
        "ORTBEZ18": "Täsch",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3922",
        "ORTBEZ18": "Kalpetran",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3930",
        "ORTBEZ18": "Visp",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3939",
        "ORTBEZ18": "Eggerberg",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3931",
        "ORTBEZ18": "Lalden",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3944",
        "ORTBEZ18": "Unterbäch VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3948",
        "ORTBEZ18": "Unterems",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3946",
        "ORTBEZ18": "Gruben",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3951",
        "ORTBEZ18": "Agarn",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3978",
        "ORTBEZ18": "Flanthey",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1977",
        "ORTBEZ18": "Icogne",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3942",
        "ORTBEZ18": "Raron",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3942",
        "ORTBEZ18": "Niedergesteln",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3945",
        "ORTBEZ18": "Gampel",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3952",
        "ORTBEZ18": "Susten",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3953",
        "ORTBEZ18": "Inden",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3960",
        "ORTBEZ18": "Sierre",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3971",
        "ORTBEZ18": "Chermignon-Bas",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3971",
        "ORTBEZ18": "Ollon VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3972",
        "ORTBEZ18": "Miège",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3973",
        "ORTBEZ18": "Venthône",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3975",
        "ORTBEZ18": "Randogne",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3961",
        "ORTBEZ18": "St-Luc",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3961",
        "ORTBEZ18": "Zinal",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3961",
        "ORTBEZ18": "Grimentz",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3963",
        "ORTBEZ18": "Montana",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3965",
        "ORTBEZ18": "Chippis",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3966",
        "ORTBEZ18": "Chalais",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3966",
        "ORTBEZ18": "Réchy",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3993",
        "ORTBEZ18": "Grengiols",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3994",
        "ORTBEZ18": "Lax",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3989",
        "ORTBEZ18": "Niederwald",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3989",
        "ORTBEZ18": "Biel VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3985",
        "ORTBEZ18": "Geschinen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3988",
        "ORTBEZ18": "Obergesteln",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3986",
        "ORTBEZ18": "Ried-Mörel",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3991",
        "ORTBEZ18": "Betten",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3995",
        "ORTBEZ18": "Ernen",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3983",
        "ORTBEZ18": "Mörel",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3985",
        "ORTBEZ18": "Münster VS",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "4002",
        "ORTBEZ18": "Basel 2 Annahme",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4002",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4003",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4011",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4013",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4016",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4019",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4020",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4051",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4054",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4056",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4102",
        "ORTBEZ18": "Binningen Gorenm.",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4105",
        "ORTBEZ18": "Biel-Benken BL",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4106",
        "ORTBEZ18": "Therwil",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4107",
        "ORTBEZ18": "Ettingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4112",
        "ORTBEZ18": "Bättwil-Flüh",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4112",
        "ORTBEZ18": "Bättwil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4115",
        "ORTBEZ18": "Mariastein",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4124",
        "ORTBEZ18": "Schönenbuch",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4125",
        "ORTBEZ18": "Riehen",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4125",
        "ORTBEZ18": "Riehen 1",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "4127",
        "ORTBEZ18": "Birsfelden",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4132",
        "ORTBEZ18": "Muttenz 1",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4132",
        "ORTBEZ18": "Muttenz Lutzert",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4144",
        "ORTBEZ18": "Arlesheim",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4145",
        "ORTBEZ18": "Gempen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4146",
        "ORTBEZ18": "Hochwald",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4153",
        "ORTBEZ18": "Reinach BL Surbaum",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4202",
        "ORTBEZ18": "Duggingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4226",
        "ORTBEZ18": "Breitenbach",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4227",
        "ORTBEZ18": "Büsserach",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4242",
        "ORTBEZ18": "Laufen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4244",
        "ORTBEZ18": "Röschenz",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4224",
        "ORTBEZ18": "Nenzlingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4229",
        "ORTBEZ18": "Beinwil SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4234",
        "ORTBEZ18": "Zullwil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4247",
        "ORTBEZ18": "Grindel",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4252",
        "ORTBEZ18": "Bärschwil Dorf",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4253",
        "ORTBEZ18": "Liesberg",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4304",
        "ORTBEZ18": "Giebenach",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4312",
        "ORTBEZ18": "Magden",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4322",
        "ORTBEZ18": "Mumpf",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4332",
        "ORTBEZ18": "Stein AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4334",
        "ORTBEZ18": "Sisseln AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5083",
        "ORTBEZ18": "Ittenthal",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5084",
        "ORTBEZ18": "Rheinsulz",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5274",
        "ORTBEZ18": "Mettau",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5272",
        "ORTBEZ18": "Gansingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5277",
        "ORTBEZ18": "Hottwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5325",
        "ORTBEZ18": "Leibstadt",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4410",
        "ORTBEZ18": "Liestal Kaserne",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4412",
        "ORTBEZ18": "Nuglar",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4413",
        "ORTBEZ18": "Büren SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4414",
        "ORTBEZ18": "Füllinsdorf",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4415",
        "ORTBEZ18": "Lausen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4418",
        "ORTBEZ18": "Reigoldswil",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4419",
        "ORTBEZ18": "Lupsingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4421",
        "ORTBEZ18": "St. Pantaleon",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4425",
        "ORTBEZ18": "Titterten",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4431",
        "ORTBEZ18": "Bennwil",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4433",
        "ORTBEZ18": "Ramlinsburg",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4434",
        "ORTBEZ18": "Hölstein",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4437",
        "ORTBEZ18": "Waldenburg",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4443",
        "ORTBEZ18": "Wittinsburg",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4445",
        "ORTBEZ18": "Häfelfingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4448",
        "ORTBEZ18": "Läufelfingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4450",
        "ORTBEZ18": "Sissach",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4451",
        "ORTBEZ18": "Wintersingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4452",
        "ORTBEZ18": "Itingen",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4460",
        "ORTBEZ18": "Gelterkinden",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4462",
        "ORTBEZ18": "Rickenbach BL",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4464",
        "ORTBEZ18": "Maisprach",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4501",
        "ORTBEZ18": "Solothurn Bahnhof",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4557",
        "ORTBEZ18": "Horriwil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4513",
        "ORTBEZ18": "Langendorf",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4515",
        "ORTBEZ18": "Oberdorf SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4524",
        "ORTBEZ18": "Günsberg",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4534",
        "ORTBEZ18": "Flumenthal",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4538",
        "ORTBEZ18": "Oberbipp",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4562",
        "ORTBEZ18": "Biberist",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4581",
        "ORTBEZ18": "Küttigkofen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4583",
        "ORTBEZ18": "Mühledorf SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4578",
        "ORTBEZ18": "Bibern SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4571",
        "ORTBEZ18": "Lüterkofen-Ichert",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4571",
        "ORTBEZ18": "Ichertswil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4577",
        "ORTBEZ18": "Hessigkofen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4600",
        "ORTBEZ18": "Olten 1",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4612",
        "ORTBEZ18": "Wangen b. Olten",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4614",
        "ORTBEZ18": "Hägendorf",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4617",
        "ORTBEZ18": "Gunzgen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4622",
        "ORTBEZ18": "Egerkingen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4623",
        "ORTBEZ18": "Neuendorf",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4624",
        "ORTBEZ18": "Härkingen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4653",
        "ORTBEZ18": "Obergösgen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4654",
        "ORTBEZ18": "Lostorf",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4655",
        "ORTBEZ18": "Rohr b. Olten",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4665",
        "ORTBEZ18": "Oftringen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "3377",
        "ORTBEZ18": "Walliswil b.Wangen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4710",
        "ORTBEZ18": "Balsthal Postauto",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4714",
        "ORTBEZ18": "Aedermannsdorf",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4715",
        "ORTBEZ18": "Herbetswil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4713",
        "ORTBEZ18": "Matzendorf",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4800",
        "ORTBEZ18": "Zofingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4806",
        "ORTBEZ18": "Wikon",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "4813",
        "ORTBEZ18": "Uerkheim",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4814",
        "ORTBEZ18": "Bottenwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4629",
        "ORTBEZ18": "Fulenbach",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4628",
        "ORTBEZ18": "Wolfwil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4853",
        "ORTBEZ18": "Riken AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "4900",
        "ORTBEZ18": "Langenthal",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4900",
        "ORTBEZ18": "Langenthal 1",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4914",
        "ORTBEZ18": "Roggwil BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4917",
        "ORTBEZ18": "Melchnau",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4934",
        "ORTBEZ18": "Madiswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4935",
        "ORTBEZ18": "Leimiswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4943",
        "ORTBEZ18": "Oeschenbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "5000",
        "ORTBEZ18": "Aarau 1",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5000",
        "ORTBEZ18": "Aarau Kaserne",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5001",
        "ORTBEZ18": "Aarau 1",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5013",
        "ORTBEZ18": "Niedergösgen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "5014",
        "ORTBEZ18": "Gretzenbach",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "5023",
        "ORTBEZ18": "Biberstein",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5026",
        "ORTBEZ18": "Densbüren",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5028",
        "ORTBEZ18": "Ueken",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5032",
        "ORTBEZ18": "Aarau Rohr",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5033",
        "ORTBEZ18": "Buchs AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5037",
        "ORTBEZ18": "Muhen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5046",
        "ORTBEZ18": "Schmiedrued",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5054",
        "ORTBEZ18": "Kirchleerau-Moosle",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5105",
        "ORTBEZ18": "Auenstein",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5107",
        "ORTBEZ18": "Schinznach Dorf",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5108",
        "ORTBEZ18": "Oberflachs",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5116",
        "ORTBEZ18": "Schinznach Bad",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5245",
        "ORTBEZ18": "Habsburg",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5212",
        "ORTBEZ18": "Hausen AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5213",
        "ORTBEZ18": "Villnachern",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5223",
        "ORTBEZ18": "Riniken",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5235",
        "ORTBEZ18": "Rüfenach AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5242",
        "ORTBEZ18": "Birr-Lupfig",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5244",
        "ORTBEZ18": "Birrhard",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5078",
        "ORTBEZ18": "Effingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5076",
        "ORTBEZ18": "Bözen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5077",
        "ORTBEZ18": "Elfingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5079",
        "ORTBEZ18": "Zeihen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5075",
        "ORTBEZ18": "Hornussen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5072",
        "ORTBEZ18": "Oeschgen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5062",
        "ORTBEZ18": "Oberhof",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5301",
        "ORTBEZ18": "Siggenthal Station",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5303",
        "ORTBEZ18": "Würenlingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5305",
        "ORTBEZ18": "Unterendingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5314",
        "ORTBEZ18": "Kleindöttingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5315",
        "ORTBEZ18": "Böttstein",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5316",
        "ORTBEZ18": "Leuggern",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5400",
        "ORTBEZ18": "Baden 1",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5400",
        "ORTBEZ18": "Baden 1 PAD",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5406",
        "ORTBEZ18": "Baden",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5412",
        "ORTBEZ18": "Gebenstorf",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5417",
        "ORTBEZ18": "Untersiggenthal",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5420",
        "ORTBEZ18": "Ehrendingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5444",
        "ORTBEZ18": "Künten",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5453",
        "ORTBEZ18": "Remetschwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5454",
        "ORTBEZ18": "Bellikon",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5503",
        "ORTBEZ18": "Schafisheim",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5525",
        "ORTBEZ18": "Fischbach-Göslikon",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5600",
        "ORTBEZ18": "Lenzburg 1",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5606",
        "ORTBEZ18": "Dintikon",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5610",
        "ORTBEZ18": "Wohlen AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5611",
        "ORTBEZ18": "Anglikon",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5612",
        "ORTBEZ18": "Villmergen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5614",
        "ORTBEZ18": "Sarmenstorf",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5615",
        "ORTBEZ18": "Fahrwangen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5616",
        "ORTBEZ18": "Meisterschwanden",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5621",
        "ORTBEZ18": "Zufikon",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5624",
        "ORTBEZ18": "Bünzen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5642",
        "ORTBEZ18": "Mühlau",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5644",
        "ORTBEZ18": "Auw",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5646",
        "ORTBEZ18": "Abtwil AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5647",
        "ORTBEZ18": "Oberrüti",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5702",
        "ORTBEZ18": "Niederlenz",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5703",
        "ORTBEZ18": "Seon",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5706",
        "ORTBEZ18": "Boniswil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5707",
        "ORTBEZ18": "Seengen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5708",
        "ORTBEZ18": "Birrwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5727",
        "ORTBEZ18": "Oberkulm",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5742",
        "ORTBEZ18": "Kölliken",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5746",
        "ORTBEZ18": "Walterswil SO",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern 1 Hauptpost",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6005",
        "ORTBEZ18": "Luzern",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6013",
        "ORTBEZ18": "Eigenthal",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6016",
        "ORTBEZ18": "Hellbühl",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6018",
        "ORTBEZ18": "Buttisholz",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6020",
        "ORTBEZ18": "Emmenbrücke 2",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6022",
        "ORTBEZ18": "Grosswangen",
        "KANTON": "LU"
    },
    {
        "PLZ": "8020",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },    {
        "PLZ": "8021",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },
    {
        "PLZ": "8022",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },
    {
        "PLZ": "8023",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },
    {
        "PLZ": "8024",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },
    {
        "PLZ": "8025",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },
    {
        "PLZ": "8026",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },
    {
        "PLZ": "8027",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },
    {
        "PLZ": "8028",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },
    {
        "PLZ": "8029",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },
    {
        "PLZ": "8030",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },
    {
        "PLZ": "8031",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },
    {
        "PLZ": "8032",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },
    {
        "PLZ": "8033",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },
    {
        "PLZ": "8034",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },
    {
        "PLZ": "8035",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },
    {
        "PLZ": "8036",
        "Ort": "Zürich",
        "Kanton": "Zürich",
        "Canton": "Zurich",
        "cantonISO": "ZH"
    },
    {
        "POSTLEITZAHL": "6027",
        "ORTBEZ18": "Römerswil LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6030",
        "ORTBEZ18": "Ebikon",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6034",
        "ORTBEZ18": "Inwil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6036",
        "ORTBEZ18": "Dierikon",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6043",
        "ORTBEZ18": "Adligenswil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6045",
        "ORTBEZ18": "Meggen",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6047",
        "ORTBEZ18": "Kastanienbaum",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6053",
        "ORTBEZ18": "Alpnachstad",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6056",
        "ORTBEZ18": "Kägiswil",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6060",
        "ORTBEZ18": "Sarnen",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6062",
        "ORTBEZ18": "Wilen (Sarnen)",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6063",
        "ORTBEZ18": "Stalden (Sarnen)",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6073",
        "ORTBEZ18": "Flüeli-Ranft",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6085",
        "ORTBEZ18": "Hasliberg Goldern",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6102",
        "ORTBEZ18": "Malters",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6103",
        "ORTBEZ18": "Schwarzenberg LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6112",
        "ORTBEZ18": "Doppleschwand",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6133",
        "ORTBEZ18": "Hergiswil LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6144",
        "ORTBEZ18": "Zell LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6152",
        "ORTBEZ18": "Hüswil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6162",
        "ORTBEZ18": "Finsterwald LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6167",
        "ORTBEZ18": "Bramboden",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6170",
        "ORTBEZ18": "Schüpfheim",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6174",
        "ORTBEZ18": "Sörenberg",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6192",
        "ORTBEZ18": "Wiggen",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6197",
        "ORTBEZ18": "Schangnau",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6203",
        "ORTBEZ18": "Sempach Station",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6213",
        "ORTBEZ18": "Knutwil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6215",
        "ORTBEZ18": "Schwarzenbach LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6217",
        "ORTBEZ18": "Kottwil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6222",
        "ORTBEZ18": "Gunzwil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6231",
        "ORTBEZ18": "Schlierbach",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6232",
        "ORTBEZ18": "Geuensee",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6234",
        "ORTBEZ18": "Triengen",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6234",
        "ORTBEZ18": "Kulmerau",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6236",
        "ORTBEZ18": "Wilihof",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6243",
        "ORTBEZ18": "Egolzwil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6245",
        "ORTBEZ18": "Ebersecken",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6247",
        "ORTBEZ18": "Schötz",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6260",
        "ORTBEZ18": "Reidermoos",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6262",
        "ORTBEZ18": "Langnau b. Reiden",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6263",
        "ORTBEZ18": "Richenthal",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6265",
        "ORTBEZ18": "Roggliswil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6274",
        "ORTBEZ18": "Eschenbach LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6277",
        "ORTBEZ18": "Kleinwangen",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6280",
        "ORTBEZ18": "Urswil",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6283",
        "ORTBEZ18": "Baldegg",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6287",
        "ORTBEZ18": "Aesch LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6294",
        "ORTBEZ18": "Ermensee",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6295",
        "ORTBEZ18": "Mosen",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6300",
        "ORTBEZ18": "Zug",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6313",
        "ORTBEZ18": "Edlibach",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6319",
        "ORTBEZ18": "Allenwinden",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6312",
        "ORTBEZ18": "Steinhausen",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6315",
        "ORTBEZ18": "Alosen",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6332",
        "ORTBEZ18": "Hagendorn",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6340",
        "ORTBEZ18": "Baar",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6353",
        "ORTBEZ18": "Weggis",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6354",
        "ORTBEZ18": "Vitznau",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6370",
        "ORTBEZ18": "Stans",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6374",
        "ORTBEZ18": "Buochs",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6382",
        "ORTBEZ18": "Büren NW",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6383",
        "ORTBEZ18": "Niederrickenbach",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6386",
        "ORTBEZ18": "Wolfenschiessen",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "6402",
        "ORTBEZ18": "Merlischachen",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6403",
        "ORTBEZ18": "Küssnacht am Rigi",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6404",
        "ORTBEZ18": "Greppen",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6415",
        "ORTBEZ18": "Arth",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6416",
        "ORTBEZ18": "Steinerberg",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6417",
        "ORTBEZ18": "Sattel",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6418",
        "ORTBEZ18": "Rothenthurm",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6430",
        "ORTBEZ18": "Schwyz",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6436",
        "ORTBEZ18": "Ried (Muotathal)",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6433",
        "ORTBEZ18": "Stoos SZ",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6434",
        "ORTBEZ18": "Illgau",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6441",
        "ORTBEZ18": "Rütli",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6442",
        "ORTBEZ18": "Gersau",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6443",
        "ORTBEZ18": "Morschach",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6460",
        "ORTBEZ18": "Altdorf UR",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6491",
        "ORTBEZ18": "Realp",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "6500",
        "ORTBEZ18": "Bellinzona 1",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6500",
        "ORTBEZ18": "Bellinzona 4",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6501",
        "ORTBEZ18": "Bellinzona",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6503",
        "ORTBEZ18": "Bellinzona",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6506",
        "ORTBEZ18": "Bellinzona 6 Autop",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6514",
        "ORTBEZ18": "Sementina",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6517",
        "ORTBEZ18": "Arbedo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6518",
        "ORTBEZ18": "Gorduno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6525",
        "ORTBEZ18": "Gnosca",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6526",
        "ORTBEZ18": "Prosito",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6532",
        "ORTBEZ18": "Castione",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6533",
        "ORTBEZ18": "Lumino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6534",
        "ORTBEZ18": "S. Vittore",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6523",
        "ORTBEZ18": "Preonzo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6524",
        "ORTBEZ18": "Moleno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6584",
        "ORTBEZ18": "Carena",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6540",
        "ORTBEZ18": "Castaneda",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6543",
        "ORTBEZ18": "Arvigo",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6546",
        "ORTBEZ18": "Cauco",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6558",
        "ORTBEZ18": "Lostallo",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6558",
        "ORTBEZ18": "Cabbiolo",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6562",
        "ORTBEZ18": "Soazza",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "6573",
        "ORTBEZ18": "Magadino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6575",
        "ORTBEZ18": "Vairano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6578",
        "ORTBEZ18": "Caviano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6571",
        "ORTBEZ18": "Indemini",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6600",
        "ORTBEZ18": "Locarno 1",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6646",
        "ORTBEZ18": "Contra",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6663",
        "ORTBEZ18": "Spruga",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6632",
        "ORTBEZ18": "Vogorno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6631",
        "ORTBEZ18": "Corippo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6633",
        "ORTBEZ18": "Lavertezzo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6634",
        "ORTBEZ18": "Brione (Verzasca)",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6636",
        "ORTBEZ18": "Frasco",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6614",
        "ORTBEZ18": "Isole di Brissago",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6622",
        "ORTBEZ18": "Ronco sopra Ascona",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6645",
        "ORTBEZ18": "Brione s. Minusio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6655",
        "ORTBEZ18": "Rasa",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6657",
        "ORTBEZ18": "Palagnedra",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6659",
        "ORTBEZ18": "Camedo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6655",
        "ORTBEZ18": "Intragna",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6678",
        "ORTBEZ18": "Coglio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6685",
        "ORTBEZ18": "Bosco/Gurin",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6690",
        "ORTBEZ18": "Cavergno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6690",
        "ORTBEZ18": "S. Carlo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6693",
        "ORTBEZ18": "Broglio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6702",
        "ORTBEZ18": "Claro",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6705",
        "ORTBEZ18": "Cresciano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6707",
        "ORTBEZ18": "Iragna",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6710",
        "ORTBEZ18": "Biasca",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6710",
        "ORTBEZ18": "Biasca Stazione",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6722",
        "ORTBEZ18": "Corzoneso",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6716",
        "ORTBEZ18": "Lottigna",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6721",
        "ORTBEZ18": "Motto (Blenio)",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6714",
        "ORTBEZ18": "Semione",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6760",
        "ORTBEZ18": "Carì",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6775",
        "ORTBEZ18": "Ambrì",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6776",
        "ORTBEZ18": "Piotta",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6763",
        "ORTBEZ18": "Mairengo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6764",
        "ORTBEZ18": "Chiggiogna",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6774",
        "ORTBEZ18": "Dalpe",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6803",
        "ORTBEZ18": "Camignolo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6838",
        "ORTBEZ18": "Cabbio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6838",
        "ORTBEZ18": "Muggio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6832",
        "ORTBEZ18": "Seseglio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6833",
        "ORTBEZ18": "Vacallo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6835",
        "ORTBEZ18": "Morbio Superiore",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6805",
        "ORTBEZ18": "Mezzovico",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6821",
        "ORTBEZ18": "Rovio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6850",
        "ORTBEZ18": "Mendrisio Stazione",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6850",
        "ORTBEZ18": "Mendrisio Borgo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6875",
        "ORTBEZ18": "Monte",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6875",
        "ORTBEZ18": "Casima",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6852",
        "ORTBEZ18": "Genestrerio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6863",
        "ORTBEZ18": "Besazio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6864",
        "ORTBEZ18": "Arzo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6865",
        "ORTBEZ18": "Tremona",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6866",
        "ORTBEZ18": "Meride",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6883",
        "ORTBEZ18": "Novazzano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6900",
        "ORTBEZ18": "Lugano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6900",
        "ORTBEZ18": "Lugano 1",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6900",
        "ORTBEZ18": "Massagno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6900",
        "ORTBEZ18": "Lugano Dist",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6903",
        "ORTBEZ18": "Lugano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6905",
        "ORTBEZ18": "Lugano 5 Autoposta",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6915",
        "ORTBEZ18": "Pambio-Noranco",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6921",
        "ORTBEZ18": "Vico Morcote",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6928",
        "ORTBEZ18": "Manno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6949",
        "ORTBEZ18": "Comano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6914",
        "ORTBEZ18": "Carona",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6925",
        "ORTBEZ18": "Gentilino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6933",
        "ORTBEZ18": "Muzzano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6950",
        "ORTBEZ18": "Tesserete",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6954",
        "ORTBEZ18": "Bigorio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6958",
        "ORTBEZ18": "Corticiasca",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6955",
        "ORTBEZ18": "Oggio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6951",
        "ORTBEZ18": "Signôra",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6959",
        "ORTBEZ18": "Cimadera",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6959",
        "ORTBEZ18": "Certara",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6959",
        "ORTBEZ18": "Curtina",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6962",
        "ORTBEZ18": "Viganello",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6963",
        "ORTBEZ18": "Pregassona",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6965",
        "ORTBEZ18": "Cadro",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6967",
        "ORTBEZ18": "Dino",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6968",
        "ORTBEZ18": "Sonvico",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6974",
        "ORTBEZ18": "Aldesago",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6993",
        "ORTBEZ18": "Iseo",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6981",
        "ORTBEZ18": "Bedigliora",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6999",
        "ORTBEZ18": "Astano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6983",
        "ORTBEZ18": "Magliaso",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6986",
        "ORTBEZ18": "Novaggio",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6988",
        "ORTBEZ18": "Ponte Tresa",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "7000",
        "ORTBEZ18": "Chur 1 Annahme",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7000",
        "ORTBEZ18": "Chur 1 Zustellung",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7026",
        "ORTBEZ18": "Maladers",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7032",
        "ORTBEZ18": "Laax GR 2",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7084",
        "ORTBEZ18": "Brienz/Brinzauls",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7019",
        "ORTBEZ18": "Fidaz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7056",
        "ORTBEZ18": "Molinis",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7029",
        "ORTBEZ18": "Peist",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7106",
        "ORTBEZ18": "Tenna",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7122",
        "ORTBEZ18": "Valendas",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7126",
        "ORTBEZ18": "Castrisch",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7130",
        "ORTBEZ18": "Ilanz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7154",
        "ORTBEZ18": "Ruschein",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7152",
        "ORTBEZ18": "Sagogn",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7127",
        "ORTBEZ18": "Sevgein",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7113",
        "ORTBEZ18": "Camuns",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7114",
        "ORTBEZ18": "Uors (Lumnezia)",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7142",
        "ORTBEZ18": "Cumbel",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7138",
        "ORTBEZ18": "Surcuolm",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7156",
        "ORTBEZ18": "Pigniu",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7159",
        "ORTBEZ18": "Andiast",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7166",
        "ORTBEZ18": "Trun",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7168",
        "ORTBEZ18": "Schlans",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7172",
        "ORTBEZ18": "Rabius",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7189",
        "ORTBEZ18": "Rueras",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7188",
        "ORTBEZ18": "Sedrun",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7176",
        "ORTBEZ18": "Cumpadials",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7203",
        "ORTBEZ18": "Trimmis",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7204",
        "ORTBEZ18": "Untervaz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7205",
        "ORTBEZ18": "Zizers",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7208",
        "ORTBEZ18": "Malans GR",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7215",
        "ORTBEZ18": "Fanas",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7228",
        "ORTBEZ18": "Schuders",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7223",
        "ORTBEZ18": "Buchen",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7224",
        "ORTBEZ18": "Putz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7231",
        "ORTBEZ18": "Pragg-Jenaz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7233",
        "ORTBEZ18": "Jenaz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7240",
        "ORTBEZ18": "Küblis",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7242",
        "ORTBEZ18": "Luzein",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7243",
        "ORTBEZ18": "Pany",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7244",
        "ORTBEZ18": "Gadenstätt",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7249",
        "ORTBEZ18": "Serneus",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7272",
        "ORTBEZ18": "Davos Clavadel",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7276",
        "ORTBEZ18": "Davos Frauenkirch",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7277",
        "ORTBEZ18": "Davos Glaris",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7278",
        "ORTBEZ18": "Davos Monstein",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7306",
        "ORTBEZ18": "Fläsch",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7307",
        "ORTBEZ18": "Jenins",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7317",
        "ORTBEZ18": "Vasön",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "7314",
        "ORTBEZ18": "Vadura",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "7312",
        "ORTBEZ18": "Pfäfers",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "7313",
        "ORTBEZ18": "St. Margrethenberg",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "7325",
        "ORTBEZ18": "Schwendi",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "7324",
        "ORTBEZ18": "Vilters",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "7404",
        "ORTBEZ18": "Feldis/Veulden",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7408",
        "ORTBEZ18": "Cazis",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7411",
        "ORTBEZ18": "Sils im Domleschg",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7416",
        "ORTBEZ18": "Almens",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7430",
        "ORTBEZ18": "Thusis",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7425",
        "ORTBEZ18": "Masein",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7422",
        "ORTBEZ18": "Tartar",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7424",
        "ORTBEZ18": "Dalin",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7430",
        "ORTBEZ18": "Rongellen",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7432",
        "ORTBEZ18": "Zillis",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7433",
        "ORTBEZ18": "Donat",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7435",
        "ORTBEZ18": "Splügen",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7442",
        "ORTBEZ18": "Clugin",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7448",
        "ORTBEZ18": "Juf",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7450",
        "ORTBEZ18": "Tiefencastel",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7458",
        "ORTBEZ18": "Mon",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7459",
        "ORTBEZ18": "Stierva",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7452",
        "ORTBEZ18": "Cunter",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7455",
        "ORTBEZ18": "Mulegns",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7457",
        "ORTBEZ18": "Bivio",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7460",
        "ORTBEZ18": "Savognin",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7472",
        "ORTBEZ18": "Surava",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7482",
        "ORTBEZ18": "Bergün/Bravuogn",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7482",
        "ORTBEZ18": "Preda",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7492",
        "ORTBEZ18": "Alvaneu Dorf",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7493",
        "ORTBEZ18": "Schmitten (Albula)",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7512",
        "ORTBEZ18": "Champfèr",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7514",
        "ORTBEZ18": "Fex",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7524",
        "ORTBEZ18": "Zuoz",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7525",
        "ORTBEZ18": "S-chanf",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7535",
        "ORTBEZ18": "Valchava",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7536",
        "ORTBEZ18": "Sta. Maria V. M.",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7537",
        "ORTBEZ18": "Müstair",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7542",
        "ORTBEZ18": "Susch",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7543",
        "ORTBEZ18": "Lavin",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7545",
        "ORTBEZ18": "Guarda",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7550",
        "ORTBEZ18": "Scuol",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7553",
        "ORTBEZ18": "Tarasp",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7557",
        "ORTBEZ18": "Vnà",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7560",
        "ORTBEZ18": "Martina",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7562",
        "ORTBEZ18": "Samnaun-Compatsch",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7563",
        "ORTBEZ18": "Samnaun Dorf",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7603",
        "ORTBEZ18": "Vicosoprano",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7605",
        "ORTBEZ18": "Stampa",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7606",
        "ORTBEZ18": "Promontogno",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7710",
        "ORTBEZ18": "Ospizio Bernina",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "8001",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8002",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8010",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8080",
        "ORTBEZ18": "Zürich 80",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8027",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8031",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8036",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8037",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8039",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8040",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8041",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8044",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8049",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8053",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8063",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8064",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8093",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8099",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8105",
        "ORTBEZ18": "Regensdorf",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8105",
        "ORTBEZ18": "Watt",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8107",
        "ORTBEZ18": "Buchs ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8113",
        "ORTBEZ18": "Boppelsen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8115",
        "ORTBEZ18": "Hüttikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8123",
        "ORTBEZ18": "Ebmatingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8127",
        "ORTBEZ18": "Forch",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8132",
        "ORTBEZ18": "Hinteregg",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8132",
        "ORTBEZ18": "Egg b. Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8133",
        "ORTBEZ18": "Esslingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8134",
        "ORTBEZ18": "Adliswil Sood",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8136",
        "ORTBEZ18": "Gattikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8142",
        "ORTBEZ18": "Uitikon Waldegg",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8152",
        "ORTBEZ18": "Opfikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8158",
        "ORTBEZ18": "Regensberg",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8162",
        "ORTBEZ18": "Steinmaur",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8174",
        "ORTBEZ18": "Stadel",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8181",
        "ORTBEZ18": "Höri",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8192",
        "ORTBEZ18": "Glattfelden",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8193",
        "ORTBEZ18": "Eglisau",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8194",
        "ORTBEZ18": "Hüntwangen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8195",
        "ORTBEZ18": "Wasterkingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8196",
        "ORTBEZ18": "Wil ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8197",
        "ORTBEZ18": "Rafz",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8200",
        "ORTBEZ18": "Schaffhausen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8207",
        "ORTBEZ18": "Schaffhausen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8219",
        "ORTBEZ18": "Trasadingen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8228",
        "ORTBEZ18": "Beggingen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8234",
        "ORTBEZ18": "Stetten SH",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8235",
        "ORTBEZ18": "Lohn SH",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8236",
        "ORTBEZ18": "Büttenhardt",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8242",
        "ORTBEZ18": "Hofen SH",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8216",
        "ORTBEZ18": "Oberhallau",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8224",
        "ORTBEZ18": "Löhningen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8225",
        "ORTBEZ18": "Siblingen",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8226",
        "ORTBEZ18": "Schleitheim",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8246",
        "ORTBEZ18": "Langwiesen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8252",
        "ORTBEZ18": "Schlatt TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8253",
        "ORTBEZ18": "Diessenhofen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8255",
        "ORTBEZ18": "Schlattingen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8259",
        "ORTBEZ18": "Wagenhausen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8263",
        "ORTBEZ18": "Buch SH",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "8268",
        "ORTBEZ18": "Mannenbach-Salenst",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8272",
        "ORTBEZ18": "Ermatingen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8273",
        "ORTBEZ18": "Triboltingen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8280",
        "ORTBEZ18": "Kreuzlingen 1",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8302",
        "ORTBEZ18": "Kloten",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8305",
        "ORTBEZ18": "Dietlikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8308",
        "ORTBEZ18": "Illnau",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8309",
        "ORTBEZ18": "Nürensdorf",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8307",
        "ORTBEZ18": "Ottikon b.Kemptth.",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8314",
        "ORTBEZ18": "Kyburg",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8322",
        "ORTBEZ18": "Madetswil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8340",
        "ORTBEZ18": "Hinwil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8345",
        "ORTBEZ18": "Adetswil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8353",
        "ORTBEZ18": "Elgg",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8374",
        "ORTBEZ18": "Dussnang",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8376",
        "ORTBEZ18": "Fischingen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8400",
        "ORTBEZ18": "Winterthur Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8405",
        "ORTBEZ18": "Winterthur",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8413",
        "ORTBEZ18": "Neftenbach",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8414",
        "ORTBEZ18": "Buch am Irchel",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8421",
        "ORTBEZ18": "Dättlikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8424",
        "ORTBEZ18": "Embrach",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8428",
        "ORTBEZ18": "Teufen ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "5466",
        "ORTBEZ18": "Kaiserstuhl AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5323",
        "ORTBEZ18": "Rietheim",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5464",
        "ORTBEZ18": "Rümikon AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5462",
        "ORTBEZ18": "Siglistorf",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5333",
        "ORTBEZ18": "Baldingen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8442",
        "ORTBEZ18": "Hettlingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8457",
        "ORTBEZ18": "Humlikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8464",
        "ORTBEZ18": "Ellikon am Rhein",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8466",
        "ORTBEZ18": "Trüllikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8477",
        "ORTBEZ18": "Oberstammheim",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8484",
        "ORTBEZ18": "Theilingen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8488",
        "ORTBEZ18": "Turbenthal",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8494",
        "ORTBEZ18": "Bauma",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8498",
        "ORTBEZ18": "Gibswil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8500",
        "ORTBEZ18": "Frauenfeld 1",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8501",
        "ORTBEZ18": "Frauenfeld",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8503",
        "ORTBEZ18": "Frauenfeld Kurzdor",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8505",
        "ORTBEZ18": "Pfyn",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8512",
        "ORTBEZ18": "Lustdorf",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8514",
        "ORTBEZ18": "Amlikon-Bissegg",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8523",
        "ORTBEZ18": "Hagenbuch ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8524",
        "ORTBEZ18": "Uesslingen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8524",
        "ORTBEZ18": "Buch b. Frauenfeld",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8535",
        "ORTBEZ18": "Herdern",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8536",
        "ORTBEZ18": "Hüttwilen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8404",
        "ORTBEZ18": "Stadel(Winterthur)",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8555",
        "ORTBEZ18": "Müllheim Dorf",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8558",
        "ORTBEZ18": "Raperswilen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8269",
        "ORTBEZ18": "Fruthwilen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8561",
        "ORTBEZ18": "Ottoberg",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8580",
        "ORTBEZ18": "Amriswil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8583",
        "ORTBEZ18": "Sulgen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8583",
        "ORTBEZ18": "Donzhausen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8584",
        "ORTBEZ18": "Leimbach TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8585",
        "ORTBEZ18": "Mattwil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8585",
        "ORTBEZ18": "Zuben",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8586",
        "ORTBEZ18": "Andwil TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8587",
        "ORTBEZ18": "Oberaach",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8593",
        "ORTBEZ18": "Kesswil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8600",
        "ORTBEZ18": "Dübendorf",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8604",
        "ORTBEZ18": "Volketswil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8605",
        "ORTBEZ18": "Gutenswil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8607",
        "ORTBEZ18": "Aathal-Seegräben",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8616",
        "ORTBEZ18": "Riedikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8617",
        "ORTBEZ18": "Mönchaltorf",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8622",
        "ORTBEZ18": "Wetzikon ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8627",
        "ORTBEZ18": "Grüningen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8632",
        "ORTBEZ18": "Tann",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8639",
        "ORTBEZ18": "Faltigberg",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8704",
        "ORTBEZ18": "Herrliberg",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8706",
        "ORTBEZ18": "Meilen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8708",
        "ORTBEZ18": "Männedorf",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8712",
        "ORTBEZ18": "Stäfa",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8716",
        "ORTBEZ18": "Schmerikon",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8750",
        "ORTBEZ18": "Klöntal",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8751",
        "ORTBEZ18": "Urnerboden",
        "KANTON": "UR"
    },
    {
        "POSTLEITZAHL": "8755",
        "ORTBEZ18": "Ennenda",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8756",
        "ORTBEZ18": "Mitlödi",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8765",
        "ORTBEZ18": "Engi",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8772",
        "ORTBEZ18": "Nidfurn",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8782",
        "ORTBEZ18": "Rüti GL",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8802",
        "ORTBEZ18": "Kilchberg ZH",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8803",
        "ORTBEZ18": "Rüschlikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8810",
        "ORTBEZ18": "Horgen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8815",
        "ORTBEZ18": "Horgenberg",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8816",
        "ORTBEZ18": "Hirzel",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8825",
        "ORTBEZ18": "Hütten",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8836",
        "ORTBEZ18": "Bennau",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8840",
        "ORTBEZ18": "Trachslau",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8854",
        "ORTBEZ18": "Galgenen",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8855",
        "ORTBEZ18": "Wangen SZ",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8858",
        "ORTBEZ18": "Innerthal",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8866",
        "ORTBEZ18": "Ziegelbrücke",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8758",
        "ORTBEZ18": "Obstalden",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8757",
        "ORTBEZ18": "Filzbach",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "8877",
        "ORTBEZ18": "Murg",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8881",
        "ORTBEZ18": "Walenstadtberg",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8882",
        "ORTBEZ18": "Unterterzen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8889",
        "ORTBEZ18": "Plons",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8890",
        "ORTBEZ18": "Flums",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8897",
        "ORTBEZ18": "Flumserberg Theim",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8902",
        "ORTBEZ18": "Urdorf",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8905",
        "ORTBEZ18": "Arni-Islisberg",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8910",
        "ORTBEZ18": "Affoltern am Albis",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8911",
        "ORTBEZ18": "Rifferswil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8914",
        "ORTBEZ18": "Aeugstertal",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8916",
        "ORTBEZ18": "Jonen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8917",
        "ORTBEZ18": "Oberlunkhofen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8918",
        "ORTBEZ18": "Unterlunkhofen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8919",
        "ORTBEZ18": "Rottenschwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8932",
        "ORTBEZ18": "Mettmenstetten",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8951",
        "ORTBEZ18": "Fahrweid",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8955",
        "ORTBEZ18": "Oetwil a.d. Limmat",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8956",
        "ORTBEZ18": "Killwangen",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8964",
        "ORTBEZ18": "Rudolfstetten",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "9000",
        "ORTBEZ18": "St. Gallen Dist Ba",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9007",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9010",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9012",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9034",
        "ORTBEZ18": "Eggersriet",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9037",
        "ORTBEZ18": "Speicherschwendi",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9050",
        "ORTBEZ18": "Appenzell",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "9055",
        "ORTBEZ18": "Bühler",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9056",
        "ORTBEZ18": "Gais",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9057",
        "ORTBEZ18": "Weissbad",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "9062",
        "ORTBEZ18": "Lustmühle",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9105",
        "ORTBEZ18": "Schönengrund",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9112",
        "ORTBEZ18": "Schachen b.Herisau",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9114",
        "ORTBEZ18": "Hoffeld",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9116",
        "ORTBEZ18": "Wolfertswil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9122",
        "ORTBEZ18": "Mogelsberg",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9125",
        "ORTBEZ18": "Brunnadern",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9216",
        "ORTBEZ18": "Heldswil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9215",
        "ORTBEZ18": "Buhwil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9230",
        "ORTBEZ18": "Flawil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9230",
        "ORTBEZ18": "Flawil 1",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9604",
        "ORTBEZ18": "Oberrindal",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9240",
        "ORTBEZ18": "Uzwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9240",
        "ORTBEZ18": "Niederglatt SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9244",
        "ORTBEZ18": "Niederuzwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9246",
        "ORTBEZ18": "Niederbüren",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9247",
        "ORTBEZ18": "Henau",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9306",
        "ORTBEZ18": "Freidorf TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9326",
        "ORTBEZ18": "Horn",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9327",
        "ORTBEZ18": "Tübach",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9400",
        "ORTBEZ18": "Rorschach",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9426",
        "ORTBEZ18": "Lutzenberg",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9427",
        "ORTBEZ18": "Wolfhalden",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "9434",
        "ORTBEZ18": "Au SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9442",
        "ORTBEZ18": "Berneck",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9452",
        "ORTBEZ18": "Hinterforst",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9462",
        "ORTBEZ18": "Montlingen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9463",
        "ORTBEZ18": "Oberriet SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9464",
        "ORTBEZ18": "Lienz",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9468",
        "ORTBEZ18": "Sax",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9472",
        "ORTBEZ18": "Grabserberg",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9473",
        "ORTBEZ18": "Gams",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9475",
        "ORTBEZ18": "Sevelen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9492",
        "ORTBEZ18": "Eschen",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "9494",
        "ORTBEZ18": "Schaan",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "9495",
        "ORTBEZ18": "Triesen",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "9496",
        "ORTBEZ18": "Balzers",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "9504",
        "ORTBEZ18": "Friltschen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9512",
        "ORTBEZ18": "Rossrüti",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9514",
        "ORTBEZ18": "Wuppenau",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9515",
        "ORTBEZ18": "Hosenruck",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9565",
        "ORTBEZ18": "Rothenhausen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9523",
        "ORTBEZ18": "Züberwangen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9524",
        "ORTBEZ18": "Zuzwil SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9527",
        "ORTBEZ18": "Niederhelfenschwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9535",
        "ORTBEZ18": "Wilen b. Wil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9548",
        "ORTBEZ18": "Matzingen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9554",
        "ORTBEZ18": "Tägerschen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9562",
        "ORTBEZ18": "Märwil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9565",
        "ORTBEZ18": "Oppikon",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9601",
        "ORTBEZ18": "Lütisburg Station",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9606",
        "ORTBEZ18": "Bütschwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9607",
        "ORTBEZ18": "Mosnang",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9622",
        "ORTBEZ18": "Krinau",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9630",
        "ORTBEZ18": "Wattwil",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9633",
        "ORTBEZ18": "Hemberg",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9657",
        "ORTBEZ18": "Unterwasser",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "3113",
        "ORTBEZ18": "Rubigen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3900",
        "ORTBEZ18": "Brig Zustellung",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "7003",
        "ORTBEZ18": "Chur Postauto",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "3800",
        "ORTBEZ18": "Sundlauenen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1031",
        "ORTBEZ18": "Mex VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "3044",
        "ORTBEZ18": "Innerberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3700",
        "ORTBEZ18": "Spiezwiler",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6908",
        "ORTBEZ18": "Massagno Caselle",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "1267",
        "ORTBEZ18": "Coinsins",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8044",
        "ORTBEZ18": "Gockhausen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1700",
        "ORTBEZ18": "Fribourg 1 Dépôt",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "8614",
        "ORTBEZ18": "Sulzbach",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "2735",
        "ORTBEZ18": "Champoz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2556",
        "ORTBEZ18": "Schwadernau",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1470",
        "ORTBEZ18": "Bollion",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1475",
        "ORTBEZ18": "Forel FR",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1484",
        "ORTBEZ18": "Granges-de-Vesin",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1475",
        "ORTBEZ18": "Montbrelloz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1410",
        "ORTBEZ18": "Prévondavaux",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1773",
        "ORTBEZ18": "Russy",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1694",
        "ORTBEZ18": "Chavannes-Orsonnen",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1697",
        "ORTBEZ18": "Les Ecasseys",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1674",
        "ORTBEZ18": "Montet (Glâne)",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1653",
        "ORTBEZ18": "Châtel-Montsalvens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1652",
        "ORTBEZ18": "Villarbeney",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1666",
        "ORTBEZ18": "Villars-sous-Mont",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1730",
        "ORTBEZ18": "Ecuvillens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1756",
        "ORTBEZ18": "Lovens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1723",
        "ORTBEZ18": "Pierrafortscha",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1772",
        "ORTBEZ18": "Ponthaux",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1724",
        "ORTBEZ18": "Zénauva",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1721",
        "ORTBEZ18": "Courtion",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1716",
        "ORTBEZ18": "Oberschrot",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1719",
        "ORTBEZ18": "Zumholz",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1174",
        "ORTBEZ18": "Pizy",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1787",
        "ORTBEZ18": "Mur (Vully) VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1580",
        "ORTBEZ18": "Oleyres",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1428",
        "ORTBEZ18": "Mutrux",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1423",
        "ORTBEZ18": "Vaugondry",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1008",
        "ORTBEZ18": "Jouxtens-Mézery",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1410",
        "ORTBEZ18": "Correvon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1683",
        "ORTBEZ18": "Sarzens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1277",
        "ORTBEZ18": "Arnex-sur-Nyon",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1279",
        "ORTBEZ18": "Bogis-Bossey",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1355",
        "ORTBEZ18": "Sergey",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1608",
        "ORTBEZ18": "Chesalles-sur-Oron",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1607",
        "ORTBEZ18": "Les Tavernes",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1407",
        "ORTBEZ18": "Gossens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1407",
        "ORTBEZ18": "Mézery-Donneloye",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1412",
        "ORTBEZ18": "Ursins",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "2063",
        "ORTBEZ18": "Engollon",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "8165",
        "ORTBEZ18": "Oberweningen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8165",
        "ORTBEZ18": "Schleinikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8543",
        "ORTBEZ18": "Bertschikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "4932",
        "ORTBEZ18": "Gutenburg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3294",
        "ORTBEZ18": "Meienried",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3429",
        "ORTBEZ18": "Höchstetten",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "2577",
        "ORTBEZ18": "Finsterhennen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3303",
        "ORTBEZ18": "Ballmoos",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3053",
        "ORTBEZ18": "Diemerswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3251",
        "ORTBEZ18": "Ruppoldsried",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3053",
        "ORTBEZ18": "Wiggiswil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3532",
        "ORTBEZ18": "Mirchel",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3629",
        "ORTBEZ18": "Oppligen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3086",
        "ORTBEZ18": "Englisberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3623",
        "ORTBEZ18": "Horrenbach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3624",
        "ORTBEZ18": "Schwendibach",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3645",
        "ORTBEZ18": "Zwieselberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3367",
        "ORTBEZ18": "Ochlenberg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6289",
        "ORTBEZ18": "Hämikon",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6284",
        "ORTBEZ18": "Sulz LU",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6038",
        "ORTBEZ18": "Honau",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "3215",
        "ORTBEZ18": "Büchslen",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3213",
        "ORTBEZ18": "Kleinbösingen",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "4583",
        "ORTBEZ18": "Aetigkofen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4556",
        "ORTBEZ18": "Bolken",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4556",
        "ORTBEZ18": "Burgäschi",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4566",
        "ORTBEZ18": "Halten",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4558",
        "ORTBEZ18": "Heinrichswil",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "7456",
        "ORTBEZ18": "Marmorera",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7116",
        "ORTBEZ18": "St. Martin",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7415",
        "ORTBEZ18": "Pratval",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7027",
        "ORTBEZ18": "Calfreisen",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "2912",
        "ORTBEZ18": "Réclère",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "5012",
        "ORTBEZ18": "Eppenberg",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "5637",
        "ORTBEZ18": "Geltwil",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8253",
        "ORTBEZ18": "Willisdorf",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8553",
        "ORTBEZ18": "Harenwilen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8573",
        "ORTBEZ18": "Altishausen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8585",
        "ORTBEZ18": "Schönenbaumgarten",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8564",
        "ORTBEZ18": "Sonterswil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8360",
        "ORTBEZ18": "Wallenwil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8537",
        "ORTBEZ18": "Uerschhausen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8572",
        "ORTBEZ18": "Andhausen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8572",
        "ORTBEZ18": "Guntershausen Berg",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8575",
        "ORTBEZ18": "Istighofen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "6720",
        "ORTBEZ18": "Ghirone",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "3995",
        "ORTBEZ18": "Ausserbinn",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3995",
        "ORTBEZ18": "Mühlebach (Goms)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3957",
        "ORTBEZ18": "Bratsch",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3983",
        "ORTBEZ18": "Goppisberg",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3994",
        "ORTBEZ18": "Martisberg",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "8525",
        "ORTBEZ18": "Wilen b. Neunforn",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "2735",
        "ORTBEZ18": "Malleray",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1626",
        "ORTBEZ18": "Treyfayes",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "5012",
        "ORTBEZ18": "Wöschnau",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "8553",
        "ORTBEZ18": "Hüttlingen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8427",
        "ORTBEZ18": "Rorbas",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6995",
        "ORTBEZ18": "Madonna del Piano",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "7743",
        "ORTBEZ18": "Miralago",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "8471",
        "ORTBEZ18": "Dägerlen",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1774",
        "ORTBEZ18": "Montagny-les-Monts",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1110",
        "ORTBEZ18": "Morges Grosse P.",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "9029",
        "ORTBEZ18": "St. Gallen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "1585",
        "ORTBEZ18": "Bellerive VD",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "4922",
        "ORTBEZ18": "Thunstetten",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8879",
        "ORTBEZ18": "Pizolpark (Mels)",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern Radio Schweiz",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3900",
        "ORTBEZ18": "Brigerbad",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1811",
        "ORTBEZ18": "Vevey",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8832",
        "ORTBEZ18": "Wilen b. Wollerau",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "1775",
        "ORTBEZ18": "Mannens",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 14",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1400",
        "ORTBEZ18": "Yverdon car postal",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1951",
        "ORTBEZ18": "Sion car postal",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "5405",
        "ORTBEZ18": "Dättwil AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6343",
        "ORTBEZ18": "Buonas",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6343",
        "ORTBEZ18": "Risch",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "3945",
        "ORTBEZ18": "Niedergampel",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "4571",
        "ORTBEZ18": "Lüterkofen",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "8070",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1631",
        "ORTBEZ18": "Bulle",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "6007",
        "ORTBEZ18": "Luzern",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "9503",
        "ORTBEZ18": "Lanterswil",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8574",
        "ORTBEZ18": "Oberhofen TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "1279",
        "ORTBEZ18": "Chavannes-Bogis LS",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "4573",
        "ORTBEZ18": "Lohn-Ammannsegg",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "8152",
        "ORTBEZ18": "Glattbrugg Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6391",
        "ORTBEZ18": "Engelberg",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "6371",
        "ORTBEZ18": "Stans",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "9471",
        "ORTBEZ18": "Buchs SG 1",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9471",
        "ORTBEZ18": "Buchs SG 3",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "1260",
        "ORTBEZ18": "Nyon 1",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1530",
        "ORTBEZ18": "Payerne Caserne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8903",
        "ORTBEZ18": "Birmensdorf ZH Kas",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8180",
        "ORTBEZ18": "Bülach Kaserne",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8600",
        "ORTBEZ18": "Dübendorf Kaserne",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8500",
        "ORTBEZ18": "Frauenfeld Kaserne",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "5620",
        "ORTBEZ18": "Bremgarten AG West",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "2800",
        "ORTBEZ18": "Delémont car post",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 70",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "4509",
        "ORTBEZ18": "Solothurn",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "6060",
        "ORTBEZ18": "Sarnen 1",
        "KANTON": "OW"
    },
    {
        "POSTLEITZAHL": "1958",
        "ORTBEZ18": "Uvrier",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1211",
        "ORTBEZ18": "Genève 5",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "4000",
        "ORTBEZ18": "Basel Postauto",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "6260",
        "ORTBEZ18": "Mehlsecken",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6161",
        "ORTBEZ18": "Entlebuch Versand",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "9201",
        "ORTBEZ18": "Gossau SG",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "6900",
        "ORTBEZ18": "Lugano 2",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "3050",
        "ORTBEZ18": "Bern Swisscom",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6900",
        "ORTBEZ18": "Lugano 8",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "7431",
        "ORTBEZ18": "Obermutten",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "1892",
        "ORTBEZ18": "Lavey-les-Bains",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8260",
        "ORTBEZ18": "Stein am Rhein 1",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "9214",
        "ORTBEZ18": "Kradolf",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "2013",
        "ORTBEZ18": "Colombier NE Cas",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "1680",
        "ORTBEZ18": "Romont Caserne",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "6032",
        "ORTBEZ18": "Emmen Kaserne",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "2900",
        "ORTBEZ18": "Porrentruy Caserne",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "8640",
        "ORTBEZ18": "Hurden",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "1721",
        "ORTBEZ18": "Misery",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "2010",
        "ORTBEZ18": "Neuchâtel OFS",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "2510",
        "ORTBEZ18": "Biel/Bienne DistBa",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8210",
        "ORTBEZ18": "Schaffhausen DistB",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "1225",
        "ORTBEZ18": "Chêne-Bourg Dist",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1196",
        "ORTBEZ18": "Gland Distribution",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1218",
        "ORTBEZ18": "Grand-Saconnex D",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1219",
        "ORTBEZ18": "Le Lignon Dist",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1217",
        "ORTBEZ18": "Meyrin Dist",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "4242",
        "ORTBEZ18": "Laufen Zustellung",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4132",
        "ORTBEZ18": "Muttenz 1 Zust",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4153",
        "ORTBEZ18": "Reinach BL Zust",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "5600",
        "ORTBEZ18": "Lenzburg Zust",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5610",
        "ORTBEZ18": "Wohlen AG 1 Zust",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6430",
        "ORTBEZ18": "Schwyz Zust",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "6210",
        "ORTBEZ18": "Sursee Zust",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "8580",
        "ORTBEZ18": "Amriswil Zust",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9220",
        "ORTBEZ18": "Bischofszell Zust",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8180",
        "ORTBEZ18": "Bülach Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8157",
        "ORTBEZ18": "Dielsdorf Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8424",
        "ORTBEZ18": "Embrach Zustellung",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8706",
        "ORTBEZ18": "Meilen Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "3960",
        "ORTBEZ18": "Sierre Dist",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3963",
        "ORTBEZ18": "C.-Montana 1 Dist",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3123",
        "ORTBEZ18": "Belp Zustellung",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8590",
        "ORTBEZ18": "Romanshorn Zust",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "4950",
        "ORTBEZ18": "Huttwil Zustellung",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3800",
        "ORTBEZ18": "Interlaken Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4900",
        "ORTBEZ18": "Langenthal 1 Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8050",
        "ORTBEZ18": "Zürich 50 Zust",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9100",
        "ORTBEZ18": "Herisau 1 Zust",
        "KANTON": "AR"
    },
    {
        "POSTLEITZAHL": "3322",
        "ORTBEZ18": "Urtenen-S Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8340",
        "ORTBEZ18": "Hinwil Zustellung",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9200",
        "ORTBEZ18": "Gossau SG 1 Zust",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "4123",
        "ORTBEZ18": "Allschwil Zust",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "9476",
        "ORTBEZ18": "Fontnas",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "6000",
        "ORTBEZ18": "Luzern 30 AAL",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "2800",
        "ORTBEZ18": "Delémont Dist Ba",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "3550",
        "ORTBEZ18": "Langnau i. E. Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1274",
        "ORTBEZ18": "Signy",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "4620",
        "ORTBEZ18": "Härkingen PZ",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4620",
        "ORTBEZ18": "Härkingen Dist Ba",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "8183",
        "ORTBEZ18": "Bülach Dist Ba",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8343",
        "ORTBEZ18": "Hinwil Dist Ba",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8564",
        "ORTBEZ18": "Hefenhausen",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8586",
        "ORTBEZ18": "Buch b. Kümmertshs",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "1347",
        "ORTBEZ18": "Le Solliat",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1304",
        "ORTBEZ18": "Allens",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1196",
        "ORTBEZ18": "Gland Dist Fil",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1933",
        "ORTBEZ18": "Vens(Sembrancher)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1933",
        "ORTBEZ18": "La Garde",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1945",
        "ORTBEZ18": "Fontaine Dessous",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1945",
        "ORTBEZ18": "Vichères (Liddes)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1945",
        "ORTBEZ18": "Chez Petit(Liddes)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1955",
        "ORTBEZ18": "Les Vérines",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1966",
        "ORTBEZ18": "Fortunau (Ayent)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1966",
        "ORTBEZ18": "St-Romain (Ayent)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1966",
        "ORTBEZ18": "Saxonne (Ayent)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1966",
        "ORTBEZ18": "Villa (Ayent)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1966",
        "ORTBEZ18": "Botyre (Ayent)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1971",
        "ORTBEZ18": "Champlan",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1991",
        "ORTBEZ18": "Arvillard (Salins)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1991",
        "ORTBEZ18": "Turin (Salins)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1993",
        "ORTBEZ18": "Clèbes (Nendaz)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1992",
        "ORTBEZ18": "Crête-à-l'Oeil",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1996",
        "ORTBEZ18": "Bieudron (Nendaz)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1955",
        "ORTBEZ18": "Mayens-de-Chamoson",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1585",
        "ORTBEZ18": "Cotterd",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "2013",
        "ORTBEZ18": "Colombier NE Dist",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "1580",
        "ORTBEZ18": "Avenches Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1510",
        "ORTBEZ18": "Moudon Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1233",
        "ORTBEZ18": "Bernex Dist",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "1040",
        "ORTBEZ18": "Echallens Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "3150",
        "ORTBEZ18": "Schwarzenburg Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3294",
        "ORTBEZ18": "Büren a.A. Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3860",
        "ORTBEZ18": "Meiringen Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4663",
        "ORTBEZ18": "Aarburg Zust",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5036",
        "ORTBEZ18": "Oberentfelden Zust",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6110",
        "ORTBEZ18": "Wolhusen Zust",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6314",
        "ORTBEZ18": "Unterägeri Zust",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "8730",
        "ORTBEZ18": "Uznach Zustellung",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9240",
        "ORTBEZ18": "Uzwil Zustellung",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "9630",
        "ORTBEZ18": "Wattwil Zust",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "7013",
        "ORTBEZ18": "Domat/Ems Zust",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7302",
        "ORTBEZ18": "Landquart Zust",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "7320",
        "ORTBEZ18": "Sargans Zustellung",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8887",
        "ORTBEZ18": "Mels Zustellung",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8890",
        "ORTBEZ18": "Flums Zustellung",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "1222",
        "ORTBEZ18": "Vésenaz Dist",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "8540",
        "ORTBEZ18": "Frauenfeld CIRCLE",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "1330",
        "ORTBEZ18": "Daillens CALL",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "6010",
        "ORTBEZ18": "Kriens 2 Sternmatt",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6500",
        "ORTBEZ18": "Bellinzona VZ",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "3989",
        "ORTBEZ18": "Grafschaft",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "9249",
        "ORTBEZ18": "Niederstetten",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "3815",
        "ORTBEZ18": "Gündlischwand",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1023",
        "ORTBEZ18": "Crissier Closalet",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "3860",
        "ORTBEZ18": "Schattenhalb",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6039",
        "ORTBEZ18": "Root D4",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "3963",
        "ORTBEZ18": "Crans-Montana 2",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "8253",
        "ORTBEZ18": "Diessenhofen Zust",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "6900",
        "ORTBEZ18": "Lugano MailSource",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6422",
        "ORTBEZ18": "Steinen Zustellung",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "1964",
        "ORTBEZ18": "Conthey Dist",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "4035",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "1618",
        "ORTBEZ18": "Châtel-Denis Dist",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "1965",
        "ORTBEZ18": "Savièse Dist",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3623",
        "ORTBEZ18": "Buchen BE",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4089",
        "ORTBEZ18": "Basel",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "9545",
        "ORTBEZ18": "Wängi Zustellung",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "9113",
        "ORTBEZ18": "Degersheim Zust",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8762",
        "ORTBEZ18": "Schwanden GL Zust",
        "KANTON": "GL"
    },
    {
        "POSTLEITZAHL": "5415",
        "ORTBEZ18": "Hertenstein AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "3976",
        "ORTBEZ18": "Champzabé",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3537",
        "ORTBEZ18": "Eggiwil Zustellung",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3818",
        "ORTBEZ18": "Grindelwald Zust",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "5605",
        "ORTBEZ18": "Dottikon Zust",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5703",
        "ORTBEZ18": "Seon Zustellung",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6037",
        "ORTBEZ18": "Root Zustellung",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6374",
        "ORTBEZ18": "Buochs Zustellung",
        "KANTON": "NW"
    },
    {
        "POSTLEITZAHL": "3071",
        "ORTBEZ18": "O'mundigen Dist Ba",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "9494",
        "ORTBEZ18": "Schaan Zustellung",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "3175",
        "ORTBEZ18": "Flamatt Zustellung",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "6017",
        "ORTBEZ18": "Ruswil Zustellung",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6182",
        "ORTBEZ18": "Escholzmatt Zust",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "8494",
        "ORTBEZ18": "Bauma Zustellung",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6204",
        "ORTBEZ18": "Sempach Stadt",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "9489",
        "ORTBEZ18": "Schaan Log",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "3053",
        "ORTBEZ18": "Lätti",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3256",
        "ORTBEZ18": "Seewil",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1607",
        "ORTBEZ18": "Palézieux Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "7513",
        "ORTBEZ18": "Silvaplana-Surlej",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "4089",
        "ORTBEZ18": "Basel SPI GLS",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "8109",
        "ORTBEZ18": "Kloster Fahr",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "1019",
        "ORTBEZ18": "Lausanne",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "6346",
        "ORTBEZ18": "Baar 3 Dist Ba",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "3085",
        "ORTBEZ18": "Wabern Weihnachten",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8952",
        "ORTBEZ18": "Schlieren DS",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6010",
        "ORTBEZ18": "Kriens LZB",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "6590",
        "ORTBEZ18": "Cadenazzo (CLL)",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "9200",
        "ORTBEZ18": "Gossau SG LZB",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "1148",
        "ORTBEZ18": "La Coudre",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "4808",
        "ORTBEZ18": "Zofingen PF",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8352",
        "ORTBEZ18": "Ricketwil",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "4133",
        "ORTBEZ18": "Pratteln SPI",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "4621",
        "ORTBEZ18": "Härkingen SPS AG",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "4809",
        "ORTBEZ18": "Zofingen PF UBS",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6039",
        "ORTBEZ18": "Root Längenbold SH",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "8545",
        "ORTBEZ18": "Rickenbach Sulz",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8471",
        "ORTBEZ18": "Bänk (Dägerlen)",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9301",
        "ORTBEZ18": "Wittenbach",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "1300",
        "ORTBEZ18": "Eclépens CC Dépôt",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8412",
        "ORTBEZ18": "Hünikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6511",
        "ORTBEZ18": "Cadenazzo SC",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "5643",
        "ORTBEZ18": "Meienberg",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "3823",
        "ORTBEZ18": "Kleine Scheidegg",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3823",
        "ORTBEZ18": "Eigergletscher",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1532",
        "ORTBEZ18": "Fétigny Dist Fil",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "8200",
        "ORTBEZ18": "Schaffhausen PF",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern Brünnen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "9050",
        "ORTBEZ18": "Appenzell Enggenh.",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "9050",
        "ORTBEZ18": "Appenzell Steinegg",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "9050",
        "ORTBEZ18": "Appenzell Schlatt",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "9442",
        "ORTBEZ18": "Büriswilen",
        "KANTON": "AI"
    },
    {
        "POSTLEITZAHL": "6595",
        "ORTBEZ18": "Riazzino Dist",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6987",
        "ORTBEZ18": "Caslano Dist",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "5506",
        "ORTBEZ18": "Mägenwil Dist Ba",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "5506",
        "ORTBEZ18": "Mägenwil GKA",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8307",
        "ORTBEZ18": "Effretikon PAG",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9245",
        "ORTBEZ18": "Sonnental",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "1227",
        "ORTBEZ18": "Genève PF Fil Car",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "2340",
        "ORTBEZ18": "Le Noirmont Dist",
        "KANTON": "JU"
    },
    {
        "POSTLEITZAHL": "3206",
        "ORTBEZ18": "Ferenbalm",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3206",
        "ORTBEZ18": "Biberen",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4800",
        "ORTBEZ18": "Zofingen B&V GK",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8852",
        "ORTBEZ18": "Altendorf B&V GK 2",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "3030",
        "ORTBEZ18": "Bern KC PK 5",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1905",
        "ORTBEZ18": "Dorénaz PA",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "9030",
        "ORTBEZ18": "St. Josefen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8723",
        "ORTBEZ18": "Maseltrangen",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "1985",
        "ORTBEZ18": "Villa (Evolène)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "5412",
        "ORTBEZ18": "Vogelsang AG",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "1300",
        "ORTBEZ18": "Eclépens CC AP",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1997",
        "ORTBEZ18": "Sornard (Nendaz)",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "8371",
        "ORTBEZ18": "Busswil TG",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "6023",
        "ORTBEZ18": "Rothenburg DIST BA",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "8010",
        "ORTBEZ18": "Zürich Briefklinik",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "4144",
        "ORTBEZ18": "Arlesheim Hub",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "1200",
        "ORTBEZ18": "Genève Les Acacias",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "8574",
        "ORTBEZ18": "Dettighofen Lengw.",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "1880",
        "ORTBEZ18": "Les Posses-sur-Bex",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "6590",
        "ORTBEZ18": "Cadenazzo CLL AP",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "1580",
        "ORTBEZ18": "Donatyre",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8006",
        "ORTBEZ18": "Zürich Asendia",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1532",
        "ORTBEZ18": "Fétigny PL3",
        "KANTON": "FR"
    },
    {
        "POSTLEITZAHL": "3030",
        "ORTBEZ18": "Bern PL3",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4704",
        "ORTBEZ18": "Niederbipp PL3",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8422",
        "ORTBEZ18": "Pfungen PL3",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8902",
        "ORTBEZ18": "Urdorf PL3",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6010",
        "ORTBEZ18": "Kriens ContCent",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "1232",
        "ORTBEZ18": "Confignon Cressy",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "8509",
        "ORTBEZ18": "Frauenfeld",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8403",
        "ORTBEZ18": "Winterthur",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6601",
        "ORTBEZ18": "Locarno SPS",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6830",
        "ORTBEZ18": "Chiasso SPS",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "6901",
        "ORTBEZ18": "Lugano SPS",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "8404",
        "ORTBEZ18": "Winterthur im LInk",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9464",
        "ORTBEZ18": "Rüthi Staatstrasse",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "1305",
        "ORTBEZ18": "Penthalaz Dist",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8500",
        "ORTBEZ18": "Frauenfeld Murgstr",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "8047",
        "ORTBEZ18": "Zürich Triemli",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6960",
        "ORTBEZ18": "Odogno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "8489",
        "ORTBEZ18": "Ehrikon",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1300",
        "ORTBEZ18": "Eclépens AVJ",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "8370",
        "ORTBEZ18": "Sirnach Dist Ba",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "6330",
        "ORTBEZ18": "Cham Hinterbergstr",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "6616",
        "ORTBEZ18": "Losone via Locarno",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "8301",
        "ORTBEZ18": "Glattzentrum N.W.",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8037",
        "ORTBEZ18": "Zürich Hönggerstr",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8045",
        "ORTBEZ18": "Zürich Brunaupark",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "3052",
        "ORTBEZ18": "Zollikofen Waldstr",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6403",
        "ORTBEZ18": "Küssnacht Zugerstr",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8045",
        "ORTBEZ18": "Zürich Friesenberg",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6014",
        "ORTBEZ18": "Luzern Fanghöfli",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "8603",
        "ORTBEZ18": "Schwerzenbach Bahn",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8031",
        "ORTBEZ18": "Zürich Limmatstr.",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "4702",
        "ORTBEZ18": "Oensingen Soloth.",
        "KANTON": "SO"
    },
    {
        "POSTLEITZAHL": "5330",
        "ORTBEZ18": "Bad Zurzach Haupt.",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "3000",
        "ORTBEZ18": "Bern Lorraine",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "4144",
        "ORTBEZ18": "Arlesheim Postpl.",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "3400",
        "ORTBEZ18": "Burgdorf Bahnhof",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8575",
        "ORTBEZ18": "Bürglen TG Postweg",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "6814",
        "ORTBEZ18": "Lamone-Cad. Cant.",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "4852",
        "ORTBEZ18": "Rothrist Bernstr.",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8460",
        "ORTBEZ18": "Marthalen Güeterst",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6340",
        "ORTBEZ18": "Baar Dorfstrasse",
        "KANTON": "ZG"
    },
    {
        "POSTLEITZAHL": "8024",
        "ORTBEZ18": "Zürich Oberdorf",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "2074",
        "ORTBEZ18": "Marin-Epagnier Lys",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "7210",
        "ORTBEZ18": "Untervaz CIRCLE",
        "KANTON": "GR"
    },
    {
        "POSTLEITZAHL": "1960",
        "ORTBEZ18": "Vétroz CCR",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1960",
        "ORTBEZ18": "Vétroz Tri",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "1940",
        "ORTBEZ18": "Vétroz CIRCLE",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "3080",
        "ORTBEZ18": "Ostermundigen CIRC",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8310",
        "ORTBEZ18": "Kemptthal Kemptp.",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "4153",
        "ORTBEZ18": "Reinach BL Austr",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "6883",
        "ORTBEZ18": "Novazzano Via P.B.",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "1400",
        "ORTBEZ18": "Yverdon Ch-Lovats",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "2500",
        "ORTBEZ18": "Biel/Bienne Rennw.",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "5630",
        "ORTBEZ18": "Muri AG Seetalstr.",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "6210",
        "ORTBEZ18": "Sursee Schellenr.",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "5430",
        "ORTBEZ18": "Wettingen Seminar",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "8636",
        "ORTBEZ18": "Wald ZH Poststr",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "9494",
        "ORTBEZ18": "Schaan Paketzust.",
        "KANTON": "FL"
    },
    {
        "POSTLEITZAHL": "8017",
        "ORTBEZ18": "Zürich",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8930",
        "ORTBEZ18": "Urdorf CALL",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "5615",
        "ORTBEZ18": "Fahrwangen Hinterg",
        "KANTON": "AG"
    },
    {
        "POSTLEITZAHL": "3063",
        "ORTBEZ18": "Ittigen Talgut-Z.",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "3073",
        "ORTBEZ18": "Gümligen Füllerich",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "8302",
        "ORTBEZ18": "Kloten Bahnhofstr",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8152",
        "ORTBEZ18": "Glattbrugg Schaff.",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "6030",
        "ORTBEZ18": "Ebikon Riedmattstr",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "3380",
        "ORTBEZ18": "Wangen B'hofallee",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "1228",
        "ORTBEZ18": "Plan-les-Oua Pl. A",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "2001",
        "ORTBEZ18": "Neuchâtel Numa-Dr.",
        "KANTON": "NE"
    },
    {
        "POSTLEITZAHL": "4914",
        "ORTBEZ18": "Roggwil BE Dorfstr",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "9021",
        "ORTBEZ18": "St. Gallen MS",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8840",
        "ORTBEZ18": "Einsiedeln Bhfpl.",
        "KANTON": "SZ"
    },
    {
        "POSTLEITZAHL": "8073",
        "ORTBEZ18": "Zürich CALL",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8048",
        "ORTBEZ18": "Zürich Altstetter",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1844",
        "ORTBEZ18": "Villeneuve VD C.S.",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "4125",
        "ORTBEZ18": "Riehen Bahnhofstr",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "8274",
        "ORTBEZ18": "Tägerwilen Hauptst",
        "KANTON": "TG"
    },
    {
        "POSTLEITZAHL": "4104",
        "ORTBEZ18": "Oberwil BL Konsum.",
        "KANTON": "BL"
    },
    {
        "POSTLEITZAHL": "6043",
        "ORTBEZ18": "Adligenswil D.Hub",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "8910",
        "ORTBEZ18": "Affoltern Obfelder",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "8215",
        "ORTBEZ18": "Hallau Neunkirch.",
        "KANTON": "SH"
    },
    {
        "POSTLEITZAHL": "9245",
        "ORTBEZ18": "Oberbüren Unterd.",
        "KANTON": "SG"
    },
    {
        "POSTLEITZAHL": "8027",
        "ORTBEZ18": "Zürich Gutenberg",
        "KANTON": "ZH"
    },
    {
        "POSTLEITZAHL": "1024",
        "ORTBEZ18": "Ecublens VD EPFL",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "4001",
        "ORTBEZ18": "Basel Freie Str",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "1227",
        "ORTBEZ18": "Les Acacias R.G.L",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "6005",
        "ORTBEZ18": "Luzern Frohburgstr",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "1030",
        "ORTBEZ18": "Bussigny Industrie",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "1224",
        "ORTBEZ18": "Chêne-Bouger Grad",
        "KANTON": "GE"
    },
    {
        "POSTLEITZAHL": "3960",
        "ORTBEZ18": "Sierre Rue Falcon",
        "KANTON": "VS"
    },
    {
        "POSTLEITZAHL": "4089",
        "ORTBEZ18": "Basel ZollNoData",
        "KANTON": "BS"
    },
    {
        "POSTLEITZAHL": "6210",
        "ORTBEZ18": "Sursee Wassergrabe",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "3123",
        "ORTBEZ18": "Belp Rubigenstr",
        "KANTON": "BE"
    },
    {
        "POSTLEITZAHL": "6131",
        "ORTBEZ18": "Willisau Competec",
        "KANTON": "LU"
    },
    {
        "POSTLEITZAHL": "1860",
        "ORTBEZ18": "Aigle Chem. Orlons",
        "KANTON": "VD"
    },
    {
        "POSTLEITZAHL": "6852",
        "ORTBEZ18": "Genestrerio Dist.",
        "KANTON": "TI"
    },
    {
        "POSTLEITZAHL": "4133",
        "ORTBEZ18": "Pratteln Hohenrain",
        "KANTON": "BL"
    }

]