import {useQuery} from "@apollo/client";
import {GET_BUCHUNGEN} from "../Queries";
import {Loading} from "../components";
import React from "react";
import {object} from "prop-types";
import {Link} from "react-router-dom";
import {toCSV} from "react-csv/lib/core";
import {GET_KURSTERMIN_STATUS} from "./UseKursStatus";
import {AbaninjaRechnungAnlegen} from "../Buchung/AbaninjaRechnungAnlegen";
export const BuchungsListe = ({kursId}) => {

    const {data, loading} = useQuery(GET_BUCHUNGEN, {
        variables: kursId ? {kurs: kursId} : {},
        fetchPolicy: "network-only"
    });


    const {data: kursstatus} = kursId ? useQuery(GET_KURSTERMIN_STATUS, {
        variables: {id: kursId},
        fetchPolicy: "network-only"
    }) : {};
    console.log(kursstatus?.kurstermine[0]?.status?.text)

    if (loading) return <Loading/>



    const download = ()=>{
        let daten = []
        for (const buchung of data.buchungen) {
            if ((!!buchung?.verschoben && buchung?.verschoben?.Id !== kursId) || (!!buchung?.storniert)) {
                continue
            }
            let val = {
                Firma: buchung?.bucherFirma,
                Vorname: buchung?.bucher?.vorname,
                Nachname: buchung?.bucher?.nachname,
                Preis: buchung?.preis?.wert,
                Waehrung: buchung?.preis?.waehrung.code,
                Rechnungsnr: buchung?.rechnungNummer,
                Teilnehmerzahl: buchung?.teilnehmerzahl,
            }
            daten.push(val)
        }
        let csv = toCSV(daten, null, ";", "\"")
        let element = document.createElement("a")
        const file = new Blob([csv], { type: 'text/csv' })
        element.href = URL.createObjectURL(file);
        element.download = data?.buchungen[0]?.kurstermin?.kursCode+ "-buchungen.csv";
        element.click();
    }

    return <div>
        {kursId && <span>Summe: {  data?.buchungen[0]?.preis && new Intl.NumberFormat('de-DE', { style: 'currency', currency: data?.buchungen[0]?.preis?.waehrung?.code })
            .format( data?.buchungen?.reduce((acc, buchung) => {
                if (buchung?.storniert || (buchung?.verschoben && buchung?.verschoben.Id !== kursId))
                    return acc
                else
                    return acc + parseInt(buchung?.preis?.wert,10)},0))}
        </span>}
      <table className="w-full alternating-table">

            <thead>
            <tr>
                <th>Bucher</th>
                <th>Firma</th>
                <th>Teilnehmerzahl</th>
                <th>Zeitpunkt</th>
                <th>Rechnung</th>
            </tr>

            </thead>
            <tbody>
            {data && !loading && data.buchungen.map(buchung => (
                <tr>
                    <td> <Link
                        className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte no-underline"
                        to={"/kurstermine/" + kursId + "/buchung/" + buchung.Id}>
                        {buchung.bucher.vorname} {buchung.bucher.nachname}
                    </Link> </td>

                    <td><Link
                        className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte no-underline"
                        to={"/kurstermine/" + kursId + "/buchung/" + buchung.Id}>
                        {buchung.bucherFirma ? buchung.bucherFirma : "Keine Firma"}
                    </Link></td>
                    <td>
                        {buchung.teilnehmerzahl} {!!buchung.storniert && <span
                        className="inline-block badge badge-rot text-xs">storniert</span>} {buchung.verschoben  && (buchung.verschoben.Id !== kursId) ? <span className="inline-block badge badge-rot text-xs">verschoben</span> : ""}
                    </td>
                    <td>
                        {buchung.zeitpunkt.humanReadable}
                    </td>

                    <td>
                        {!buchung.storniert && (buchung.rechnungNummer ? buchung.rechnungNummer :  kursId && <AbaninjaRechnungAnlegen buchung={buchung}/>)}
                    </td>
                </tr>))}
            { kursId && kursstatus?.kurstermine[0]?.status?.text !=="Abgesagt"  && <div className={"heading md:h-10"}>  <Link className="btn btn-gruen  gruen no-underline" role="button"
                         to={"/kurstermine/" + kursId + "/buchung/neu"}>Neue Buchung </Link>

                <button className={"btn btn-gruen"} onClick={download}>Liste herunterladen</button>
            </div> }

            </tbody>

        </table>
    </div>

}


BuchungsListe.propTypes = {
    kursId: String,
    buchung: object
}

BuchungsListe.defaultProps = {
    kursId: "",
    buchung: {
        bucher: {
            vorname: "",
            nachname: ""
        },
        bucherFirma: "",
        teilnahmezahl: "",
        zeitpunkt:{
            humanReadable:""
        }
    }
}


