import React from "react";
import {labelClass} from "../components";


export const TeilnahmeModusAuswahl = (props) => {
    return <div>
        <label className={labelClass}>Teilnahmemodus:</label>
        <br/>

        <select value={props.value} onChange={props.onChange}>
            <option value={"Offline"}>👬 Vor Ort</option>
            <option value={"Online"}>💻 Live Online</option>
            <option value={"Mixed"}>💻/👬 Gemischt</option>
        </select>;
    </div>
}
