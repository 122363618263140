import React, { useState } from "react"
import { ErrorMessage, InputField, Label, SubmitButton, TextareaField } from "./components"
import { Region_soll_angelegt_werden } from "./Daten/FlugplanMessages.gen";
import { Region_Zeitzone_soll_zugeordnet_werden } from "./Daten/FlugplanMessages.gen";

import { ModalContainer2 } from "./UI/ModalContainer";
import {LaenderAutosuggestField, RegionAutosuggestField} from "./AutoSuggestFields";
import {CREATE_REGION, GET_LOCATIONS_COMPLETE, GET_REGIONS, GET_REGIONS_FOR_REGION_FIELD} from "./Queries";
import { gql, useApolloClient, useQuery, useMutation } from "@apollo/client";


export const RegionAnlegen2 = ({ def_region, closeHandler }) => {
    const newEmptyRegion = { region_ID: "", intern_Name: "", name: "", englischer_Name: "", land: "DEU", zeitzone: "Europe/Berlin"};

    const [region, setRegion] = useState(def_region ? def_region : newEmptyRegion);

    // field constants
    const Fields = Object.freeze({
        ID: "region_ID",
        NAME: "name",
        INTERN_NAME: "intern_Name",
        ENGLISCHER_NAME: "englischer_Name",
        ZEITZONE: "zeitzone",
        LAND: "land",
    })
    // store dirty fields here to save only changed fields
    const [dirtyFields, setDirty] = useState([]);
    // use direct access to ApolloClient in order to accellerate storage processes
    const client = useApolloClient();

    const [errorState, setErrorState] = useState(false);


    const resetFormAndClose = (e) => {
        if (e) { e.preventDefault() }
        setRegion(newEmptyRegion);
        setDirty([]);
        if (closeHandler) {
            closeHandler();
        }
    }

    const [create,
        { loading: createLoading, error: createError },
    ] = useMutation(CREATE_REGION, {
        onCompleted: (data) => {
            if (data[Region_soll_angelegt_werden.messageName] === "ok") {
                resetFormAndClose();
            } else {
                alert("Fehler beim Anlegen einer Region!");
                setErrorState(true)
            }
        }
    });


    const saveRegion = async () => {
        const mutations = [];

        if (!def_region) {
            // check required fields
            if (region.name  && region.land && region.zeitzone && region.intern_Name && region.englischer_Name ) {

                setErrorState(false);
                if (region.region_ID == "") {
                    region.region_ID = region.name + "." + region.zeitzone
                }
                const message = new Region_soll_angelegt_werden(
                    region.region_ID, region.intern_Name, region.name, region.englischer_Name, region.land.iso, region.zeitzone
                );
                create({ variables: { message: JSON.stringify(message) } })
            } else {
                setErrorState(true);
            }
        } else { 

           for (const field of dirtyFields) {
                let query;
                let message;
               /* switch (field) {
                    case Fields.NAME:
                        message = Region_Name_soll_geaendert_werden.messageName;
                        query = new Kurstermin_Region_soll_geaendert_werden(kurstermin_Id, region.name); break;
                    case Fields.LAND:
                        message = Region_Land_soll_zugeordnet_werden.messageName;
                       query = new Region_Zeitzone_soll_zugeordnet_werden(regionId, region.land); break;
                   
                } */
                if(field === Fields.ZEITZONE){
                    message = Region_Zeitzone_soll_zugeordnet_werden.messageName;
                    query = new Region_Zeitzone_soll_zugeordnet_werden(region.region_ID, region.zeitzone); break;
                }
                const mutation = gql`mutation ${message}($message: String!){${message}(message: $message)}`
                const variables = { message: JSON.stringify(query) }
                mutations.push(client.mutate({ mutation, variables }))
                
            } 
            Promise.all(mutations).then(
                () => { resetFormAndClose() }
            )
            
        } 
        
    }

    const onChange = (e) => {
        setRegion({ ...region, [e.target.name]: e.target.value });
        if (!dirtyFields.includes(e.target.name)) {
            const dirtyCopy = [...dirtyFields]
            dirtyCopy.push(e.target.name)
            setDirty(dirtyCopy)
        }
    };

    // gets executed when the submit button is clicked
    const onSubmit = (e) => {
        e.preventDefault();
        saveRegion();
    }

    // call onClose on actions that could occur accidentally
    const onClose = () => {
        if (dirtyFields.length === 0 || window.confirm("Wirklich verlassen? Geänderte Werte werden nicht gespeichert!")) {
            if (closeHandler) {
                closeHandler();
            }
        }
    }


    return (
        <ModalContainer2 title={"Region " + (def_region ? "bearbeiten" : "anlegen")} onClose={onClose}>
            {errorState && <ErrorMessage customText="Beim Speichern ist ein Fehler aufgetreten." />}
            <div className="region-anlegen-container">
                <span></span>
                <form onSubmit={onSubmit} className="orteingabe">

                    <InputField name="region_ID" type="text" title="ID" value={region.region_ID}
                                onChange={onChange} />

                    <InputField name="name" type="text" title="Name" value={region.name}
                                onChange={onChange} />

                    <InputField name="intern_Name" type="text" title="Intern_Name" value={region.intern_Name}
                                onChange={onChange} />

                    <InputField name="englischer_Name" type="text" title="Englischer Name" value={region.englischer_Name}
                                onChange={onChange} />

                    <LaenderAutosuggestField
                            initialValue={region.land}
                            setValueObject={suggestion => setRegion({ ...region, land: suggestion })}
                        />

                    <InputField name="zeitzone" type="text" title="Zeitzone" value={region.zeitzone}
                                onChange={onChange} />

                    <div><SubmitButton title={def_region ? "Ändern" : "Anlegen"} />
                        <button className="btn btn-rot" onClick={resetFormAndClose}>Abbrechen</button></div>
                </form>
            </div >
        </ModalContainer2>
    )
}