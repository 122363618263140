import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {App} from './App';
import './index.css';
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import {WebSocketLink} from "apollo-link-ws";
import {BrowserRouter} from "react-router-dom";


let kute_url = process.env.REACT_APP_KUTE_URL
if (kute_url.match(/^:\d+$/g)) {
    let protocol = "ws://"
    if (window.location.protocol === "https" || window.location.protocol === "https:") {
        protocol = "wss://"
    }
    kute_url = protocol + window.location.hostname + kute_url // the protocol
}
export const kute_http_url = "http" + kute_url.substr(2)
export const moodle_host = process.env.REACT_APP_MOODLE_URL
export const zapier_hook_register_moodle = process.env.REACT_APP_ZAPIER_URL


const link = new WebSocketLink({
    uri: kute_url, options: {
        reconnect: true,
        connectionParams: {
            authorization: localStorage.getItem('Kute::LoginToken'),
        },
    },
})

export const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </ApolloProvider>,
    document.getElementById('root'),
);
