import {gql} from "@apollo/client";
import {client} from "./index";

/**
 *
 * @param messages
 * @returns {Promise<unknown[]>}
 */
export async function sendMessages(messages) {
    const mutations = []
    messages.forEach((message) => {
        mutations?.push(sendMessage(message))
    })
    return Promise.all(mutations)

}

/**
 *
 * @param message
 * @returns {Promise<unknown>}
 */
export async function sendMessage(message) {
    const mutation = gql`mutation ${message?.constructor.messageName}($message: String!){${message?.constructor.messageName}(message: $message)}`
    const variables = {message: JSON.stringify(message)}
    return client.mutate({mutation, variables})
}