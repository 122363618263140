import * as React from "react";
import {useRef} from "react";
import {Link} from "react-router-dom"

import logo from './DST-Logo-RGB.svg';
import PropTypes from "prop-types"
import {gql, useQuery} from "@apollo/client"
import {graphql} from '@apollo/client/react/hoc';
import {VersionInfo} from "./VersionCheck"
import {DebounceInput} from "react-debounce-input";
import {formattingByIndex, onFilterChange} from "./Infrastruktur/Helpers";
import {GET_AVAILABLE_COURSE_STATUS} from "./Queries";
import Editable from "./Editable"
import {TopAlertContainer} from "./UI/Alert"


/*
*
* STYLES
* */
export const labelClass = "text-gray-700 w-32 text-xs"
export const editableClass = "text-gray-700 mb-2 w-32"
export const inputClass = "w-full h-10 pl-3 pr-8 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
export const buttonClass = "absolute inset-y-0 right-0 flex items-center px-4 font-bold text-lg text-white bg-dst-logo-gruen-mitte rounded-r-lg btn"
export const listClass = "list-reset block appearance-none w-full h-64 bg-white border border-gray-400 hover:border-gray-500 px-6 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline overflow-auto"
export const itemClass = "text-gray-700 block px-4 py-2 text-sm"
/*
* CAPTIONS
 */

export const btnOK = "✔"
export const btnCancel = "♺"
export const btnAdd = <div className={"border-gruen"}>hinzufügen</div>
export const btnRemove = <strong>❌</strong>


export const LinkButton = (props) => <button {...props}
                                             className={(props.className || "") + " text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte"}/>

export const QuarterInputField = (props) => <InputFieldWithClassName
    className="w-1/4 leading-normal border" {...props} />;

const HeadingButton = ({label, link, func}) => {
    const classes = "no-underline btn-gruen font-normal btn";

    return  (
        func ? <button key={label + "-button"} onClick={func} className={classes}>{label}</button> :
            <Link key={label + "-button"} className={classes} role="button" to={link}>{label}</Link>
    )
}

// populate table rows
export const DataLine = ({rowData, index, id, className}) => {
    const getElementForCol = (col) => {
        if (col.link) return <GreenLink2 label={col.text} link={col.link}/>
        if (col.func) return <GreenLink2 label={col.text} func={col.func}/>
        return <span>{col.text}</span>
    }
    return (
        <tr id={id} key={id} className={`w-full border h-8 p-2 border-0 leading-normal ${formattingByIndex(index)}`}>
            {rowData.map((col, colIndex) => {
                return <td className={className} key={col.text + id + colIndex + "-col"}
                           style={
                               {
                                   textAlign: col.func ? "center" : "inherit",
                                   minWidth: col.minWidth || "auto",
                                   maxWidth: col.maxWidth || "auto"
                               }
                           }>
                    {getElementForCol(col)}
                </td>
            })}
        </tr>
    )
}

export const ErrorMessage = ({customText, emoji}) =>
    <p className={"mb-2 text-red"}>{customText ? customText : "Ein Fehler ist aufgetreten."} <span role="img"
                                                                                                   aria-label="Emoji">&nbsp;{emoji ? emoji : "⚡️"}</span>
    </p>

export const GreenLink2 = ({label, link, func}) => {
    if (func) return <span className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte"
                           style={{cursor: "pointer"}} onClick={func}>{label}</span>

    return <div>{ (link.startsWith("http"))
        ? <a className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte" href={link}>{label}</a>
        : <Link className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte" to={link}>{label}</Link>}
    </div>

}


export const HeadingLine = ({title, icon, links}) => {
    return (<div id="heading-container" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}
                 className="py-3">
        <div><h2>{title}&nbsp;<span role="img" aria-label="heading-icon">{icon}</span></h2></div>
        <div>{links && links.map((link, index) =>
            <HeadingButton key={link.label + "-" + index} label={link.label} id={link.label} link={link.link} func={link.func}/>


        )}
        </div>
    </div>)
}

export const FifthSelectField = (props) => <select className={"leading-normal border " + props.className} name={props.name}
                                                   onChange={props.onChange} value={props.value}>
    {props.values.map((value, index) => <option value={props.valueKey ? value[props.valueKey] : value} key={index}>
        {props.titleKey ? value[props.titleKey] : value}
    </option>)}
</select>

export const FilterField2 = ({handleFilterChange, filterString, debounceTimeout, placeholder, size, defaultValue}) => {
    const onChange = (e) => onFilterChange(handleFilterChange, defaultValue)(e.target.value.trim());
    const placeholderString = filterString ? filterString : (placeholder || "Zum Filtern hier tippen...");
    const isSmallSize = size === "small";
    return (/*<div style={{ display: isSmallSize ? "inline" : "block" }} className={isSmallSize ? "w-1/4" : "w-full" + "filter-container relative mt-2"}>*/
        <DebounceInput className={(isSmallSize ? "w-1/4" : "w-full") + " border h-17 my-3 py-3"}
                       debounceTimeout={debounceTimeout || 300}
                       placeholder={placeholderString} onChange={onChange} type="search"/>
        /*<button onClick={() => handleFilterChange(defaultValue)} className="absolute pin-r mr-2 h-16">
            <ResetButtonItem />
        </button>
    </div>*/)
}


export const StatusButtonLeiste2 = ({active, onStatusButtonToggle}) => {
    const {data} = useQuery(GET_AVAILABLE_COURSE_STATUS, {fetchPolicy: "cache-and-network"});


    function onStatusButtonToggleHandler(text) {
        if (onStatusButtonToggle) {
            onStatusButtonToggle(text)
        }
    }

    return (<div className="flex justify-between mt-2 pr-2">
        {data && data.kursstatus.map(
            (kursstatus) => <StatusButton2 key={kursstatus.text}
                                           name={kursstatus.text}
                                           active={active.includes(kursstatus.text)}
                                           toggleButton={(text) => onStatusButtonToggleHandler(text)}>
                {kursstatus.text}
                </StatusButton2>
        )}
    </div>)
}

export const StatusButton2 = ({active, name, toggleButton, children}) => {

    function onClickHandler(e) {
        if (toggleButton) {
            toggleButton(e.target.name)
        }
    }

    return (
        <button onClick={(e) => onClickHandler(e)}
                name={name}
                className={(active ? "bg-dst-logo-gruen-mitte" : "bg-dst-grau-1") + " max-w-64 relative px-2 font-normal btn"}>
            {children}
        </button>
    )
}

export const InputField = (props) => <div className={props.divClassName}>
    <Label name={props.name} title={props.title}/><br/>
    <Input {...props} /><br/>
</div>;

export const InputFieldWithClassName = (props) => <input className={inputClass + props.className}
                                                         type={props.type}
                                                         value={props.value} placeholder={props.title}
                                                         onChange={props.onChange} name={props.name} {...props} />;

export const Input = (props) => <input className="leading-normal border w-full" type={props.type} value={props.value}
                                       onChange={props.onChange} name={props.name} onKeyDown={props.onKeyDown}
                                       onKeyUp={props.onKeyUp} onBlur={props.onBlur}
                                       placeholder={props.placeholder ? props.placeholder : props.title} {...props}
                                        />

export const TextareaField = (props) => <div>
    <Label name={props.name} title={props.title}/><br/>
    <Textarea {...props} />
</div>;

export const Textarea = (props) => <textarea className={props.className || "leading-normal border w-full"}
                                             onChange={props.onChange}
                                             onKeyUp={props.onKeyUp}
                                             placeholder={props.placeholder ? props.placeholder : props.title}
                                             name={props.name} onKeyDown={props.onKeyDown} value={props.value}/>

export const SelectField = (props) => <div>
    {props.noHeader || <Label name={props.name} title={props.title}/>}{props.noHeader || <br/>}
    <Select {...props} />
    <br/>
</div>;

SelectField.defaultProps = {
    noHeader: false,
}

export const Select = (props) => <select name={props.name} onChange={props.onChange} value={props.value}>
    {props.values.map((value, index) => <option value={props.valueKey ? value[props.valueKey] : value} key={index}>
        {props.titleKey ? value[props.titleKey] : value}
    </option>)}
</select>

export const Label = (props) => <label className="font-bold leading-normal" htmlFor={props.name}>{props.title}</label>;

Label.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export const SubmitButton = (props) => <input className={"mt-2 btn btn-gruen " + (props.full ? "w-full" : "")}
                                              type="submit" value={props.title}
                                              disabled={props.disabled}/>
SubmitButton.defaultProps = {
    disabled: false,
}

export const Header = () => {
    return <header className="bg-dst-hintergrund-hellgruen text-center relative">
        <TopAlertContainer>
            <VersionInfo/>
        </TopAlertContainer>
        <Link to="/"><img src={logo} className="h-16 md:h-24 mt-3" alt="logo"/></Link>
        <h1 className="text-dst-logo-gruen-hinten font-bold text-2xl leading-loose">reisebüro2</h1>
    </header>
}

export const Vier04 = () => {
    return <h1>404</h1>
}

export const TeilnehmerNeuZeile = (props) => <div>
    <AnredeField name={"teilnehmer_" + props.index + "_anrede"} title="Anrede"
                 className={"w-1/5"}
                 value={props?.teilnehmer?.anrede?.code}
                 onChange={props.onChange}/>
    <InputFieldWithClassName name={"teilnehmer_" + props.index + "_titel"} type="text" title="Titel"
                             value={props.teilnehmer.titel} className="w-full lg:w-1/5 leading-normal border"
                             onChange={props.onChange}/>
    <InputFieldWithClassName name={"teilnehmer_" + props.index + "_vorname"} type="text" title="Vorname"
                             value={props.teilnehmer.vorname} className="w-full lg:w-1/5 leading-normal border"
                             onChange={props.onChange}/>
    <InputFieldWithClassName name={"teilnehmer_" + props.index + "_nachname"} type="text" title="Nachname"
                             value={props.teilnehmer.nachname} className="w-full lg:w-1/5 leading-normal border"
                             onChange={props.onChange}/>
    <InputFieldWithClassName name={"teilnehmer_" + props.index + "_email"} type="email" title="E-Mail"
                             value={props.teilnehmer.email} className="w-full lg:w-1/5 leading-normal border"
                             onChange={props.onChange}/>
</div>

export const BulkUpload = (props) => <div className={"w-full float-left"}>
    <h4>Teilnehmer-Upload copy & paste</h4>
    <textarea onChange={(e) => props.onTextChange && props.onTextChange(e.target.value)} className={"border-2 w-1/2"}>{props.text ?? props.text }</textarea>
    <button className="align-top btn btn-gruen" onClick={props.onClick?? props.onClick} type="button">Upload</button>

</div>

// Return array of string values, or NULL if CSV string not well formed.
export function CSVtoArray(text) {
    const re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
    const re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
    // Return NULL if input string is not well formed CSV string.
    if (!re_valid.test(text)) return null;
    let a = [];                     // Initialize array to receive values.
    text.replace(re_value, // "Walk" the string using replace with callback.
        function(m0, m1, m2, m3) {
            // Remove backslash from \' in single quoted values.
            if      (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
            // Remove backslash from \" in double quoted values.
            else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
            else if (m3 !== undefined) a.push(m3);
            return ''; // Return empty string.
        });
    // Handle special case of empty last value.
    if (/,\s*$/.test(text)) a.push('');
    return a;
}

const _AnredeField = (props) => <FifthSelectField name={props.name} title={props.title}
                                                  className={props.className}
                                                  value={props.value}
                                                  onChange={props.onChange}
                                                  values={props.data.anreden || []} valueKey="code" titleKey="text"
                                                  onBlur={onblur}/>

export const AnredeField = graphql(gql`{anreden{code,text}}`, {
    options: {
        fetchPolicy: "network-only",
    },
})(_AnredeField)

export const StatusButton = (props) => <button key={props.status.text} type="button"
                                               className={(props.active ? "bg-dst-logo-gruen-mitte" : "bg-dst-grau-1") + " max-w-64 relative px-2 font-normal btn " + (props.className || "")}
                                               onClick={() => props.onClick(props.status.text, !props.active)}
                                               disabled={props.disabled}
>
    <span className="mr-5">{props.status.text}</span>
</button>

StatusButton.propTypes = {
    status: PropTypes.shape({text: PropTypes.string.isRequired}).isRequired,
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
}

export const StatusButtonField = (props) => <StatusButton status={{text: props.placeholder}} active={props.value}
                                                          onClick={(_, state) => props.onChange(state)}/>

StatusButtonField.propTypes = {
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
}

TeilnehmerNeuZeile.propTypes = {
    'index': PropTypes.number.isRequired,
    'teilnehmer': PropTypes.shape({
        anrede: PropTypes.string.isRequired,
        vorname: PropTypes.string.isRequired,
        nachname: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
    }).isRequired,
    'onChange': PropTypes.func.isRequired,
}


export const Loading = () => <div className="spinner">
    <div className="rect1"/>
    <div className="rect2"/>
    <div className="rect3"/>
    <div className="rect4"/>
    <div className="rect5"/>
</div>

export const ResetButtonItem = () => {
    return <svg className="DateRangePickerInput_clearDates_svg DateRangePickerInput_clearDates_svg_1"
                viewBox="0 0 12 12">
        <path fillRule="evenodd"
              d="M11.53.47a.75.75 0 0 0-1.061 0l-4.47 4.47L1.529.47A.75.75 0 1 0 .468 1.531l4.47 4.47-4.47 4.47a.75.75 0 1 0 1.061 1.061l4.47-4.47 4.47 4.47a.75.75 0 1 0 1.061-1.061l-4.47-4.47 4.47-4.47a.75.75 0 0 0 0-1.061z"/>
    </svg>
}
export const TD = (props) =>
    <td {...props} className={(props.className || "") + " w-1/2 h-6 border border-white leading-normal align-top"}/>
export const TH = (props) =>
    <th className="text-left align-top leading-normal w-1/4">{props.children}</th>




export function EditorField2({title, type, pattern, value, onChange}) {
    const inputRef = useRef()

    return <span>
        <label className={labelClass}>{title}</label>
        <br />
        <Editable
        className={editableClass}
        text={value}
        title={title}
        type="input"
        placeholder={title}
        childRef={inputRef}
        >
        <input
            className={inputClass}
            ref={inputRef}
            value={value}
            type={type}
            onChange={onChange}
            pattern={pattern}

        />
        </Editable>
        </span>
}

export function EditorButton(props) {
    return  <button className={props.className + " p-2"} {...props}>
        {props.children}
    </button>
}

export const GoogleMapsLink = (props) => {
    console.log(props.googleMapsURL)

    if (props?.teilnahmeModus.text === "Online" ) {
        return <span>{props?.kursLocation}</span>
    } else {
        if (props.googleMapsURL) {
            return <span>
            <a className={"font-light"}
               href={props.googleMapsURL ?? "#"}
               target={"_blank"}
            >
                {props.kursLocation}
            </a>
        </span>
        } else {
            return <span className={"bg-red"}>
                {props?.kursLocation}<br />
                Google-Maps-Link nicht gesetzt!
            </span>
        }



    }
}

