import {useLazyQuery, useQuery} from "@apollo/client";
import {
    GET_TEILNEHMER,
    GET_MOODLE_TEILNEHMER,
    GET_EXTERNE_ANFORDERUNGEN
} from "../Queries";
import {object} from "prop-types";
import React, {useState} from "react";
import {sendMessage} from "../SendMessages";
import {useSelect} from "downshift";
import {
    Moodle_Externe_Einschreibung_soll_angefordert_werden,
    Moodle_Teilnehmer_soll_angemeldet_werden, Moodle_Teilnehmer_soll_aus_Moodle_Kurs_herausgenommen_werden,
    Moodle_Teilnehmer_soll_in_Moodle_Kurs_eingeschrieben_werden
} from "../Daten/MoodleMessages.gen";
import {Kontakt_Moodle_Teilnehmer_soll_zugeordnet_werden} from "../Daten/CheckinMessages.gen";
import {Loading} from "../components";


export const MoodeAnmeldung = ({kursId, moodleHost, moodleCourseId, teilnehmer, onChanged, externeEinladungAngefordert}) => {
    console.log(teilnehmer)
    const [selectedTn, setSelectedTn] = useState()
    const [teilnehmerZuordnungInArbeit, setTeilnehmerZuordnungInArbeit] = useState([])

    const emojis = {
        found: `➜`,
        notAvailable: `⛔`,
        notfound: `✴`
    };
    const [getMoodleTeilnehmer, {
        loading: moodleLoading,
        error: moodleError,
        data: moodleData,
        refetch: moodleRefetch,
    }] = useLazyQuery(GET_MOODLE_TEILNEHMER);

    const zeigeAlleRestlichenAnmelden = false // feature flag

    const [getMoodleAnforderungen, {
        data: moodleAnforderungen,
        refetch: moodleAnforderungenRefetch
    }] = useLazyQuery(GET_EXTERNE_ANFORDERUNGEN);

    const {data, loading, refetch} = useQuery(GET_TEILNEHMER,
        {
            variables: {kurs: kursId},
            fetchPolicy: "network-only",
            onCompleted: () => {
                console.log(data)
                if (moodleCourseId > 0) {
                    getMoodleTeilnehmer({
                        variables: {
                            moodleKursId: moodleCourseId
                        }
                    }).then(()=>{})

                }
                getMoodleAnforderungen( {variables: {kursID: kursId}})?.then(()=>{})
                console.log(moodleAnforderungen)
            }
        },
    );

    const MoodleTnSelect = ({moodleTn, selected, onSelectChange}) => {
        const itemToString = (item) => (item ? item.email : '');

        const {
            isOpen,
            selectedItem,
            getToggleButtonProps,
            getMenuProps,
            highlightedIndex,
            getItemProps,
        } = useSelect({
            items: moodleTn,
            itemToString,
            selectedItem: selected,
            onSelectedItemChange: (item) => (onSelectChange ? onSelectChange(item) : {})
        });

        if(moodleLoading ){
            return <Loading />
        }

        return (
            <div>
                <button className="md:w-64 border border-2" type="button" {...getToggleButtonProps()} >
                    {selectedItem ? itemToString(selectedItem) : 'Teilnehmer auswählen'}
                </button>
                <ul className={isOpen ? "list-reset w-64 h-64 overflow-y-auto border-2 bg-white rounded " : "hidden"}  {...getMenuProps()} >
                    {moodleTn.map((item, index) => (
                        <li
                            style={
                                highlightedIndex === index
                                    ? {backgroundColor: '#bde4ff'}
                                    : {}
                            }
                            key={`${item.email}${index}`}
                            {...getItemProps({item, index})}
                        >
                            {item.email}
                        </li>
                    ))}

                </ul>
            </div>)

    }


  //  const TeilnehmerInMoodle = ({teilnehmer, onChanged, externeEinladungAngefordert}) => {

      // const [selectedTn, setSelectedTn] = useState()


        if (!moodleHost || !moodleData) {
            return <span>
                {emojis.notAvailable}
            </span>
        }

        /**
         * @param mTn
         * @param mTn.fullname
         * @returns mTn or undefined
         *
         * Ich kann das so machen, weil JS Array[] bei ungültigem Index ein <undefined> zurückgibt
         */

        let mTn = moodleData.moodleTeilnehmer[moodleData.moodleTeilnehmer.findIndex((tn) => {
           // console.log("teilnehmer email "+teilnehmer.email); console.log("tn.email "+tn.email)
                return (teilnehmer.kontakt.moodleTeilnehmer === tn?.Id?.toString() || (tn?.email?.toLowerCase().trim() === teilnehmer?.email?.toLowerCase().trim()))
            }
        )];

        function handleClickMoodleTeilnehmer(mtn, moodleTeilnehmer) {
            const message = new Kontakt_Moodle_Teilnehmer_soll_zugeordnet_werden(mtn.kontaktId, moodleTeilnehmer.Id.toString())
            sendMessage(message).then((r) => {
                console.log(r)
                if (r.data.Kontakt_Moodle_Teilnehmer_soll_zugeordnet_werden === "ok") {
                    refetch({kursId}).then(() => {})
                }
            })

        }


        async function aendereKursTeilnahme(mtn, message, timeout) {
            let tnsOld = teilnehmerZuordnungInArbeit
            if (!teilnehmerZuordnungInArbeit.includes(mtn.kontaktId)) {
                let tns = [...teilnehmerZuordnungInArbeit]
                tns.push(teilnehmer.kontaktId)
                setTeilnehmerZuordnungInArbeit(tns)
            }

            sendMessage(message).then((r) => {
                if (r.data[message?.constructor.messageName] === "ok") {
                    setTimeout(() => {
                        setTeilnehmerZuordnungInArbeit([...tnsOld])
                        refetch().then(() => {
                            moodleRefetch().then(()=>{
                                if (onChanged) {
                                    onChanged(teilnehmer)
                                }
                            })
                        })
                    }, timeout ?? 3000)
                } else {
                    alert("Anlegen fehlgeschlagen.")
                }
            })
        }


        // Logik und Feature-Flags
        const zeigeSelect = !mTn && !teilnehmer?.kontakt?.moodleTeilnehmer
        const zeigeEinladenLink = !mTn && !teilnehmer?.kontakt?.moodleTeilnehmer && false // feature flag
        const zeigeVerknuepfen = !(teilnehmer?.kontakt?.moodleTeilnehmer) && (mTn) && true // feature flag
        const zeigeMoodleLink = mTn && teilnehmer?.kontakt?.moodleTeilnehmer
        const zeigeZuordnungsFehler = !mTn && teilnehmer.kontakt.moodleTeilnehmer
        const zeigeAnmeldeButton = (zeigeSelect || zeigeZuordnungsFehler) && false
        const zeigeAbmeldeButton = zeigeMoodleLink && false // feature flag
        const zeigeExterneEinladung = (zeigeSelect || zeigeZuordnungsFehler) && true // feature flag


        if (loading || moodleLoading) return <Loading/>


        return <div className={"grid grid-cols-2 gap-1"}>
            {/*moodleAnforderungen && <div>
                <h6>{moodleAnforderungen?.moodleExterneAnforderungen?.length} Externe Moodle-Anmeldung(en) angefragt</h6>
                <ul>

                </ul>
            </div>*/}

            <div>
                {zeigeMoodleLink && <a
                    target="_blank"
                    rel="noopener, noreferrer"
                    href={`https://${moodleHost}/user/profile.php?id=` + mTn.Id}>
                    Ⓜ️ {mTn.email}
                </a>
                }


                {zeigeVerknuepfen && mTn && <span> {mTn.email}</span>}
                {zeigeVerknuepfen && <button
                    className={"btn btn-gruen"}
                    disabled={teilnehmerZuordnungInArbeit.includes(teilnehmer.kontaktId || selectedTn === null)}
                    onClick={() => handleClickMoodleTeilnehmer(teilnehmer, selectedTn ? selectedTn : mTn)}
                    title={"Moodle-Teilnehmer verknüpfen"}>
                    Ⓜ️ Verknüpfen
                </button>}
                {zeigeAnmeldeButton && <div>
                    <button id={teilnehmer.kontaktId} className={"btn btn-gruen"}
                            disabled={teilnehmerZuordnungInArbeit.includes(teilnehmer?.kontaktId)} onClick={() => {

                        if (zeigeZuordnungsFehler) {
                            // vorhandenen Teilnehmer in Kurs einschreiben
                            let message = new Moodle_Teilnehmer_soll_in_Moodle_Kurs_eingeschrieben_werden(
                                teilnehmer.kontaktId,
                                kursId
                            )
                            aendereKursTeilnahme(teilnehmer, message).then(() => {
                                console.log("Teilnehmer eingeschrieben")
                            })
                        } else {
                            // neu einladen
                            let message = new Moodle_Teilnehmer_soll_angemeldet_werden(
                                teilnehmer.kontaktId,
                                null
                            )

                            aendereKursTeilnahme(teilnehmer, message).then(() => {

                                let message = new Moodle_Teilnehmer_soll_in_Moodle_Kurs_eingeschrieben_werden(
                                    teilnehmer.kontaktId,
                                    kursId
                                )
                                aendereKursTeilnahme(teilnehmer, message).then(() => {
                                    console.log("Teilnehmer eingeschrieben")
                                })
                            })

                        }

                    }
                    }>{!zeigeZuordnungsFehler ? "Neu Anmelden" : "Einschreiben"}
                    </button>
                </div>}
                {zeigeAbmeldeButton && <div>

                    <button id={teilnehmer.kontaktId} className={"btn btn-rot"}
                            disabled={teilnehmerZuordnungInArbeit.includes(teilnehmer.kontaktId)} onClick={() => {
                        let message = new Moodle_Teilnehmer_soll_aus_Moodle_Kurs_herausgenommen_werden(
                            teilnehmer.kontaktId,
                            kursId
                        )
                        aendereKursTeilnahme(teilnehmer, message).then(() => {

                        })
                    }
                    }>Abmelden
                    </button>
                </div>}

                {zeigeExterneEinladung && <div>
                    <button id={teilnehmer.kontaktId + "extern"} className={"btn btn-rot"}
                            disabled={teilnehmerZuordnungInArbeit?.includes(teilnehmer?.kontaktId)}  onClick={() => {

                        let message = new Moodle_Externe_Einschreibung_soll_angefordert_werden(
                            teilnehmer?.kontaktId,
                            kursId
                        )
                        aendereKursTeilnahme(teilnehmer, message, 15000).then(() => {
                            console.log("Externe Einladung angefordert")
                            moodleAnforderungenRefetch().then(()=>{})
                        })
                    }
                    }>Ⓜ️ Neu einladen
                    </button>
                    {externeEinladungAngefordert && <span><br /><h6>Ⓜ️ Eingeladen</h6><br /></span>}


                </div>}


            </div>
            <div>


                {zeigeZuordnungsFehler && <span>⚡️ Kontakt, aber nicht im Kurs.</span>}
            </div>
        </div>
  //  }


}


MoodeAnmeldung.propTypes = {
    kursId: String,
    moodleHost: String,
    teilnehmer: object
}

MoodeAnmeldung.defaultProps = {
    kursId: "",
    moodleHost: "",
    teilnehmer: {
        email: "",
        kontakt: {
            nachname: "",
            email: "",
            vorname: "",
            moodleTeilnehmer: "",
        },
        storniert: ""
    }
}