import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_EVENT_LOG } from "../Queries";
import { DataLine, FilterField2, HeadingLine, Loading } from "../components";
import { onFilterChange } from "../Infrastruktur/Helpers";

export const EventLogViewer = () => {
    const [count, setCount] = useState();

    const [stream, setStream] = useState();
    const [type, setType] = useState();
    const [user, setUser] = useState();
    const [before, setBefore] = useState();

    // local search field
    const [filterString, setFilterString] = useState("");

    const [autoreload, setAutoreload] = useState(true);

    // get data
    const { data, loading, variables, client } = useQuery(GET_EVENT_LOG,
        {
            fetchPolicy: "no-cache",
            pollInterval: autoreload ? 5000 : 0,
            variables: { count, stream, type, user }
        }
    )

    // filter for search
    const filter = eventLogEntry => {
        if (!filterString) { return true }
        const lowerQuery = filterString.toLowerCase();
        return (eventLogEntry.streams || []).join("").toLowerCase().indexOf(lowerQuery) > -1 ||
            eventLogEntry.user.toLowerCase().indexOf(lowerQuery) > -1 ||
            eventLogEntry.name.toLowerCase().indexOf(lowerQuery) > -1 ||
            eventLogEntry.data.toLowerCase().indexOf(lowerQuery) > -1
    }

    // action buttons
    const links = [{ label: `Autoreload ${autoreload ? "deaktivieren" : "aktivieren"}`, func: () => { setAutoreload(!autoreload) } }]

    return (
        <div>
            <HeadingLine title="Eventlog" icon="👨‍💻" links={links} />
            <div>Aktuelle Abfrage: {JSON.stringify(variables)}, WS-Verbindung: {client.link.subscriptionClient.url}</div>
            {loading && <Loading />}
            {data && <div>
                <FilterField2 filterString={filterString} handleFilterChange={setFilterString} placeholder="Lokale Suche, zum Filtern hier tippen..." />
                <FilterField2 size="small" filterString={stream} handleFilterChange={setStream} placeholder="Streamfilter..." />
                <FilterField2 size="small" filterString={type} handleFilterChange={setType} placeholder="Eventtypfilter..." />
                <FilterField2 size="small" filterString={user} handleFilterChange={setUser} placeholder="Userfilter..." />
                <FilterField2 size="small" filterString={count} handleFilterChange={setCount} defaultValue="20" placeholder="Result count (default 20)..." />
                <table style={{ width: "100%" }}>
                    <thead><tr>
                        <th>ID</th>
                        <th>Zeit</th>
                        <th>Streams</th>
                        <th>Typ</th>
                        <th>User</th>
                        <th>Data</th>
                    </tr></thead>
                    <tbody>{data && !loading && data.adminevents
                        .filter(filter)
                        .map((eventLogEntry, index) => <DataLine id={eventLogEntry.Id} index={index} rowData={[
                            { text: eventLogEntry.Id },
                            { text: eventLogEntry.time, minWidth: "200px" },
                            { text: (eventLogEntry.streams || []).join(" ") },
                            { text: eventLogEntry.name, maxWidth: "70%" },
                            { text: eventLogEntry.user },
                            { text: JSON.stringify(JSON.parse(eventLogEntry.data), null, 2), maxWidth: "70%" }
                        ]} />)}
                    </tbody>
                </table></div>}
        </div>
    )
}