import React, { useState } from "react"
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
    Trainer_soll_aufgenommen_werden,
    Trainer_CMS_Code_soll_gesetzt_werden,
    Trainer_Email_soll_eingegeben_werden,
    Trainer_Mobiltelefon_soll_eingegeben_werden,
    Trainer_Name_soll_geaendert_werden,
    Trainer_Slackname_soll_eingegeben_werden
} from "../Daten/FlugplanMessages.gen";
import { ModalContainer2 } from "../UI/ModalContainer";
import { CREATE_TRAINER, GET_TRAINER_FOR_ID } from "../Queries";
import { InputField, Loading, SubmitButton } from "../components";
import { Trainer_Bemerkung_soll_ausgeblendet_werden } from "../Daten/BemerkungenMessages.gen";


export const TrainerAnlegenBearbeiten = ({ createNew, id, closeHandler }) => {
    const [errorState, setErrorState] = useState(false);
    const [trainer, setTrainer] = useState();
    const [dirtyFields, setDirty] = useState([]);
    const client = useApolloClient()
    const Fields = Object.freeze({
        TITEL: "titel",
        VORNAMEN: "vornamen",
        NACHNAMEN: "nachname",
        EMAIL: "email",
        TELEFON: "telefon",
        SLACKNAME: "slackname",
        CMSCODE: "cmscode"
    })

    const {loading } = useQuery(GET_TRAINER_FOR_ID, {

        fetchPolicy: "no-cache",
        variables: { Id: id },
        onCompleted: data => {
            // onCompleted returns empty query result as well
            setTrainer(data.trainer ? data.trainer : {
                Id : "",
                titel : "",
                vornamen : "",
                nachname : "",
                email : "",
                telefon : "",
                slackname : "",
                cmscode : ""
            });        
        }
    });


    const [create,
        {  },
    ] = useMutation(CREATE_TRAINER, {
        onCompleted: (data) => {         
            if (data[Trainer_soll_aufgenommen_werden.messageName] === "ok") {
                setSaveLoading(false);
                cleanupAndClose();
            } else {
                setErrorState(true)
            }
        }
    });  
    
    const [saveLoading, setSaveLoading] = useState(false);

    const saveTrainer = async () => {
        
        const mutations = [];

       // creation of new object needs to come first
        if (createNew) {

        
            // check required fields
            if (trainer.vornamen && trainer.nachname) {
                setErrorState(false);
                setSaveLoading(true);
                const message = new Trainer_soll_aufgenommen_werden(
                    trainer.titel,
                    trainer.nachname,
                    trainer.vornamen
                );
                create({variables: {message: JSON.stringify(message)}})
            } else {
                setErrorState(true);
            }
        } else {
            for (const field of dirtyFields) {
                let query;
                let message;
                switch (field) {
                    case Fields.TITEL:
                    case Fields.VORNAMEN:    
                    case Fields.NACHNAMEN:
                        message = Trainer_Name_soll_geaendert_werden.messageName;
                        query = new Trainer_Name_soll_geaendert_werden(trainer.Id, trainer.titel, trainer.nachname, trainer.vornamen); break;
                    case Fields.EMAIL:
                        message = Trainer_Email_soll_eingegeben_werden.messageName;
                        query = new Trainer_Email_soll_eingegeben_werden(trainer.Id, trainer.email); break;
                    case Fields.SLACKNAME:
                        message = Trainer_Slackname_soll_eingegeben_werden.messageName;
                        query = new Trainer_Slackname_soll_eingegeben_werden(trainer.Id, trainer.slackname); break;
                    case Fields.CMSCODE:
                        message = Trainer_CMS_Code_soll_gesetzt_werden.messageName;
                        query = new Trainer_CMS_Code_soll_gesetzt_werden(trainer.Id, trainer.cmscode); break;
                    case Fields.TELEFON:
                        message = Trainer_Mobiltelefon_soll_eingegeben_werden.messageName;
                        query = new Trainer_Mobiltelefon_soll_eingegeben_werden(trainer.Id, trainer.telefon); break;
                }
                const mutation = gql`mutation ${message}($message: String!){${message}(message: $message)}`
                const variables = { message: JSON.stringify(query) }
                mutations.push(client.mutate({ mutation, variables }))
            }
            Promise.all(mutations).then(
                () => { setSaveLoading(false); cleanupAndClose() }
            )
        }
        return;
    }
    
    const onSubmit = (e) => {
        e.preventDefault();
        saveTrainer();
    }

    // gets executed every time a value in a field changes
    const onChange = (e) => {
        setTrainer({
            ...trainer,
            [e.target.name]: e.target.value
        });
        if (!dirtyFields.includes(e.target.name)) {
            const dirtyCopy = [...dirtyFields]
            dirtyCopy.push(e.target.name)
            setDirty(dirtyCopy)
        }
    }

    // call onClose on actions that could occur accidentally 
    const onClose = () => {
        if (dirtyFields.length === 0 || window.confirm("Wirklich verlassen? Geänderte Werte werden nicht gespeichert!")) {
            closeHandler();
        }
    }

    // cleanup and close (e.g. on cancel and on successful save)
    const cleanupAndClose = () => {
        setDirty([]);
        closeHandler()
    }   

    return (<ModalContainer2 title={createNew ? "Trainer anlegen" : "Trainerdaten bearbeiten"} onClose={onClose} >
        {                    
            !loading && !saveLoading && trainer &&
            <div className="trainer-anlegen-container">
                <code>ID: {trainer.Id}</code>
                <form onSubmit={onSubmit} className="trainereingabe">
                    <InputField name={Fields.TITEL} type="text" title={Fields.TITEL} value={trainer.titel} onChange={onChange} />
                    <InputField name={Fields.VORNAMEN} type="text" title={Fields.VORNAMEN} value={trainer.vornamen} onChange={onChange} />
                    <InputField name={Fields.NACHNAMEN} type="text" title={Fields.NACHNAMEN} value={trainer.nachname} onChange={onChange} />
                     <InputField name={Fields.EMAIL} type="text" title={Fields.EMAIL} value={trainer.email} onChange={onChange} />
                    <InputField name={Fields.SLACKNAME} title={Fields.SLACKNAME} value={trainer.slackname} onChange={onChange} />
                     <InputField name={Fields.CMSCODE} type="text" title={Fields.CMSCODE} value={trainer.cmscode} onChange={onChange} />

                    <div><SubmitButton title={createNew ? "Anlegen" : "Ändern"} />
                        <button className="btn btn-rot" onClick={cleanupAndClose}>Abbrechen</button></div>
                </form>
            </div >
        }
        {(loading || saveLoading) && <Loading />}
    </ModalContainer2>)
         
}






