import {gql} from "@apollo/client";
export const GET_LOCATIONS = gql`{kursorte{name, region{name,land{name}},Id}}`;

export const GET_REGIONS_FOR_REGION_FIELD = gql`{regionen{Id,name,land{name,iso},ausgeblendet}}`;

export const GET_REGIONS = gql`{regionen{Id,name,englischer_Name,land{name},ausgeblendet}}`;

export const GET_LAENDER = gql`{laender{iso, name, nameEn }}`;

export const GET_ID = gql`{id}`;

export const LOGGER_SUBSCRIPTION = gql`  subscription logger { logger }`;

export const GET_PERMISSIONS_AND_LOGGER = gql`query logger {berechtigungen, logger {significantVersion{uuid, version}}}`

export const GET_COURSE_TYPES = gql`{kurstypen{Id,name}}`


export const GET_COURSE_DETAILS = gql`query kurstermin($id: String){kurstermine(Id: $id){
    titel,
    titelEn,
    beginn{
        humanReadable,
        isoString},
    dauer,
    preis{
        wert,
        waehrung: waehrungTyp{
            code,
            name
        }
    },
    fruehbucherPreis{
        preis{
            wert,
            waehrung: waehrungTyp{
                code,
                name
            }
        },
        gueltigBis{
            isoString,
            humanReadable
        },
        gueltig_bis_Tage_vor_dem_Kurs
    },
    smallInvoiceProduktId,
    smallInvoiceProduktName,
    typ{
        Id,
        name
    },
    status{
        text,
        transitions{text}
    },
    teilnehmerKreis,
    wenigeRestplaetzeAb,
    maxTeilnahmeZahl,
    location{
        Id,
        name,
        vollstaendigeAdresse,
        googleMapsUrl
    },
    region{
        Id,
        name,
        land{
            iso,
            name
        }
    },
    
    
    sprache: spracheTyp {
        name,
        code
    },
    kursCode,
    trainer{
        Id,
        nachname,
        vornamen
    },
    freitext,
    freitextEn,
    kurstage{
        beginn,
        ende,
        offset,
        Id
    },
    kontaktstunden,
    bemerkungen{
        Id,
        text,
        autor{name},
        erstellt{
            isoString,
            humanReadable
        }
    },
    buchungen { count },
    teilnehmer { count },
    inListe,
    oeffentlich,
    drupalUrl,
    moodleTermin,
    Id
}}`

export const GET_EVENT_LOG = gql(`query admin($count: Int, $type: String, $user: String, $stream: String) { adminevents(count: $count, stream: $stream, user: $user, type: $type) { Id, time, name, data, user, streams } }`)

export const GET_COURSE_CODE = gql`query k($kursId: String){kurstermine(Id: $kursId){kursCode}}`;

export const GET_LOGS = gql`query logger {logger {messages{timestamp, level, sender, message}}}`;

export const GET_LOGIN_STATUS = gql`query loginStatus{loginStatus}`;

export const GET_BUCHUNGEN = gql`query  buchungen($kurs: String){buchungen(kurs: $kurs){
  bucher{
      anrede{code,text},
      titel,
      vorname,
      nachname,
      email,
      telefon,
      Id
  },
  bucherFirma,
  storniert,
  zeitpunkt{humanReadable,isoString},
  bucherRechnungsAdresse{
      description,
      vorname,
      nachname
      abteilung,
      strasse,
      plz,
      ort,
      land{iso}
  },
  bestellNummer,
  ustId,
  affiliateInfo,
  gutscheinCode,
  rechnungPerPost,
  preis{
      waehrung: waehrungTyp{code,name},
      wert,
      description},
  rechnungNummer,
  kommentar,
  teilnehmerzahl,
  teilnehmer{
      anrede{text,code},
      titel,
      vorname,
      nachname,
      storniert,
      Id
  },
  verschoben {
      Id,
      titel,
  },
  Id,
  kurstermin {
   smallInvoiceProduktId,
   abaninjaProduktId,
   abaninjaProduktName,
   kursCode,
   Id
  }
}}

`

export const GET_TEILNEHMER = gql`query teilnehmer($kurs: String){teilnehmer(kurs: $kurs){
  Id
  bemerkungenAnzahl
  bestaetigt
  buchungId
  kontaktId
  kontakt{vorname, nachname, email, moodleTeilnehmer, newsletter}
  storniert,
  
}}`


export const GET_BENUTZER = gql`query Benutzerliste{ benutzer{ Id,name }}`

export const GET_KONTAKTE = gql`query Kontakte($filter: String, $sort: String, $after: String, $limit: Int){kontaktepages(filter: $filter, sort: $sort, after: $after, limit: $limit){
  totalItems
  pages {
      number
      after
  }
  page {
      pageNumber
      nextPageAfterCursor
      previousPageAfterCursor
      items {
          buchungen{Id},
          teilnahmen{Id kurstermin{Id kursCode }},
          vorname,
          nachname,
          email,
          firma,
          Id,
          moodleTeilnehmer
      }
  }
}}`

export const GET_TRAINERLISTE = gql`{trainerliste{Id, titel, nachname, vornamen, email, telefon, slackname, cmscode, bemerkungenAnzahl, bemerkungen{Id, text, autor{name}, erstellt{humanReadable,isoString}}}}`;

export const GET_TRAINER_FOR_ID = gql`query trainer($Id:String) {trainer(Id:$Id){Id,titel, vornamen, nachname, cmscode, email, telefon, slackname, }}`

export const GET_AVAILABLE_COURSE_STATUS = gql`
query kursstatus{
  kursstatus{
    renderText
    renderTextEn
    text
    textEn
  }
}`

export const GET_COURSES_FOR_OVERVIEW = gql`
  query kurstermine($after: String, $before: String, $status: [String])
  { kurstermine (
    after: $after
    status: $status
    before: $before
    ) {
      titel
      freitext
      maxTeilnahmeZahl
      buchungen { count }
      teilnehmer { count }
      beginn {
        isoString
        humanReadable
      }
      teilnehmerKreis
      trainer { text, cmscode, Id }
      dauer
      preis { description }
      listeFruehbucher: fruehbucherPreis(versteckenWennAbgelaufen: true) {
        gueltigBis {
          humanReadable
        }
        preis {
          description
        }
      }
      fruehbucherPreis {
        gueltigBis {
          humanReadable
        }
        preis {
          description
        }
      }
      fruehbucherAktiv
      typ {
        kuerzel
        name
      }
      status {
        text
        textEn
        renderText
        renderTextEn
      }
      inListe
      oeffentlich
      location {
        Id
        name
        vollstaendigeAdresse
        googleMapsUrl
        region {
          Id
          name
          land {
            name
            nameEn
          }
        }
      }
      region {
        Id
        name
        land {
          name
          nameEn
        }
      }
      sprache
      spracheEn
      kursCode
      teilnahmeModus{text} 
      moodleTermin
      Id
    }
  }
  `

export const CREATE_LOCATION = gql`mutation kursortAnlegen($message: String!){Kursort_soll_angelegt_werden(message: $message)}`

export const GET_LOCATIONS_COMPLETE = gql`query kursort($Id: String, $region: String){kursorte(Id: $Id, region: $region){Id,name,nameEn,vollstaendigeAdresse,region{Id,name,land{name}},googleMapsUrl}}`

export const LOGIN_MUTATION = gql`
mutation anmelden($benutzer: String!, $passwort: String!)
{
    anmelden(
        benutzer: $benutzer, passwort: $passwort
        )
    {success,jwt}
}`;

export const CREATE_TRAINER = gql`mutation trainerAnlegen($message:String!){Trainer_soll_aufgenommen_werden(message: $message)}`;

export const GET_SPRACHEN = gql`{sprachen{code,name}}`

export const GET_WAEHRUNGEN = gql`{waehrungen{code,symbol,name}}`

export const CREATE_KURSTERMIN = gql`mutation terminAnlegen($message: String!){Kurstermin_soll_geplant_werden(message: $message, type: "Kurstermin_soll_angelegt_werden")}`;

export const CREATE_REGION = gql`mutation regionAnlegen($message: String!){Region_soll_angelegt_werden(message: $message, type: "Region_soll_angelegt_werden")}`;

export const MUTATE_KURSORT_ZUORDNEN = gql`mutation mut($message: String!){Kursort_soll_zugeordnet_werden(message: $message, type: "Kursort_soll_zugeordnet_werden")}`;

export const MUTATE_FREITEXT_ZUORDNEN = gql`mutation mut($message: String!){Kurstermin_Freitext_soll_gesetzt_werden(message: $message, type: "Kurstermin_Freitext_soll_gesetzt_werden")}`;

export const MUTATE_MOODLEID_ZUORDNEN = gql`mutation mut($message: String!){Kurstermin_MoodleTermin_soll_zugeordnet_werden(message: $message, type: "Kurstermin_MoodleTermin_soll_zugeordnet_werden")}`;

export const MUTATE_MOODLEID_ENTFERNEN = gql`mutation mut($message: String!){Kurstermin_MoodleTermin_Zuordnung_soll_aufgehoben_werden(message: $message, type: "Kurstermin_MoodleTermin_Zuordnung_soll_aufgehoben_werden")}`;


export const MUTATE_TRAINER_ZUORDNEN = gql`mutation mut($message: String!){Kurstermin_Trainer_soll_zugeordnet_werden(message: $message, type: "Kurstermin_Trainer_soll_zugeordnet_werden")}`;
export const GET_KURSTERMIN_TRAINER = gql`query kurstermin($id: String){kurstermine(Id: $id){ trainer{ Id, nachname, vornamen }}}`;

export const MUTATE_PREIS_SETZEN = gql`mutation mut($message: String!){Kurstermin_Preis_soll_gesetzt_werden(message: $message, type: "Kurstermin_Preis_soll_gesetzt_werden")}`;

export const MUTATE_FRUEHBUCHER_PREIS_SETZEN = gql`mutation mut($message: String!){Kurstermin_Fruehbucher_Preis_soll_gesetzt_werden(message: $message, type: "Kurstermin_Fruehbucher_Preis_soll_gesetzt_werden")}`;

export const MUTATE_TEILNAHMEZAHL_BEGRENZEN = gql`mutation mut($message: String!){Kurstermin_Teilnahmezahl_soll_begrenzt_werden(message: $message, type: "Kurstermin_Teilnahmezahl_soll_begrenzt_werden")}`;

export const MUTATE_TRAINER_ENTFERNEN = gql`mutation entfernen($message: String!){Kurstermin_Trainer_soll_entfernt_werden(message: $message, type: "Kurstermin_Trainer_soll_entfernt_werden")}`;
export const MUTATE_BEMERKUNG_ERSTELLEN = gql`mutation erstellen($message: String!){Kurstermin_Bemerkung_soll_erstellt_werden(message: $message, type: "Kurstermin_Bemerkung_soll_erstellt_werden")}`;
export const BUCHUNG_BEMERKUNG_ERSTELLEN = gql`mutation erstellen($message: String!){Kursbuchung_Bemerkung_soll_erstellt_werden(message: $message, type: "Kursbuchung_Bemerkung_soll_erstellt_werden")}`;
export const GET_KURSTERMIN_BEMERKUNGEN = gql`query kurstermin($id: String){kurstermine(Id: $id){ bemerkungen{
      Id, text, autor{name}, erstellt{humanReadable}, bearbeitet{humanReadable}
    } }}`;
export const GET_SMALLINVOICE_PRODUKTE = gql `{smallInvoiceProdukte{name, number}}`;
export const GET_KURSTERMIN_SMALLINVOICE_PRODUKTE = gql`query kurstermin($id: String){kurstermine(Id: $id){ Id, smallInvoiceProduktId, smallInvoiceProduktName}}`;

export const MUTATE_SMALLINVOICE_PRODUKTE_ZUORDNEN = gql`mutation mut($message: String!){Kurstermin_SmallInvoice_Produkt_soll_zugeordnet_werden(message: $message, type: "Kurstermin_SmallInvoice_Produkt_soll_zugeordnet_werden")}`;

export const MUTATE_KURSDATUM_HINZUFUEGEN = gql`mutation mut($message: String!){Kurstermin_Kurstag_soll_hinzugefuegt_werden(message: $message, type: "Kurstermin_Kurstag_soll_hinzugefuegt_werden")}`;
export const MUTATE_KURSDATUM_ENTFERNEN = gql`mutation mut($message: String!){Kurstermin_Kurstag_soll_entfernt_werden(message: $message, type: "Kurstermin_Kurstag_soll_entfernt_werden")}`;




export const LOAD_SMALL_INVOICE_TOKEN = gql`mutation smallInvoiceLogin {SmallInvoice_Login_Prozess_starten{token client_id}}`;
export const LOAD_ABANINJA_TOKEN = gql`mutation abaninjaLogin {Abaninja_Login_Prozess_starten{token client_id}}`;
export const GET_SMALL_INVOICE_STATUS = gql `query{ smallInvoiceLoginStatus }`;
export const GET_ABANINJA_STATUS = gql `query{ abaninjaLoginStatus }`;
export const GET_KONTAKTDETAILS = gql`query Kontakt($id: String!){kontakte(Id: $id){
    anrede{text,code},
    titel,
    vorname,
    nachname,
    firma,
    telefon,
    email,
    newsletter,
    buchungen { Id kurstermin{ Id kursCode } teilnehmerzahl },
    teilnahmen { Id kurstermin{ Id kursCode } teilnehmerzahl bucher { vorname nachname firma Id } },
    moeglicheDuplikate { Id vorname nachname email },
    activeCampaignId,
    Id
}}`;

export const CREATE_BUCHUNG = gql`mutation buchen($message: String!){Kurs_soll_gebucht_werden(message: $message, type: "Kurs_soll_gebucht_werden")}`;
export const GET_BUCHUNG_TEILNEHMER = gql`query buchungen($buchung: String){
  buchungen(buchungId: $buchung){
   teilnehmer{
          anrede{text,code},
          titel,
          vorname,
          nachname,
          email,
          storniert,
          bestaetigt,
          kontaktId
          
          }
}}`;



export const GET_BUCHUNGDETAILS = gql`query buchungen($buchung: String){
  buchungen(buchungId: $buchung){
      bucher{
          anrede{code,text},
          titel,
          vorname,
          nachname,
          email,
          telefon,
          Id
      },
      kurstermin{
      vat{satz},
       Id,
        preis{
        wert, waehrungTyp{code}
        }
        kursCode
      },
      verschoben{kursCode, Id},
      bucherFirma,
      storniert,
      zeitpunkt{humanReadable},
      bucherRechnungsAdresse{
          description,
          vorname,
          nachname
          abteilung,
          strasse,
          plz,
          ort,
          land{iso}
      },
      bestellNummer,
      ustId,
      affiliateInfo,
      buchungssprache {code,name},
      gutscheinCode,
      rechnungPerPost,
      preis{
          waehrung: waehrungTyp{code,name},
          wert,
          description},
      rechnungNummer,
      kommentar,
      teilnehmer{
          anrede{text,code},
          titel,
          vorname,
          nachname,
          email,
          storniert,
          bestaetigt,
          kontaktId,
          bemerkungenAnzahl,
          kontakt{moodleTeilnehmer},
          bemerkungen{
              Id,
              text,
              autor{name},
              erstellt{humanReadable,isoString}},
          Id
      },
      bemerkungen{
          Id,
          text,
          autor{name},
          erstellt{isoString, humanReadable}
      },
      Id
  }
}`;

export const NEWSLETTERZUSTIMMEN = gql`mutation ja($message: String!){Kontakt_wird_Newsletterempfang_zustimmen(message: $message, type: "Kontakt_wird_Newsletterempfang_zustimmen")}`;
export const NEWSLETTERWIEDERRUFEN = gql`mutation nein($message: String!){Kontakt_wird_Zustimmung_zum_Newsletterempfang_widerrufen(message: $message, type: "Kontakt_wird_Zustimmung_zum_Newsletterempfang_widerrufen")}`;
export const KONTAKTENTFERNEN = gql`mutation x($message: String!){Kontakt_ActiveCampaign_Id_soll_entfernt_werden(message: $message)}`;
export const KONTAKTSYNCHRONISIEREN = gql`mutation x($message: String!){Kontakt_ActiveCampaign_Sync_anfordern(message: $message)}`;
export const KONTAKTMOODLETNZUORDNEN = gql`mutation mut($message: String!){ Kontakt_Moodle_Teilnehmer_soll_zugeordnet_werden(message: $message, type: "Kontakt_Moodle_Teilnehmer_soll_zugeordnet_werden")}`;

export const PREIS_AENDERN = gql`mutation x($message: String!){Kursbuchung_Preis_soll_geaendert_werden(message: $message)}`;
export const REMOVE_TN = gql`mutation y($message: String!){Teilnehmer_soll_storniert_werden(message: $message)}`;
export const MAIL_SENDEN = gql`mutation sendmail($message: String!){Bestaetigungsemail_soll_versendet_werden(message: $message)}`;
export const ADD_TN = gql`mutation addtn($message: String!){Kursbuchung_Teilnehmer_soll_hinzugefuegt_werden(message: $message)}`;
export const EDIT_FIRMA = gql`mutation firma($message: String!){Buchung_Verwaltungskontakt_Firma_soll_hinterlegt_werden(message: $message, type: "Buchung_Verwaltungskontakt_Firma_soll_hinterlegt_werden")}`;
export const EDIT_EMAIL = gql`mutation firma($message: String!){Kontakt_Email_hinterlegen(message: $message, type: "Kontakt_Email_hinterlegen")}`;
export const EDIT_TELEFON = gql`mutation telefon($message: String!){Kontakt_Telefonnummer_hinterlegen(message: $message, type: "Kontakt_Telefonnummer_hinterlegen")}`;
export const EDIT_USTID = gql`mutation firma($message: String!){Kursbuchung_Ust_Id_hinterlegen(message: $message, type: "Kursbuchung_Ust_Id_hinterlegen")}`;
export const EDIT_NUMMER = gql`mutation firma($message: String!){Kursbuchung_Bestellnummer_hinterlegen(message: $message, type: "Kursbuchung_Bestellnummer_hinterlegen")}`;
export const EDIT_TEILNEHMER= gql`mutation firma($message: String!){Kontakt_Name_soll_korrigiert_werden(message: $message, type: "Kontakt_Name_soll_korrigiert_werden")}`;
export const GET_KURS = gql`{kurstermine{Id,kursCode}}`;
export const VERSCHIEBE_BUCHUNG = gql`mutation pushb($message: String!){Kursbuchung_soll_verschoben_werden(message: $message, type: "Kursbuchung_soll_verschoben_werden")}`;
export const RVERSAND = gql`mutation pushb($message: String!){Kursbuchung_Rechnungsanschrift_soll_geaendert_werden(message: $message, type: "Kursbuchung_Rechnungsanschrift_soll_geaendert_werden")}`;
export const CONFIRM_TEILNAHME = gql`mutation confirm($message: String!){Kursbuchung_Teilnahme_soll_bestaetigt_werden(message: $message, type: "Kursbuchung_Teilnahme_soll_bestaetigt_werden")}`;

export const GET_SI_KONTAKTE = gql`query{smallInvoiceKunden(limit:5){id,name,mainAddressId, contactId,street,city}}`;

export const MUTATE_SI_KONTAKT = gql`mutation confirm($message: String!){SmallInvoice_Kontakt_soll_angelegt_werden(message: $message, type: "SmallInvoice_Kontakt_soll_angelegt_werden")}`;
export const MUTATE_RECHNUNG = gql`mutation confirm($message: String!){SmallInvoice_Rechnung_soll_angelegt_werden(message: $message, type: "SmallInvoice_Rechnung_soll_angelegt_werden")}`;

export const GET_MOODLE_TEILNEHMER = gql`query moodleTeilnehmer($kuteKursCode : String, $id : Int, $moodleKursId : Int){moodleTeilnehmer(kuteKursCode: $kuteKursCode, id: $id, moodleKursId: $moodleKursId){Id, email, username}}`;

export const GET_EXTERNE_ANFORDERUNGEN = gql`query moodleExterneAnforderungen($kontaktID : String, $kursID : String){moodleExterneAnforderungen(kontaktID: $kontaktID, kursID: $kursID){kontaktID, kursID}}`;



export const GET_KONTAKTE_FOR_MOODLEUPDATE = gql`query{
  kontakte(limit:50000){
    Id
    email
    moodleTeilnehmer
  }}`;


export const GET_KURS_FOR_NEWSLETTER = gql`query Kurstermine($startDate: String){kurstypen{Id,kuerzel,name},kurstermine(after: $startDate){typ{Id, name},region{name},status{text},teilnehmerKreis,beginn{isoString},Id,kurstage{Id,offset},dauer,drupalUrl, sprache, preis{description}}}`;

export const MAILSENDEN = gql`mutation tragen($message: String!){Bestaetigungsemail_soll_versendet_werden(message: $message, type: "Bestaetigungsemail_soll_versendet_werden")}`;

export const CANCEL_BUCHUNG = gql`mutation tragen($message: String!){Buchung_soll_storniert_werden(message: $message, type: "Buchung_soll_storniert_werden")}`;

export const TRAGE_RECHNUNG = gql`mutation tragen($message: String!){Rechungsstellung_soll_eingetragen_werden(message: $message, type: "Rechungsstellung_soll_eingetragen_werden")}`;

export const GET_RECHNUNGSTERMIN = gql`query smallInvoiceRechnungKurstermin($Id: String!){kurstermine(Id: $Id){
            titel,
            titelEn,
            beginn{
                humanReadable,
                isoString},
            dauer,
            preis{
                wert,
                waehrung: waehrungTyp{
                    code,
                    name
                }
            },
            vat { satz, reverseCharge },
            smallInvoiceProduktId,
            abaninjaProduktId,
            typ{
                Id,
                name
            },
            region{
                Id,
                name,
                land {
                    iso
                }
            },
            sprache: spracheTyp {
                name,
                code
            },
            kursCode,
            Id
        }}`;



export const BUCHUNG_BEMERKUNGEN = gql`query buchungen($buchung: String){
  buchungen(buchungId: $buchung){
      bemerkungen{
          Id,
          text,
          autor{name},
          erstellt{isoString, humanReadable}
      }  
  }
}`;


export const GET_TN_ETIQUETTEN =   gql`query teilnehmerliste($Id: String!, $typ: String){kurstermine(Id: $Id){ kursCode, teilnehmer { teilnehmeretiketten } } }`;
export const GET_TN_LISTE =   gql`query teilnehmerliste($Id: String!, $typ: String){kurstermine(Id: $Id){ kursCode, teilnehmer { teilnehmerliste } } }`;
export const GET_MOODLE_KURSE = gql`query moodleKurse($kuteKursCode : String){moodleKurse(kuteKursCode: $kuteKursCode){Id, idnumber, shortname}}`;

export const CREATE_ABANINJA_ADDRESS = gql`mutation confirm($message: String!){Abaninja_Kontakt_soll_angelegt_werden(message: $message, type: "Abaninja_Kontakt_soll_angelegt_werden")}`;

export const GET_ABANINJA_ADDRESSES = gql`query{abaninjaKunden{uuid, name,addresses}}`;
export const GET_ABANINJA_PRODUKTE = gql `{abaninjaProdukte{name, number}}`;
export const GET_KURSTERMIN_ABANINJA_PRODUKTE = gql`query kurstermin($id: String){kurstermine(Id: $id){abaninjaProduktId, abaninjaProduktName}}`;
export const ABANINJA_PRODUKTE_ZUORDNEN = gql`mutation mut($message: String!){Kurstermin_Abaninja_Produkt_soll_zugeordnet_werden(message: $message, type: "Kurstermin_Abaninja_Produkt_soll_zugeordnet_werden")}`;
export const CREATE_ABANINJA_INVOICE = gql`mutation confirm($message: String!){Abaninja_Rechnung_soll_angelegt_werden(message: $message, type: "Abaninja_Rechnung_soll_angelegt_werden")}`;
export const CREATE_MAKE_MOODLEKURS_ANLEGEN = gql`mutation confirm($message: String!){Make_Kurstermin_soll_auf_Moodle_angelegt_werden(message: $message, type: "Make_Kurstermin_soll_auf_Moodle_angelegt_werden")}`;