import React, {useState} from "react"
import {EditorField2, InputField, Label, Loading, StatusButton, SubmitButton} from "./components"
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_KURSTERMIN, GET_ID, MUTATE_KURSDATUM_HINZUFUEGEN} from "./Queries";
import {
    Kurstermin_Kurstag_soll_hinzugefuegt_werden,
    Kurstermin_soll_geplant_werden
} from "./Daten/FlugplanMessages.gen";
import {useHistory} from "react-router-dom";
import {TeilnehmerKreis_Inhouse, TeilnehmerKreis_Oeffentlich} from "./Kursdetails/KursterminDetailHeader";
import {SingleDatePicker} from "react-dates";
import {KurstypAutoSuggestField, RegionAutosuggestField, SpracheAutoSuggestField} from "./AutoSuggestFields";
import {client} from "./index";
import {TeilnahmeModusAuswahl} from "./Fields/TeilnahmeModusAuswahl";

export const KursterminAnlegen2 = () => {

    let history = useHistory();


    const [date, setDate] = useState();
    const [dauer, setDauer] = useState(2);
    const [kursTyp, setKursTyp] = useState();
    const [teilnehmerKreis, setTeilnehmerKreis] = useState(TeilnehmerKreis_Oeffentlich);
    const [region, setRegion] = useState()
    const [sprache, setSprache] = useState({code: "DE", name: "Deutsch"});
    const [kurstageAnlegen, setKurstageAnlegen] = useState(true)
    const [kurstag, setKurstag] = useState({
        beginn: "09:00",
        ende:   "16:00"
    })

    const {loading} = useQuery(GET_ID, {
        fetchPolicy: "network-only",
        onCompleted: data => {
            if (data.id) {
                setKursId(data.id);
            }
        }
    });

    const [create,
        {loading: createLoading},
    ] = useMutation(CREATE_KURSTERMIN, {});


    const [kursId, setKursId] = useState();

    const [teilnahmeModus, setTeilnahmeModus] = useState("Online")

    const onSubmit = (e) => {
        e.preventDefault();
        if (allSet()) {
            let success = false;
            const kurstermin = new Kurstermin_soll_geplant_werden(
                date.format("YYYY-MM-DD"),
                dauer,
                kursTyp.Id,
                region.Id,
                sprache.code,
                teilnehmerKreis,
                kursId,
                teilnahmeModus
            );
            create({variables: {message: JSON.stringify(kurstermin)}}).then(r => {
                console.log(r.data);
                success = r.data.Kurstermin_soll_geplant_werden === "ok";
                if (success && kursId) {
                    //Kurstermine automatisch anlegen?
                    if (kurstageAnlegen) {
                        const mutations = []

                        for(let i=0; i<dauer; i++) {
                            const mutation = MUTATE_KURSDATUM_HINZUFUEGEN
                            const variables = { message: JSON.stringify(new Kurstermin_Kurstag_soll_hinzugefuegt_werden(kursId,i,kurstag.beginn, kurstag.ende))}
                            mutations.push(client.mutate({ mutation, variables }))

                        }
                        Promise.all(mutations).then(() => {
                            history.push("/kurstermine/" + kursId)
                        })
                    } else {
                        history.push("/kurstermine/" + kursId)
                    }


                }
            })
        }
    }

    const allSet = () => {
        return date && dauer && kursTyp && teilnehmerKreis && region && sprache;
    }

    const [focused, setFocused] = useState();

    if (loading || createLoading) return <Loading/>

    return <div className="kurstermin-anlegen-container">

        <h2 className="heading">Kurstermin anlegen</h2>
        <form onSubmit={(e) => onSubmit(e)} className="termineingabe">
            <div className="w-full flex justify-between border p-4">
                <div className="w-1/3">
                    <Label name="Kurstermin_Datum" title="Beginn (erster Kurstermin)"/><br/>

                    <SingleDatePicker
                        date={date}
                        onDateChange={(d) => setDate(d)}
                        focused={focused}
                        onFocusChange={({focused}) => setFocused(focused)}
                        isOutsideRange={() => false}
                        id="Kurstermin_Datum"
                        className="w-1/3"
                    />
                    <KurstypAutoSuggestField initialItem={kursTyp}
                                             setValueObject={(kurstyp => setKursTyp(kurstyp))}/>

                    <InputField name="dauer" type="number" min="1" max="100"
                                className="w-32"
                                title="Anzahl der Kurstage"
                                defaultValue={"2"}
                                value={dauer}
                                onChange={(e) => setDauer(e.target.valueAsNumber)}/>
                </div>
                    <div className={"w-1/3  inline-block"}>
                        <div className={"w-full inline-flex"}>
                        <label className={"w-64"}>Kurstage automatisch anlegen:</label>

                        <input readOnly={false}
                               id={"kurstagesetzen"}
                               type="checkbox"
                               checked={kurstageAnlegen}
                               onChange={() => setKurstageAnlegen(!kurstageAnlegen)}
                               onKeyUp={() => setKurstageAnlegen(!kurstageAnlegen)}
                               tabIndex={0} />
                        </div>
                        <div className={"w-full"}>
                        <EditorField2 title={"Beginn"} type={"time"}
                                      pattern="HH:mm"
                                      value={kurstag.beginn}
                                      onChange={(e) => setKurstag({...kurstag, beginn: e.target.value})}/>
                        <EditorField2 title={"Ende"} type={"time"}
                                      pattern="HH:mm"
                                      value={kurstag.ende}
                                      onChange={(e) => setKurstag({...kurstag, ende: e.target.value})}/>
                        </div>
                    </div>


                <div className="w-1/3 float-none justify-between">
                    <div className="block float-left w-5/6">
                        <Label name="teilnehmerKreis" title="Teilnehmerkreis"/><br/>
                        <StatusButton status={{text: TeilnehmerKreis_Oeffentlich}}
                                      active={teilnehmerKreis === TeilnehmerKreis_Oeffentlich}
                                      onClick={() => setTeilnehmerKreis(TeilnehmerKreis_Oeffentlich)} className="mr-2"/>
                        <StatusButton status={{text: TeilnehmerKreis_Inhouse}}
                                      active={teilnehmerKreis === TeilnehmerKreis_Inhouse}
                                      onClick={() => setTeilnehmerKreis(TeilnehmerKreis_Inhouse)}/>
                    </div>
                    <div className="block float-left w-5/6">
                        <RegionAutosuggestField initialItem={region}
                                                setValueObject={(region) => (setRegion(region))}/>
                        <TeilnahmeModusAuswahl value={teilnahmeModus} onChange={ (e) => setTeilnahmeModus(e?.target?.value)}/>
                        <SpracheAutoSuggestField initialItem={sprache}
                                                 setValueObject={(sprache) => (setSprache(sprache))}/>
                    </div>
                </div>
            </div>

            <SubmitButton disabled={!allSet()} title="Anlegen"/>

        </form>
    </div>

}