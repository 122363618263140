import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import React, {useEffect, useRef, useState} from "react"
import {useParams, useRouteMatch} from "react-router-dom"
import {HeadingLine, Loading, StatusButton} from "../components";
import {GET_COURSE_DETAILS, MUTATE_MOODLEID_ENTFERNEN, MUTATE_MOODLEID_ZUORDNEN} from "../Queries";
import {moodle_host, zapier_hook_register_moodle} from "..";
import {KursterminDetailHeader} from "./KursterminDetailHeader";
import {Preisinformationen} from "./Preisinformationen";

import {Freitext} from "./Freitext";
import {KursTrainer} from "./KursTrainer";
import {Teilnahmezahl} from "./Teilnahmezahl";
import {Bemerkung2} from "./Bemerkung2";
import Kursstatus from "./Kurstatus";
import Location from "./Location";
import {
    Kurstermin_MoodleTermin_soll_zugeordnet_werden,
    Kurstermin_MoodleTermin_Zuordnung_soll_aufgehoben_werden
} from "../Daten/FlugplanMessages.gen";
import {BuchungsListe} from "./BuchungsListe";
import {TeilnehmerListe} from "./TeilnehmerListe";
import {Hinweise} from "./Hinweise";
import gql from "graphql-tag";

export const KursterminDetails2 = () => {

    let {id} = useParams();
    let routeMatchBuchungen = useRouteMatch({
            path: "/kurstermine/:id/buchungen",
            exact: true,
            strict: true,
        }
    );
    let routeMatchTeilnehmer = useRouteMatch( {
        path: "/kurstermine/:id/teilnehmer",
        exact: true,
        strict: true,
    })

    const [kursTyp, setKursTyp] = useState({name: ""});
    const [kursCode, setKursCode] = useState();
    const [moodleTerminZuordnung, setMoodleTerminZuordnung] = useState();
    const scrollRef = useRef(null);


    //query kurstermin($id: String){kurstermine(Id: $id){
    const GET_MOODLE_KURSE = gql`query moodleKurse($kuteKursCode : String){moodleKurse(kuteKursCode: $kuteKursCode){Id, idnumber, shortname}}`
    const [getMoodleData, {loading: moodleLoading, data: moodleData}] = useLazyQuery(GET_MOODLE_KURSE)

    useEffect(() => {
        if (scrollRef.current != null && (routeMatchBuchungen != null || routeMatchTeilnehmer != null)) {
            scrollRef.current.scrollIntoView()

        }

    })

    /**
     *
     * @param data
     * @param data.kurstermine
     * @param data.kurstermine[].typ
     * @param data.kurstermine[].kursCode
     * @param data.kurstermine[].kursTyp
     * @param data.kurstermine[].kursTyp.name
     * @param data.kurstermine[].beginn
     * @param data.kurstermine[].beginn.isoString
     * @param data.kurstermine[].dauer
     * @param data.kurstermine[].teilnehmerKreis
     * @param data.kurstermine[].region
     * @param data.kurstermine[].sprache
     * @param data.kurstermine[].maxTeilnahmeZahl
     * @param data.kurstermine[].wenigeRestplaetzeAb
     * @param data.kurstermine[].status
     * @param data.kurstermine[].name
     * @param data.kurstermine[].drupalUrl
     * @param data.kurstermine[].fruehbucherPreis
     * @param data.kurstermine[].moodleTermin
     *
     */
    const {loading, error, data, refetch} = useQuery(GET_COURSE_DETAILS, {
        variables: {id}, fetchPolicy: "network-only",
        onCompleted: (data) => updateData(data)})


    function updateData(data) {
        setKursTyp(data?.kurstermine[0]?.typ);
        setKursCode(data?.kurstermine[0]?.kursCode);
        setMoodleTerminZuordnung(data?.kurstermine[0]?.moodleTermin)
        getMoodleData( {variables: {kuteKursCode : data?.kurstermine[0]?.kursCode}})
    }



    const [teilnehmerListe, setTeilnehmerListe] = useState(routeMatchTeilnehmer != null);
    const [buchungsListe, setBuchungsListe] = useState(routeMatchBuchungen != null);


    const [detailHeaderVisible, setDetailHeaderVisible] = useState(false);



    const [setzeMoodleZuordnung,
        {},
    ] = useMutation(MUTATE_MOODLEID_ZUORDNEN, {
        onCompleted: (data) => {
            if (data[Kurstermin_MoodleTermin_soll_zugeordnet_werden.messageName] === "ok") {
                refetch({id}).then(r => {
                    setMoodleTerminZuordnung(r?.data?.kurstermine[0]?.moodleTermin)
                })
            }
        }
    });

    const [entferneMoodleZuordnung,
        {},
    ] = useMutation(MUTATE_MOODLEID_ENTFERNEN, {
        onCompleted: (data) => {
            if (data[Kurstermin_MoodleTermin_Zuordnung_soll_aufgehoben_werden.messageName] === "ok") {
                setMoodleTerminZuordnung(null)
            }
        }
    });




    if (loading || moodleLoading) return <Loading/>;
    if (error) return `Error! ${error.message}`;


    function onClickMoodleZuordnung() {

        if (moodleData) {
            if (moodleTerminZuordnung) {
                const message = new Kurstermin_MoodleTermin_Zuordnung_soll_aufgehoben_werden(id)
                entferneMoodleZuordnung({variables: {message: JSON.stringify(message)}}).then(r => {
                    console.log(r.data)
                })
            } else {
                const message = new Kurstermin_MoodleTermin_soll_zugeordnet_werden(id, moodleCourseId?.toString())
                setzeMoodleZuordnung({variables: {message: JSON.stringify(message)}}).then(r => {
                    console.log(r.data)
                })
            }
        } else {
            alert("Kurs in Moodle nicht gefunden!")
        }

    }

    const moodleCourseId = moodleData && (moodleData.moodleKurse.length === 1) ? moodleData.moodleKurse[0].Id : undefined

    function ToggleHeaderButton() {

        return <StatusButton status={{text: "📆 Termindetails"}} active={detailHeaderVisible} onClick={() => setDetailHeaderVisible(!detailHeaderVisible)}/>
    }






    return <div className="lg:max-w-full border">
        <HeadingLine title={kursCode} icon="📆" links={[]} />
        <div className={"w-full bg-gray-500 inline-block p-2"}>
            <h3>{kursTyp?.name}{ToggleHeaderButton()}</h3>
            <div>
                {data?.kurstermine[0]?.drupalUrl &&
                <a href={data?.kurstermine[0]?.drupalUrl}
                   target="_blank"
                   rel="noopener,noreferrer"
                   className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">Auf DasScrumTeam.com
                    anzeigen</a>}</div>
            <KursterminDetailHeader id={id} onCallback={() => {
                setTimeout(() => {
                    refetch({id}).then((r) =>
                        updateData(r?.data))
                },250)


            }} showFull={detailHeaderVisible} />
            <Kursstatus id={id}/>

            <div className="termindetail-details w-full border p-2">

                <Teilnahmezahl id={id}/>
                <Preisinformationen id={id}/>

                <Location id={id} />
                <KursTrainer id={id} />
            </div>
        </div>

            <div className={"w-full md:pt-6"}>

                <Freitext id={id} />
                <Bemerkung2 id={id} />
        </div>

        <div className={"w-full bg-white"}>

        <div>

            <div><br/>{moodleCourseId &&
            <a href={`https://${moodle_host}/course/view.php?id=${moodleData?.moodleKurse[0]?.Id}`}
               target="_blank"
               rel="noopener,noreferrer"
               className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">Zum Kurs in Moodle</a>}<br/><br/>
                {  !moodleCourseId &&
                    <a href={`https://${moodle_host}/`}
                                       target="_blank"
                                       rel="noopener,noreferrer"
                                       className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">
                Zu Moodle</a>}

                <StatusButton status={{text: "🗓 = Ⓜ️"}}
                              active={moodleTerminZuordnung && moodleTerminZuordnung !== ""}
                              disabled={!moodleCourseId}
                              onClick={() => onClickMoodleZuordnung()}
                              className="mr-2"/>
            </div>
            <div ref={scrollRef} className={"border border-dst-grau-1"}>
                <StatusButton status={{text: "👩‍🎓 " + data.kurstermine[0]?.buchungen?.count  +" Buchungen"}} active={buchungsListe} onClick={() => setBuchungsListe(!buchungsListe) }/>

            {
                buchungsListe && <BuchungsListe kursId={id}/>
            }
            </div>
            <div className={"border border-dst-grau-1"}>
                <StatusButton status={{text: "👩‍🎓 " + data.kurstermine[0]?.teilnehmer?.count  +" Teilnehmende"}} active={teilnehmerListe} onClick={() => setTeilnehmerListe(!teilnehmerListe)}/>
            {
                teilnehmerListe &&
                <TeilnehmerListe
                    kursId={id}
                    kursCode={kursCode}
                    kursTyp={kursTyp}
                    moodleHost={moodle_host}
                    moodleCourseId={moodleCourseId}
                    zapierHook={zapier_hook_register_moodle}
                    trainer={data?.kurstermine[0]?.trainer}
                    dauer={data?.kurstermine[0]?.dauer}
                    beginn={data?.kurstermine[0]?.beginn}
                    kontaktStunden={data?.kurstermine[0]?.kontaktstunden}
                    zeiten={data?.kurstermine[0]?.kurstage}
                    location={data?.kurstermine[0]?.location}
                    region={data?.kurstermine[0]?.region?.name}
                />
            }
            </div>


        </div>
        <div className={"w-full"}>
            {!moodleCourseId && <Hinweise kursTyp={kursTyp ? kursTyp.name : ""} kursCode={kursCode}/>}
    </div>
        </div>

</div>

}









