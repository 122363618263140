import {ModalContainer2} from "../UI/ModalContainer";
import {
    Kursbuchung_soll_verschoben_werden,
    Kursbuchung_Ust_Id_hinterlegen
} from "../Daten/CheckinMessages.gen";
import * as React from "react";
import {KursAutosuggestField} from "../AutoSuggestFields";
import {useState} from "react";
import {useMutation} from "@apollo/client";
import {VERSCHIEBE_BUCHUNG} from "../Queries";
import {Loading, SubmitButton} from "../components";

export const BuchungVerschieben2 = ({buchungId, kursId}) =>{
    const [kurs, setKurs] = useState();
    const [showModal, setModal] = useState(false);
    const [bestaetigungsmail, setBestaetigungsmail] = useState(false);
    const [preisuebernehmen, setPreisBerechnen] = useState(false);
    const [done, setDone] = useState(false);


    const saveBuchung =(e) =>{
       e.preventDefault()
            const message = new Kursbuchung_soll_verschoben_werden(buchungId, kursId, kurs?.Id, bestaetigungsmail, preisuebernehmen)
            schiebebuchung({variables: {message: JSON.stringify(message)}}).then(r => {
                if(r.data.Kursbuchung_soll_verschoben_werden === "ok")
                setDone(true)
            })
    }

    const onCloseModal = () =>{
        setModal(false)
    }

    const onCloseDone = () =>{
        setDone(false)
    }

    const  [schiebebuchung,
        {loading: loading, error: error},
    ] = useMutation(VERSCHIEBE_BUCHUNG, {
        onCompleted: (data) => {
            setModal(false)
            if (data[Kursbuchung_Ust_Id_hinterlegen] === "ok") {

            }
        }
    });

    if(error){
        alert("Die Buchung konnte nicht verschoben werden")
        setModal(false)
    }
    if(loading) return <Loading />

    return <div>
    <button
        className={"btn btn-gruen"}
        onClick={() => setModal(true)}>Verschieben</button> &nbsp;

        { showModal && <ModalContainer2 onClose={onCloseModal}  title="Buchung verschieben">
        <form onSubmit={(e) =>saveBuchung(e)}>
            <KursAutosuggestField setValueObject={(suggestion) => setKurs(suggestion)} startDate={new Date()}/>  <p> &nbsp;</p>
            <p> &nbsp;</p>
           <input name="bestaetigungsmail" type={"checkbox"} checked={bestaetigungsmail} onChange={(e)=>{ if(e.target.checked)setBestaetigungsmail(true)}}/><label> Bestätigungsmail senden</label>
            &nbsp;
           <input name="preisuebernehmen" type={"checkbox"} checked={preisuebernehmen} onChange={(e)=>{ if(e.target.checked)setPreisBerechnen(true)}} /><label> Preis übernehmen</label> <br />
            <p> &nbsp;</p>
            <SubmitButton  title={"Verschieben"} />  &nbsp;
            <button className={"btn btn-rot"} onClick={onCloseModal}>Abbrechen</button>
        </form>
    </ModalContainer2>
        }
        {done && <div className={"w-1/4 h-4"}> <ModalContainer2 onClose={onCloseDone}title="Verschiebungsbestätigung">
            <span> Die Buchung wurde erfolgreich nach {kurs?.kursCode} verschoben</span> <p> &nbsp;</p>
            <button className={"btn btn-rot"} onClick={onCloseDone}>OK</button>
        </ModalContainer2> </div>}
    </div>
}