import * as React from "react"
import { graphql } from "@apollo/client/react/hoc"
import gql from "graphql-tag";
import { reisebuero_version } from "./version"
import { Alert, BlueAlert } from "./UI/Alert"
import { useQuery } from "@apollo/client";
import { kute_http_url, moodle_host } from ".";

const _VersionCheck = ({ data }) => {
    if (!data.loading) {
        if (data.version) {
            let min = "v2.0"
            let max = "v10.0"
            let v_1 = version_compare(min, data.version.kute)
            let v_2 = version_compare(max, data.version.kute)
            if (v_1 > 0 || v_2 < 1) {
                return <Alert title="Versions-Differenz">
                    <div>
                        Server-Version ist <code>{data.version.kute}</code>, aber erwartet ist eine Version
                        zwischen <code>{min}</code> und <code>{max}</code>!
                    </div>
                </Alert>
            }
        }
    }
    return null
}

export const VersionCheck = graphql(gql`{version{kute}}`, {
    options: {
        fetchPolicy: "network-only",
    },
})(_VersionCheck);


const ServerVersionInfo = () => {
    const { data, loading } = useQuery(gql`{version{kute, kute_fachlichkeit}}`);
    if (!loading && data) {
        return <React.Fragment>
            <span className="w-full inline-block">API: <code>{data.version.kute}</code></span>
            <span className="w-full inline-block">Events: <code>{data.version.kute_fachlichkeit}</code></span>
        </React.Fragment>
    }
    return null;
}

export const VersionInfo = () => {
    const { data, loading, client } = useQuery(gql`{berechtigungen}`);
    return (
        <BlueAlert title="Versionsinfo">
            <span className="w-full inline-block">reisebüro: <code>{reisebuero_version}</code></span>
            <span className="w-full inline-block">WS-Connection: <code>{client.link.subscriptionClient.url}</code></span>
            <span className="w.full inline-block">moodle: <code>{moodle_host}</code></span>
            {data && !loading && <ServerVersionInfo />}
        </BlueAlert>
    )
}

export const TestVersionCheck = () => {
    const regex = /^v?[0-9]+\.[0-9]+(\.[0-9]+)?$/gm
    if (!regex.test(reisebuero_version)) {
        return <Alert title="Testversion">
            <div>
                Bei dieser Reisebüro-Instanz handelt es sich um eine Testversion (<code>{reisebuero_version}</code>).
            </div>
        </Alert>
    }
    return null
}

/**
 * Vergleicht zwei Versionen (Semantic Versioning).
 * @param v1 string
 * @param v2 string
 * @return int 1, wenn v1 > v2
 * @return int 0, wenn v1 === v2
 * @return int -1, wenn v1 < v2
 */
const version_compare = (v1, v2) => {
    if (v1 === "dev") {
        return 1
    }
    if (v2 === "dev") {
        return -1
    }
    if (v1 === v2) {
        return 0
    }
    const regex = /([0-9]+\.[0-9]+)(\.[0-9]+)?$/g
    if (!regex.test(v1)) {
        return 1
    }
    regex.lastIndex = 0
    if (!regex.test(v2)) {
        return -1
    }
    regex.lastIndex = 0
    let v1_match = regex.exec(v1)
    regex.lastIndex = 0
    let v2_match = regex.exec(v2)
    if (typeof v1_match[1] === 'undefined') {
        return -1
    }
    if (typeof v2_match[1] === 'undefined') {
        return 1
    }
    let major_minor_v1 = v1_match[1], major_minor_v2 = v2_match[1]
    if (major_minor_v1 === major_minor_v2) {
        return 0
    }
    let v1_vers = major_minor_v1.split('.'), v2_vers = major_minor_v2.split('.')
    v2_vers = v2_vers.map(item => parseInt(item, 10))
    v1_vers = v1_vers.map(item => parseInt(item, 10))
    if (v1_vers[0] > v2_vers[0]) {
        return 1
    }
    if (v1_vers[0] < v2_vers[0]) {
        return -1
    }
    if (v1_vers[1] > v2_vers[1]) {
        return 1
    }
    if (v1_vers[1] < v2_vers[1]) {
        return -1
    }
    return 0
}