import {useMutation} from "@apollo/client";
import {CREATE_MAKE_MOODLEKURS_ANLEGEN} from "../Queries";
import {Make_Kurstermin_soll_auf_Moodle_angelegt_werden} from "../Daten/MakeMessages.gen";

export const MoodleKursAnlegen = ({id}) => {
    const [legeKurs,
        {loading, error, data}
    ] = useMutation(CREATE_MAKE_MOODLEKURS_ANLEGEN, {
        onCompleted: (data) => {
            console.log(data)
        }
    })

    const legeMoodleKursAn = () => {
        const message = new Make_Kurstermin_soll_auf_Moodle_angelegt_werden(id)
        legeKurs({variables: {message: JSON.stringify(message)}}).then(r => console.log("result: "+r))
    }

    return <button className={"lg:hover:bg-dst-grau-1 text-dst-logo-gruen-hinten"} onClick={legeMoodleKursAn}>✳︎</button>

}