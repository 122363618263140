import * as React from "react"
import { ErrorMessage, Loading, SubmitButton } from "../components"
import { useMutation } from "@apollo/client";
import { LOGIN_MUTATION } from "../Queries";

export const Login = ({ callback }) => {
    let username, password;

    // docs for useMutation: https://www.apollographql.com/docs/react/data/mutations/#usemutation-api
    const [
        login,
        { loading: mutationLoading, error: mutationError, data: mutationData },
    ] = useMutation(LOGIN_MUTATION, {
        onCompleted: (mutationData) => {
            if (mutationData.anmelden.jwt) {
                localStorage.setItem('Kute::LoginToken', mutationData.anmelden.jwt)
            }
            callback();
        }
    });

    const onSubmit = e => {
        e.preventDefault();
        login({
            variables: {
                benutzer: username.value,
                passwort: password.value
            }
        });
    }

    return (
        <div className="w-1/4 my-4 mx-auto login-container">
            {mutationLoading && <Loading />}
            {mutationData && !mutationData.anmelden.success && <ErrorMessage customText="Da hat sich wohl der Fehlerteufel eingeschlichen." emoji="😈" />}
            {mutationError && <ErrorMessage customText="Ein Fehler bei der Verbindung zum Server ist aufgetreten." />}
            <form onSubmit={onSubmit}
                className="loginForm">
                <input className="leading-normal border w-full" type="text" ref={node => { username = node }}
                    name="username" autoFocus={true} placeholder="Benutzername" /><br />
                <input className="leading-normal border w-full mt-2" type="password" ref={node => { password = node }}
                    name="password" id="password" placeholder="Passwort" /><br />
                <SubmitButton title="Anmelden" full={true} />
            </form>
        </div >
    )
}

