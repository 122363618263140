import React, {useState} from "react"
import {useQuery} from "@apollo/client"
import {GET_COURSES_FOR_OVERVIEW} from "./Queries"
import {DataLine, HeadingLine, Loading, StatusButtonLeiste2} from "./components"

import 'moment/locale/de'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import {DateRangePicker} from 'react-dates'
import moment from "moment"
import {TrainerAutosuggestField} from "./AutoSuggestFields"

function ZeitraumAuswahl({setzeZeitraum}) {
    return <div>
        <select className={""} onChange={(e) => setzeZeitraum && setzeZeitraum(e)}>
            <option value={"frei"}>frei</option>
            <option value={"quartal"}>Quartal</option>
            <option value={"aktuell"}>Aktuelles Jahr</option>
            <option value={"letztes"}>Letztes Jahr</option>
        </select>



    </div>
}

export const Statistics = () => {
    const [startDate, setStartDate] = useState(moment().weekday(0));
    const [endDate, setEndDate] = useState();

    const [statusFilter, setStatusFilter] = useState(["Veröffentlicht", "Wenige Restplätze", "Ausverkauft", "Wird durchgeführt", "Durchgeführt", "Abgeschlossen"]);
    const onStatusButtonToggle = (buttonName) => {
        if (statusFilter.includes(buttonName)) {
            setStatusFilter(statusFilter.filter(status => status !== buttonName))
        } else {
            setStatusFilter(statusFilter.concat(buttonName))
        }
    }

    // get data from API
    const {loading, data} = useQuery(GET_COURSES_FOR_OVERVIEW, {
            variables: {
                after: startDate ? startDate.format("YYYY-MM-DD") : new Date(),
                before: endDate ? endDate.format("YYYY-MM-DD") : '2022-12-31',
            }
        }
    );

    const [countInhouse, setCountInhouse] = useState(false);
    const [countInhouseEvenIfScheduled, setCountInhouseEvenIfScheduled] = useState(false);

    const onDatesChange = ({startDate, endDate}) => {
        setStartDate(startDate);
        setEndDate(endDate)
    }
    const [focusedInput, setFocusedInput] = useState(null);
    const onFocusChange = newFocusedInput => setFocusedInput(newFocusedInput);

    // action button
    const links = [
        /*{ label: "Neuer Kursort", func: () => { setCurrentLocation(); setLocationEditorVisible(true) } }, {
        label: "Kursübersicht", link: "/"}*/
    ]

    const filter = (course) => {
        for (const filterTrainer of course.trainer) {
            return (
                (!trainer || (trainer && filterTrainer.Id === trainer.Id))
                // check course status 
                && ((!course.oeffentlich && statusFilter.includes(course.status.text) && countInhouse) ||
                (course.oeffentlich && statusFilter.includes(course.status.text)) ||
                (countInhouseEvenIfScheduled && !course.offentlich && course.status.text === "Geplant")))
        }
    }

    const statsData = () => {
        const statsByCourseTrainer = {}
        if (data && data.kurstermine) {
            data.kurstermine.filter(filter)
                .forEach(kurstermin => {
                    const courseType = kurstermin.typ.kuerzel;
                    kurstermin.trainer.map(trainer => trainer.text)
                        .forEach(trainerName => {
                            if (Object.keys(statsByCourseTrainer).includes(trainerName)) {
                                if (Object.keys(statsByCourseTrainer[trainerName]).includes(courseType)) {
                                    statsByCourseTrainer[trainerName][courseType].participantCount += kurstermin.teilnehmer.count;
                                    statsByCourseTrainer[trainerName][courseType].coursetypeCount++
                                } else {
                                    statsByCourseTrainer[trainerName][courseType] = {
                                        participantCount: kurstermin.teilnehmer.count,
                                        coursetypeCount: 1
                                    }
                                }
                            } else {
                                statsByCourseTrainer[trainerName] = {
                                    [courseType]: {
                                        participantCount: kurstermin.teilnehmer.count,
                                        coursetypeCount: 1
                                    }
                                };
                            }
                        })
                });
        }
        return Object.keys(statsByCourseTrainer).map(
            trainerName => {
                return (<ul key={trainerName}><h3>Statistiken für {trainerName} -
                    Trainings: {Object.keys(statsByCourseTrainer[trainerName]).reduce(
                        (cnt, type) => {
                            if (typeof cnt !== "number") {
                                cnt = statsByCourseTrainer[trainerName][cnt].coursetypeCount
                            }
                            return (cnt + statsByCourseTrainer[trainerName][type].coursetypeCount)
                        }, 0
                    )
                    }</h3>
                    {Object.keys(statsByCourseTrainer[trainerName]).map(
                        type => <li key={trainerName + type}>{type}:
                            Kurse: {statsByCourseTrainer[trainerName][type].coursetypeCount} Teilnehmer: {statsByCourseTrainer[trainerName][type].participantCount}</li>
                    )}
                </ul>)
            }
        )
    }

    const [trainer, setTrainer] = useState();

    function setzeZeitraumFuerAuswahl(e) {
        switch (e.target.value) {
            case "quartal":
                setStartDate(moment("2021-10-01", "YYY-MM-DD"))
                setEndDate(moment('2022-03-31', 'YYYY-MM-DD'))
                break

            case "aktuell":
                setStartDate (moment('2021-01-01', 'YYYY-MM-DD'))
                setEndDate(moment('2021-12-31', 'YYYY-MM-DD') )

                break
            case "frei":
                setStartDate (moment('2022-01-01', 'YYYY-MM-DD'))
                setEndDate(moment('2022-12-31', 'YYYY-MM-DD') )
                break;
            default:
                break;

        }

    }

    return (
        <div>
            <HeadingLine title="Statistiken" icon="🧮" links={links}/>
            <DateRangePicker
                // ids are picked at random
                startDateId="15ecf1c2a0912d3242b7a09ff27383e1"
                endDateId="65d184f81029cca20dfe7334ca5c6901"
                startDate={startDate}
                endDate={endDate}
                onDatesChange={onDatesChange}
                onFocusChange={onFocusChange}
                focusedInput={focusedInput}
                showClearDates={true} showDefaultInputIcon={true} block={true}
                numberOfMonths={3} isOutsideRange={() => false}
            />
            <ZeitraumAuswahl setzeZeitraum={(e) => setzeZeitraumFuerAuswahl(e)}/>
            <StatusButtonLeiste2
                active={statusFilter}
                onStatusButtonToggle={onStatusButtonToggle}
            />
            <TrainerAutosuggestField
                setValueObject={suggestion => setTrainer(suggestion)}
            />
            <div>
                <form>
                    <input id="countInhouse" type="checkbox" checked={countInhouse} onChange={e => {
                        setCountInhouse(e.target.checked);
                        setCountInhouseEvenIfScheduled(false)
                    }}/>
                    <label for="countInhouse">Inhouse-Kurse und Teilnehmer mitzählen</label>
                    <input id="countInhouseEvenIfScheduled" type="checkbox" enabled={countInhouse}
                           checked={countInhouse && countInhouseEvenIfScheduled}
                           onChange={e => setCountInhouseEvenIfScheduled(e.target.checked)}/>
                    <label for="countInhouseEvenIfScheduled">Inhouse-Kurse und Teilnehmer mitzählen, die als "geplant"
                        gekennzeichnet sind</label>
                </form>
            </div>
            {loading && <Loading/>}
            {data && <div>
                {statsData()}
                <table style={{width: "100%"}}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Region</th>
                        <th>Land</th>
                    </tr>
                    </thead>
                    <tbody>{data && !loading && data.kurstermine && data.kurstermine
                        .filter(filter)
                        .map((kurstermin, index) => <DataLine key={kurstermin.Id} id={kurstermin.Id} index={index}
                                                              rowData={[
                                                                  {text: kurstermin.titel},
                                                                  {text: kurstermin.region.name},
                                                                  {text: kurstermin.region.land.name},
                                                                  /*{ text: "Bearbeiten", func: () => { setCurrentLocation(kursort); } }*/
                                                              ]}/>)}
                    </tbody>
                </table>
            </div>}
        </div>
    )
}

