import React, { useState, useEffect } from "react"
import { notImplemented, sortByName } from "./Infrastruktur/Helpers"
import { KursortAnlegen2 } from "./KursortAnlegen2"
import { useQuery } from "@apollo/client"
import { GET_LOCATIONS } from "./Queries"
import { DataLine, FilterField2, HeadingLine, Loading } from "./components"
import { KursortBearbeiten } from "./KursortBearbeiten"

export const KursortListe2 = () => {
    // get data from API
    const { loading, data, refetch } = useQuery(GET_LOCATIONS, { pollInterval: 120000 });

    // visibility for editor
    const [locationEditorVisible, setLocationEditorVisible] = useState(false);

    // location for editor
    const [currentLocation, setCurrentLocation] = useState()
    const closeEditor = () => { setLocationEditorVisible(false); setCurrentLocation(); refetch() };

    // refetch if editor is opened or closed
    useEffect(() => {
        refetch();
    }, [locationEditorVisible])

    // search field
    const [filterString, setFilterString] = useState();

    // filter for search
    const filter = kursort => {
        if (!filterString) { return true }
        const lowerQuery = filterString.toLowerCase();
        return kursort.name.toLowerCase().indexOf(lowerQuery) > -1 ||
            kursort.region.land.name.toLowerCase().indexOf(lowerQuery) > -1 ||
            kursort.region.name.toLowerCase().indexOf(lowerQuery) > -1
    }

    console.log(process.env)

    const createLocation = () => {
     
        setCurrentLocation(undefined); 
        setLocationEditorVisible(true) 

    }

    const editLocation = () => {
        setLocationEditorVisible(true)
    }

    // action button
    const links = [
        { label: "Neuer Kursort", func: () => { createLocation() } }
    ]

    const anotherlinks = [
        { label: "Was ist?", func: () => { alert(currentLocation.Id) } }
    ]

    return (
        <div>
            <HeadingLine title="Kursorte" icon="📍" links={links} />
            {locationEditorVisible && <KursortAnlegen2 kursortId={currentLocation ? currentLocation.Id : undefined} closeHandler={closeEditor} />}
            {loading && <Loading />}
            {data && <div>
                <FilterField2 filterString={filterString} handleFilterChange={setFilterString} />
                <table style={{ width: "100%" }}>
                    <thead><tr><th>Name</th><th>Region</th><th>Land</th><th>Aktionen</th></tr></thead>
                    <tbody>{data && !loading && data.kursorte
                        .filter(filter)
                        .sort(sortByName)
                        .map((kursort, index) => <DataLine key={kursort.Id} id={kursort.Id} index={index} rowData={[
                            { text: kursort.name },
                            { text: kursort.region.name },
                            { text: kursort.region.land.name },
                            { text: "Bearbeiten", func: () => { setCurrentLocation(kursort); editLocation()} }
                        ]} />)}
                    </tbody>
                </table></div>}
        </div>
    )
}

