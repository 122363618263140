import * as React from "react"
import { Loading, Vier04 } from "./components"
import { Route, Switch } from "react-router-dom";
import { KursterminAnlegen2 } from "./KursterminAnlegen2"
import { Navigation } from "./Navigation"
import { KursortListe2 } from "./KursortListe"
import { KursterminDetails2 } from "./Kursdetails/KursterminDetails2"
import { LogViewer2 } from "./LogViewer"
import { TrainerListe2 } from "./Trainer/TrainerListe";
import { EventLogViewer } from "./Admin/EventLogViewer";
import { TestVersionCheck, VersionCheck } from "./VersionCheck"
import { AlertContainer } from "./UI/Alert"
import { KontaktListe2 } from "./KontaktListe"
import { Login } from "./UI/Login"
import { UpdateCheck } from "./UpdateCheck"
import { RegionListe2 } from "./RegionListe"
import { RegionAnlegen2 } from "./RegionAnlegen2"
import { KursterminListe2 } from "./KursterminListe2";
import { useQuery } from "@apollo/client";
import { GET_LOGIN_STATUS } from "./Queries";
import { UserListe } from "./Admin/UserListe";
import { Statistics } from "./Statistics";
import {BuchungAnlegen2} from "./Buchung/BuchungAnlegen2";
import {Kontaktdetails2} from "./Kontaktdetails/Kontaktdetails2";
import {BuchungDetails2} from "./Buchung/BuchungDetails2";
import {Konfiguration} from "./Admin/Konfiguration"
import {BuchungsListe} from "./Kursdetails/BuchungsListe";

export const MainRouter = () => {
    const pollInterval = 2000;
    const { data, loading, refetch } = useQuery(GET_LOGIN_STATUS, { pollInterval, fetchPolicy: "no-cache" });
    {
        if (!loading && data.loginStatus && !!data.loginStatus) {
            return (
                <div className="app-container">
                    <Navigation />
                    <main className="ml-2 mr-2 pb-8">
                        <Switch>
                            <Route exact path="/" component={KursterminListe2} />
                            <Route path="/kurstermine/neu" component={KursterminAnlegen2} />
                            <Route path="/kurstermine/:id/buchung/neu" component={BuchungAnlegen2} />
                            <Route path="/kurstermine/:id/buchung/:id" component={BuchungDetails2} />
                            <Route path="/buchungen/" component={BuchungsListe} />
                            <Route path="/kurstermine/:id/:buchungen(buchungen)?/:teilnehmer(teilnehmer)?" component={KursterminDetails2} />
                            <Route path="/trainer" component={TrainerListe2} />
                            <Route path="/kursorte/:id" component={KursortListe2} />
                            <Route path="/kursorte" component={KursortListe2} />

                            <Route path="/regionen/neu" component={RegionAnlegen2} />
                            <Route path="/regionen" component={RegionListe2} />
                            <Route path="/log" component={LogViewer2} />
                            <Route path="/admin" component={EventLogViewer} />
                            <Route path="/user" component={UserListe} />
                            <Route path="/kontakte/:id/" component={Kontaktdetails2} />
                            <Route path="/kontakte" component={KontaktListe2} />
                            <Route path="/statistics" component={Statistics} />
                            <Route path="/kurstermine/:id" component={KursterminDetails2} />
                            <Route path="/kurstermine" component={KursterminListe2} />
                            <Route path="/konfiguration" component={Konfiguration} />

                            <Route component={Vier04} />
                        </Switch>
                    </main>
                    <AlertContainer>
                        <TestVersionCheck />
                        <VersionCheck />
                        <UpdateCheck />
                    </AlertContainer>
                    <div className={"h-32"}><hr /></div>
                </div>
            );
        } else if (data && 'loginStatus' in data) {
            return <Login callback={() => { refetch(); }} />
        } else {
            return <Loading />
        }
    }
}
