import React from "react";

export const Hinweise = ({kursTyp, kursCode}) => {


    return <div>
        <hr/>
        <h4>HINWEISE</h4>
        <ol>
            <li>Der Kurs muss in Moodle angelegt sein und die <em>Kurs-ID</em> muss mit
                dem <em>Kurs-Code</em> übereinstimmen.
            </li>
            <li>Die Teilnehmer-Emailadressen müssen im Reisebüro und Moodle identisch sein.</li>
            <li>Jeder Teilnehmer wird in drei globale Gruppen eingeschrieben:
                <ul>
                    <li>Globale Gruppe SCRUM</li>
                    <li>Globale Gruppe Kurstyp: {kursTyp}</li>
                    <li>Globale Gruppe Kurs: {kursCode}</li>
                </ul>
            </li>

        </ol>

    </div>

}

