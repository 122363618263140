import {InputField, InputFieldWithClassName, Label, Loading, StatusButton} from "../components";

import {SingleDatePicker} from "react-dates";
import {KurstypAutoSuggestField, RegionAutosuggestField, SpracheAutoSuggestField,} from "../AutoSuggestFields";
import React, {useState} from "react";
import PropTypes from "prop-types";
import Kursdaten from "./Kursdaten";
import {useQuery} from "@apollo/client";
import {GET_COURSE_DETAILS} from "../Queries";
import moment from "moment";
import {
    Kurstermin_Datum_soll_geaendert_werden,
    Kurstermin_Dauer_soll_geaendert_werden,
    Kurstermin_Region_soll_geaendert_werden,
    Kurstermin_Sprache_soll_geaendert_werden,
    Kurstermin_Titel_soll_entfernt_werden,
    Kurstermin_Titel_soll_vergeben_werden,
} from "../Daten/FlugplanMessages.gen";
import {sendMessage} from "../SendMessages";

export const TeilnehmerKreis_Oeffentlich = "Öffentlich";
export const TeilnehmerKreis_Inhouse = "Inhouse";

export function KursterminDetailHeader({id, onCallback, showFull = false}) {
    const [focused, setFocused] = useState();
    const [kurstermin, setKurstermin] = useState();
    const [beginnDatum, setBeginnDatum] = useState();

    const {loading, data, refetch} = useQuery(GET_COURSE_DETAILS, {
        variables: {id}, fetchPolicy: "network-only",
        onCompleted: (data) => {
            setKurstermin(data?.kurstermine[0])
            setBeginnDatum(moment(data?.kurstermine[0].beginn.isoString))
        },
        skip: (!id)
    });

    const Termininformationen = ({beginn, dauer}) => {
        return <div>Beginn: {moment(beginn).format("DD.MM.YYYY")} -- Umfang: {dauer} {(dauer > 1) ? "Termine" : "Termin"}</div>
    }


    if (loading || !kurstermin) return <Loading/>

    if (!showFull) {
        return <div className={"border"}>
            <Termininformationen beginn={beginnDatum} dauer={kurstermin?.dauer}/>
        </div>
    }


    function handleBlur(e) {
        if (kurstermin?.dauer) {
            sendMessage(new Kurstermin_Dauer_soll_geaendert_werden(id, kurstermin?.dauer)).then(
                onFulfilled()
            )
        }
    }


    function saveRegion(region) {
        setKurstermin({...kurstermin, region: region})
        sendMessage(new Kurstermin_Region_soll_geaendert_werden(id,region.Id)).then(
            onFulfilled())
    }

    function saveSprache(sprache) {
        setKurstermin({...kurstermin, sprache: sprache})
        sendMessage(new Kurstermin_Sprache_soll_geaendert_werden(id, sprache.code)).then(
            onFulfilled())
    }


    function onFulfilled() {
        return () => {

            refetch().then((r) => {
                setKurstermin(r?.data?.kurstermine[0])
                setBeginnDatum(moment(r?.data?.kurstermine[0].beginn.isoString))

                if (onCallback) {
                    onCallback()
                }
            })
        }

    }

    function saveDatum(datum) {
        setBeginnDatum(datum)
        sendMessage(new Kurstermin_Datum_soll_geaendert_werden(id, datum.format("YYYY-MM-DD"))).then(
            onFulfilled()
        )
    }

    function handleTitelChange(e) {
        let message = null
        setKurstermin({...kurstermin, [e.target.name]: e.target.value})
        let sprache = e.target.name === "titel" ? "DE" : "EN"
        if (e.target.value  != "") {
            message = new Kurstermin_Titel_soll_vergeben_werden(id, sprache, e.target.value)
        } else {
            message = new Kurstermin_Titel_soll_entfernt_werden(id, sprache)
        }
        sendMessage(message).then(
            onFulfilled()
        )

    }

    return <div className="w-full justify-between border p-4">
        {id && <div className={"w-full inline-flex"}>
            <div className={"w-1/2 inline-block"}>
                <Label title={"Titel:"} name={"titel"}/><br />
                <InputFieldWithClassName className={"w-1/2"} type={"text"} title="Titel" name="titel" value={kurstermin?.titel} onChange={(e) => handleTitelChange(e)}/>
            </div>
            <div className={"w-1/2"}>
                <Label title={"Titel (englisch):"}  name={"titelEn"}/><br />
                <InputFieldWithClassName className={"w-1/2"} type={"text"} title="Titel (englisch)" name="titelEn" value={kurstermin?.titelEn} onChange={(e) => setKurstermin({...kurstermin, titelEn: e.target.value})} />
            </div>
        </div>}
        <div className={"w-full flex inline-flex"}>
        <div className="w-1/3">
            <Label name="Kurstermin_Datum" title="Beginn (erster Kurstermin)"/><br/>


            <SingleDatePicker
                date={beginnDatum}
                onDateChange={(datum) => saveDatum(datum)}
                focused={focused}
                onFocusChange={({focused}) => setFocused(focused)}
                isOutsideRange={() => false}
                id="Kurstermin_Datum"
                className="w-1/3"
            />
            {(!id) && <KurstypAutoSuggestField
                initialItem={kurstermin?.typ}
                setValueObject={(kurstyp => setKurstermin({...kurstermin, typ: kurstyp}))}/>}

            <InputField name="dauer" type="number" min="1" max="100"
                        className="w-32"
                        title="Anzahl der Kurstage"
                        defaultValue={"2"}
                        value={kurstermin.dauer}
                        disabled={kurstermin.kurstage?.length > 0}
                        onChange={(e) => setKurstermin({...kurstermin, dauer: e.target.valueAsNumber})}
                        onBlur={(e) => handleBlur(e)}
            />
        </div>
        <div className={"w-1/3 float-none justify-between"}>
            <Kursdaten id={id} onKursdatenChange={(kurstage) => setKurstermin({
                ...kurstermin,
                dauer: kurstage.length,
                kurstage: kurstage
            })}/>

        </div>
        <div className="w-1/3 float-none justify-between">
            {(!id) && <div className="block float-left w-5/6">
                <Label name="teilnehmerKreis" title="Teilnehmerkreis"/><br/>
                <StatusButton status={{text: TeilnehmerKreis_Oeffentlich}}
                              active={kurstermin?.teilnehmerKreis === TeilnehmerKreis_Oeffentlich}
                              onClick={() => setKurstermin({
                                  ...kurstermin,
                                  teilnehmerKreis: TeilnehmerKreis_Oeffentlich
                              })} className="mr-2"/>
                <StatusButton status={{text: TeilnehmerKreis_Inhouse}}
                              active={kurstermin?.teilnehmerKreis === TeilnehmerKreis_Inhouse}
                              onClick={() => setKurstermin({...kurstermin, teilnehmerKreis: TeilnehmerKreis_Inhouse})}/>
            </div>}
            <div className="block float-left w-5/6">
                <RegionAutosuggestField initialItem={kurstermin.region}
                                        setValueObject={(region) => saveRegion(region)}/>
                <SpracheAutoSuggestField initialItem={kurstermin.sprache} setValueObject={(sprache) => saveSprache(sprache)}/>
            </div>
        </div>
        </div>
    </div>

}

KursterminDetailHeader.propTypes = {
    id: PropTypes.string

};
