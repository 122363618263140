import React, { useState } from "react"
import { FilterField2, Loading, HeadingLine, DataLine } from "./components"
import { GET_LOGS } from "./Queries"
import { useQuery } from "@apollo/client"
import { onFilterChange } from "./Infrastruktur/Helpers"

export const LogViewer2 = () => {
    // get data from API
    const { loading, error, data } = useQuery(GET_LOGS, { pollInterval: 5000 });

    // search field
    const [filterString, setFilterString] = useState("");
    // filter for search
    const filter = logEntry => {
        const lowerQuery = filterString.toLowerCase();
        return logEntry.sender.toLowerCase().indexOf(lowerQuery) > -1 ||
            logEntry.message.toLowerCase().indexOf(lowerQuery) > -1
    }

    // action buttons
    const links = [{ label: "Alarm", func: () => { alert("Alarm hilft leider auch nicht. Das musst du jetzt selbst reparieren.") } }]

    // todo: reimplement
    const [levels, setLevels] = React.useState([
        "🔵 debug",
        "⚪ info",
        "⚠️️  warn",
        "🔴 error",
    ])
    const [shownLevels, setShownLevels] = React.useState({
        "🔵 debug": false,
        "⚪ info": true,
        "⚠️️  warn": true,
        "🔴 error": true,
    })

    const filterTable = (e) => {
        setFilterString(e.target.value);
    }

    /*
    const levelFilterClick = (event) => {
        const target = event.target
        const check = target.checked
        const name = target.name

        const shownLevels = this.state.shownLevels
        shownLevels[name] = check
        this.setState({ shownLevels })
    }
    */

    return (
        <div>
            <HeadingLine title="Logs" icon="🧻" links={links} />
            {loading && <Loading />}
            {data && <div>
                <FilterField2 filterString={filterString} handleFilterChange={onFilterChange(setFilterString)} />
                <table style={{ width: "100%" }}>
                    <thead><tr><th>Zeit</th><th>Level</th><th>Absender</th><th>Nachricht</th></tr></thead>
                    <tbody>{data && !loading && data.logger && data.logger.messages
                        .filter(filter)
                        .map((logEntry, index) => <DataLine id={logEntry.timestamp + logEntry.sender} index={index} rowData={[
                            { text: logEntry.timestamp },
                            { text: logEntry.level },
                            { text: logEntry.sender },
                            { text: logEntry.message }
                        ]} />)}
                    </tbody>
                </table></div>}
        </div>
    )
}

