import React, {useState} from "react";
import {
    Loading,
    InputFieldWithClassName, labelClass
} from "../components";
import { gql, useMutation, useQuery} from "@apollo/client";
import {MUTATE_TEILNAHMEZAHL_BEGRENZEN} from "../Queries";
import {
    Kurstermin_Teilnahmezahl_soll_begrenzt_werden
} from "../Daten/FlugplanMessages.gen";


const GET_KURSTERMIN_TEILNAHMEZAHL = gql`query kurstermin($id: String){kurstermine(Id: $id){
   teilnehmer { count },
   wenigeRestplaetzeAb,
   maxTeilnahmeZahl,    
    status{
        text,
        transitions{text}
    },     
   Id
}}`


/**
 *
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
export function Teilnahmezahl({id}) {
    const [maxTn,setMaxTn] = useState()
    const [wenigeRestplaetzeAb, setWenigeRestplaetzeAb] = useState()

    const {loading, refetch} = useQuery(GET_KURSTERMIN_TEILNAHMEZAHL,
        {
            variables: {id},
            onCompleted: (data) => {
                if (data && data.kurstermine && data.kurstermine.length > 0) {
                    setMaxTn(data.kurstermine[0].maxTeilnahmeZahl)
                    setWenigeRestplaetzeAb(data.kurstermine[0].wenigeRestplaetzeAb)
                }
            }
        }
    )

    const [speichereWenigeRestplaetze] = useMutation(MUTATE_TEILNAHMEZAHL_BEGRENZEN, {
        onCompleted: (data) => {
            if (data[ Kurstermin_Teilnahmezahl_soll_begrenzt_werden] === "ok") {
                console.log("Participant limit set")
            } else {
                resetTeilnahmezahl()
            }
        }
    });

    function saveTeilnahmezahl () {
        const message = new Kurstermin_Teilnahmezahl_soll_begrenzt_werden(
            id,
            maxTn ? parseInt(maxTn) : 0,
            wenigeRestplaetzeAb? parseInt(wenigeRestplaetzeAb) : 0)
        speichereWenigeRestplaetze({variables: {message: JSON.stringify(message)}}).then(r => {
                console.log(r)
            }
        )

    }

    function resetTeilnahmezahl () {
        refetch({id}).then((r) => {
            setMaxTn(r.data?.kurstermine[0]?.maxTeilnahmeZahl)
            setWenigeRestplaetzeAb(r.data?.kurstermine[0]?.wenigeRestplaetzeAb)
            }

        )
    }

    const onMaxTnChange = e => {
        setMaxTn(e.target.value)
    };


    const onWenigeRestAbChange = e => {
        setWenigeRestplaetzeAb(e.target.value)

    };


    if (loading) return <Loading />

    return <div className={"grid grid-cols-4"}>
        <div className={"w-1/4 px-2 overflow-hidden inline-block"}>
            <label className={labelClass}>Maximale Teilnehmer:</label><br />

            <InputFieldWithClassName
            title={"Teilnahmezahlbegrenzung"}
            type={"number"}
            value={maxTn} defaultValue="18"
            min={wenigeRestplaetzeAb? wenigeRestplaetzeAb : ""}
            className={"w-16"}
            onChange={(e) => onMaxTnChange(e)}
            onBlur={() => saveTeilnahmezahl()}
        />
        </div>
        <div className={"w-1/4 px-2 overflow-hidden inline-block"}>
            <label className={labelClass}>Wenige Restplätze ab:</label><br />
            <InputFieldWithClassName title={"Wenige Restplätze ab"}
                      type={"number"}
                      value={wenigeRestplaetzeAb}
                      defaultValue={"5"}
                      min={"0"}
                      max={maxTn?.toString()}
                      pattern={"#"}
                      className={"w-16"}
                      onChange={(e) => onWenigeRestAbChange(e) }
                      onBlur={() => saveTeilnahmezahl()}
        />
        </div>


    </div>

}

