import React, {useState} from "react";
import {
    BulkUpload, CSVtoArray,
    InputField,
    Label,
    Loading,
    StatusButton,
    SubmitButton,
    TeilnehmerNeuZeile,
    TextareaField,
    TH
} from "../components";
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_BUCHUNG, GET_ID, MAILSENDEN, PREIS_AENDERN} from "../Queries";
import {
    Bestaetigungsemail_soll_versendet_werden,
    Kurs_soll_gebucht_werden,
    Kursbuchung_Preis_soll_geaendert_werden
} from "../Daten/CheckinMessages.gen";
import {useHistory, useParams} from "react-router-dom";
import Rechnungsadresse from "../Daten/Rechnungsadresse";
import Teilnehmer from "../Daten/Teilnehmer";
import {LaenderAutosuggestField, WaehrungAutoSuggestField} from "../AutoSuggestFields";

export const BuchungAnlegen2 = () => {
    let {id} = useParams();
    const defaultWaehrung = {name: "Euro", code: "EUR", symbol: "€"}
    const [land, setLand] = useState(
        {
            iso: "DEU",
            name: "Deutschland",
            nameEn: "Deutschland"
        }
    )

    const [neueBuchung, setNeueBuchung] = useState(
    {
        bucherVorname: "",
        bucherNachname: "",
        bucherFirma: "",
        bestellNummer: "",
        ustId: "",
        telefon: "",
        emailAdresse: "",
        gutscheinCode: "",
        rechnungPerPost: false,
        teilnehmer: [
            {
                "anrede": "0",
                "titel": "",
                "vorname": "",
                "nachname": "",
                "email": "",
            },
        ],

        kommentar: "",
        success: false,
        bestaetigungsMailVersenden: false,
        bucherIsTeilnehmer: false,
        buchernamen_in_Rechnungsadresse_aufnehmen: true,
        buchenWennVoll: false,
        submitButtonDisabled: false,
        preisEintragen: false,
        bucherRechnungsAdresse : {
            description :"",
            vorname : "",
            nachname : "",
            abteilung : "",
            strasse : "",
            plz :"",
            ort :"",
            land :"DEU",
        },
        affiliateInfo: "",
    });

    const [buchungsId, setbuchungsId] = useState();
    let history = useHistory();
    const { errorId} = useQuery(GET_ID,
        {
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                if(data) {
                    setbuchungsId(data?.id);
                }
            }}
    );
    if(errorId){
        alert("BuchungsId konnte nicht generiert werden!");
    }

    const [bucheKurs,
        { loading: loading, error: error},
    ] = useMutation(CREATE_BUCHUNG,  {
            fetchPolicy: "network-only",
        },
    );


    const handleAdressChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        let neueAdresse = {...neueBuchung.bucherRechnungsAdresse, [name] : value}
        setNeueBuchung({...neueBuchung, bucherRechnungsAdresse: neueAdresse})
    }


  const onInputChange = (e) =>{
      const value = e.target.value;
      const name = e.target.name;
      let teilnehmer = neueBuchung.teilnehmer
      if (neueBuchung.bucherIsTeilnehmer && (
          name === "bucherVorname" || name === "bucherNachname" || name === "emailAdresse")) {

          let neuerTeilnehmer = neueBuchung.teilnehmer[0]
          switch (name) {
              case "bucherVorname":
                  neuerTeilnehmer.vorname = value;
                  break
              case "bucherNachname":
                  neuerTeilnehmer.nachname = value;
                  break
              case "emailAdresse":
                  neuerTeilnehmer.email = value;
                  break
          }
          teilnehmer[0] = neuerTeilnehmer
      }
      let geaenderteBuchung = {...neueBuchung,  [name] : value}
      setNeueBuchung(geaenderteBuchung);

  }

    const onBucherIsTeilnehmerClick = (_, val) =>{
        let teilnehmer = neueBuchung.teilnehmer;
        if (val) {
            let neuerTeilnehmer = {
                "anrede": "W",
                "titel": "",
                "vorname": neueBuchung.bucherVorname,
                "nachname": neueBuchung.bucherNachname,
                "email": neueBuchung.emailAdresse,
            }

            if (teilnehmer[0].vorname.trim() === ""
                && teilnehmer[0].nachname.trim() === ""
                && teilnehmer[0].email.trim() === "") {
                teilnehmer[0] = neuerTeilnehmer
            } else {
                teilnehmer.reverse().push(neuerTeilnehmer)
                teilnehmer.reverse()
            }
        } else {
            teilnehmer.reverse().pop()
            teilnehmer.length === 0 && teilnehmer.push({
                "anrede": "W",
                "titel": "",
                "vorname": "",
                "nachname": "",
                "email": "",
            })
            teilnehmer.reverse()
        }
        setNeueBuchung({...neueBuchung, bucherIsTeilnehmer: val})
    }


    const  onTeilnehmerChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const parts = name.split('_');
        let idx = parseInt(parts[1], 10);
        let item = parts[2];
        let teilnehmer = neueBuchung.teilnehmer;
        let thisTeilnehmer = teilnehmer[idx];
        thisTeilnehmer[item] = value;
        teilnehmer[idx] = thisTeilnehmer;
        setAdd(teilnehmer.concat(add));
    }


   const [add, setAdd] = useState([]);
    const addTeilnehmer = (e) => {
        e.preventDefault();
        let teilnehmer = neueBuchung?.teilnehmer;
        teilnehmer?.push({"anrede": "W", "titel": "", "vorname": "", "nachname": "", "email": ""});
        setAdd(teilnehmer?.concat(add))
    }

    const [addMultiple, setAddMultiple] = useState("Nachname\tVorname\tEmail")


    const [aenderePreis,
        {loading: speicherLoading, error: speicherError},
    ] = useMutation(PREIS_AENDERN, {
        onCompleted: (data) => {
            if (data[Kursbuchung_Preis_soll_geaendert_werden] === "ok") {
            }
        }
    });



    if(speicherLoading){
        return <Loading />
    }

    const  [sendMail,
        {loading: msloading, error: mserror },
    ] = useMutation(MAILSENDEN, {
        onCompleted: (data) => {
            if (data[Bestaetigungsemail_soll_versendet_werden] === "ok") {
            }
        }
    });

  
 
    const onSubmitBuchung =  (e) => {
        e.preventDefault();
        let adresse;
        if (neueBuchung.buchernamen_in_Rechnungsadresse_aufnehmen) {
          adresse = new Rechnungsadresse(neueBuchung.bucherVorname, neueBuchung.bucherNachname, neueBuchung.bucherRechnungsAdresse.strasse, neueBuchung.bucherRechnungsAdresse.plz, neueBuchung.bucherRechnungsAdresse.ort, land.iso);
        }

        const teilnehmer = neueBuchung.teilnehmer.reduce((array, item) => {

            if (item.vorname !== "" && item.nachname !== "") {
                if (item.email === "") {
                    alert("Der Teilnehmer " + item.vorname + " " + item.nachname + " hat keine E-Mail-Rechnungsadresse. Bitte angeben.");

                    return array;
                }
            }
            if (item.titel === "") {
                item.titel = ""
            }
            array.push(new Teilnehmer(item.anrede, item.titel, item.vorname, item.nachname, item.email.trim()))
            return array
        }
        , [])


        if (allSet()) {
            let success = false;

            const kursbuchung = new Kurs_soll_gebucht_werden(
                id,
                neueBuchung.bucherVorname,
                neueBuchung.bucherNachname,
                neueBuchung.bucherFirma,
                adresse,
                neueBuchung.bestellNummer,
                neueBuchung.ustId,
                neueBuchung.telefon,
                neueBuchung.emailAdresse,
                neueBuchung.gutscheinCode,
                teilnehmer,
                neueBuchung.rechnungPerPost,
                neueBuchung.kommentar,
                !neueBuchung.preisEintragen && neueBuchung.bestaetigungsMailVersenden,
                buchungsId,
                neueBuchung.buchen_wenn_voll,
                "intern",
                "DE",
            );
            bucheKurs({variables: {message: JSON.stringify(kursbuchung)}}).then(r => {
                console.log(r.data);
                success = r.data.Kurs_soll_gebucht_werden.split(":")[0] === "ok"
                if (success) {
                    let buchungsId = r?.data?.Kurs_soll_gebucht_werden?.split(":")[1]
                    if (buchungsId) {
                        history.push("/kurstermine/" + id + "/buchung/" + buchungsId)
                    }
                } else {
                    alert("Fehler bei der Buchung -" + r.data?.Kurs_soll_gebucht_werden)
                }

            })

        }


        if(neueBuchung.preisEintragen){
            if(neuerPreis){
                let waehrung = neuerPreis.waehrung;
                if(!waehrung) {
                    setNeuerPreis({...neuerPreis, waehrung: defaultWaehrung})
                }
                const message = new Kursbuchung_Preis_soll_geaendert_werden(buchungsId, {wert: neuerPreis.wert, waehrung: waehrung.code},);
                aenderePreis({variables: {message: JSON.stringify(message)}}).then(r => {
                    console.log("Preis data: "+r.data)
                    if(r?.data?.Kursbuchung_Preis_soll_geaendert_werden === "ok"){
                        mailVersenden()
                    }

                    })
           }
        }


    }
    const [neuerPreis, setNeuerPreis] = useState({})
    const mailVersenden = () =>{
        const message = new Bestaetigungsemail_soll_versendet_werden(buchungsId)
        sendMail({variables: {message: JSON.stringify(message)}}).then(r => {
            console.log(r.data)
        })
    }

   const allSet = () => {
     return neueBuchung.emailAdresse && neueBuchung.bucherRechnungsAdresse && neueBuchung.teilnehmer;
    }

    function legeMehrereTnAn () {

        let inputArray = addMultiple.split("\n")
        let teilnehmer = neueBuchung?.teilnehmer ?? []
        let oldlength = teilnehmer.length

        inputArray.forEach((tnZeile) => {
            let tnDaten = CSVtoArray(tnZeile) //tnZeile.split("\t")
            if (tnDaten.length === 3) {
                let neuerTeilnehmer = {
                    "anrede": "M",
                    "titel": "",
                    "vorname": tnDaten[1],
                    "nachname": tnDaten[0],
                    "email": tnDaten[2]
                }
                teilnehmer.push(neuerTeilnehmer)
            } else {
                alert(`Fehler beim Einlesen von ${tnDaten}`)
            }

        })

        alert(`Lege ${teilnehmer.length - oldlength} Teilnehmer an`)

        setNeueBuchung({...neueBuchung, teilnehmer: teilnehmer})

    }


    return <div className="kurstermin-anlegen-container">
     {(speicherLoading ||  loading || msloading || mserror) && <Loading/>} {speicherError && alert(speicherError)}
        <h2 className="heading">Buchung anlegen</h2>
        <form  className="buchungEingabe" onSubmit={e => onSubmitBuchung(e)}>
            <h3 className="heading">Rechnungsdaten</h3>
          <InputField  name="bucherVorname" type="text" title={"Vorname"}  value={neueBuchung.bucherVorname} onChange={e => onInputChange(e)} />
            <InputField  name="bucherNachname" type="text" title="Nachname" value={neueBuchung.bucherNachname} onChange={ e=> onInputChange(e)} />
           <InputField  name="emailAdresse" type="email" title="Email-Adresse" value={neueBuchung.emailAdresse} onChange={e=>onInputChange(e)} />
             <InputField  name="bucherFirma" type="text" title="Firma" value={neueBuchung.bucherFirma} onChange={e=>onInputChange(e)}/>
             <InputField  name="strasse" type="text" title="Straße" value={neueBuchung?.bucherRechnungsAdresse?.strasse} onChange={e => handleAdressChange(e)} />
           <InputField  name="plz" type="text" title="PLZ" value={neueBuchung?.bucherRechnungsAdresse?.plz} onChange={ e => handleAdressChange(e)} required={true}/>
           <TH>Ort: *<input className="leading-normal border w-3/4" name="ort" type="text" title="Ort" value={neueBuchung?.bucherRechnungsAdresse?.ort} onChange={e => handleAdressChange(e)} required={true} /></TH> <br />
            <LaenderAutosuggestField initialItem={land} setValueObject={ (suggestion) => setLand(suggestion)  }  required={true} />
            <InputField name="bestellNummer" type="text" title="Bestellnummer" value={neueBuchung.bestellNummer} onChange={ e => onInputChange(e)}/>
            <InputField name="ustId" type="text" title="Ust-ID" value={neueBuchung.ustId} onChange={e=>onInputChange(e)}/>
            <InputField name="affiliateInfo" type="text" title="Affiliate info" value={neueBuchung.affilliateInfo} onChange={e=>onInputChange(e)}/>

            <InputField name="telefon" type="tel" title="Telefon" value={neueBuchung.telefon} onChange={ e => onInputChange(e)} />
            <InputField name="gutscheinCode" type="text" title="Gutscheincode" value={neueBuchung.gutscheinCode} onChange={ e => onInputChange(e)}/>
            <Label name="preis" title="Preis"/><br/>
            <div className={"w-full inline-flex"}>
            <StatusButton status={{text: "Preis aus Kurs übernehmen"}} active={!neueBuchung.preisEintragen}
                          onClick={() => setNeueBuchung(({...neueBuchung, preisEintragen: false}))} className="mt-1 mr-2"/>

            <StatusButton status={{text: "Preis festlegen"}} active={neueBuchung.preisEintragen}
                          onClick={() => setNeueBuchung(({...neueBuchung, preisEintragen: true}))} className="mt-1 mr-2"/>
            </div>

              {neueBuchung.preisEintragen &&  <input className="leading-normal border w-3/4" placeholder={"Preis"} type={"number"} name={"wert"} value={neuerPreis?.wert} onChange={(e) =>
                  setNeuerPreis({...neuerPreis, wert: e.target.value, waehrung: neuerPreis.waehrung? neuerPreis?.waehrung : defaultWaehrung})}

              />}
              {neueBuchung.preisEintragen  && <WaehrungAutoSuggestField initialItem={neuerPreis.waehrung} setValueObject={(suggestion) => {setNeuerPreis({...neuerPreis, wert: neuerPreis?.wert,  waehrung: suggestion })}} />}

            <div className={"w-full inline-flex"}>
                <StatusButton status={{text: "Bestätigungsmail versenden"}} active={neueBuchung.bestaetigungsMailVersenden}
                              onClick={() => setNeueBuchung(({...neueBuchung, bestaetigungsMailVersenden: true}))} className="mt-1 mr-2"/>
            </div>



            <h3 className="heading">Teilnehmer-Daten{neueBuchung?.teilnehmer?.length}</h3>
            {
               neueBuchung?.teilnehmer?.map((teilnehmer, index) => <TeilnehmerNeuZeile index={index}
                                                                                     teilnehmer={teilnehmer}
                                                                                       onChange={e => onTeilnehmerChange(e)}
                                                                                         />)
            }
            <div className={"w-full inline-flex"}>
                <StatusButton
                    status={{text: "Der Bucher nimmt selbst teil"}}
                    active={neueBuchung.bucherIsTeilnehmer}
                    onClick={onBucherIsTeilnehmerClick}
                    className="mt-1 mr-2"/>

                <button className="mt-2 btn btn-gruen" onClick={e=>addTeilnehmer(e)} type="button">Weiterer Teilnehmer</button>

            </div>




         <BulkUpload onClick={legeMehrereTnAn} text={addMultiple} onTextChange={(t) => {
             setAddMultiple(t)
         }}/>

            <TextareaField name="kommentar" title="Kommentar"
                           value={neueBuchung.kommentar} onChange={(e) => onInputChange(e)}/>
            <StatusButton status={{text: "Rechnung per Email"}} active={!neueBuchung?.rechnungPerPost}
                          onClick={() => setNeueBuchung({...neueBuchung, rechnungPerPost: false})} className="mt-1 mr-2"/>
            <StatusButton status={{text: "Rechnung per Post"}} active={neueBuchung?.rechnungPerPost}
                          onClick={() => setNeueBuchung({...neueBuchung, rechnungPerPost: true})} className="mt-1 mr-2"/>
            <StatusButton status={{text: "Auch buchen, wenn der Kurs bereits ausgebucht ist"}}
                          active={neueBuchung?.buchenWennVoll} className="mt-1"
                          onClick={() => setNeueBuchung({...neueBuchung, buchenWennVoll: true})}/>
            <SubmitButton disabled={!allSet() || loading} title="Buchung anlegen"/> {loading && <Loading/>} { error && alert(error)}
        </form>
        </div>
}
