import {useQuery} from "@apollo/client";
import React, {useState} from "react";
import {HeadingLine, Loading} from "../components";
import {Benutzer_Passwort_Login_soll_aktiviert_werden} from "../Daten/BenutzerManagerMessages.gen";
import {notImplemented} from "../Infrastruktur/Helpers";
import {GET_BENUTZER} from "../Queries";
import {UserBearbeiten} from "./UserBearbeiten";
import {BerechtigungBearbeiten} from "./BerechtigungBearbeiten";

export const UserListe = () => {
    const { data, loading, refetch } = useQuery(GET_BENUTZER);
    const [currentUser, setCurrentUser] = useState();
    const links = [{ label: "Neuer Benutzer", func: notImplemented }]

    const closeEditor = () => { setCurrentUser(); refetch() };

    return (<div>
        <HeadingLine title="Benutzerverwaltung" icon="🛠" links={links} />
        <table style={{ width: "100%" }}>
            {currentUser && <UserBearbeiten id={currentUser.Id} closeHandler={closeEditor} />}
            <thead><tr><th>Benutzername</th><th>Id</th><th>Berechtigungen</th><th>Aktionen</th></tr></thead>
            {loading && <Loading />}
            <tbody>{data && !loading && data.benutzer
                .map((benutzer, index) => {
                    const userData = new Benutzer_Passwort_Login_soll_aktiviert_werden(benutzer.Id, benutzer.name, "asdqwe123");
                    return <tr className={"w-full border h-8 p-2 border-0 leading-normal"}>
                        <td>{benutzer.name}</td>
                        <td><small>{benutzer.Id}</small></td>
                        <td><BerechtigungBearbeiten benutzer={benutzer} /></td>
                        <td><button className={"btn btn-gruen"} onClick={()=>setCurrentUser(benutzer)}>Passwort ändern</button></td>
                    </tr>
                })}
            </tbody>
        </table>
    </div>)

}