import React from "react";
import {
    Benutzer_Berechtigungen_sollen_entfernt_werden,
    Benutzer_Berechtigungen_sollen_hinzugefuegt_werden
} from "../Daten/BenutzerManagerMessages.gen";
import {sendMessage} from "../SendMessages";
import {KUTE_BERECHTIGUNGEN, KUTE_BUCHUNGS_BERECHTIGUNGEN} from "./Berechtigungen";


export function BerechtigungBearbeiten({benutzer}) {

    function cannotBeModified(benutzer) {
        let blockedAccounts = ["SYSTEM","IMPORT","apiUser","andy","pit","tester"]
        return blockedAccounts.indexOf(benutzer.name) >= 0
    }

    function fuegeBerechtigungenHinzu(benutzer, berechtigungen) {
        if (benutzer && berechtigungen?.length && confirm(`Wirklich Admin-Rechte für ${benutzer.name} setzen?`)) {
            let message = new Benutzer_Berechtigungen_sollen_hinzugefuegt_werden(benutzer.Id, berechtigungen)
            sendMessage(message).then(() => {})
        }
    }



    function entferneBerechtigungen(benutzer, berechtigungen) {
        if (benutzer && berechtigungen?.length && confirm(`Wirklich alle Rechte für ${benutzer.name} entfernen?`)) {
            let message = new Benutzer_Berechtigungen_sollen_entfernt_werden(benutzer.Id, berechtigungen)
            sendMessage(message).then(() => {})
        }

    }

    return <div>
        <button name={"btnAdmin"} className={"btn btn-gruen"} disabled={cannotBeModified(benutzer) } onClick={() => fuegeBerechtigungenHinzu(benutzer, ["alle_berechtigungen"])}>Admin-Rechte vergeben</button>
        <button name={"btnBlock"} className={"btn btn-rot"}  disabled={cannotBeModified(benutzer) } onClick={()=>{entferneBerechtigungen(benutzer, KUTE_BERECHTIGUNGEN)}}>Sperren</button>
    </div>

}