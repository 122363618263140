import React, { useState } from "react"
import { DataLine, FilterField2, HeadingLine, LinkButton, Loading } from "./components"
import { useQuery } from "@apollo/client";
import { GET_KONTAKTE } from "./Queries";
import { notImplemented } from "./Infrastruktur/Helpers";
import { moodle_host } from "./index";
import KontakteZuMoodleZuordnen from "./Kontaktdetails/KontakteZuMoodleZuordnen"


export const KontaktListe2 = () => {
    const [filterString, setFilterString] = useState("");
    // pagination state
    const [after, setAfter] = useState()
    const [kontaktZuordnerVisible, setKontaktZuordnerVisible] = useState(false)

    const { data, loading, refetch } = useQuery(GET_KONTAKTE, {
        variables: {
            filter: filterString,
            sort: "nachname",
            after,
            limit: 50
        },
        fetchPolicy: "network-only"
    });

    // links for navigation
    const links = [
        { label: "Aktuelle Suche als CSV exportieren", func: notImplemented },
        { label: "Moodle-Zuordnungen versuchen", func: () => {setKontaktZuordnerVisible(!kontaktZuordnerVisible)} },
    ]

    function closeKontaktZordner() {
        setKontaktZuordnerVisible(false)
        refetch().then(r => {
            console.log(r.error)
        })
    }

    return (
        <div>
            <HeadingLine title="Kontakte" icon="📇" links={links} />
            {kontaktZuordnerVisible && <KontakteZuMoodleZuordnen closeHandler={() => closeKontaktZordner()}/>}
            {/* FilterFields musn't be in a conditional that depends on data. Otherwise they reset themselves after each query */}
            <FilterField2 filterString={filterString} handleFilterChange={setFilterString} />
            {loading && <Loading />}
            {data && <div>
                <table style={{ width: "100%" }}>
                    <thead><tr><th>Vorname</th><th>Nachname</th><th>Firma</th><th>Email</th><th>Kurs</th><th>Moodle-ID</th>{/*<th className="w-32">Aktionen</th>*/}</tr></thead>
                    <tbody>{data && !loading && data.kontaktepages.page.items
                        .map((kontakt, index) => {
                            const bookings = kontakt.buchungen ? kontakt.buchungen.length : 0;
                            const participation = kontakt.teilnahmen ? kontakt.teilnahmen.length : 0;
                            const bookingsAndParticipation = `${bookings} Buchungen - ${participation} Teilnahmen`;
                            const moodleTn = kontakt.moodleTeilnehmer ? "Ⓜ️ " + kontakt.moodleTeilnehmer : "☁"
                            const moodleLink = kontakt.moodleTeilnehmer
                                ? `https://${moodle_host}/user/profile.php?id=` + kontakt.moodleTeilnehmer
                                : ""

                            return <DataLine key={kontakt.Id} id={kontakt.Id} index={index} rowData={[
                                { text: kontakt.vorname, link: "/kontakte/" + kontakt.Id },
                                { text: kontakt.nachname, link: "/kontakte/" + kontakt.Id },
                                { text: kontakt.firma, link: "/kontakte/" + kontakt.Id },
                                { text: kontakt.email, link: "/kontakte/" + kontakt.Id },
                                { text: kontakt.teilnahmen[0]?.kurstermin?.kursCode, link: "/kontakte/" + kontakt.Id },
                                { text: moodleTn, link: moodleLink } ,
                                { text: bookingsAndParticipation }
                            ]} />
                        })}
                    </tbody>
                </table>
                {data && data.kontaktepages && data.kontaktepages.page &&
                    <TablePaginator for="Kontakte" pages={data.kontaktepages.pages}
                        page={data.kontaktepages.page.pageNumber}
                        prevPage={data.kontaktepages.page.previousPageAfterCursor}
                        nextPage={data.kontaktepages.page.nextPageAfterCursor}
                        change={after => setAfter(after)} />}</div>}
        </div>
    )
}


// lustiger legacy-code, der aber funktioniert
const TablePaginator = ({ pages, prevPage, nextPage, page, change }) => {
    const p = { className: "m-1" }

    return <div className="my-0 mx-auto text-center">
        {prevPage && <span {...p}><LinkButton
            onClick={() => change(prevPage)}>&lt;&lt;</LinkButton></span>}
        {pages.map(curPage => {
            if (curPage.number === page) {
                return <span key={curPage.number} {...p}>{curPage.number}</span>
            }
            if (pages.length > 10) {
                if (Math.abs(curPage.number - page) === 5) {
                    return <span key={curPage.number} {...p}>...</span>
                }
                if (Math.abs(curPage.number - page) > 5
                    && curPage.number !== 1 && curPage.number !== pages[pages.length - 1].number) {
                    return null
                }
            }
            return <span key={curPage.number} {...p}><LinkButton
                onClick={() => change(curPage.after)}>{curPage.number}</LinkButton></span>
        },
        )}
        {nextPage && <span {...p}><LinkButton
            onClick={() => change(nextPage)}>&gt;&gt;</LinkButton></span>}
    </div>
};
