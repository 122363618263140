class Teilnehmer {
    anrede;
    titel;
    vorname;
    nachname;
    email;

    constructor(anrede, titel, vorname, nachname, email) {
        this.anrede = anrede;
        this.titel = titel;
        this.vorname = vorname;
        this.nachname = nachname;
        this.email = email;
    }
}

export default Teilnehmer;
