import React, {useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {
    CREATE_ABANINJA_ADDRESS,
    CREATE_ABANINJA_INVOICE,
    GET_ABANINJA_STATUS, GET_RECHNUNGSTERMIN
} from "../Queries";
import {
    Abaninja_Kontakt_soll_angelegt_werden,
    Abaninja_Rechnung_soll_angelegt_werden
} from "../Daten/AbaninjaMessages.gen";
import {AbaninjaAddressenAutosuggestField, AbaninjaAutosuggestField} from "../AutoSuggestFields";
import {ModalContainer2} from "../UI/ModalContainer";
import {Loading, Textarea} from "../components";
import moment from "moment/moment";
import {schweizerPlz} from "./GetCanton";





export const AbaninjaRechnungAnlegen = ({buchung}) => {
    let isostate = ""
    let plz = ""
    schweizerPlz.forEach(function (arrayElem){
         plz = buchung?.bucherRechnungsAdresse?.plz?.trim()
           if(arrayElem.POSTLEITZAHL === plz?.toString()){
           isostate = arrayElem.KANTON
               return false;
       }
    });




    const [modal, setModal] = useState(false)
    const [buchungProdukt, setBuchungProdukt] = useState()
    let strHnbr = buchung?.bucherRechnungsAdresse?.strasse?.split(/[\s]+/)
    const [done, setDone] = useState(false)
    const [kurs, setKurs] = useState();
    const {loading: kursLoading} = useQuery(GET_RECHNUNGSTERMIN,
        {
            fetchPolicy: "network-only",
            variables: {Id: buchung?.kurstermin?.Id},
            onCompleted: (data) => {
                if (data) {
                    setKurs(data?.kurstermine[0])
                }
            }
        }
    );

    const address = {
        firma:   buchung.bucherFirma ?? "",
        vorname:  buchung?.bucher?.vorname ?? "",
        nachname:  buchung?.bucher?.nachname ?? "",
        email: buchung?.bucher?.email,
        plz:  buchung?.bucherRechnungsAdresse?.plz,
        ort:  buchung?.bucherRechnungsAdresse?.ort,
        land:  buchung?.bucherRechnungsAdresse?.land.iso,
        ustId: buchung.ustId,
        bundesland: isostate,
        hausnummer: strHnbr?.length > 1 ? strHnbr?.[strHnbr?.length - 1]  : " ",
        strasse: strHnbr?.slice(0, strHnbr?.length - 1)?.reduce((prev, cur) => prev + " " + cur, "").trim(),
        waehrung: buchung?.preis?.waehrung?.code,
        anrede: buchung?.bucher?.anrede?.code
    }

    const [receiver, setReceiver] = useState({
        companyUUID: "",
        addressUUID: "",
        personUUID: ""
    })


    const vatInfo = kurs?.vat?.reverseCharge ? "Steuerschuldnerschaft des Leistungsempfängers.\n\n" : (
        kurs?.vat?.satz === 0 && kurs?.region?.land?.iso === "CHE" ? "Öffentliche Kurse sind in der Schweiz von der Mehrwertsteuer ausgenommen.\n\n" : ""
    )

    const bankdaten = {
        ibanch: "CH43 0900 0000 8551 3236 0 \n",
        ibande: "CH88 0900 0000 9134 3698 9 \n",
        kontoch: "85-513236-0 \n",
        kontode: "91-343698-9 \n"
    }

    const [infos, setInfos] = useState({
        intro: "Sehr geehrte Damen und Herren,\n\nvielen Dank für Ihre Anmeldung" + (buchung?.bestellNummer ? " mit der Bestellnummer " + buchung?.bestellNummer : "") + ", die wir hiermit gerne bestätigen. Wir bitten Sie um die Begleichung der Kursgebühren.",
        condition: vatInfo +
        "Bitte überweisen Sie den Rechnungsbetrag auf das angegebene Konto.\nSwiss Post - Postfinance\nKonto-Nr.:"
        + (buchung?.preis?.waehrung?.code === "EUR" ? bankdaten.kontode : bankdaten.kontoch) +
            "IBAN: " + (buchung?.preis?.waehrung?.code === "EUR" ? bankdaten.ibande : bankdaten.ibanch) +
            "SWIFT-Code/BIC: POFICHBEXXX \n\nMit freundlichen Grüssen\nDasScrumTeam\n\n"
    })

    const [chooseAddress, setChooseAddress] = useState(false);
    const [loginStatus, setLoginStatus] = useState(false)

    const {loading: loadingStatus} = useQuery(GET_ABANINJA_STATUS, {
        variables: {},
        onCompleted: (data) => {
            if(data.abaninjaLoginStatus){setLoginStatus(true)}}
    });


    moment.locale("de")
    let beginn = moment(kurs?.beginn?.isoString).format("L")
    let ende = moment(kurs?.beginn?.isoString).add(kurs?.dauer - 1, "days").format("L")
    let tns = []
    buchung?.teilnehmer?.filter(tn => !tn.storniert).forEach(tn => tns.push((tn.titel ? tn.titel + " " : "") + tn.vorname + " " + tn.nachname))
    const teilnehmer = (tns.length === 1) ? tns[0] : (tns.length === 2) ? tns[0] + " und " + tns[1] : tns.reduce((prev, cur) => prev + ", " + cur, "").substr(1).trim()
    const produktbeschreibung = kurs?.kursCode +" "+  kurs?.titel + " Training\n in "+(kurs?.kursCode?.includes("Online") ? "Form eines interaktiven Fernkurs\n" : kurs?.region?.name) +"vom " + beginn.substr(0, 6) +
        " bis " + ende + "\n\nTeilnehmer: " + teilnehmer

    const  [legeAdresse,

        {loading: loading, error: createAddressError},
    ] = useMutation(CREATE_ABANINJA_ADDRESS, {
        onCompleted: (data) => {
            if (data[Abaninja_Kontakt_soll_angelegt_werden] === "ok") {

            }
        }
    });

    const  [legerechnung,
        {loading: loadingr},
    ] = useMutation(CREATE_ABANINJA_INVOICE, {
        onCompleted: (data) => {
            if (data[Abaninja_Rechnung_soll_angelegt_werden] === "ok") {}
        }
    });

    const createAddress = () => {

        if(address.land === "CHE" && address.bundesland === ""){
            alert("Die angegebene PLZ ist nicht vorhanden")
            return
        }

        const message = new Abaninja_Kontakt_soll_angelegt_werden(
            address?.firma, isostate, address?.vorname, address?.nachname, address?.email, address?.strasse, address?.hausnummer, address?.plz, address?.ort, address?.land, address?.ustID, address.waehrung, address.anrede)
        legeAdresse({variables: {message: JSON.stringify(message)}}).then(r => {
            let s = r.data.Abaninja_Kontakt_soll_angelegt_werden.split(":");
            if(s[0] === "ok"){
              setReceiver({...receiver,
                  companyUUID: s[1],
                  addressUUID: s[2],
                  personUUID: (s?.length > 3) ? s[3] : ""
              } )
            } else alert(r.data.Abaninja_Kontakt_soll_angelegt_werden)
        })
    }

    const [nummer, setNummer] = useState(buchung?.rechnungNummer)
    const closeModal = () =>{
        setModal(false);
    }

    const createInvoice = (dokument) =>{
        const message = new Abaninja_Rechnung_soll_angelegt_werden(
            receiver.companyUUID,
            receiver.addressUUID,
            receiver.personUUID ?? "",
            infos.intro,
            infos.condition,
            produktbeschreibung,
            buchungProdukt?.number ?? "",
            buchung?.Id,
            buchung?.bestellnummer ?? "",
            dokument
            )

        legerechnung({variables: {message: JSON.stringify(message)}}).then(r => {
            let success = JSON.parse(r?.data?.Abaninja_Rechnung_soll_angelegt_werden)
            if(success?.result === "ok"){
                if(success?.quote){
                    setModal(false)
                    setDone(true)
                }
                else if(success?.invoice){
                    setNummer(success?.invoice)
                }
            }
            else alert(r?.data?.Abaninja_Rechnung_soll_angelegt_werden)
        })

    }

return  <div> {nummer? <span>{nummer} </span> :<div>
    <button  className={"btn btn-gruen"} onClick={createAddress}>Adresse anlegen</button> &nbsp; {loading  || loadingr || loadingStatus || kursLoading && <Loading />}
    <button  className={"btn btn-gruen"} onClick={()=>setChooseAddress(true)}>Adresse auswählen</button> &nbsp;
    {chooseAddress && <AbaninjaAddressenAutosuggestField setValueObject={(suggestion) =>{setReceiver({...receiver,
        companyUUID: suggestion.uuid,
        addressUUID: suggestion?.addresses[0]?.uuid,
        personUUID: suggestion?.contactPerson ? suggestion?.contactPerson[0]?.uuid : ""
    })
    }}/>}

    {receiver?.companyUUID !== "" && <button className={"btn btn-gruen"} onClick={()=>setModal(true)}>Anlegen</button>}&nbsp;

    {modal && <ModalContainer2 onClose={closeModal}>
        <h1 className={"md:text-dst-stift-gruen"}>Rechnung bei Abaninja anlegen </h1>
        <h3 className={"badge-rot"}>Wähle bitte erst ein Produkt für die Buchung aus, sonst kann keine Rechnung angelegt werden</h3>
        <AbaninjaAutosuggestField setValueObject={suggestion => setBuchungProdukt(suggestion)} />

        <Textarea  className={""}
                   title={"Öffentliche Notizen"}
                   type={"text"}
                   name={"into"}
                   onChange={e => {setInfos({...infos, intro: e.target.value})}}
                   value={infos.intro}

        />

        <br />

        <Textarea  className={""}
                   title={"Weitere Informationen"}
                   type={"text"}
                   name={"condition"}
                   onChange={e => {setInfos({...infos, condition : e.target.value})}}
                   value={infos.condition}

        />
        {buchungProdukt?.number && <div>
            <button  className={"btn btn-gruen"} onClick={ () => createInvoice("Rechnung")}>Rechnung anlegen</button>
         <span className={"text-center"}>oder</span>  <button  className={"btn btn-gruen"} onClick={ () => createInvoice("Angebot")}>Angebot anfordern</button>
        </div>}

    </ModalContainer2>}
    {done && <div className={"w-1/4 h-4"}> <ModalContainer2 onClose={()=> setDone(false)} title="Bestätigung">
        <span> Das Angebot wurde erfolgreich erstellt!</span><p> &nbsp;</p>
        <button className={"btn btn-rot"} onClick={()=>setDone(false)}>Schliessen</button>
    </ModalContainer2> </div>}


    {createAddressError && alert(createAddressError)}

</div>
    }
</div>

}