import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
    GET_TEILNEHMER,
    GET_MOODLE_TEILNEHMER,
    EDIT_EMAIL, GET_TN_ETIQUETTEN, GET_TN_LISTE, GET_EXTERNE_ANFORDERUNGEN
} from "../Queries";
import {useSelect} from "downshift";
import {Loading, StatusButton} from "../components";
import React, {useState} from "react";
import {object} from "prop-types";
import {
    Kontakt_Email_hinterlegen,
} from "../Daten/CheckinMessages.gen";
import {
    Moodle_Teilnehmer_soll_in_Moodle_Kurs_eingeschrieben_werden,
} from "../Daten/MoodleMessages.gen";
import {sendMessages} from "../SendMessages";
import {TeilnahmeBestaetigen2} from "../Buchung/TeilnahmeBestaetigen2";
import {Newsletter2} from "../Kontaktdetails/Newsletter";
import {toCSV} from "react-csv/lib/core";
import {Link} from "react-router-dom";
import moment from "moment";
import {MoodeAnmeldung} from "./MoodleAnmeldung";
import {moodle_host} from "../index";

export const TeilnehmerListe = ({
                                    kursId,
                                    kursCode,
                                    kursTyp,
                                    moodleHost,
                                    moodleCourseId,
                                    trainer,
                                    kontaktStunden,
                                    location,
                                    beginn,
                                    dauer,
                                    zeiten,
                                    region
                                }) => {


    const zeigeAlleRestlichenAnmelden = false // feature flag
    const [teilnehmerObj, setTeilnehmerObj] = useState([])



    const [getMoodleTeilnehmer, {
        loading: moodleLoading,
        error: moodleError,
        data: moodleData,
        refetch: moodleRefetch,
    }] = useLazyQuery(GET_MOODLE_TEILNEHMER);


    const [getMoodleAnforderungen, {
        data: moodleAnforderungen,
        refetch: moodleAnforderungenRefetch
    }] = useLazyQuery(GET_EXTERNE_ANFORDERUNGEN);
    const {data, loading, refetch} = useQuery(GET_TEILNEHMER,
        {
            variables: {kurs: kursId},
            fetchPolicy: "network-only",
            onCompleted: () => {
                let neueEmailAdressen = { "" : "" }
                let neueTn = []


                data.teilnehmer.map( tn =>{
                    console.log(tn)
                        neueEmailAdressen[tn.kontaktId] = tn.kontakt.email
                        let tnobj = {vorname: tn.kontakt.vorname, nachname: tn.kontakt.nachname, kontaktId: tn.kontaktId, email: tn.kontakt.email, kontakt: {moodleTeilnehmer: tn.kontakt.moodleTeilnehmer}}
                        neueTn.push(tnobj)



                })
                setTeilnehmerObj(neueTn)
                setEmailAdressen(neueEmailAdressen)
                if (moodleCourseId > 0) {
                    getMoodleTeilnehmer({
                        variables: {
                            moodleKursId: moodleCourseId
                        }
                    }).then(()=>{})

                }
                getMoodleAnforderungen( {variables: {kursID: kursId}})?.then(()=>{})


            }
        },
    );

    // map tnId => Email
    const[emailadressen, setEmailAdressen] = useState({"" : ""})
    const [isEditing, setIsediting] = useState(false);




    const TeilnehmerStatus = ({storniert}) => {
        return <span>{storniert ? "storniert" : "angemeldet"}</span>
    }

    TeilnehmerStatus.propTypes = {
        storniert: Boolean
    }
    TeilnehmerStatus.defaultProps = {
        storniert: false
    }

    /**
     *
     * @param moodleTeilnehmer[]
     * @param selected
     * @param onSelectChange
     * @returns {JSX.Element}
     * @constructor
     */



    /**
     *
     * @param teilnehmer
     * @param onChanged
     * @param externeEinladungAngefordert
     * @param teilnehmer[].kontakt
     * @param teilnehmer[].storniert
     * @returns {JSX.Element}
     * @constructor
     */


    const TeilnehmerMarkierung = ({text, storniert, link}) => {

       return <Link className="text-dst-logo-gruen-hinten font-bold hover:text-dst-logo-gruen-mitte no-underline"
            to={link}>
           <span>
               {storniert ? <s>{text}</s> : text}

           </span>
       </Link>

    }



    const  [editEmail,
        {loading: loadMail, error: errorMail},
    ] = useMutation(EDIT_EMAIL, {
        onCompleted: (data) => {
            if (data[Kontakt_Email_hinterlegen] === "ok") {
            }
        }
    });

    const saveEmail = (tnId) => {
        if(emailadressen[tnId]) {
          const message = new Kontakt_Email_hinterlegen(tnId, emailadressen[tnId])
          editEmail({variables: {message: JSON.stringify(message)}}).then(r => {
              console.log(r.data)
          })
      }
    }


    const copyList = (type) =>{
        let tns = []
        for(const tn of data?.teilnehmer){
            if (!!tn.storniert) {
                continue
            }
            if(type === "bestaetigt" ){
                if (!tn.bestaetigt) {
                    continue
                }
            }
            let obj = [
                tn.kontakt.nachname,
                tn.kontakt.vorname,
                tn.kontakt.email
            ]
            tns.push(obj)
        }

        if (Object.keys(tns).length === 0) {
            alert("Es gibt keine bestätigte Teilnehmer in diesem Kurs.")
            return
        }

        const csv =  toCSV(tns, null, "\t", "")
         navigator.clipboard.writeText(csv).then(
             function (){
                 alert("Teilnehmerliste wurde erfolgreich kopiert")
             }, function (){alert("Die Liste konnte nicht kopiert werden")}
         )
    }


    const [loadEtiketten, {loading: etikettenLoading }] = useLazyQuery(GET_TN_ETIQUETTEN, {
        variables: {Id: kursId},
        fetchPolicy: "network-only"
    });

    const [loadTnListe, {loading: tnListeLoading}] = useLazyQuery(GET_TN_LISTE, {
        variables: {Id: kursId},
        fetchPolicy: "network-only"
    });

    const downloadCSV = async () => {
        moment.locale('de')
        let tns = []
        for(const tn of data?.teilnehmer){
            if (!!tn.storniert) {
                continue
            }

            const obj = {
                Anrede: tn?.anrede?.code !== "D" ? tn?.anrede?.text : "",
                Vorname: ((tn.kontakt.titel ? tn.kontakt.titel : "") + " " + tn.kontakt.vorname).trim(),
                Nachname: tn.kontakt.nachname,
                Email:  tn.kontakt.email,
                Kurscode: kursCode,
                Beginn: beginn?.humanReadable,
                Ende: moment(beginn.isoString).add(dauer - 1, "days").format("L"),
                Kontaktstunden: kontaktStunden,
                Trainer: trainer[0]?.vornamen +" " +trainer[0]?.nachname,
                "Trainer 2": trainer?.length > 1 ? trainer[1]?.vornamen +" " +trainer[2]?.nachname : "",
                Kurstyp: kursTyp.name,
                Location: location?.vollstaendigeAdresse?.replace(/(\r|\n|\r\n)/, ", "),
                Region: region,
                "Google Maps Link": location?.googleMapsUrl ? location.googleMapsUrl : "",
                Kurszeiten: zeiten?.map(kurstag => "Tag " + (kurstag.offset + 1) + ": " + kurstag.beginn + "–" + kurstag.ende + " Uhr").reduce((s, e) => s + "\n" + e, "").trim()
            }
            tns.push(obj)
        }
        let csv = toCSV(tns, null, ";", "\"")
        let element = document.createElement("a")
        const file = new Blob([csv], {type: 'text/csv'})
        element.href = URL.createObjectURL(file);
        element.download = kursCode + "-teilnehmer.csv";
        element.click();
    }

     const downloadPdf = async (fileNamePart, data = null) => {
         if (data === null)
             return

         try {
             const linkSource = `data:application/pdf;base64,${data}`;
             const downloadLink = document.createElement("a");
             downloadLink.style.display = "none";
             document.body.appendChild(downloadLink);
             const fileName = `${fileNamePart}.pdf`;
             downloadLink.href = linkSource;
             downloadLink.download = fileName;
             downloadLink.click();
             document.body.removeChild(downloadLink);
         } catch (error) {
             alert('Fehler beim Download: ' + error)
         }
     }

    const [showStorno, setshowStorno] = useState(false)
    const [showBestaetigt, setShowBestaetigt] = useState(false)
    const [moodleDisabled, setMoodleDisabled] = useState(false)




    async function meldeTeilnehmerBeiMoodleAn() {

        const messages = []

        data?.teilnehmer.filter((tn) => tn.storniert === 0).map((tn) => {

            messages.push(new Moodle_Teilnehmer_soll_in_Moodle_Kurs_eingeschrieben_werden(
                    tn.kontaktId,
                    kursId))

        })
        return sendMessages(messages)
    }

    return <div>

        <h3>Teilnehmer</h3>
       <h4>
           <StatusButton status={{text: "Stornierte Teilnehmer anzeigen"}} active={showStorno} onClick={()=>setshowStorno(!showStorno)} />
           <StatusButton status={{text: "Nicht bestätigte Teilnehmer anzeigen"}} active={showBestaetigt} onClick={()=>setShowBestaetigt(!showBestaetigt)} />
       </h4>
        {loading || moodleLoading || moodleDisabled&& <Loading/>}
        {moodleError && <span className={"text-red"}>Fehler beim Zugriff auf Moodle<br /></span>}

        {moodleAnforderungen && <div>
            <h6>{moodleAnforderungen?.moodleExterneAnforderungen?.length} Externe Moodle-Anmeldung(en) angefragt</h6>
            <ul>

            </ul>
        </div>}

        <table className="w-full alternating-table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Anmeldestatus</th>
                <th>Moodle-Status</th>
                <th>Bestätigung</th>
                <th>Newsletter</th>
            </tr>
            </thead>

            <tbody>{data && !loading && data?.teilnehmer?.filter((tn) => {
                    return (showStorno || (!tn.storniert)) && (!showBestaetigt || (!tn.bestaetigt))})
                .map((teilnehmer, index) => (

                <tr key={teilnehmer.kontakt.Id + "-" + index}>


                    <td>
                        <TeilnehmerMarkierung text={teilnehmer.kontakt.vorname +" "+ teilnehmer.kontakt.nachname}
                                              link={"/kurstermine/" + kursId + "/buchung/" + teilnehmer.buchungId}
                                              storniert={teilnehmer.storniert}/>
                    </td>


                    <td className="w-auto">

                        <input className="w-full bg-transparent" type={"text"} value={emailadressen[teilnehmer.kontaktId]} onChange={
                        (e) => {
                            setIsediting(true)
                            let neueEmailAdressen = emailadressen
                            neueEmailAdressen[teilnehmer.kontaktId] = e.target.value
                            setEmailAdressen({...neueEmailAdressen})
                        }
                    }
                               onKeyDown={(e)=>{
                                   if(e.keyCode === 27){
                                       let neueEmailAdressen = emailadressen
                                       neueEmailAdressen[teilnehmer.kontaktId] = teilnehmer.kontakt.email
                                       setEmailAdressen({...neueEmailAdressen})
                                   } else if (e.keyCode === 13) {
                                       saveEmail(teilnehmer.kontaktId)
                                   }
                               }}
                    />
                        {isEditing && <button onClick={()=>{
                            let neueEmailAdressen = emailadressen
                            neueEmailAdressen[teilnehmer.kontaktId] = teilnehmer.kontakt.email
                            setEmailAdressen({...neueEmailAdressen})
                        }
                        }>↩️ &nbsp; </button> }
                        {isEditing && <button onClick={()=>saveEmail(teilnehmer.kontaktId)}>🆗</button>  } {loadMail &&<Loading/>} {errorMail && alert(errorMail)} </td>

                    <td><TeilnehmerStatus storniert={teilnehmer.storniert}/></td>

                    <td><MoodeAnmeldung
                        kursId={kursId}
                        moodleHost={moodle_host}
                        moodleCourseId={moodleCourseId}
                        teilnehmer={teilnehmerObj?.find(tn=> (tn.email === teilnehmer.kontakt.email)  )  }
                        onChanged={ () => { refetch({id: kursId}).then(() => {})
                        }}
                        externeEinladungAngefordert={ moodleAnforderungen?.moodleExterneAnforderungen?.find((item) => {
                            return (item.kontaktID === teilnehmer.kontaktId)})}
                    /></td>
                    <td><TeilnahmeBestaetigen2 teilnehmerId={teilnehmer.Id} kursId={kursId} bestaetigt={teilnehmer.bestaetigt}  buchungId={teilnehmer.buchungId}/></td>
                    <td><Newsletter2 kontaktId={teilnehmer.kontaktId} newsLetter={teilnehmer?.kontakt?.newsletter}/></td>
                </tr>))}
            <tr>
                <td>&nbsp;</td>
            </tr>
            </tbody>
        </table>
            <div className={"w-full inline-flex"}>
                <button className={"btn btn-gruen"} onClick={() => copyList("alle")}>Alle TN kopieren📋</button>&nbsp;
                <button className={"btn btn-gruen"} onClick={() => copyList("bestaetigt")}>Bestätigte TN kopieren📋</button>&nbsp;
                <button className={"btn btn-gruen"} onClick={() => downloadCSV()}>Teilnehmerliste CSV ⬇️</button>&nbsp;
                <button className="ml-2 btn btn-gruen no-underline" disabled={etikettenLoading}
                        onClick={() => {
                            if (!etikettenLoading) loadEtiketten().then((result)=>{
                                downloadPdf(result?.data?.kurstermine[0]?.kursCode + "_Etiketten", result?.data.kurstermine[0]?.teilnehmer?.teilnehmeretiketten).then(() => {})
                            })
                        }}
                        >
                    Namensetiketten ⬇️
                </button>&nbsp;

                <button className="ml-2 btn btn-gruen no-underline" disabled={tnListeLoading}
                        onClick={() => {
                            if (!tnListeLoading) loadTnListe().then((result)=>{
                                downloadPdf(result?.data?.kurstermine[0]?.kursCode +"_Teilnehmerliste", result?.data.kurstermine[0].teilnehmer?.teilnehmerliste).then(() => {})
                            })
                        }}
                >
                    Teilnehmerliste PDF ⬇️
                </button>

                {zeigeAlleRestlichenAnmelden && <button className="ml-2 btn btn-gruen no-underline"
                        disabled={!(moodleHost && data?.teilnehmer) || moodleDisabled}
                        onClick={() => {
                            setMoodleDisabled(true)
                            meldeTeilnehmerBeiMoodleAn().then(() => {
                                setTimeout(()=>{
                                    setMoodleDisabled(false)
                                    refetch({kursId}).then(()=>{
                                        moodleRefetch().then(()=>{

                                        })
                                    })
                                }, 7500)
                            })
                        }}
                >
                    Restliche Teilnehmer in Moodle-Kurs einschreiben
                </button>}
            </div>

    </div>
}

TeilnehmerListe.propTypes = {
    kursId: String,
    moodleHost: String,
    teilnehmer: object
}

TeilnehmerListe.defaultProps = {
    kursId: "",
    moodleHost: "",
    teilnehmer: {
        email: "",
        kontakt: {
            nachname: "",
            email: "",
            vorname: "",
            moodleTeilnehmer: "",
        },
        storniert: ""
    }
}