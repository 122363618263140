import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import {useQuery} from "@apollo/client";
import {GET_KURS_FOR_NEWSLETTER} from "./Queries";
import {ModalContainer2} from "./UI/ModalContainer";
import {SingleDatePicker} from "react-dates";
import {Input, Loading} from "./components";

export const NewsletterEintraege2 = ({closeModal}) => {
    const [eintrag, setEintrag] = useState({
        newsletterTag: "news" + moment().format("YYMM"),
        startDate: moment(),
        anzahlKurstermine: 1,
        dateFocused: false,
    })

    useEffect(() => {
        refetchDate();
    }, [eintrag.startDate])

    const {data: data, loading: loading, refetch: refetch} = useQuery(GET_KURS_FOR_NEWSLETTER,
        {
            variables: { startDate: moment().format("YYYY-MM-DD")},
            options: {
                fetchPolicy: "network-only",
            },
            onCompleted: () => {
                if (data) {
                    moment.locale('de')
                    setEintrag({...eintrag, startDate: moment()});
                }
            }
        }
    );

    const listenRef = useRef(null)
    const [kopiert, setKopiert] = useState("")

    const refetchDate =()=>{
        refetch({
            startDate: eintrag.startDate.format("YYYY-MM-DD"),
        })
    }

    /*
    // REGELN:
    // Einzelnes Datum:     DD.MM                   13.07.
    // Zwei Tage:           DD.MM-DD.MM.            13.07.-14.07.
    // Reihe                DD.MM-DD.MM.            13.07.-15.07.
    // Separate Daten:      DD.MM, DD.MM.           13.07., 04.08.
    // Reihe und separat    DD.MM.-DD.MM, DD.MM.    13.07.-15.07., 04.08.
     */
    function formatiereKurstage(termin) {

        const beginn = moment(termin.beginn.isoString)
        var zeile = ""


        termin.kurstage.map((kurstag, index) => {
            const aktuell = beginn.clone().add(kurstag.offset, 'days')

            // skip-check: wenn vorheriges element ein Tag früher und nächstes einen Tag später ist
            // termin.kurstage[index-1].offset = kurstag.offset -1 && termin.kurstage[index+1] == kurstag.offset +1

            if ( !((termin.kurstage[index-1] != null && termin.kurstage[index+1] != null)
                && (termin.kurstage[index-1].offset === kurstag.offset -1)
                && (termin.kurstage[index+1].offset === kurstag.offset +1) )
            ) {
                // Datum ausgeben
                zeile = zeile + aktuell.format(index === termin.kurstage.length -1 ? "DD.MM.YYYY" : "DD.MM")

                // Komma, Bindestrich oder nichts?
                if (termin.kurstage[index+1] != null) {
                    if(termin.kurstage[index+1].offset === kurstag.offset+1) {
                        zeile = zeile + " - "
                    } else {
                        zeile = zeile + " + "
                    }
                }
            }
        })

        return <span>{zeile}</span>

    }

    const  kurstyp = (typ) => {
        let termine = data.kurstermine
        termine = termine?.map(termin => {
            if (termin.typ.Id === "csms" || termin.typ.Id === "csma") {
                termin.typ.Id = "csm"
            }
            return termin
        })
        termine = termine?.filter(termin => termin.typ.Id === typ.Id)
        termine = termine?.filter(termin => termin.teilnehmerKreis !== "Inhouse")
        termine = termine?.filter(termin => ['Veröffentlicht', 'Wenige Restplätze'].includes(termin.status.text))
        termine = termine?.sort((a, b) => {
            if (a.beginn.isoString < b.beginn.isoString) {
                return -1
            }
            if (a.beginn.isoString > b.beginn.isoString) {
                return 1
            }
            return 0
        })

       return (

           termine?.length > 0 &&
           (
                    termine?.slice(0, eintrag.anzahlKurstermine).map(termin =>
                        <tr  className={"termin-fuer-newsletter"}>
                            <td headers="view-nothing-table-column" className={"views-field views-field-nothing"}>
                               <div className={"title"}> <a className={"text-dst-logo-gruen-mitte no-underline"} href={termin.drupalUrl + "?utm_campaign=newsletter&utm_medium=email&utm_source=email-broadcast&utm_content=" + eintrag.newsletterTag + "&utm_term=existing-list"}>
                                    {termin?.typ?.name} {!termin?.typ?.name?.includes("online") && termin.region.name}
                                   </a> <br/><em> {termin.sprache}</em></div>
                            </td>
                            <td>
                               <div className={"daten"}> {formatiereKurstage(termin)}</div> </td>
                            <td >{termin?.preis?.description} <br /> <em className={"text-dst-stift-orange"}> {termin.status.text === "Wenige Restplätze" && "(Wenige Restplätze)"}</em></td>
                            <td>
                                <a className={"btn btn-rot no-underline text-center"} href={"https://www.dasscrumteam.com/de/kurstermin/"+termin?.Id + "/buchen"}> buchen</a></td>
                        </tr>)
                   )


           )


    }
    const onClose = () => {
        closeModal();
    }

    function copyHTML()  {
       let  element = listenRef?.current
       navigator.clipboard?.writeText(element?.innerHTML).then (() => {
           setKopiert("HTML kopiert")
       })

    }

    function copyText() {
        let element = listenRef?.current
        const clipboardItem = new ClipboardItem({'text/html' : new Blob([element?.innerHTML], {type: 'text/html'})})
        if (clipboardItem) {
            navigator.clipboard?.write([clipboardItem]).then(() => {
                setKopiert("Text kopiert")
            })
        } else {
            setKopiert("")
        }



    }
    //copy html content and its style


    return  <ModalContainer2 title="Terminliste für Newsletter" onClose={onClose}>
        <div className={"float-left w-full"}>
        <SingleDatePicker
            date={eintrag.startDate}
            onDateChange={(datum) => {
                setKopiert("")
                setEintrag({...eintrag, startDate: datum})
            }}
            focused={eintrag.dateFocused}
            onFocusChange={({focused}) => setEintrag({...eintrag, dateFocused :focused})}
            numberOfMonths={3} isOutsideRange={() => false}
            id="NewsletterDatum"
            className="w-1/3"
        />
            <div className={"float-right w-1/3 align-middle"}>{kopiert}</div>
        </div>
        <div> </div>
       <label>UTM-Content <Input name="newsletterTag" value={eintrag?.newsletterTag}
                    onChange={e => {setEintrag({...eintrag, newsletterTag: e.target.value})}}/></label>
       <label>Max. Kurstermine pro Kurstyp <Input type="number" name="anzahlKurstermine"
                    value={eintrag.anzahlKurstermine}
                    onChange={e => setEintrag({...eintrag, anzahlKurstermine: e.target.value})}/></label>
        <div ref={listenRef}>
            <>
                <style>
                    {`
          .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  vertical-align: middle;
}
div.title {
  min-width: 250px;
  max-width: 250px;
}
.btn-outline {
  background: #fff;
  border-color: #6bbd45;
  color: #747474;
  transition: all 0.3s ease;
}
.btn-sm {
  padding: 7px 13px;
  font-size: 12px;
}
.daten{
  max-width: 100px;
  min-width: 100px;
}
td em{
    font-size: 12px;
}
.no-underline {
    text-decoration: none;
}
.text-dst-logo-gruen-mitte {
    color: #6bbd45;
}
.text-dst-stift-orange {
    color: #ec8022;
}
.btn-rot {
    background-color: #ec8022;
}
.btn {
    font-weight: 700;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    border-radius: 0.25rem;
    color: #fff !important;
}
.text-dst-logo-gruen-mitte {
    color: #6bbd45;
}
.table{
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,0.05);
}
.text-dst-logo-gruen-mitte {
    color: #6bbd45 !important;
}

.thead{
text-align: left;
margin-bottom: 10px;
margin-top: 10px;
}
tbody{
margin-top: -0.5rem !important;
}
th{
padding: 0.75rem;
}
        `}
                </style>
            </>
            <>
            {loading ? <Loading/> :
                <table className={"views-table views-view-table cols-6 table table-striped table-condensed"}>

                    <thead>
                    <tr>

                        <th className={"thead"}><h3>Kurstyp <br/></h3></th>
                        <th className={"thead"}><h3>Zeitraum</h3></th>
                        <th className={"thead"}><h3>Preis</h3></th>
                        <th className={"thead"}></th>

                    </tr>
                    <div className={"thead"}></div>
                    </thead>

                    <tbody>
                    {data?.kurstypen && data?.kurstypen.map(kurstyp)}

                    </tbody>

                </table> }
            </>
        </div>
        <div className={"float-center"}>
            <button className="btn btn-gruen" onClick={() => copyHTML()}>HTML-Code kopieren</button>
            <button className="btn btn-gruen" onClick={() => copyText()}>Rich Text kopieren</button>
            <button className="btn btn-rot" onClick={onClose}>Fenster schließen</button>
        </div>
    </ModalContainer2>

}




