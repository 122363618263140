/*
 * Kursstatus-Komponente
*/

import React, {useState} from "react"
import {useKursStatus} from "./UseKursStatus";
import {useSelect} from "downshift";
import {labelClass, Loading} from "../components"
import {Kurstermin_Teilnahmemodus_soll_geaendert_werden} from "../Daten/FlugplanMessages.gen";
import {sendMessage} from "../SendMessages";
import {TeilnahmeModusAuswahl} from "../Fields/TeilnahmeModusAuswahl";
import {MoodleKursAnlegen} from "./MoodleKursAnlegen";



export default function Kursstatus({id}) {

    const kursdetailDivClass = "w-full inline-flex";

    const [status, setStatus] = useState({
        status: {
            text: "",
            transitions: [],
        },
        inListe : false,
        oeffentlich : false,
        teilnehmerKreis : "",
        teilnahmeModus : "",
        Id : "",

    })



    const {loading, setKursTerminStatus} = useKursStatus({
        id: id,
        onStatusChanged: (kursTerminStatus) => {
            setStatus({...kursTerminStatus})

        }
    })


    function onKursStatusChange(e) {
        setKursTerminStatus(e.selectedItem.text, e.selectedItem.text)

    }

    function onTeilnahmeModusChange(e) {
        let aktuellerStatus = status
        aktuellerStatus = {...aktuellerStatus, teilnahmeModus: e.target.value}
        setStatus(aktuellerStatus)

        let message = new Kurstermin_Teilnahmemodus_soll_geaendert_werden(id,e.target.value)
        sendMessage(message).then(() => {})

    }

    const {
        isOpen,
        selectedItem,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
    } = useSelect({
        items: status.status?.transitions,
        onSelectedItemChange: (e) => onKursStatusChange(e),
    })

    if (loading) return <Loading />

    function getColorFor(text) {
        switch(text) {
            case "Geplant":
                return "text-blue"
            case "Abgesagt":
            case "Gestrichen":
                return "text-red"
            case "Wird durchgeführt":
                return "text-orange"
            case "Durchgeführt":
            case "Abgeschlossen":
            case "Abgebrochen":
                return "text-grey"
            case "Veröffentlicht":
            case "Wenige Restplätze":
            default:
                return "text-green"
        }

    }

    const statusText = selectedItem ? selectedItem.text : status?.status?.text

    return <div className={kursdetailDivClass}>
        <div className={"w-1/4"}>
            <label className={labelClass}>Durchführungsart:</label>
            <br/>
            {status?.teilnehmerKreis}
        </div>
        <div className={"w-1/4"}>
            <TeilnahmeModusAuswahl value={status?.teilnahmeModus?.text} onChange={onTeilnahmeModusChange}/>

        </div>
        <div className={"w-1/2 grid grid-colums-1"}>
            <label {...getLabelProps()} className={labelClass}>KursStatus:</label>
            <br/>
            <button type="button" {...getToggleButtonProps()} className={getColorFor(statusText) + " border-2 w-64"}>
                {statusText}
            </button>
            {isOpen &&
                <ul className={"list-reset w-64 overflow-y-auto h-32 border-2"}{...getMenuProps()}>
                    {status.status.transitions.map((item, index) => (
                        // TODO: Transitionen in Backend abfangen
                        (item.text !== "Wird durchgeführt") &&
                        <li
                            style={
                                highlightedIndex === index
                                    ? {backgroundColor: '#bde4ff'}
                                    : {}
                            }
                            key={`${item.text}${index}`}
                            {...getItemProps({item, index})}
                        >
                            <span className={getColorFor(item.text)}>{item.text}</span>
                        </li>
                    ))}
                </ul>}
            {(status?.sprache?.code !== "DE" && !status?.moodleTermin) &&  <span>&nbsp;<MoodleKursAnlegen id={id}/> Auf Moodle anlegen</span>}

        </div>
    </div>
}