import {LocationAutosuggestField} from "../AutoSuggestFields";
import React, {useState} from "react";
import {gql, useMutation, useQuery} from "@apollo/client";
import {MUTATE_KURSORT_ZUORDNEN} from "../Queries";
import {Kursort_soll_zugeordnet_werden} from "../Daten/FlugplanMessages.gen";
import {labelClass, Loading} from "../components";


export const GET_KURSTERMIN_LOCATION = gql`query kurstermin($id: String){kurstermine(Id: $id){
 
    location{
        Id,
        name,
        vollstaendigeAdresse,
        googleMapsUrl
    },
    region{
        Id,
        name,
        land{
            iso,
            name
        }
    },
      Id
}}`

export default function Location({id}) {

    const [location, setLocation] = useState()
    const [region, setRegion] = useState()

    const {loading} = useQuery(GET_KURSTERMIN_LOCATION,
        {
            variables: {id},
            onCompleted: (data) => {
                if (data && data.kurstermine && data.kurstermine.length > 0) {
                    setRegion(data.kurstermine[0]?.region)
                    setLocation(data.kurstermine[0]?.location)
                }
            }
        }
    )





    /*
 Mutation to set location for course
  */
    const [setzeKursort,
        {},
    ] = useMutation(MUTATE_KURSORT_ZUORDNEN, {
        onCompleted: (data) => {
            if (data[Kursort_soll_zugeordnet_werden.messageName] === "ok") {
                //
            }
        }
    });

    /*
    OnChange handler for AutoSuggest field
    */
    function onLocationChange(newLocation) {
        setLocation(newLocation)
        if (newLocation) {
            const message = new Kursort_soll_zugeordnet_werden(id, newLocation.Id)
            setzeKursort({variables: {message: JSON.stringify(message)}}).then(r => {
                console.log(r)
            })
        }

    }

    if (loading) return <Loading />

    return <div className={"w-2/3 pt-3 pb-3 inline-flex"}>
        <LocationAutosuggestField initialItem={location} setValueObject={newLocation => onLocationChange(newLocation)} region={region}  />
        {location && <div className={"w-1/2 pt-1 pb-3"} >
            <span className={labelClass}>Adresse:</span><br/>
                {location.name}<br />
                {location.vollstaendigeAdresse}
        </div>}
    </div>

}