class Rechnungsadresse {
    vorname;
    nachname;
    strasse;
    plz;
    ort;
    land;

    constructor(vorname, nachname, strasse, plz, ort, land) {
        this.vorname = vorname;
        this.nachname = nachname;
        this.strasse = strasse;
        this.plz = plz;
        this.ort = ort;
        this.land = land;
    }
}

export default Rechnungsadresse;