import React, { useState } from "react"
import { useQuery } from "@apollo/client";
import { GET_TRAINERLISTE } from "../Queries";
import { DataLine, FilterField2, HeadingLine, Loading } from "../components";
import { TrainerAnlegenBearbeiten } from "./TrainerAnlegenBearbeiten";

export const trainerSort = (a, b) => {
    let anachname = a.nachname.toLowerCase()
    let bnachname = b.nachname.toLowerCase()
    if (anachname < bnachname) {
        return -1
    }
    if (anachname > bnachname) {
        return 1
    }
    let avorname = a.vornamen.toLowerCase()
    let bvorname = b.vornamen.toLowerCase()
    if (avorname < bvorname) {
        return -1
    }
    if (avorname > bvorname) {
        return 1
    }
    return 0
}

export const TrainerDetails = () => <div>Trainer</div>;

export const TrainerListe2 = () => {
    // get data from API
    const { loading, data, refetch } = useQuery(GET_TRAINERLISTE, { pollInterval: 120000 });

    // location for editor
    const [currentTrainer, setCurrentTrainer] = useState()
    
    const closeEditor = () => { 
        setIsAddMode(false)
        setCurrentTrainer()
        refetch().then(()=>{})
    };

    // add or edit mode - default is edit mode
    const [isAddMode, setIsAddMode] = useState(false);

    // search field
    const [filterString, setFilterString] = useState();

    // filter for search
    const filter = trainer => {
        if (!filterString) { return true }
        const lowerQuery = filterString.toLowerCase();
        return trainer.nachname.toLowerCase().indexOf(lowerQuery) > -1 ||
            trainer.vornamen.toLowerCase().indexOf(lowerQuery) > -1 ||
            (trainer.email ? trainer.email.toLowerCase().indexOf(lowerQuery) > -1 : false) ||
            (trainer.cmscode? trainer.cmscode.toLowerCase().indexOf(lowerQuery) > -1 : false);
    }

    // action button
    const links = [
        { label: "Neuer Trainer", func: () => { setIsAddMode(true); } }
    ]

    return (
        <div>
            <HeadingLine title="Trainer" icon="👨‍🏫" links={links} />

            {(currentTrainer || isAddMode) && <TrainerAnlegenBearbeiten createNew={isAddMode} id={isAddMode ? 0 : currentTrainer.Id} closeHandler={closeEditor} />}
            {loading && <Loading />}
            {data && <div>
                <FilterField2 filterString={filterString} handleFilterChange={setFilterString} />
                <table style={{ width: "100%" }}>
                    <thead><tr><th>Name</th><th>CMS Code</th><th>Telefon</th><th>Email</th><th>Aktionen</th></tr></thead>
                    <tbody>{data && !loading && data.trainerliste
                        .filter(filter)
                        .sort(trainerSort)
                        .map((trainer, index) => <DataLine key={trainer.Id} id={trainer.Id} index={index} rowData={[
                            { text: `${trainer.vornamen} ${trainer.nachname}` },
                            { text: trainer.cmscode },
                            { text: trainer.telefon },
                            { text: trainer.email },
                            { text: "Bearbeiten", func: () => { setCurrentTrainer(trainer); } }
                        ]} />)}
                    </tbody>
                </table></div>}
        </div>
    )
}
