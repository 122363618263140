import {gql, useQuery} from "@apollo/client"
import React, {useState} from "react"
import {useCombobox} from "downshift";
import {lowerAndTrim} from "./Infrastruktur/Helpers"
import {Loading, labelClass, inputClass, buttonClass, listClass, itemClass} from "./components"
import {
    GET_REGIONS_FOR_REGION_FIELD,
    GET_TRAINERLISTE,
    GET_SPRACHEN,
    GET_COURSE_TYPES,
    GET_LAENDER,
    GET_LOCATIONS_COMPLETE,
    GET_WAEHRUNGEN,
    GET_SMALLINVOICE_PRODUKTE,
    GET_SI_KONTAKTE, GET_ABANINJA_ADDRESSES, GET_ABANINJA_PRODUKTE,
} from "./Queries"
import {AutosuggestTheme} from "./App";






export const WaehrungAutoSuggestField = ({initialItem, setValueObject}) => {

    const filterResults = (dataArray, value) => (value === undefined) ? dataArray :
        dataArray.filter(item =>
            lowerAndTrim(value).split(' ').every(input =>
                (item.code.toLowerCase() + " " + item.name.toLowerCase()).includes(input)
            )
        )

    const WaehrungSuggestionValue = (suggestion) => (<div>{suggestion.code}</div>);

    const getSuggestionValue = suggestion => suggestion ? suggestion.code : "";

    return <AutoSuggestField
        initialItem={initialItem}
        setValueObject={setValueObject}
        // the array that is returned from Kute's GraphQL API
        dataArray="waehrungen"
        // the value that should be displayed in the field
        query={GET_WAEHRUNGEN}
        placeholder="Währung"
        getSuggestionValue={getSuggestionValue}
        suggestionValueComponent={WaehrungSuggestionValue}
        filterResults={filterResults}
    />

}


export const KurstypAutoSuggestField = ({initialItem, setValueObject}) => {

    const filterResults = (dataArray, value) => (value === undefined) ? dataArray :
        dataArray.filter(item =>
            item ?
            lowerAndTrim(value).split(' ').every(input =>
                (item.Id.toLowerCase() + " " + item.name.toLowerCase()).includes(input),
            ) : true
        )

    const KurstypSuggestionValue = (suggestion) => (<div>{suggestion.name}</div>);

    const getSuggestionValue = suggestion => suggestion ? suggestion.name : "";

    return <AutoSuggestField
        initialItem={initialItem}
        setValueObject={setValueObject}
        // the array that is returned from Kute's GraphQL API
        dataArray="kurstypen"
        // the value that should be displayed in the field
        query={GET_COURSE_TYPES}
        placeholder="Kurstyp"
        getSuggestionValue={getSuggestionValue}
        suggestionValueComponent={KurstypSuggestionValue}
        filterResults={filterResults}
    />
}


export const SpracheAutoSuggestField = ({initialItem, setValueObject}) => {
    const filterResults = (dataArray, value) => (value === undefined) ? dataArray :
        dataArray.filter(item =>
            lowerAndTrim(value).split(' ').every(input =>
                (item.name.toLowerCase() + " " + item.code.toLowerCase()).includes(input),
            )
        )

    const SpracheSuggestionValue = (suggestion) => (<div>{suggestion.name}</div>);

    const getSuggestionValue = suggestion => suggestion ? suggestion["name"] : "";


    return <AutoSuggestField
        initialItem={initialItem}
        setValueObject={setValueObject}
        // the array that is returned from Kute's GraphQL API
        dataArray="sprachen"
        // the value that should be displayed in the field
        query={GET_SPRACHEN}
        placeholder="Sprache"
        getSuggestionValue={getSuggestionValue}
        suggestionValueComponent={SpracheSuggestionValue}
        filterResults={filterResults}
    />
}

export const RegionAutosuggestField = ({initialItem, setValueObject}) => {
    const filterResults = (dataArray, value) => (value === undefined) ? dataArray :
        dataArray.filter(item =>
            !item.ausgeblendet &&
            lowerAndTrim(value).split(' ').every(input =>
                (item.name.toLowerCase() + " " + item.land.name.toLowerCase()).includes(input),
            )
        )

    const RegionSuggestionValue = (suggestion) => (<div>{suggestion.name}, {suggestion.land.name}</div>)
    const getSuggestionValue = suggestion => suggestion ? suggestion["name"] : ""

    return <AutoSuggestField
        initialItem={initialItem}

        setValueObject={setValueObject}
        // the array that is returned from Kute's GraphQL API
        dataArray="regionen"
        // the value that should be displayed in the field
        query={GET_REGIONS_FOR_REGION_FIELD}
        placeholder="Region"
        getSuggestionValue={getSuggestionValue}
        suggestionValueComponent={RegionSuggestionValue}
        filterResults={filterResults}
    />
}

export const LaenderAutosuggestField = ({initialItem, setValueObject}) => {
    const filterResults = (dataArray, value) => (value === undefined) ? dataArray :
            dataArray.filter(item =>
                lowerAndTrim(value).split(' ').every(input =>
                    (item.name.toLowerCase() + " " + item.nameEn.toLowerCase() + "  " + item.iso.toLowerCase()).includes(input),
                ))


    const LaenderSuggestionValue = (suggestion) => (<div>{suggestion.name}</div>)
    const getSuggestionValue = suggestion => suggestion ? suggestion.iso : ""

    return <AutoSuggestField
        initialItem={initialItem}

        setValueObject={setValueObject}
        // the array that is returned from Kute's GraphQL API
        dataArray="laender"
        // the value that should be displayed in the field
        query={GET_LAENDER}
        placeholder="Land"
        getSuggestionValue={getSuggestionValue}
        suggestionValueComponent={LaenderSuggestionValue}
        filterResults={filterResults}
    />
}

export const LocationAutosuggestField = ({initialItem, setValueObject, region}) => {
    const filterResults = (dataArray, value) =>
    {
        if (value) {
            dataArray = dataArray.filter((item) => lowerAndTrim(value).split(' ').every(input =>
                (item.name.toLowerCase() + " " + item.vollstaendigeAdresse.toLowerCase()).includes(input)))
        }
        return dataArray
    }


    const LocationSuggestionValue = (suggestion) => (<div>{suggestion.name}</div>)
    const getSuggestionValue = suggestion => suggestion ? suggestion["name"] : ""

    return <AutoSuggestField
        initialItem={initialItem}

        setValueObject={setValueObject}
        // the array that is returned from Kute's GraphQL API
        dataArray="kursorte"
        // the value that should be displayed in the field
        query={GET_LOCATIONS_COMPLETE}
        variables={region ? {region: region.Id} : {}}
        placeholder="Veranstaltungsort"
        getSuggestionValue={getSuggestionValue}
        suggestionValueComponent={LocationSuggestionValue}
        filterResults={filterResults}
    />
}

export const TrainerAutosuggestField = ({initialItem, setValueObject}) => {
    const filterResults = (dataArray, value) => (value === undefined) ? dataArray :
    dataArray.filter(item =>
        value ? lowerAndTrim(value).split(' ').every(input =>
        (item.vornamen.toLowerCase() + " " + item.nachname.toLowerCase() + " " + item.cmscode.toLowerCase()).includes(input)) : true
    )

    const TrainerSuggestionValue = (suggestion) => (<div>{suggestion.vornamen} {suggestion.nachname}</div>)
    const getSuggestionValue = suggestion => `${suggestion["vornamen"]} ${suggestion["nachname"]}`

    return <AutoSuggestField
        initialItem={initialItem}
        setValueObject={setValueObject}
        // the array that is returned from Kute's GraphQL API
        dataArray="trainerliste"
        // the value that should be displayed in the field
        query={GET_TRAINERLISTE}
        placeholder="Trainer"
        getSuggestionValue={getSuggestionValue}
        suggestionValueComponent={TrainerSuggestionValue}
        filterResults={filterResults}
        theme={AutosuggestTheme}
    />
}

export const SmallInvoiceAutosuggestField = ({initialItem, setValueObject}) => {
    const filterResults = (dataArray, value) => (value === undefined) ? dataArray :
    dataArray.filter(item => value ? lowerAndTrim(value).split(' ').every(input =>
        (item.name.toLowerCase() + " " + item.number.toLowerCase()).includes(input)) : true
    )

    const SmallInvoiceSuggestionValue = (suggestion) => (<div>{suggestion.name} </div>)
    const getSuggestionValue = suggestion => suggestion ? suggestion.name : "" //suggestion => `${suggestion["name"]} ${suggestion["number"]}`

    return <AutoSuggestField
        initialItem={initialItem}
        setValueObject={setValueObject}
        // the array that is returned from Kute's GraphQL API
        dataArray="smallInvoiceProdukte"
        // the value that should be displayed in the field
        query={GET_SMALLINVOICE_PRODUKTE}
        placeholder="SmallInvoice-Produkt"
        getSuggestionValue={getSuggestionValue}
        suggestionValueComponent={SmallInvoiceSuggestionValue}
        filterResults={filterResults}
        theme={AutosuggestTheme}
    />
}

export const KursAutosuggestField = ({initialItem, setValueObject, startDate}) => {

    const filterResults = (dataArray, value) => (value === undefined) ? dataArray :
    dataArray.filter(item => lowerAndTrim(value).split(' ').every(input =>
        (item.kursCode.toLowerCase()).includes(input))
    )

    const KursSuggestionValue = (suggestion) => (<div>{suggestion.kursCode} </div>)
    const getSuggestionValue = suggestion => suggestion ? suggestion["kursCode"] : "" //suggestion => `${suggestion["name"]} ${suggestion["number"]}`

    const GET_KURS_AFTER = gql`query kurstermine($after : String){kurstermine(after: $after){Id,kursCode}}`

    const useDate = startDate ? startDate : new Date()
    const dateString = useDate.toISOString().split("T")[0]

    return <AutoSuggestField
        initialItem={initialItem}
        setValueObject={setValueObject}
        // the array that is returned from Kute's GraphQL API
        dataArray="kurstermine"
        // the value that should be displayed in the field
        query={GET_KURS_AFTER}
        variables={{after: dateString}}
        placeholder="kurstermin"
        getSuggestionValue={getSuggestionValue}
        suggestionValueComponent={KursSuggestionValue}
        filterResults={filterResults}
        theme={AutosuggestTheme}
    />
}

export const SmallInvoiceKundenAutosuggestField = ({initialItem, setValueObject}) => {
    const filterResults = (dataArray, value) => (value === undefined) ? dataArray :
        dataArray.filter(item => value ? lowerAndTrim(value).split(' ').every(input =>
        (item.name.toLowerCase()).includes(input)) : true
    )

    const SmallInvoiceKundenSuggestionValue = (suggestion) => (<div>{suggestion.name +" "+ suggestion.street +" "+ suggestion.city}  </div>)
    const getSuggestionValue = suggestion => `${suggestion["name"]} `
    //`${suggestion["vornamen"]} ${suggestion["nachname"]}`
    return <AutoSuggestField
        initialItem={initialItem}
        setValueObject={setValueObject}
        // the array that is returned from Kute's GraphQL API
        dataArray="smallInvoiceKunden"
        // the value that should be displayed in the field
        query={GET_SI_KONTAKTE}
        placeholder="SI-Kontakt auswählen"
        getSuggestionValue={getSuggestionValue}
        suggestionValueComponent={SmallInvoiceKundenSuggestionValue}
        filterResults={filterResults}
        theme={AutosuggestTheme}
    />
}


export const AbaninjaAddressenAutosuggestField = ({initialItem, setValueObject}) => {
    const filterResults = (dataArray, value) => (value === undefined) ? dataArray :
        dataArray.filter(item => value ? lowerAndTrim(value).split(' ').every(input =>

            (item?.name.toLowerCase())?.includes(input)) : true
        )

    const AbaninjaAddressenSuggestionValue = (suggestion) => (<div>{suggestion?.name+" "+ (suggestion.addresses[0]["address"]??"")+" "+suggestion?.addresses[0]["zip_code"]+ " "+ suggestion?.addresses[0]["city"]}  </div>)

    const getSuggestionValue = suggestion =>`${suggestion["name"]} `

    return <AutoSuggestField
        initialItem={initialItem}
        setValueObject={setValueObject}
        // the array that is returned from Kute's GraphQL API
        dataArray="abaninjaKunden"
        // the value that should be displayed in the field
        query={GET_ABANINJA_ADDRESSES}
        placeholder="Addresse auswählen"
        getSuggestionValue={getSuggestionValue}
        suggestionValueComponent={AbaninjaAddressenSuggestionValue}
        filterResults={filterResults}
        theme={AutosuggestTheme}
    />
}


export const AbaninjaAutosuggestField = ({initialItem, setValueObject}) => {
    const filterResults = (dataArray, value) => (value === undefined) ? dataArray :
        dataArray.filter(item => value ? lowerAndTrim(value).split(' ').every(input =>
            (item.name.toLowerCase() + " " + item.number.toLowerCase()).includes(input)) : true
        )

    const AbaninjaSuggestionValue = (suggestion) => (<div>{suggestion?.name} </div>)
    const getSuggestionValue = suggestion => suggestion?.name

    return <AutoSuggestField
        initialItem={initialItem}
        setValueObject={setValueObject}
        // the array that is returned from Kute's GraphQL API
        dataArray="abaninjaProdukte"
        // the value that should be displayed in the field
        query={GET_ABANINJA_PRODUKTE}
        placeholder="Abaninja Produkte"
        getSuggestionValue={getSuggestionValue}
        suggestionValueComponent={AbaninjaSuggestionValue}
        filterResults={filterResults}
        theme={AutosuggestTheme}
    />
}


export const AutoSuggestField = ({
                                     initialItem,
                                     setValueObject,
                                     dataArray,
                                     getSuggestionValue,
                                     query,
                                     variables,
                                     placeholder,
                                     filterResults,
                                     suggestionValueComponent
                                 }) => {


    const [allItems, setAllItems] = useState([])
    const [inputItems, setInputItems] = useState([])

    const {loading, error} = useQuery(query,
        {
            variables: variables,
            onCompleted: (data) => {
                if (data) {
                    setAllItems(data[dataArray])
                    setInputItems(data[dataArray])

                }
            }
        }
    )

    const itemToString = (item) => (getSuggestionValue(item))
    const onSelectedItemChange = (changes) => {
        changes && setValueObject(changes.selectedItem)
    }


    const {
        isOpen,
        getToggleButtonProps,
        getMenuProps,
        getLabelProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
    } = useCombobox({
            items: inputItems,
            selectedItem: initialItem, //sollte initialSelectedItem sein?!?
            itemToString,
            onSelectedItemChange,
            onInputValueChange: ({inputValue}) => {
                setInputItems(filterResults(allItems, inputValue))

            },
        }
    )

    if (loading) return <Loading/>
    if (error) return <div>Fehler: {error.message}</div>

    function getClassForItem(index, highlightedIndex) {
        if (index === highlightedIndex) {
            return "bg-dst-logo-gruen-mitte text-white"
        } else {
            return "text-black"
        }

    }

    return <div className={"w-3/4 pr-2 inline-block"} {...getMenuProps}>
        <div className={"w-full block"}  {...getComboboxProps()}>
            <label className={labelClass} {...getLabelProps()}>
                {placeholder}:
            </label>
            <br/>
            <div className={"w-full inline-flex relative"}>
                    <input name={dataArray}
                           className={inputClass}
                           {...getInputProps()}
                           placeholder={placeholder}

                    />
                <button
                    type={"button"}
                    style={{
                        right : 0
                    }}
                    className={buttonClass}
                    {...getToggleButtonProps()}
                    aria-label="toggle-menu"
                >
                    <svg className="w-3 h-4 fill-current" viewBox="0 0 20 20">
                        <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            />
                    </svg>
                </button>
            </div>
            {isOpen && <ul className={listClass} {...getMenuProps()} >
                {inputItems.map((item, index) => (
                    <li className={itemClass + " " + getClassForItem(index, highlightedIndex)}


                        key={`${item}${index}`}
                        {...getItemProps({item, index})}
                    >
                        {suggestionValueComponent(item)}
                    </li>
                ))}
            </ul>}
        </div>
    </div>

}
