import {
    Kursbuchung_Teilnahme_soll_bestaetigt_werden, Kursbuchung_Teilnahmebestaetigung_soll_zurueckgenommen_werden
} from "../Daten/CheckinMessages.gen";
import {StatusButton} from "../components";
import * as React from "react";
import {useState} from "react";
import {sendMessage} from "../SendMessages";

export const TeilnahmeBestaetigen2 = ({teilnehmerId, kursId, buchungId, bestaetigt})=> {

    const btnTextBestaetigt = "☑︎"
    const btnTextNichtBestaetigt = "☐"

    const [teilnahmeBestaetigt, setTeilnahmeBestaetigt] = useState(bestaetigt)
      return <div>
          <StatusButton className={"text-xl "  + teilnahmeBestaetigt ? "btn btn-rot" : "btn btn-gruen"} active={teilnahmeBestaetigt} status={{text: teilnahmeBestaetigt ? btnTextBestaetigt : btnTextNichtBestaetigt }} onClick={ () => {
            let message = teilnahmeBestaetigt
                ?  new Kursbuchung_Teilnahmebestaetigung_soll_zurueckgenommen_werden(buchungId, teilnehmerId, kursId)
                : new Kursbuchung_Teilnahme_soll_bestaetigt_werden(buchungId, teilnehmerId, kursId)
            setTeilnahmeBestaetigt(!teilnahmeBestaetigt)
            sendMessage(message).then(() => {
            })
          }}/>
      </div>
}


