import * as React from "react"
import PropTypes from 'prop-types'

export const AlertContainer = ({children}) => <div className="fixed pin-b pin-r mr-4 mb-4 w-1/4 max-w-sm ">
    {children}
</div>

class InternAlert extends React.Component {
    static defaultProps = {
        color: "red-dark",
        dismissible: false,
        onClick: null,
    }

    state = {
        collapsed: this.props.dismissible,
    }

    render() {
        const {title, children, color, dismissible, onClick} = this.props
        return <div
            className={"mb-2 p-2 rounded w-full min-h-16 text-white text-center  bg-" + color + (dismissible || onClick ? " cursor-pointer" : "")}
            onClick={() => {
                if (onClick) {
                    onClick()
                }
                if (dismissible) {
                    this.setState({collapsed: !this.state.collapsed})
                }
            }}>
            {title && <h4 className="font-bold py-1">{title}</h4>}
            <div className={(this.state.collapsed ? "hidden" : "")+" pb-1"}>{children}</div>
        </div>
    }
}

export const Alert = ({title, children}) => <InternAlert title={title} children={children}/>

Alert.propTypes = {
    title: PropTypes.string,
    children: PropTypes.element.isRequired,
}

export const TopAlertContainer = ({children}) => <div className="absolute mr-4 mb-4 w-1/4 max-w-sm"
                                                      style={{bottom: 20, left: 60}}>
    {children}
</div>

export const BlueAlert = ({title, children, dismissible, onClick}) => <InternAlert title={title} children={children}
                                                                                   dismissible={dismissible}
                                                                                   onClick={onClick}
                                                                                   color="blue-light"/>

BlueAlert.defaultProps = {
    dismissible: true,
}

Alert.propTypes = {
    title: PropTypes.string,
    children: PropTypes.element.isRequired,
}