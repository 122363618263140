import React from 'react';
import { Header } from "./components"
import { MainRouter } from "./MainRouter";

export const App = () => {
    return (<div className="App">
        <Header />
        <MainRouter />
    </div>)
}

export const AutosuggestTheme = {
    suggestionsContainer: 'w-64 absolute mt-1 z-30',
    suggestionsList: 'list-reset w-full border border-b-0 border-dst-logo-gruen-mitte bg-dst-hintergrund-hellgruen dark:bg-black',
    suggestion: 'w-full border border-t-0 border-l-0 border-r-0 border-dst-logo-gruen-mitte p-1 cursor-pointer '
        + 'bg-dst-hintergrund-hellgruen text-left text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte',
}

