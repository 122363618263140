import React, {useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {GET_KURSTERMIN_BEMERKUNGEN, MUTATE_BEMERKUNG_ERSTELLEN} from "../Queries";
import {
    Kurstermin_Bemerkung_soll_bearbeitet_werden,
    Kurstermin_Bemerkung_soll_erstellt_werden
} from "../Daten/BemerkungenMessages.gen";
import {btnCancel, btnOK, EditorButton, Loading, Textarea} from "../components";
import {sendMessage} from "../SendMessages";


export const Bemerkung2 = ({id}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [bemerkungen, setBemerkungen] = useState([])
    const [neueBemerkung, setNeueBemerkung] = useState("")
    const [edited, setEdited] = useState({
        Id: "",
        text: ""
    })

    const {loading, refetch } = useQuery(GET_KURSTERMIN_BEMERKUNGEN,
        {
            variables: {id},
            onCompleted: (data) => {
                if(data) {
                    setBemerkungen(data.kurstermine?.[0]?.bemerkungen)
                }
            }}
    );

    const [erstelleBemerkung,
        {error: speicherError},
    ] = useMutation(MUTATE_BEMERKUNG_ERSTELLEN, {
        onCompleted: (data) => {
            if (data[Kurstermin_Bemerkung_soll_erstellt_werden] === "ok") {

            }
        }
    });

   function saveBemerkung(){
        if(neueBemerkung){
            const message = new Kurstermin_Bemerkung_soll_erstellt_werden(id, neueBemerkung)
            erstelleBemerkung({variables: {message: JSON.stringify(message)}}).then(() => {
                refetch({id}).then(r => {
                    setBemerkungen(r?.data?.kurstermine[0]?.bemerkungen)
                })
                resetBemerkung()
            })
        }
    }

    const onBemerkungChange = () =>{
        setIsEditing(true)
       if(edited && edited.Id) {
           const message = new Kurstermin_Bemerkung_soll_bearbeitet_werden(edited?.Id, edited?.text)
           sendMessage(message).then((r) => {
               console.log(r.data)
           })
       }

    }

   function resetBemerkung(){
       setNeueBemerkung("")
    }


   return <div className={"w-full inline-block"}>

       <Textarea  className={""}
           title={"Bemerkungen"}
           type={"text"}
           name={"bemerkung"}
           onChange={e => {setNeueBemerkung(e.target.value); setIsEditing(true)}}
           value={neueBemerkung}

       />
     {isEditing && <EditorButton name={"btnSaveBemerkung"} type={"submit"} onClick={e => saveBemerkung(e)}  className={"rounded-l"}>{btnOK}</EditorButton>}
     {isEditing && <EditorButton name={"btnResetBemerkung"} type={"reset"} onClick={e => resetBemerkung(e)}  className={"rounded-l"}>{btnCancel}</EditorButton>}

       { (loading) && <Loading />}
       {(!loading) &&
           <div>
               {speicherError && <span className={"border-2 border-red-dark"}>Beim Speichern der Bemerkung ist ein Fehler aufgetreten.</span>}
               {bemerkungen?.map(item => {
                   return <div className={"inline-flex w-full border-b-2"} key={item.Id}>
                        <div className={"w-3/4"}>
                       <textarea className={"w-5/6 inline-flex"} value={item.Id === edited?.Id ? edited.text : item.text}
                                 onChange={e => setEdited({...edited, Id: item.Id, text: e.target.value})}
                                 onKeyDown={e => {
                                     if (e.keyCode === 27) {
                                         setEdited(null)
                                    }
                                 }}
                                 />
                            <div className={"w-1/6 inline-flex p-2 align-top" }>
                                <EditorButton name={"btnSaveOldBemerkung"} type={"submit"} onClick={() => onBemerkungChange()}  className={"rounded-l"}>{btnOK}</EditorButton>
                                <EditorButton name={"btnResetOldBemerkung"} type={"reset"} onClick={() => setEdited(null)}  className={"rounded-l"}>{btnCancel}</EditorButton>
                            </div>
                        </div>
                       <div className="flex justify-between w-1/4 text-sm mb-2 pl-2">
                           <div className="w-1/2">Autor: {item?.autor?.name}</div>
                           <div className="w-1/2 text-right">Erstellt: {item?.erstellt?.humanReadable}</div>
                           <div className="w-1/2 text-right">Bearbeitet: {item?.bearbeitet?.humanReadable}</div>
                       </div>
                   </div>
               })}
           </div>
       }
   </div>

}

