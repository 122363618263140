
export function textValueFromPreis(preis) {
    if (preis === null) {
        return ""
    }
    const val = numberValueFromPreis(preis)
    return textValueFromFloat(val)
}

export function textValueFromFloat(val) {
    return val?.toLocaleString('de-CH', {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
}
export function numberValueFromPreis(preis) {
    if (preis === null) {
        return NaN
    }
    let val = preis?.wert
    if (typeof val === 'string' || val instanceof String) {
        val = val.replace(",", ".")
        val = parseFloat(val)
    }
    return val
}

// helper for filter fields, sets only if value has really changed and resets to default, if field is empty
export const onFilterChange = (setter, defaultValue) => (newValue) => {
    // set filter to default value
    if (!newValue || newValue.trim() === "") { setter(defaultValue) }
    // only set new filter, if the value has changed
    else { setter(prevValue => newValue === prevValue ? prevValue : newValue) }
}

export const sortByName = (a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);

export const formattingByIndex = (index) => index % 2 === 0 ? "" : "bg-blue-lightest";

export const lowerAndTrim = (string) => string.trim().toLowerCase();

export const ifFieldNull = (object, field, ifNullValue) => object ? object[field] : ifNullValue;

export const notImplemented = () => alert("Nicht implementiert");