import React, {useState} from "react";
import {
    ADD_TN, CANCEL_BUCHUNG,
    EDIT_EMAIL,
    EDIT_FIRMA, EDIT_NUMMER, EDIT_TEILNEHMER, EDIT_TELEFON, EDIT_USTID,
    GET_BUCHUNG_TEILNEHMER,
    GET_BUCHUNGDETAILS, GET_EXTERNE_ANFORDERUNGEN,GET_MOODLE_KURSE, MAILSENDEN,
    PREIS_AENDERN,

    REMOVE_TN, RVERSAND, TRAGE_RECHNUNG

} from "../Queries";
import {useLocation, useParams} from "react-router-dom";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
    Bestaetigungsemail_soll_versendet_werden,
    Buchung_soll_storniert_werden,
    Buchung_Verwaltungskontakt_Firma_soll_hinterlegt_werden,
    Kontakt_Email_hinterlegen,
    Kontakt_Name_soll_korrigiert_werden,
    Kontakt_Telefonnummer_hinterlegen, Kursbuchung_Affiliate_Info_soll_hinzugefuegt_werden,
    Kursbuchung_Bestellnummer_hinterlegen,
    Kursbuchung_Gutscheincode_soll_hinterlegt_werden,
    Kursbuchung_Preis_soll_geaendert_werden,
    Kursbuchung_Rechnungsanschrift_soll_geaendert_werden,
    Kursbuchung_Teilnehmer_soll_hinzugefuegt_werden,
    Kursbuchung_Ust_Id_hinterlegen, Rechungsstellung_soll_eingetragen_werden,
    Teilnehmer_soll_storniert_werden
} from "../Daten/CheckinMessages.gen";
import {
    LaenderAutosuggestField,
    WaehrungAutoSuggestField
} from "../AutoSuggestFields";
import {
    AnredeField, BulkUpload,
    Input,
    InputFieldWithClassName,
    Loading,
    TD,
    TeilnehmerNeuZeile,
    TH,
    CSVtoArray,
} from "../components";
import {BuchungVerschieben2} from "./BuchungVerschieben2";
import {TeilnahmeBestaetigen2} from "./TeilnahmeBestaetigen2";
import {sendMessage} from "../SendMessages";
import {BuchungBemerkung} from "./BuchungBemerkung";
import {MoodeAnmeldung} from "../Kursdetails/MoodleAnmeldung";
import {moodle_host} from "../index";
import { AbaninjaRechnungAnlegen } from "./AbaninjaRechnungAnlegen";


//r.data.Kursbuchung_Teilnehmer_soll_hinzugefuegt_werden = undefined;
export const BuchungDetails2 = () => {
    let {id} = useParams();
    let {pathname} = useLocation()
    let termin = pathname.split("/")[2]
    console.log(termin)
    const [neuerPreis, setNeuerPreis] = useState({})
    const [bucher, setBucher] = useState({})
    const [buchung, setBuchung] = useState({})
    const [addTeilnehmer, setAddTeilnehmer] = useState(false)
    const [tn, setTn] = useState({vorname: "", nachname: "", email: "", anrede: "W", titel: ""});
    const defaultWaehrung = {name: "Euro", code: "EUR", symbol: "€"}
    const [rechnungsnummer, setRechnungsNummer] = useState("");
    const [affiliateInfo, setAffiliateInfo] = useState("")
    const [gutschein, setGutscheincode] = useState("")
    const [bucherName, setBuchername] = useState({
        vorname: "",
        nachname: ""
    })

    const[kcode, setKcode] =useState("");
    const [rechnungAdresse, setAdresse] = useState({})
    const [getMoodleData, {loading: moodleLoading, data: moodleData}] = useLazyQuery(GET_MOODLE_KURSE)

    const [addMultiple, setAddMultiple] = useState("Nachname\tVorname\tEmail")


    const {loading: getLoading, refetch: refetch} = useQuery(GET_BUCHUNGDETAILS,
        {
            variables: {buchung: id},
            fetchPolicy: "network-only",
            onCompleted: (data) => {
                setKcode( data?.buchungen[0]?.kurstermin?.kursCode)
                buchungsDatenAktualisieren(data);
                // getMoodleData({variables: {kuteKursCode: data?.buchungen[0].kurstermine?.kursCode}})
                console.log(kcode)
            }
        }
    );


    function legeMehrereTnAn () {

        let inputArray = addMultiple.split("\n")
        let teilnehmer = buchung?.teilnehmer ?? []
        let oldlength = teilnehmer.length

        inputArray.forEach((tnZeile) => {
            let tnDaten = CSVtoArray(tnZeile) //tnZeile.split("\t")
            if (tnDaten.length === 3) {
                let neuerTeilnehmer = {
                    "anrede": "0",
                    "titel": "",
                    "vorname": tnDaten[1],
                    "nachname": tnDaten[0],
                    "email": tnDaten[2]
                }
                teilnehmer.push(neuerTeilnehmer)
            } else {
                alert(`Fehler beim Einlesen von ${tnDaten}`)
            }

        })

        alert(`Füge ${teilnehmer.length - oldlength} Teilnehmer hinzu`)

        setBuchung({...buchung, teilnehmer : teilnehmer})


    }
//let moodleCourseId
    function buchungsDatenAktualisieren(data) {
        if (data) {
            if (data?.buchungen[0]) {
                setBuchung(data?.buchungen[0])
                setBucher(data?.buchungen[0].bucher)
                setNeuerPreis(data?.buchungen[0].preis)
                setAdresse(data?.buchungen[0]?.bucherRechnungsAdresse)
                setBuchername({
                    ...bucherName,
                    vorname: data.buchungen[0].bucher.vorname,
                    nachname: data.buchungen[0].bucher.nachname
                })
                setRechnungsNummer(data?.buchungen[0]?.rechnungNummer)
                setAffiliateInfo(data?.buchungen[0]?.affiliateInfo ?? "")
                setGutscheincode(data?.buchungen[0]?.gutscheinCode ?? "")
                console.log(kcode)
                getMoodleData({variables: {kuteKursCode: data?.buchungen[0]?.kurstermin?.kursCode}})?.then(r => {

                    console.log(r)
                })

                getMoodleAnforderungen( {variables: {kursID: data?.buchungen[0]?.kurstermin?.Id}})?.then(()=>{})



            }
        }
    }




    const moodleCourseId = moodleData && (moodleData?.moodleKurse?.length === 1) ? moodleData?.moodleKurse[0].Id : undefined
console.log(moodleCourseId)


    const [aenderePreis,
        {loading: preisLoading, error: editError},
    ] = useMutation(PREIS_AENDERN, {
        onCompleted: (data) => {
            if (data[Kursbuchung_Preis_soll_geaendert_werden] === "ok") {

            }
        }
    });

    if(editError){alert("Der Preis konnte nicht geändert werden")}

    const onPreisChange = (wert, waehrung ) => {
       if (wert || waehrung){
            const message = new Kursbuchung_Preis_soll_geaendert_werden(buchung?.Id, {
                wert: wert,
                waehrung: waehrung.code ? waehrung?.code : defaultWaehrung.code
            });
            aenderePreis({variables: {message: JSON.stringify(message)}}).then(r => {
                    console.log(r.data);
                    refetch().then()
                }
            )
       }
    }


    const [removeTn,
        {loading: removeLoading, error: removeError},
    ] = useMutation(REMOVE_TN,{
        refetchQueries: [{ query: GET_BUCHUNG_TEILNEHMER, variables:{buchung: id} }],
        onCompleted: (data) => {
            if (data[Teilnehmer_soll_storniert_werden] === "ok") {
               refetch({buchung: id}).then((r)=>{
                   console.log(r)
                   buchungsDatenAktualisieren(r.data)

               })
            }
        }
    });
    if(removeError){alert("Der Teilnehmer konnte nicht stoniert werden")}

    const [addTn,
        {loading: addLoading},
    ] = useMutation(ADD_TN, {
        refetchQueries: [{ query: GET_BUCHUNG_TEILNEHMER, variables:{buchung: id} }],

        onCompleted: (data) => {
            if (data[Kursbuchung_Teilnehmer_soll_hinzugefuegt_werden] === "ok") {
                refetch({buchung: id}).then((r)=>{
                    console.log(r)
                    buchungsDatenAktualisieren(r.data)

                })
            }
        }
    });



    const onTeilnehmerChange = async (e) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        value = e.target.type === 'number' ? parseInt(value, 10) : value;
        let name = e.target.name;
        name = name.split('_')[2]
        let tnNeu = ({...tn, [name]: value})
        setTn(tnNeu)
    }



    const saveTeilnehmer = async () => {
        let neuBerechnen = true
        const message = new Kursbuchung_Teilnehmer_soll_hinzugefuegt_werden(buchung?.Id, tn?.anrede,
            tn?.titel,
            tn?.vorname, tn?.nachname, tn?.email, neuBerechnen)
        addTn({
            fetchPolicy: "network-only",
            variables: {message: JSON.stringify(message)},
        }).then(r => {
            if(r.data.Kursbuchung_Teilnehmer_soll_hinzugefuegt_werden === "ok"){
                setTimeout(()=>{
                    refetch({id}).then( refetchR => {
                        console.log(refetchR.data)
                        buchungsDatenAktualisieren(refetchR.data)
                        setTn({vorname: "", nachname: "", email: "", anrede: "W"})
                        setAddTeilnehmer(false);
                    })
                }, 1  )
        }
       else setAddTeilnehmer(false);

    })
    }


    const stornoTn = async (tnId) => {
        let teilnehmer
        for (const tn of buchung?.teilnehmer) {
            if (tn.Id === tnId) {
                teilnehmer = tn
                break
            }
        }
        let neuBerechnen = true
        if (window.confirm('Preis neu berechnen')){
            neuBerechnen = false
        }
        const message = new Teilnehmer_soll_storniert_werden(tnId, buchung.Id, neuBerechnen)
       removeTn({
            variables: {message: JSON.stringify(message)},
            refetchQueries: [{query: GET_BUCHUNG_TEILNEHMER, variables: buchung.Id}],
        },).then(r => {
                console.log(r.data);
                if(r.data.Teilnehmer_soll_storniert_werden === "ok"){
                    refetch().then((refetchR)=>{
                        buchungsDatenAktualisieren(refetchR.data)
                    })
                }
                else
                    neuBerechnen = false;
            }
        )
    }


    const  [editFirma,
        {loading: editloading},
    ] = useMutation(EDIT_FIRMA, {
        refetchQueries: [{ query: GET_BUCHUNG_TEILNEHMER, variables:{buchung: id} }],
        onCompleted: (data) => {
            if (data[Buchung_Verwaltungskontakt_Firma_soll_hinterlegt_werden] === "ok") {
            }
        }
    });

    const saveFirma = (firma)=>{
        if(firma){
            const message = new Buchung_Verwaltungskontakt_Firma_soll_hinterlegt_werden(buchung?.Id, firma)
            editFirma({variables: {message: JSON.stringify(message)}}).then(r => {
                console.log(r.data)
            })
        }
    }


    const  [editEmail,
        {},
    ] = useMutation(EDIT_EMAIL, {
        refetchQueries: [{ query: GET_BUCHUNG_TEILNEHMER, variables:{buchung: id} }],
        onCompleted: (data) => {
            if (data[Kontakt_Email_hinterlegen] === "ok") {
            }

        }
    });

    const saveEmail = (kontaktId, email) => {
        if(kontaktId && email){
            const message = new Kontakt_Email_hinterlegen(kontaktId, email)
            editEmail({variables: {message: JSON.stringify(message)}}).then(r => {
                if(r.data.Kontakt_Email_hinterlegen === "ok"){

                }
            })
        }
    }


const  [editTelefon,
        {},
    ] = useMutation(EDIT_TELEFON, {
        refetchQueries: [{ query: GET_BUCHUNG_TEILNEHMER, variables:{buchung: id} }],
        onCompleted: (data) => {
            if (data[Kontakt_Telefonnummer_hinterlegen] === "ok") {
            }
        }
    });

    const saveTelefon =(telefon) =>{
        if(telefon){
            const message = new Kontakt_Telefonnummer_hinterlegen(bucher?.Id, telefon)
            editTelefon({variables: {message: JSON.stringify(message)}}).then(r => {
                console.log(r.data)
            })
        }
    }


   const  [editUst,
        {},
    ] = useMutation(EDIT_USTID, {
        refetchQueries: [{ query: GET_BUCHUNG_TEILNEHMER, variables:{buchung: id} }],
        onCompleted: (data) => {
            if (data[Kursbuchung_Ust_Id_hinterlegen] === "ok") {
            }
        }
    });

    const saveUst =(ustid) =>{
        if(ustid){
            const message = new Kursbuchung_Ust_Id_hinterlegen(buchung?.Id, ustid)
            editUst({variables: {message: JSON.stringify(message)}}).then(r => {
                console.log(r.data)
            })
        }

    }

    const saveAffiliateInfo = (affiliateInfo) => {
        const message = new Kursbuchung_Affiliate_Info_soll_hinzugefuegt_werden(buchung?.Id, affiliateInfo ?? "")
        sendMessage(message).then(()=>{})
    }

    const saveGutscheinCode = (gutschein) => {
        const message = new Kursbuchung_Gutscheincode_soll_hinterlegt_werden(buchung?.Id, gutschein ?? "")
        sendMessage(message).then(()=>{})
    }

  const  [editNummer,
        {},
    ] = useMutation(EDIT_NUMMER, {
        refetchQueries: [{ query: GET_BUCHUNG_TEILNEHMER, variables:{buchung: id} }],
        onCompleted: (data) => {
            if (data[Kursbuchung_Bestellnummer_hinterlegen] === "ok") {
            }
        }
    });

    const saveBestellNbr =(nummer) =>{
        if(nummer){
            const message = new Kursbuchung_Bestellnummer_hinterlegen(buchung?.Id, nummer)
            editNummer({variables: {message: JSON.stringify(message)}}).then(r => {
              console.log(r.data)
            })
        }
    }


    const  [rechnungsenden,
        {},
    ] = useMutation(RVERSAND, {
        onCompleted: (data) => {
            if (data[Kursbuchung_Rechnungsanschrift_soll_geaendert_werden] === "ok") {
            }
        }
    });

    const  [bstornieren,
        {loading: bsloading, error: bserror },
    ] = useMutation(CANCEL_BUCHUNG, {
        onCompleted: (data) => {
            if (data["Buchung_soll_storniert_werden"] === "ok") {
                refetch().then(r =>
                    buchungsDatenAktualisieren(r.data)
                )
            }
        }
    });
    const  [mailsenden,
        {loading: msloading, error: mserror },
    ] = useMutation(MAILSENDEN, {
        onCompleted: (data) => {
            if (data[Bestaetigungsemail_soll_versendet_werden] === "ok") {
            }
        }
    });


    const onVersandChange = (versandArt) =>{
          const  message = new Kursbuchung_Rechnungsanschrift_soll_geaendert_werden(buchung.Id,
                buchung.bucherFirma,
                buchung.bucher.vorname,
                buchung.bucher.nachname,
                buchung.bucherRechnungsAdresse.abteilung,
                buchung.bucherRechnungsAdresse.strasse,
                buchung.bucherRechnungsAdresse.plz,
                buchung.bucherRechnungsAdresse.ort,
                buchung.bucherRechnungsAdresse.land.iso, (versandArt === "perPost"))

        rechnungsenden({variables: {message: JSON.stringify(message)}}).then(r => {
            console.log(r.data)
        })
    }
    const saveAdresse = () =>{
            if(rechnungAdresse) {
                const  message = new Kursbuchung_Rechnungsanschrift_soll_geaendert_werden(buchung.Id,
                    buchung.bucherFirma,
                    rechnungAdresse.vorname,
                    rechnungAdresse.nachname,
                    rechnungAdresse.abteilung,
                    rechnungAdresse.strasse,
                    rechnungAdresse.plz,
                    rechnungAdresse.ort,
                    rechnungAdresse.land.iso, !!buchung.rechnungPerPost)
                rechnungsenden({variables: {message: JSON.stringify(message)}}).then(r => {
                    console.log(r.data)
                })
            }

    }

    const handleClick = (e) => {
        let message
        if(e.target.name === "stornieren"){
            message = new Buchung_soll_storniert_werden(buchung?.Id)
            setBuchung({...buchung, storniert: true})
            bstornieren({variables: {message: JSON.stringify(message)}}).then(r => {
                console.log(r.data)
            })
        }
        else if(e.target.name === "bestaetigungsemail"){
            message = new Bestaetigungsemail_soll_versendet_werden(buchung?.Id);
            mailsenden({variables: {message: JSON.stringify(message)}}).then(r => {
                console.log(r.data)
            })
        }
    }


    const  [trageRechnung,
        {},
    ] = useMutation(TRAGE_RECHNUNG, {
        onCompleted: (data) => {
            if (data[Kursbuchung_Ust_Id_hinterlegen] === "ok") {
            }
        }
    });

    const saveRechnung =(nummer) =>{
        if(nummer){
            const message = new Rechungsstellung_soll_eingetragen_werden(buchung?.Id, nummer)
            trageRechnung({variables: {message: JSON.stringify(message)}}).then(r => {
                console.log(r.data)
            })
        }
    }



    const  [editteilnehmer,
        {loading: loadtn, error: tnError},
    ] = useMutation(EDIT_TEILNEHMER, {
        refetchQueries: [{ query: GET_BUCHUNG_TEILNEHMER, variables:{buchung: id} }],
        onCompleted: (data) => {
            if (data[Kontakt_Name_soll_korrigiert_werden] === "ok") {
            }
        }
    });

    const editTeilnehmer = (teilnehmer) => {
        if(teilnehmer) {
           const message = new Kontakt_Name_soll_korrigiert_werden(teilnehmer?.kontaktId, teilnehmer?.anrede?.code, teilnehmer.titel, teilnehmer.nachname, teilnehmer.vorname)
            editteilnehmer({variables: {message: JSON.stringify(message)}}).then(r => {
                console.log(r.data)
                refetch().then( (r) => {
                    buchungsDatenAktualisieren( r.data )
                })
            })
        }
    }


    const saveBucher = () => {
        if(buchung?.bucher?.vorname && buchung?.bucher?.nachname){
            const message = new Kontakt_Name_soll_korrigiert_werden(buchung?.bucher?.Id, bucher?.anrede?.code, bucher.titel, bucherName.nachname, bucherName.vorname)
            editteilnehmer({variables: {message: JSON.stringify(message)}}).then(r => {
                console.log(r.data)
                refetch().then(r=>{
                    buchungsDatenAktualisieren( r.data )
                })
            })
        }
    }


  const [getMoodleAnforderungen, {
        data: moodleAnforderungen
    }] = useLazyQuery(GET_EXTERNE_ANFORDERUNGEN);

    const TeilnehmerZeile2 = ({TN, onSaveTn}) =>{
        const [teilnehmer, setTeilnehmer] = useState( TN ? TN : {
            anrede:"0",
            vorname: "",
            nachname: "",
            titel: "",
            email: "",
           // moodleTeilnehmer:""
        })

        const onEditChange = (e) => {
            if(e.target.name === "anrede"){
                const message = new Kontakt_Name_soll_korrigiert_werden(teilnehmer?.kontaktId, e.target.value, teilnehmer.titel, teilnehmer.nachname, teilnehmer.vorname)
                editteilnehmer({variables: {message: JSON.stringify(message)}}).then(r => {
                    console.log(r.data)
                    refetch().then(r=>{
                        buchungsDatenAktualisieren( r.data )
                    })
                })
            }
        else   setTeilnehmer({...teilnehmer, [e.target.name]: e.target.value})
        }

        if (!!teilnehmer.storniert) {
            return <tr>
                <td className="max-w-1/4">{teilnehmer?.anrede?.text} {teilnehmer?.titel} {teilnehmer?.vorname} {teilnehmer?.nachname}</td>
                <td className="max-w-1/4">{teilnehmer?.email}</td>
                <td className="max-w-1/4"><span
                    className="inline-block badge badge-rot text-xs">storniert</span></td>
            </tr>
        }

        if(moodleLoading)
            return <Loading />

        return <tr>
                <td><AnredeField name={"anrede"} title="Anrede"
                                                     value={teilnehmer?.anrede?.code}
                                                     onChange={(e) => onEditChange(e)}
                /> {loadtn && <Loading/>} {tnError && alert(tnError)}
                </td>
                <td className={"w-1/5"}>  <Input name={"vorname"}
                                               type={"text"}
                                               value={teilnehmer?.vorname}
                                               onChange={(e) => onEditChange(e)}
                                               onBlur={() => editTeilnehmer(teilnehmer)}
                                               onKeyUp={(e) => {if(e.keyCode === 13) onSaveTn(teilnehmer)}}
                /></td>
                <td className={"w-1/5"}> <Input name={"nachname"}
                            type={"text"}
                            value={teilnehmer?.nachname}
                            onChange={(e) => onEditChange(e)}
                            onKeyUp={(e) => {if(e.keyCode === 13) onSaveTn(teilnehmer)}}
                            onBlur={() => editTeilnehmer(teilnehmer)}
                />  </td>
                <td className={"w-1/3"}>
                    <Input name={"email"}
                           type={"text"}
                           value={teilnehmer?.email}
                           onChange={(e) => onEditChange(e)}
                           onKeyUp={(e) => {if(e.keyCode === 13)  saveEmail(teilnehmer?.kontaktId, e.target.value)}}


                /></td>


                { !!teilnehmer?.storniert ||
                <td>  <button onClick={() =>{ if (window.confirm("Teilnehmer "+ teilnehmer.vorname+ "  "+ teilnehmer.nachname+ " stonieren?"))  stornoTn(teilnehmer?.Id)}} >
                    ❌
                </button>{(removeLoading || getLoading) && <Loading />}  </td> }
            {!!teilnehmer?.storniert || <td className="max-w-1/4"><TeilnahmeBestaetigen2 teilnehmerId={teilnehmer?.Id}
                                                                                         kursId={buchung?.kurstermin?.Id}
                                                                                         buchungId={buchung?.Id}
                                                                                         bestaetigt={teilnehmer?.bestaetigt}/></td>
            }
            <td>
                <MoodeAnmeldung     teilnehmer={teilnehmer}
                                    onChanged={() => { refetch({id: buchung?.kurstermin?.Id}).then(() => {})}}
                                    kursId={buchung?.kurstermin?.Id}
                                    moodleCourseId={moodleCourseId}
                                    moodleHost={moodle_host}
                                    externeEinladungAngefordert={moodleAnforderungen?.moodleExterneAnforderungen?.find((item) => {
                                    return (item.kontaktID === teilnehmer.kontaktId)})}/>
            </td>
        </tr>
    }
    function changeBucherName(e) {
        switch(e.target.name) {
            case "buchervorname":
                setBuchername({...bucherName, vorname: e.target.value})
                break;
            case "buchernachname":
                setBuchername({...bucherName, nachname: e.target.value})

                break;
            default:
                // what?
        }
    }


    return <div>

        <a href={"/kurstermine/" + buchung?.kurstermin?.Id}>
            <span className={"inline-block badge " + (!!buchung?.storniert ? "badge-rot" : "badge-gruen")}>
                                {buchung?.storniert ? "storniert" :  (buchung?.verschoben&& ((buchung?.verschoben?.Id) !== termin) ? "verschoben nach " +buchung?.verschoben?.kursCode
                                    : (buchung?.verschoben && buchung.verschoben.Id === termin) ? " hierher verschoben":  kcode + " - aktiv")}</span>
        </a>

            <h2 className="heading">Buchungsdaten</h2>
            {getLoading  && <Loading />} { editloading && <Loading />}


        <table className="w-full alternating-table border-4">
        <thead className="text-left">
            <tr>
                <th>Anrede</th>
                <th>Vorname</th>
                <th>Name</th>
                <th>Email</th>
                <th>Storno</th>
                <th>Bestätigen</th>
                <th>Moodle</th>
            </tr>

            </thead>

            <tbody>
            {buchung && buchung?.teilnehmer?.map((teilnehmer, index) => <TeilnehmerZeile2
                teilnehmer={teilnehmer}
                key={teilnehmer?.Id}
                TN={teilnehmer}
                storno={(teilnehmer) => stornoTn(teilnehmer)}
                onSaveTn={editTeilnehmer}
                index={index}/>)}
            {buchung && (!!buchung?.storniert || (addTeilnehmer) ? <tr>
                    <td colSpan={5}><TeilnehmerNeuZeile index={0} teilnehmer={buchung?.teilnehmer}
                                                        onChange={(e) => {onTeilnehmerChange(e)}}/></td>
                    <td>
                        <button onClick={() => saveTeilnehmer()}
                                className="btn btn-gruen h-8">Speichern
                        </button> { addLoading && <Loading />} { getLoading && <Loading />}
                    </td>
                </tr> : <tr>
                    <td colSpan={2}>Teilnehmer hinzufügen
                    <button className="mt-2 btn btn-gruen"
                            onClick={() => setAddTeilnehmer(true)}
                            type="button">+
                    </button>
                    </td>
                    </tr>
            )}

            </tbody>
        </table>
        <BulkUpload onClick={legeMehrereTnAn} text={addMultiple} onTextChange={(t) => {
            setAddMultiple(t)
        }}/>
        <h2 className="heading">Rechnungsdaten</h2>
        <table className="buchungdetails-details alternating-table w-full">


            <tbody>
            <tr>

            </tr>

            <tr>
                <TH>Rechnungsnummer</TH>
                <TD>
                     <InputFieldWithClassName value={rechnungsnummer} onChange={(e)=>setRechnungsNummer(e.target.value)}
                            placeholder={"Rechnungsnummer"}
                            onKeyUp={(e) => {if(e.keyCode === 13) {e.target.blur()}}}
                            onBlur={() => saveRechnung(rechnungsnummer)}
                     />
                    {!buchung.storniert &&  !rechnungsnummer && <AbaninjaRechnungAnlegen buchung={buchung}/>}

                </TD>
            </tr>
            <tr>
                <TH>Firma</TH>
                <InputFieldWithClassName title={"Firma"} type={"text"}
                                         name={"firma"}
                                         value={buchung?.bucherFirma}
                                         onChange={(e) => setBuchung({...buchung, bucherFirma: e.target.value}) }
                                         onKeyUp={(e) => {if(e.keyCode === 13){
                                             e.target.blur()
                                         }}}
                                         onBlur={() =>saveFirma(buchung?.bucherFirma) }
                />

            </tr>

            <tr>
                <TH>Vorname</TH>
                <TD>
                    <InputFieldWithClassName title={"Vorname"} type={"text"} name={"buchervorname"}
                                             value={bucherName?.vorname} onChange={(e) => changeBucherName(e)}
                                             onKeyUp={(e) => {if(e.keyCode === 13) {
                                                 e.target.blur()
                                             }}}
                                             onBlur={() => saveBucher()}/>
                </TD>  </tr>
            <tr>
                <TH>Nachname</TH>
                <TD>     <InputFieldWithClassName title={"nachname"} type={"text"} name={"buchernachname"} onChange={(e)=> changeBucherName(e)}
                                                  value={bucherName?.nachname}
                                                  onKeyUp={(e) => {if(e.keyCode === 13) {
                                                      e.target.blur()
                                                  }}}
                                                  onBlur={() => saveBucher()}/>
                </TD>
            </tr>
            <tr>  <TH>Rechnungsadresse</TH>
                <TD>
                <div>
                    <input value={rechnungAdresse?.abteilung} placeholder={"Abteilung"} name={"abteilung"}
                           onChange={e => setAdresse({...rechnungAdresse, abteilung: e.target.value})}
                           onKeyUp={(e) => {if(e.keyCode === 13) {e.target.blur()}}}
                           onBlur={saveAdresse} />&nbsp;
                    <input value={rechnungAdresse?.strasse} placeholder={"Strasse, Hnbr"} name={"strasse"}
                           onChange={e => setAdresse({...rechnungAdresse, strasse: e.target.value})}
                           onKeyUp={(e) => {if(e.keyCode === 13) {e.target.blur()}}}
                           onBlur={saveAdresse} /> <br />
                    <input className={"w-16 mr-0"} value={rechnungAdresse?.plz} placeholder={"PLZ"} name={"plz"}
                           onChange={e => setAdresse({...rechnungAdresse, plz: e.target.value})}
                           onKeyUp={(e) => {if(e.keyCode === 13) {e.target.blur()}}}
                           onBlur={saveAdresse}/>&nbsp;
                    <input className={"w-48"} value={rechnungAdresse?.ort} placeholder={"Ort"} name={"ort"}
                           onChange={e => setAdresse({...rechnungAdresse, ort: e.target.value})}
                           onKeyUp={(e) => {if(e.keyCode === 13) {e.target.blur()}}}
                           onBlur={saveAdresse} /> <br />
                    <LaenderAutosuggestField  initialItem={rechnungAdresse?.land} setValueObject={val => setAdresse({...rechnungAdresse, land: val.iso})} />

                </div>
                </TD>

            </tr>
            <tr>
                <TH>Rechnungsversand</TH>
                <TD>
                    <select value={(buchung?.rechnungPerPost)? "perPost" : "perEmail"}
                            name={"versand"}
                            onChange={(e) => onVersandChange(e.target.value)}>
                        <option value={"perPost"}>per Post</option>
                        <option value={"perEmail"}>per Mail 📧</option>
                    </select>
                    <span className={"pl-2"}>Buchungssprache: {buchung?.buchungssprache?.name} </span>
                </TD>
            </tr>
            <tr>  <TH>Bestellnummer</TH>
                <TD>
                    <InputFieldWithClassName value={buchung?.bestellNummer} title={"Bestellnummer"}
                                             name={"bestellnummer"}  saveBestellNbr
                                             onChange={(e) => setBuchung({...buchung, bestellNummer: e.target.value})}
                                             onKeyUp={(e) => {if(e.keyCode === 13) {e.target.blur()} }}
                                             onBlur={()=>saveBestellNbr(buchung?.bestellNummer)}

                    />
                </TD>
            </tr>
            <tr>
                <TH>Ust-ID</TH>
                <TD>
                    <InputFieldWithClassName value={buchung?.ustId} title={"ustId"}
                                             name={"ustId"} type={"text"}
                                             onChange={(e) => setBuchung({...buchung, ustId: e.target.value})}
                                             onKeyUp={(e) => {if(e.keyCode === 13) {e.target.blur()}}}
                                             onBlur={()=>saveUst(buchung?.ustId) }
                    />
                </TD>

            </tr>
            <tr>
                <TH>Affiliate Info</TH>
                <TD>
                    <InputFieldWithClassName value={affiliateInfo} onChange={(e) => {setAffiliateInfo(e.target.value)}}
                                             placeholder={"Affiliate Info"}
                                             onKeyUp={(e) => {if(e.keyCode === 13) {e.target.blur()}}}
                                             onBlur={() => saveAffiliateInfo(affiliateInfo)}
                    />
                </TD>
            </tr>

            <tr>
                <TH>Gutscheincode</TH>
                <TD>
                    <InputFieldWithClassName value={gutschein} onChange={(e) => {setGutscheincode(e.target.value)}}
                                             placeholder={"Gutscheincode"}
                                             onKeyUp={(e) => {if(e.keyCode === 13) {e.target.blur()}}}
                                             onBlur={() => saveGutscheinCode(gutschein)}
                    />
                </TD>
            </tr>

            <tr>
                <TH>Telefonnummer</TH>
                <TD>
                    <InputFieldWithClassName value={bucher?.telefon}
                                             title={"Telefon"} name={"telefon"}
                                             onChange={(e)=> setBucher({...bucher, telefon: e.target.value})}
                                             onKeyUp={(e) => {if(e.keyCode === 13) {e.target.blur()}}}
                                             onBlur={()=>saveTelefon(bucher?.telefon)}
                    />
                </TD>
            </tr>

            <tr>

                <TH>Bucher Email-Adresse</TH>
                <InputFieldWithClassName title={"email"} type={"text"}
                                         name={"firma"}
                                         value={bucher?.email}
                                         onChange={(e) =>setBucher({...bucher, email: e.target.value})}
                                         onKeyUp={(e) => {if(e.keyCode === 13) saveEmail(bucher.Id, bucher.email)}}

                />


            </tr>
            <tr>
                <TH>Kommentar</TH>
                <TD>
                    <textarea disabled={true} value={buchung?.kommentar}/>
                </TD>

            </tr>

            <tr>
                <TH>Buchungszeitpunkt</TH>
                <td>{buchung?.zeitpunkt?.humanReadable}</td>
            </tr>
            <tr> <TH> Preis</TH>
                <InputFieldWithClassName  title={"preis"}
                                          type={"text"}
                                          name={"wert"}
                                          value={neuerPreis?.wert}
                                          onChange={(e) => setNeuerPreis({...neuerPreis, wert: e.target.value})}
                                          onKeyUp={(e) => {if(e.keyCode === 13) {e.target.blur()}}}
                                          onBlur={()=>onPreisChange(neuerPreis?.wert, neuerPreis.waehrung)}

                /> {preisLoading && <Loading/>}
                <WaehrungAutoSuggestField initialItem={neuerPreis?.waehrung} setValueObject={(suggestion) => {onPreisChange(neuerPreis.wert, suggestion)}}/></tr>

            </tbody>
        </table>

        <ul className="buchung-status-actions list-reset flex justify-between">
            <li className="h-8">
                <ul className="list-reset flex">
                    {(!!buchung?.storniert ) ||
                    <li className="h-8 mr-3">
                        <button className={"btn btn-rot"}
                            name="stornieren"
                                 onClick={(e)=>{if (window.confirm("Buchung wirklich stonieren?")) handleClick(e)}}> Stornieren
                        </button> &nbsp;
                    </li>
                    }
                    {(!!buchung?.storniert ) ||
                    <li className="h-8"> <BuchungVerschieben2 buchungId={buchung?.Id}
                                                              kursId={buchung?.kurstermin?.Id}
                    />&nbsp;
                    </li>
                    }

                    {(!!buchung?.storniert) ||
                    <li className="h-8">
                        <button className={"btn btn-gruen"}
                                name="bestaetigungsemail"
                                value={buchung?.bestaetigungsMailVersenden}
                                onClick={(e) => handleClick(e)}> Bestätigungsmail versenden
                        </button> {(msloading || bsloading) && <Loading/>} {(bserror || mserror) && alert(bserror ? bserror : mserror)}
                    </li>
                    }

                </ul>
            </li>

        </ul>
        <BuchungBemerkung id={buchung?.Id} />
    </div>
}