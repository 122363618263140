/*gen*/class Make_Kurstermin_soll_auf_Moodle_angelegt_werden {
/*gen*/    static messageName = "Make_Kurstermin_soll_auf_Moodle_angelegt_werden"
/*gen*/	kurstermin_Id
/*gen*/	constructor(kurstermin_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/export {
/*gen*/    Make_Kurstermin_soll_auf_Moodle_angelegt_werden,
/*gen*/}