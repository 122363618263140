import React, {useState} from "react";
import {
    Kontakt_wird_Newsletterempfang_zustimmen,
    Kontakt_wird_Zustimmung_zum_Newsletterempfang_widerrufen
} from "../Daten/CheckinMessages.gen";
import {sendMessage} from "../SendMessages";

export const Newsletter2 = ({kontaktId, newsLetter}) =>{
    console.log(kontaktId)
    console.log(newsLetter)
    const [newsletterNeu, setNewsletterNeu] = useState(newsLetter);
    console.log(newsletterNeu)
    let willNewsletter = !newsLetter

    return <div> <button className={newsletterNeu ? "btn btn-rot" :"btn btn-gruen" } value={newsletterNeu} onClick={()=>{
        console.log(willNewsletter)
        let message = newsletterNeu ?
            new Kontakt_wird_Zustimmung_zum_Newsletterempfang_widerrufen(kontaktId)
            :  new  Kontakt_wird_Newsletterempfang_zustimmen(kontaktId)
        setNewsletterNeu(!newsletterNeu)
        sendMessage(message).then((r) => {
            console.log(r.data)
        })
    }
    }
       >
        {newsletterNeu ? "Newsletter widerrufen" : "Newsletter bestellen"} {/*{(wloading || bloading) && <Loading/>} {(berror || werror) && alert(werror ? werror : berror)}*/}
    </button>
    </div>

}