/*gen*/class Benutzer_soll_angelegt_werden {
/*gen*/    static messageName = "Benutzer_soll_angelegt_werden"
/*gen*/	benutzer_Id
/*gen*/	text
/*gen*/	constructor(benutzer_Id, text) {
/*gen*/		
/*gen*/			
/*gen*/			this.benutzer_Id = benutzer_Id
/*gen*/		
/*gen*/			
/*gen*/			this.text = text
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Benutzer_Berechtigungen_sollen_hinzugefuegt_werden {
/*gen*/    static messageName = "Benutzer_Berechtigungen_sollen_hinzugefuegt_werden"
/*gen*/	benutzer_Id
/*gen*/	berechtigungen
/*gen*/	constructor(benutzer_Id, berechtigungen) {
/*gen*/		
/*gen*/			
/*gen*/			this.benutzer_Id = benutzer_Id
/*gen*/		
/*gen*/			
/*gen*/			this.berechtigungen = berechtigungen
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Benutzer_Berechtigungen_sollen_entfernt_werden {
/*gen*/    static messageName = "Benutzer_Berechtigungen_sollen_entfernt_werden"
/*gen*/	benutzer_Id
/*gen*/	berechtigungen
/*gen*/	constructor(benutzer_Id, berechtigungen) {
/*gen*/		
/*gen*/			
/*gen*/			this.benutzer_Id = benutzer_Id
/*gen*/		
/*gen*/			
/*gen*/			this.berechtigungen = berechtigungen
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Benutzer_Email_soll_gesetzt_werden {
/*gen*/    static messageName = "Benutzer_Email_soll_gesetzt_werden"
/*gen*/	benutzer_Id
/*gen*/	email
/*gen*/	constructor(benutzer_Id, email) {
/*gen*/		
/*gen*/			
/*gen*/			this.benutzer_Id = benutzer_Id
/*gen*/		
/*gen*/			
/*gen*/			this.email = email
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Benutzer_Passwort_Login_soll_aktiviert_werden {
/*gen*/    static messageName = "Benutzer_Passwort_Login_soll_aktiviert_werden"
/*gen*/	benutzer_Id
/*gen*/	login
/*gen*/	passwort
/*gen*/	constructor(benutzer_Id, login, passwort) {
/*gen*/		
/*gen*/			
/*gen*/			this.benutzer_Id = benutzer_Id
/*gen*/		
/*gen*/			
/*gen*/			this.login = login
/*gen*/		
/*gen*/			
/*gen*/			this.passwort = passwort
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/export {
/*gen*/    Benutzer_soll_angelegt_werden,
/*gen*/    Benutzer_Berechtigungen_sollen_hinzugefuegt_werden,
/*gen*/    Benutzer_Berechtigungen_sollen_entfernt_werden,
/*gen*/    Benutzer_Email_soll_gesetzt_werden,
/*gen*/    Benutzer_Passwort_Login_soll_aktiviert_werden,
/*gen*/}