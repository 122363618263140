import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { gql, useSubscription, useQuery } from "@apollo/client"
import { Badge } from "./UI/Badge";
import { useHistory } from "react-router-dom";
import { GET_PERMISSIONS_AND_LOGGER, LOGGER_SUBSCRIPTION } from "./Queries"

export const Navigation = () => {
    // display badge on log tab
    const [currentLogVersion, setCurrentLogVersion] = useState({
        uuid: localStorage.getItem("currentLogVersionUUID"),
        version: localStorage.getItem("currentLogVersionVersion") ? parseInt(localStorage.getItem("currentLogVersionVersion"), 10) : 0,
    })
    const [logBadgeNumber, setLogBadgeNumber] = useState(localStorage.getItem("currentLogVersionLogBadgeNumber") ? parseInt(localStorage.getItem("currentLogVersionLogBadgeNumber"), 10) : 0)

    // get data for logger
    const { data } = useSubscription(LOGGER_SUBSCRIPTION)
    const { data: queryData } = useQuery(GET_PERMISSIONS_AND_LOGGER, { fetchPolicy: "network-only" })

    let history = useHistory();

    // update badge on new data for logger
    useEffect(() => {
        if (data && data.logger && data.logger.significantVersion) {
            const version = data.logger.significantVersion
            if (version.uuid !== currentLogVersion.uuid) {
                setCurrentLogVersion(version);
                setLogBadgeNumber(version.version)
                localStorage.setItem("currentLogVersionUUID", currentLogVersion.uuid)
                localStorage.setItem("currentLogVersionVersion", currentLogVersion.version)
                localStorage.setItem("currentLogVersionLogBadgeNumber", logBadgeNumber)
            } else if (version.version !== currentLogVersion.version) {
                setLogBadgeNumber(logBadgeNumber + (version.version - currentLogVersion.version))
                setCurrentLogVersion({ ...currentLogVersion, version: version.version })
                localStorage.setItem("currentLogVersionVersion", currentLogVersion.version)
                localStorage.setItem("currentLogVersionLogBadgeNumber", logBadgeNumber)
            }
        }
    }, [data, currentLogVersion, logBadgeNumber])

    // reset log badge on click
    const handleClick = () => {
        setLogBadgeNumber(0);
        localStorage.setItem("currentLogVersionLogBadgeNumber", 0);
        history.push("/log");
    }

    // check for permissions
    const may = queryData && queryData.berechtigungen &&
        (queryData.berechtigungen.includes('alle_Rechte') || queryData.berechtigungen.includes('administration'))


    return (
        <ul className="flex justify-around bg-dst-hintergrund-hellgruen border-t-4 border-b-4 border-dst-logo-gruen-mitte mb-4 pl-0">
            <Navlink link="/" label="Kurstermine" />
            <Navlink link="/kontakte" label="Kontakte" />
            <Navlink link="/trainer" label="Trainer" />
            <Navlink link="/kursorte" label="Kursorte" />
            <Navlink link="/regionen" label="Regionen" />
            <Navlink link="/statistics" label="Auswertungen" />
            {may && <li className="list-reset">
                <button onClick={handleClick}
                    className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte underline">Logs{logBadgeNumber > 0 &&
                        <Badge>{logBadgeNumber}</Badge>}</button>
            </li>}
            {may && <Navlink link="/admin" label="Eventlog" />}
            {may && <Navlink link="/user" label="Benutzer" />}
            {may && <Navlink link="/konfiguration" label="Konfiguration" /> }
            <LogoutButton />
        </ul>)
}

const LogoutButton = () => {
    const { loading, data } = useQuery(gql`{ loginUser }`, { fetchPolicy: "network-only" })
    return (
        <li className="list-reset">
            <button onClick={() => logoutUser()}
                className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte underline">
                {!loading && data.loginUser} abmelden
    </button>
        </li>)
}

const logoutUser = () => {
    localStorage.removeItem('Kute::LoginToken');
    window.location.reload();
}


const Navlink = ({ label, link }) => <li className="list-reset"><Link
    className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte"
    to={link}>{label}</Link></li>
