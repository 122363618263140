import React, {useState} from "react";
import {Loading, InputFieldWithClassName, labelClass} from "../components";
import {gql, useMutation, useQuery} from "@apollo/client";
import {MUTATE_FRUEHBUCHER_PREIS_SETZEN, MUTATE_PREIS_SETZEN} from "../Queries";
import {
    Kurstermin_Fruehbucher_Preis_soll_gesetzt_werden,
    Kurstermin_Preis_soll_gesetzt_werden
} from "../Daten/FlugplanMessages.gen";
import {WaehrungAutoSuggestField} from "../AutoSuggestFields";


export function Preisinformationen({id}) {

    const defaultFruehTageVorKurs = 56
    const defaultWaehrung = {name: "Euro", code: "EUR", symbol: "€"}

    const [preis, setPreis] = useState()
    const [fruehbucherPreis, setFruehbucherPreis] = useState()
    const [fruehGueltigBisTageVorKurs, setFruehGueltigBisTageVorKurs] = useState(defaultFruehTageVorKurs)
    const [waehrung, setWaehrung] = useState()

    const GET_KURSTERMIN_PREISE = gql`query kurstermin($id: String){kurstermine(Id: $id){
    preis{
        wert,
        waehrung: waehrungTyp{
            code,
            name,
            symbol
        }
    },
    fruehbucherPreis{
        preis{
            wert,
            waehrung: waehrungTyp{
                code,
                name,
                symbol
            }
        },
        gueltigBis{
            isoString,
            humanReadable
        },
        gueltig_bis_Tage_vor_dem_Kurs
    },
    Id
}}`

    const {loading, refetch} = useQuery(GET_KURSTERMIN_PREISE,
        {
            variables: {id},
            onCompleted: (data) => {
                if (data && data.kurstermine && data.kurstermine.length > 0) {
                    setPreis(data.kurstermine[0].preis?.wert)
                    setWaehrung(
                        data.kurstermine[0].preis?.waehrung
                            ? data.kurstermine[0].preis?.waehrung
                            : defaultWaehrung
                    )
                    setFruehbucherPreis(data.kurstermine[0].fruehbucherPreis?.preis?.wert)
                    setFruehGueltigBisTageVorKurs(
                        data.kurstermine[0].fruehbucherPreis?.gueltig_bis_Tage_vor_dem_Kurs
                            ? data.kurstermine[0].fruehbucherPreis?.gueltig_bis_Tage_vor_dem_Kurs
                            : defaultFruehTageVorKurs
                    )

                }
            }
        }
    )

    const [speicherePreis] = useMutation(MUTATE_PREIS_SETZEN, {
        onCompleted: (data) => {
            if (data[Kurstermin_Preis_soll_gesetzt_werden] === "ok") {
                console.log("Price saved")
            } else {
                resetPreis()
            }
        }
    });

    const [speichereFruehbucherPreis] = useMutation(MUTATE_FRUEHBUCHER_PREIS_SETZEN, {
        onCompleted: (data) => {
            if (data[Kurstermin_Fruehbucher_Preis_soll_gesetzt_werden] === "ok") {
                console.log("Early bord price saved")
            } else {
                resetFrueh()
            }
        }
    });


    function onFruehChange(e) {
        setFruehbucherPreis(e.target.value)
        if (!fruehGueltigBisTageVorKurs) {
            setFruehGueltigBisTageVorKurs(defaultFruehTageVorKurs)
        }
    }

    function onFruehBisChange(e) {
        setFruehGueltigBisTageVorKurs(e.target.value)
    }

    function savePreis(neuPreis, neuWaehrungCode) {
        const message = new Kurstermin_Preis_soll_gesetzt_werden(id, {wert: neuPreis, waehrung: neuWaehrungCode})
        speicherePreis({variables: {message: JSON.stringify(message)}}).then(r => {
                console.log(r)
            }
        )
    }

    function saveFruehbucherPreis(neuFBPreis, neuWaehrungCode, gueltigBisTageVor) {
        const message = new Kurstermin_Fruehbucher_Preis_soll_gesetzt_werden(
            id,
            {wert: neuFBPreis, waehrung: neuWaehrungCode},
            gueltigBisTageVor ? parseInt(fruehGueltigBisTageVorKurs) : defaultFruehTageVorKurs)
        speichereFruehbucherPreis({variables: {message: JSON.stringify(message)}}).then(r => {
                console.log(r)
            }
        )
    }

    function resetPreis() {
        refetch({id}).then(r => {
                setPreis(r.data?.kurstermine[0]?.preis?.wert)
            }
        )
    }

    function resetFrueh() {
        refetch({id}).then(r => {
            setFruehbucherPreis(r.data?.kurstermine[0]?.fruehbucherPreis?.preis?.wert)
            setFruehGueltigBisTageVorKurs(r.data?.kurstermine[0]?.fruehbucherPreis?.gueltig_bis_Tage_vor_dem_Kurs)

        })
    }


    if (loading) return <Loading/>

    function updateWaehrung(neuWaehrung) {
        setWaehrung(neuWaehrung)
        savePreis(preis, neuWaehrung.code)
        if (fruehbucherPreis) {
            saveFruehbucherPreis(fruehbucherPreis, neuWaehrung.code, fruehGueltigBisTageVorKurs)
        }
    }

    return <div className={"flex flex-wrap -mx-1 overflow-hidden"}>
            <div className={"my-1 px-1 w-1/4 overflow-hidden"}>
                <label className={labelClass}>Preis:</label><br/>
                <InputFieldWithClassName title={"Preis"} type={"text"}
                                         pattern="[0-9]+([\.,][0-9]+)?" step="0.01"
                                         value={preis}
                                         onChange={(e) => setPreis(e.target.value)}
                                         onBlur={() => savePreis(preis, waehrung.code)}
                />
            </div>

        <div className={"my-1 px-1 w-1/4 overflow-hidden"}>
                <WaehrungAutoSuggestField
                    initialItem={waehrung}
                    setValueObject={(suggestion) =>
                        updateWaehrung(suggestion)
                    }/>
            </div>
        <div className={"my-1 px-1 w-1/4 overflow-hidden"}>
            <label className={labelClass}>Frühbucherpreis:</label><br/>
            <InputFieldWithClassName
                title={"Frühbucher"} type={"text"}
                pattern="[0-9]+([\.,][0-9]+)?" step="0.01"
                value={fruehbucherPreis}
                onChange={onFruehChange}
                onBlur={() => {
                    saveFruehbucherPreis(fruehbucherPreis, waehrung.code, fruehGueltigBisTageVorKurs)
                }}
            />

        </div>
        <div className={"my-1 px-1 w-1/4 overflow-hidden"}>
            <label className={labelClass}>Frühbucherpreis bis Tage vor Kurs:</label><br/>
            <InputFieldWithClassName
                title={"Tage vor Kurs"}
                type={"number"}
                value={fruehGueltigBisTageVorKurs}
                onChange={onFruehBisChange}
                onBlur={() => saveFruehbucherPreis(fruehbucherPreis, waehrung.code, fruehGueltigBisTageVorKurs)}
            />

        </div>
    </div>


}