import React, {useState} from "react";
import {gql, useMutation, useQuery} from "@apollo/client";
import {Loading} from "../components";
import moment from "moment";
import {MUTATE_KURSDATUM_ENTFERNEN} from "../Queries";
import {Kurstermin_Kurstag_soll_entfernt_werden} from "../Daten/FlugplanMessages.gen";
import {KursDatumAnlegen, offsetZuDatum} from "./KursDatumAnlegen";


const GET_KURSTERMIN_DATEN = gql`query kurstermin($id: String){kurstermine(Id: $id){
    beginn{
        humanReadable,
        isoString},
    dauer,
    kontaktstunden,
    kurstage {
        beginn,
        ende,
        offset,
        Id,
    },
    Id
}}`

KursDatumAnlegen.propTypes = {};


export default function Kursdaten({id, onKursdatenChange}) {

    const [kursDatumAnlegenVisible, setKursDatumAnlegenVisible] = useState()

    const {loading, data, refetch} = useQuery(GET_KURSTERMIN_DATEN, {
        variables: {id}
    })

    const [entferneKursdatum,{}] = useMutation(MUTATE_KURSDATUM_ENTFERNEN, {
        onCompleted(data) {
            if (data[Kurstermin_Kurstag_soll_entfernt_werden.messageName] === "OK") {
                console.log("Kurstag entfernt")
            }
        }

    })




    if (loading) return <Loading />

    function onClickEntferneKursdatum(e, tag) {
        if (tag) {
            let message = new Kurstermin_Kurstag_soll_entfernt_werden(id,tag.Id)
            entferneKursdatum({variables: {message: JSON.stringify(message)}}).then(r => {
                console.log(r.data)
                refetch({id}).then(r => {
                    if (onKursdatenChange) {
                        onKursdatenChange(r.data?.kurstermine[0].kurstage)
                    }
                    console.log("Aktualisiert")
                })
            })
        }
    }

    function onCloseNeuesKursdatum(e) {
        setKursDatumAnlegenVisible(false)
        refetch({id}).then(r => {
            console.log(r.data)
            if (onKursdatenChange) {
                onKursdatenChange(r.data?.kurstermine[0].kurstage)
            }
        })
    }

    return <div>
        <h3>Kursdaten<button
            className={"border-dst-grau-1 pl-2 text-green text-lg font-bold"}
            onClick={() => setKursDatumAnlegenVisible(!kursDatumAnlegenVisible)}>⨁
        </button></h3>
        {kursDatumAnlegenVisible && <KursDatumAnlegen
            id={id}
            startDatum={data?.kurstermine[0].beginn?.isoString}
            kurstage={data?.kurstermine[0].kurstage}
            onClose={onCloseNeuesKursdatum}/>}
        <ul className={data?.kurstermine[0].kurstage?.length == data?.kurstermine[0].dauer ? "text-black" : "text-red" }>
        {data?.kurstermine[0]?.kurstage && data.kurstermine[0].kurstage.map(tag => {
                return <li>{offsetZuDatum(data.kurstermine[0].beginn.isoString,tag.offset).format("DD.MM")}: {tag.beginn} - {tag.ende}
                    <button className={"pl-2"} onClick={(e) => onClickEntferneKursdatum(e, tag)}>❌</button></li>
        })}

        </ul>

    </div>
}