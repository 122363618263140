import React, { useState } from "react"
import { ErrorMessage, InputField, Label, SubmitButton, TextareaField } from "./components"
import { Kursort_soll_angelegt_werden } from "./Daten/FlugplanMessages.gen";
import { Kursort_Adresse_soll_geaendert_werden, Kursort_Google_Maps_URL_soll_gesetzt_werden, Kursort_Name_soll_geaendert_werden } from "./Daten/FlugplanMessages.gen";

import { ModalContainer2 } from "./UI/ModalContainer";
import { RegionAutosuggestField } from "./AutoSuggestFields";
import { CREATE_LOCATION, GET_LOCATIONS_COMPLETE, GET_REGIONS_FOR_REGION_FIELD } from "./Queries";
import { gql, useApolloClient, useQuery, useMutation } from "@apollo/client";


export const KursortAnlegen2 = ({ kursortId, closeHandler }) => {
    const newEmptyKursort = { name: "", nameEn: "", vollstaendigeAdresse: "", region: {name: ""}, land: {name:""} };

    const [kursort, setKursort] = useState(newEmptyKursort);

    // field constants
    const Fields = Object.freeze({
        NAME: "name",
        NAMEEN: "nameEn",
        VOLLSTAENDIGEADRESSE: "vollstaendigeAdresse",
        REGION: "region",
        GOOGLEMAPSURL: "googleMapsUrl"
    })
    // store dirty fields here to save only changed fields
    const [dirtyFields, setDirty] = useState([]);
    // use direct access to ApolloClient in order to accellerate storage processes
    const client = useApolloClient();

    const [errorState, setErrorState] = useState(false);

    // load location data for a given ID
    const { loading } = useQuery(GET_LOCATIONS_COMPLETE, {
        fetchPolicy: "no-cache",
        variables: { Id: kursortId },
        onCompleted: data => {
            if (kursortId && data.kursorte.length === 1) {
                setKursort(data.kursorte[0]);
            } else {
                setKursort(newEmptyKursort);
            }
        }
    });


    const resetFormAndClose = (e) => {
        if (e) { e.preventDefault() }
        setKursort(newEmptyKursort);
        setDirty([]);
        closeHandler();
    }

    const [create,
        { loading: createLoading, error: createError },
    ] = useMutation(CREATE_LOCATION, {
        onCompleted: (data) => {
            if (data[Kursort_soll_angelegt_werden.messageName] === "ok") {
                resetFormAndClose();
            } else {
                alert("Fehler beim Anlegen eines Kursorts");
                setErrorState(true)
            }
        }
    });


    const saveLocation = async () => {
        const mutations = [];

        if (!kursortId) {
            // check required fields
            if (kursort.name && kursort.vollstaendigeAdresse && kursort.region && kursort.region.Id) {
                setErrorState(false);
                const message = new Kursort_soll_angelegt_werden(
                    kursort.name, kursort.vollstaendigeAdresse, kursort.region.Id
                );
                create({ variables: { message: JSON.stringify(message) } })
            } else {
                setErrorState(true);
            }
        } else {

            for (const field of dirtyFields) {
                let query;
                let message;
                switch (field) {
                    case Fields.NAME:
                        message = Kursort_Name_soll_geaendert_werden.messageName;
                        query = new Kursort_Name_soll_geaendert_werden(kursort.Id, "DE", kursort.name); break;
                    case Fields.NAMEEN:
                        message = Kursort_Name_soll_geaendert_werden.messageName;
                        query = new Kursort_Name_soll_geaendert_werden(kursort.Id, "EN", kursort.name); break;
                    case Fields.VOLLSTAENDIGEADRESSE:
                        message = Kursort_Adresse_soll_geaendert_werden.messageName;
                        query = new Kursort_Adresse_soll_geaendert_werden(kursort.Id, kursort.vollstaendigeAdresse); break;
                    case Fields.GOOGLEMAPSURL:
                        message = Kursort_Google_Maps_URL_soll_gesetzt_werden.messageName;
                        query = new Kursort_Google_Maps_URL_soll_gesetzt_werden(kursort.Id, kursort.googleMapsUrl); break;
                }
                const mutation = gql`mutation ${message}($message: String!){${message}(message: $message)}`
                const variables = { message: JSON.stringify(query) }
                mutations.push(client.mutate({ mutation, variables }))
            }
            Promise.all(mutations).then(
                () => { resetFormAndClose() }
            )
        }
    }

    const onChange = (e) => {
        setKursort({ ...kursort, [e.target.name]: e.target.value });
        if (!dirtyFields.includes(e.target.name)) {
            const dirtyCopy = [...dirtyFields]
            dirtyCopy.push(e.target.name)
            setDirty(dirtyCopy)
        }
    };

    // gets executed when the submit button is clicked
    const onSubmit = (e) => {
        e.preventDefault();
        saveLocation();
    }

    // call onClose on actions that could occur accidentally 
    const onClose = () => {
        if (dirtyFields.length === 0 || window.confirm("Wirklich verlassen? Geänderte Werte werden nicht gespeichert!")) {
            closeHandler();
        }
    }


    return (
        <ModalContainer2 title={"Kursort " + (kursortId ? "bearbeiten" : "anlegen")} onClose={onClose}>
            {errorState && <ErrorMessage customText="Beim Speichern ist ein Fehler aufgetreten." />}
            <div className="kursort-anlegen-container">
                <span></span>
                <form onSubmit={onSubmit} className="orteingabe">
                    <InputField name="name" type="text" title="Name" value={kursort.name}
                        onChange={onChange} />
                    {kursortId &&
                        <InputField name="nameEn" type="text" title="Name (Englisch)" value={kursort.nameEn}
                            onChange={onChange} />
                    }
                    <TextareaField name="vollstaendigeAdresse" title="Vollständige Adresse" placeholder="Straße Hausnummer&#10;PLZ Ort"
                        value={kursort.vollstaendigeAdresse} onChange={onChange} />
                    <Label name="region" title="Region" /><br />
                    {!kursortId ?
                        <RegionAutosuggestField
                            setValueObject={suggestion => setKursort({ ...kursort, region: suggestion })}
                        />
                        :
                        <p>{kursort && kursort.region && kursort.region.land && <span>{kursort.region.name}, {kursort.region.land.name}</span>}</p>
                    }
                    {kursortId &&
                        <InputField name="googleMapsUrl" type="url" title="Google Maps URL" value={kursort.googleMapsUrl}
                            onChange={onChange} placeholder="https://google.de/maps/place/..." />
                    }
                    {!(loading || createLoading) && <div><SubmitButton title={kursortId ? "Ändern" : "Anlegen"} />
                        <button className="btn btn-rot" onClick={resetFormAndClose}>Abbrechen</button></div>}
                </form>
            </div >
        </ModalContainer2>)
}