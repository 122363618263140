import React, {useState} from "react";
import {ModalContainer2} from "../UI/ModalContainer";
import {EditorField2, Label, SubmitButton} from "../components";
import {SingleDatePicker} from "react-dates";
import moment from "moment";
import {useMutation} from "@apollo/client";
import {MUTATE_KURSDATUM_HINZUFUEGEN} from "../Queries";
import {Kurstermin_Kurstag_soll_hinzugefuegt_werden} from "../Daten/FlugplanMessages.gen";

export function offsetZuDatum(beginn,offset) {
    try {
        let datum = moment(beginn)
        datum.add('days', offset)
        return datum
    } catch (e) {
        return moment()
    }

}


export function datumZuOffset(d1, d2) {
    try {
        let datumStart = moment(d1)
        let datumOffset = moment(d2)
        return datumOffset.diff(datumStart, 'days')
    } catch (e) {
        return 0
    }

}

export function KursDatumAnlegen({id, startDatum, kurstage, onClose}) {

    const [focused, setFocused] = useState()
    const [kurstag, setKurstag] = useState({
        datum: moment(startDatum),
        offset: 0,
        beginn: "09:00",
        ende: "16:00"
    })

    const [speichereKursdatum, {}] = useMutation(MUTATE_KURSDATUM_HINZUFUEGEN, {
        onCompleted: (r) => {
            console.log(r.data)
        }
    })

    function onSubmit(e) {
        e.preventDefault()
        const message = new Kurstermin_Kurstag_soll_hinzugefuegt_werden(id,kurstag.offset, kurstag.beginn, kurstag.ende)
        speichereKursdatum({variables: {message: JSON.stringify(message) }}).then(r => {
            console.log(r.data)
            onClose()
        })

    }


    function isValid() {
        return (kurstag?.offset >= 0) && (kurstag?.ende > kurstag?.beginn)
    }

    return <ModalContainer2 title={"Neues Kursdatum anlegen"} onClose={onClose}>
        <div className={"kursdatum-anlegen-container"}>
            <form onSubmit={(e) => onSubmit(e)}>
                <div className={"w-full inline-flex"}>
                    <div className={"w-1/2 "}>
                        <Label name="Kursdatum" title="Datum"/><br/>
                        <SingleDatePicker
                            date={kurstag.datum}
                            onDateChange={(d) => setKurstag({...kurstag, datum: d, offset: datumZuOffset(startDatum,d)})}
                            isOutsideRange={() => false}
                            focused={focused}
                            onFocusChange={({focused}) => setFocused(focused)}
                            id="Kurstermin_Datum"
                        /> <br/>
                        Offset: {datumZuOffset(startDatum, kurstag.datum)}
                    </div>
                    <div className={"w-1/2"}>
                        <Label name={"Kursdaten"} title={"Kursdaten"}/>
                        <ul>
                            {kurstage && kurstage.map(tag => {
                                return <li>{offsetZuDatum(startDatum,tag.offset).format("DD.MM")}: {tag.beginn} - {tag.ende}
                                    </li>
                            })}
                        </ul>
                    </div>
                </div>

                <div className={"ws-full inline-flex"}>

                    <EditorField2 title={"Beginn"} type={"time"}
                                  pattern="HH:mm"
                                  value={kurstag.beginn}
                                  onChange={(e) => setKurstag({...kurstag, beginn: e.target.value})}/>
                    <EditorField2 title={"Ende"} type={"time"}
                                  pattern="HH:mm"
                                  value={kurstag.ende}
                                  onChange={(e) => setKurstag({...kurstag, ende: e.target.value})}/>
                </div>

                <div className={"w-full"}>
                    <SubmitButton title={"Anlegen"} disabled={!isValid()}/>
                    <button className="btn btn-rot" onClick={onClose}>Abbrechen</button>
                </div>
            </form>
        </div>
    </ModalContainer2>
}