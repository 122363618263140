import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom";
import {FilterField2, GreenLink2, HeadingLine, Loading, StatusButtonLeiste2, GoogleMapsLink} from "./components"

import 'moment/locale/de'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates'
import moment from "moment"


import { GET_COURSES_FOR_OVERVIEW} from "./Queries";


import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { formattingByIndex, ifFieldNull, notImplemented } from "./Infrastruktur/Helpers";
import {NewsletterEintraege2} from "./NewsletterEintraege2";
import {MoodleKursAnlegen} from "./Kursdetails/MoodleKursAnlegen";
import {moodle_host} from "./index";




// setting current settings as URL parameters
const setHashParameters = (parameters, historyObject) => {
  if (parameters) {
    // get all parameters as array
    const urlParameters = Object.keys(parameters)
      .map(key => {
            // check for non-null
            if (parameters[key]) {
              // check for type and handle objects differently from strings and numbers
              if (typeof (parameters[key]) === "object") {

                const obj = parameters[key];

                if (obj.constructor) {
                  if (obj instanceof moment ) {
                    return `${encodeURIComponent(key)}=${encodeURIComponent(obj.format("YYYY-MM-DD"))}`;
                  } else {
                    return null
                  }
                }
               return `${encodeURIComponent(key)}=${encodeURIComponent(parameters[key])}`
              }
            }
        else { return null }
      })
      // remove empty values
      .filter(parameter => !!parameter)
      .join("&");
    historyObject.push("#" + urlParameters);
  }
}

const getHashParameters = (key, locationObject) => {
  const dateRegex = /\d{4}-\d{2}-\d{2}/
  const parameterObject = {}
  const locationString = locationObject.hash.substring(1);
  // split all parameters
  locationString.split("&")
    // split and decode pairs (map in map!)
    .map(value => value.split("=").map(halfValue => decodeURIComponent(halfValue)))
    // filter undefined and empty strings
    .filter(value => value[1] !== "undefined" && value[1] !== "" && value[1] !== undefined)
    // detect date values
    .map(value =>
      [value[0],
      value[1].match(dateRegex) ? moment(value[1], "YYYY-MM-DD") : value[1]
      ]
    )
    // store them on an object
    .forEach((value) => {
      parameterObject[value[0]] = value[1];
    })
  return parameterObject[key];
}

export const KursterminListe2 = () => {
  let location = useLocation();
  let history = useHistory();


  const [startDate, setStartDate] = useState(getHashParameters("startDate", location) || moment().weekday(0));
  const [endDate, setEndDate] = useState(getHashParameters("endDate", location) || null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [statusFilter, setStatusFilter] = useState(["Geplant", "Veröffentlicht", "Wenige Restplätze", "Ausverkauft", "Wird durchgeführt", "Durchgeführt"]);
  const [filterString, setFilterString] = useState(getHashParameters("filterString", location) || "");
  const [modal, setModal] = useState(false);


  useEffect(() => console.log(`"${filterString}"`), [filterString])

  useEffect(() => {
    setHashParameters({ filterString, startDate, endDate }, history);
  }, [filterString, startDate, endDate])



  const onFocusChange = newFocusedInput => setFocusedInput(newFocusedInput);

  const onDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate); setEndDate(endDate)
  }

  const onStatusButtonToggle = (buttonName) => {
    if (statusFilter.includes(buttonName)) {
      setStatusFilter(statusFilter.filter(status => status !== buttonName))
    } else {
      setStatusFilter(statusFilter.concat(buttonName))
    }
  }

  function createKurstermin() {
    history.push("/kurstermine/neu");
  }
  function Newsletter(){
      setModal(true)
  }
  const closeModal = () =>{
      setModal(false)

  }

  // links for navigation
  const links = [
    { label: "Neuer Kurstermin", func: () => { createKurstermin() } },
    { label: "Newsletter", func:()=>{ Newsletter()}},
    { label: "Alle Buchungen herunterladen", func: notImplemented },
    { label: "Alle Teilnehmer herunterladen", func: notImplemented },
  ]

  return (<div>
    <HeadingLine title="Kurstermine" icon="📆" links={links} />
      {modal && <NewsletterEintraege2 closeModal={closeModal}/>}

    <DateRangePicker
      // ids are picked at random
      startDateId="15ecf1c2a0912d3242b7a09ff27383e1"
      endDateId="65d184f81029cca20dfe7334ca5c6901"
      startDate={startDate}
      endDate={endDate}
      onDatesChange={onDatesChange}
      onFocusChange={onFocusChange}
      focusedInput={focusedInput}
      showClearDates={true} showDefaultInputIcon={true} block={true}
      numberOfMonths={3} isOutsideRange={() => false}
    />

    <StatusButtonLeiste2 active={statusFilter} onStatusButtonToggle={onStatusButtonToggle} />
    <FilterField2 filterString={filterString} defaultValue="" handleFilterChange={setFilterString} />
    <Liste2
      after={startDate ? startDate.format("YYYY-MM-DD") : null}
      before={endDate ? endDate.format("YYYY-MM-DD") : null}
      status={statusFilter}
      query={filterString}
    />
  </div>)
}

/**
 * Main list component
 */
const Liste2 = ({ after, before, status, query }) => {
  const { loading, error, data} = useQuery(GET_COURSES_FOR_OVERVIEW,

    { fetchPolicy: "cache-and-network",
      variables: { after, before, status }, pollInterval: 30000 }
  );

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  return (
    <table style={{ width: "100%" }}>
      <thead><tr>
        <th>Kurs</th>
        <th>Typ</th>
        <th>Modus</th>
        <th>Moodle</th>
        <th>Zeitpunkt</th>
        <th>Kursort</th>
        <th>Trainer</th>
        <th>Status</th>
        <th>Buchungen</th>


      </tr></thead>
      <tbody>
        {data.kurstermine
          // first the local filter query is applied
          .filter(kurstermin => {
            if (!query) { return true }
            // tokenize the search string
            const queryTokens = query.toLowerCase().split(" ");
            const tokenResults = queryTokens.map(token => kurstermin.kursCode.toLowerCase().indexOf(token) > -1 || // filter kursCode              
              kurstermin.titel.toLowerCase().indexOf(token) > -1 || // filter kursType
              // if "public" is in filter query, return all public trainings
              (((query.indexOf("public") > -1) || (query.indexOf("öffentlich") > -1)) && kurstermin.oeffentlich) ||
              // if "inhouse" is in filter query, return all inhouse trainings
              ((query.indexOf("inhouse") > -1) && !kurstermin.oeffentlich) ||
              // filter for trainer
              kurstermin.trainer.filter(
                trainer => {
                  return (trainer.text.toLowerCase().indexOf(token) > -1) ||
                    (trainer.cmscode.toLowerCase().indexOf(token) > -1)
                } // this is how "mentos" will work as a query
              ).length > 0 ||
              // filter all regions and locations
              ifFieldNull(kurstermin.location, "name", "Kein Ort").toLowerCase().indexOf(token) > -1 ||
              ifFieldNull(kurstermin.region, "name", "TBA").toLowerCase().indexOf(token) > -1)
            return tokenResults.every(value => value)
          })
          // now we sort them by code
          .sort((a, b) => (a.kursCode > b.kursCode) ? 1 : ((b.kursCode > a.kursCode) ? -1 : 0))
          .map((kurstermin, index) => (
            <KursterminEintrag2 key={kurstermin.Id} index={index} kurstermin={kurstermin} />
          ))}
      </tbody>
    </table>
  );
}

const KursterminEintrag2 = ({ index, kurstermin }) => {
  const kursLocation = `${ifFieldNull(kurstermin.location, "name", "Kein Ort")} (${ifFieldNull(kurstermin.region, "name", "Keine Region")})`;
  const googleMapsUrl = kurstermin?.location?.googleMapsUrl;
  const tnAnzahl = kurstermin.teilnehmer.count;
  const maxTnAnzahl = kurstermin.maxTeilnahmeZahl;

  function teilnahmeModus(text) {

    switch(text) {
      case "Online":
        return <span className={"text-2xl"}>💻</span>
      case "Mixed":
        return <span className={"text-xl"}>💻/👬</span>
      case "Offline":
      default:
        return <span className={"text-2xl"}>👬</span>
    }

  }

  return (
    <tr key={kurstermin.Id} className={`w-full border h-8 p-2 border-0 leading-normal ${formattingByStatus(kurstermin.status.text)} ${formattingByIndex(index)}`}>
      <TableLinkCell2 link={"/kurstermine/" + kurstermin.Id} label={kurstermin.kursCode} />
      <TableLinkCell2 link={"/kurstermine/" + kurstermin.Id} label={kurstermin.titel} />
      <td className={"text-center"}>{teilnahmeModus(kurstermin.teilnahmeModus.text)}</td>
      <td className={"text-2xl text-center"}>{kurstermin.moodleTermin ?<a className={"no-underline"} href={moodle_host+"/course/view.php?id="+kurstermin.moodleTermin}>Ⓜ️</a> : <MoodleKursAnlegen id={kurstermin.Id} /> }</td>
      <td>{kurstermin.beginn.humanReadable}<br />{kurstermin.dauer} Tage</td>
      <td><GoogleMapsLink teilnahmeModus={kurstermin.teilnahmeModus} kursLocation={kursLocation} googleMapsURL={googleMapsUrl}/> </td>
      <td>{kurstermin.trainer.map(trainer => <span style={{ display: "block" }}>{trainer.text}</span>)}</td>
      <td style={{ minWidth: "125px" }}>{kurstermin.status.text}<br />{kurstermin.teilnehmerKreis}{kurstermin.teilnehmerKreis === "Inhouse" && "🏡"}</td>
      <td style={{ minWidth: "115px" }}>
        <GreenLink2 link={`/kurstermine/${kurstermin.Id}/buchungen`} label={`${kurstermin.buchungen.count} Buchungen`} /><br />
        <GreenLink2 link={`/kurstermine/${kurstermin.Id}/teilnehmer`} label={`${kurstermin.teilnehmer.count} Teilnehmer`} />
      </td>
      <td><div style={{ height: 44, width: 44 }}><CircularProgressbar value={tnAnzahl / maxTnAnzahl * 100} text={`${tnAnzahl} / ${maxTnAnzahl}`} /></div></td>
    </tr>)
}

/**
 * Universal components
 */
const TableLinkCell2 = ({ label, link }) => {
  return <td><GreenLink2 label={label} link={link} /></td>
}



/**
 * Helper functions
 */


const formattingByStatus = (statusText) => {
  switch (statusText) {
    case "Abgesagt": return "text-grey";
    case "Geplant": return "text-blue-dark"
    case "Wenige Restplätze": return "text-green-dark"
    default: return ""
  }
}
