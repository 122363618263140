import * as React from "react"
import { Badge } from "./Badge";

export class ModalContainer extends React.Component {
    onKeyUp(event) {
        if (event.key === 'Escape') {
            this.props.close();
            event.preventDefault();
        }
    }

    render() {
        return <div className="fixed pin z-50 overflow-auto bg-smoke-light flex justify-center w-full h-full"
            onClick={() => this.props.close()} onKeyUp={() => this.onKeyUp()}>
            <div className="relative flex flex-col border-dst-logo-gruen-mitte border-4 bg-white rounded-lg self-center p-4 lg:max-w-3/4"
                style={{ minWidth: "50vw", minHeight: "50vh" }} onClick={(e) => { e.stopPropagation(); }}
                onKeyUp={(e) => { e.stopPropagation() }}>
                <div className="self-end absolute pin-t pin-r mr-neg-25 mt-neg-15">
                    <button onClick={() => { this.props.close(); }}><Badge>X</Badge></button>
                </div>
                <div className="flex flex-row justify-between">
                    <h2 className="heading">{this.props.title}</h2>
                </div>
                {this.props.children}
            </div>
        </div>;
    }
}

export const ModalContainer2 = ({ children, onClose, title }) => {
    const closeOnEsc = (e) => {
        if (e.key === 'Escape') { e.preventDefault(); onClose() }
    }

    return (<div className="fixed pin z-50 overflow-auto bg-smoke-light flex justify-center w-full h-full"
        onClick={() => onClose()} onKeyUp={() => closeOnEsc()}>
        <div className="relative flex flex-col border-dst-logo-gruen-mitte border-4 bg-white rounded-lg self-center p-4 lg:max-w-3/4"
            style={{ minWidth: "50vw", minHeight: "50vh" }} onClick={(e) => { e.stopPropagation(); }}
            onKeyUp={(e) => { e.stopPropagation() }}>
            <div className="self-end absolute pin-t pin-r mr-neg-25 mt-neg-15">
                <button onClick={() => { onClose(); }}><Badge>X</Badge></button>
            </div>
            <div className="flex flex-row justify-between">
                <h2 className="heading">{title}</h2>
            </div>
            {children}
        </div>
    </div>)
}